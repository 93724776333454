import axios from 'axios'

const normalAxios = axios.create({
    timeout: 10 * 60 * 1000, // 10 minutes
})

normalAxios.interceptors.request.use((config) => {
    return config
})

normalAxios.interceptors.response.use(
    (res) => {
        return res?.data
    },
    (error) => {
        if (axios?.isCancel(error)) return Promise.reject({...error, isCancel: true})
        else return Promise.reject(error?.response)
    }
)

export default normalAxios
