export const getAppVersionSelector = (state) => state?.setting?.appVersion
export const getBitbucketBranchesSelector = (state) => state?.setting?.bb_branches
export const getCompanyTypeSelector = (state) => state?.setting?.companyProfile?.company_type
export const getCompanyProfileSelector = (state) => state?.setting?.companyProfile
export const getCompanySettingSelector = (state) => state?.setting?.companySetting ?? null
export const getTimeZoneDataSelector = (state) => state?.setting?.timeZoneData ?? null
export const getCompanyOverrideSettingSelector = (state) =>
    state?.setting?.companyOverrideSetting ?? null
export const getCompanyUpfrontSettingSelector = (state) =>
    state?.setting?.companyUpfrontSetting ?? null
export const getOverdueBillingDataSelector = (state) => state?.setting?.overdueBillingData ?? null
export const getCompanyCustomFieldSettingSelector = (state) =>
    state?.setting?.companyCustomFieldSetting ?? []
export const getCompanyAdvancePaymentSettingSelector = (state) =>
    state?.setting?.companyAdvancePaymentSetting ?? []
export const getUserPositionDataSelector = (state) => state?.setting?.userPositionData ?? null
export const getPositionSettingSelector = (state) => state?.setting?.positionSetting ?? null
export const getPayFrequencyTypeSelector = (state) => state?.setting?.payFrequencyType ?? []
export const getPayFrequencySettingSelector = (state) => state?.setting?.payFrequencySetting ?? []
export const getWeeklyPayPeriodSelector = (state) => state?.setting?.weeklyPayPeriod ?? []
export const getWeeklyExecutedPayPeriodSelector = (state) =>
    state?.setting?.weeklyExecutedPayPeriod ?? []
export const getMonthlyPayPeriodSelector = (state) => state?.setting?.monthlyPayPeriod ?? []
export const getMonthlyExecutedPayPeriodSelector = (state) =>
    state?.setting?.monthlyExecutedPayPeriod ?? []
export const getBiWeeklyPayPeriodSelector = (state) => state?.setting?.biWeeklyPayPeriod ?? []
export const getBiWeeklyExecutedPayPeriodSelector = (state) =>
    state?.setting?.biWeeklyExecutedPayPeriod ?? []
export const getSemiMonthlyPayPeriodSelector = (state) => state?.setting?.semiMonthlyPayPeriod ?? []
export const getSemiMonthlyExecutedPayPeriodSelector = (state) =>
    state?.setting?.semiMonthlyExecutedPayPeriod ?? []

export const getAllNotificationSelector = (state) => state?.setting?.allNotification ?? []
export const getAllReportingManagerListSelector = (state) =>
    state?.setting?.allReportingManager ?? []
export const getNotificationSelector = (state) => state?.setting?.notificationData ?? {}
export const getSingleNotificationSelector = (state) => state?.setting?.singleNotification ?? {}
export const getAllStatesAndCitiesSelector = (state) => state?.setting?.state_city ?? []
export const getUsedStateSelector = (state) => state?.setting?.usedState ?? []
export const getDocumentTypessSelector = (state) => state?.setting?.documentTypes ?? []
export const getCostCenterListSelector = (state) => state?.setting?.costCenterList ?? []
export const getReconciliationScheduleSelector = (state) =>
    state?.setting?.reconciliationSchedule ?? []
export const getReconStartDataSelector = (state) => state?.setting?.reconStartData ?? {}
export const getPositionsSelector = (state) => state?.setting?.positions ?? []
export const getDepartmentsSelector = (state) => state?.setting?.departments ?? []
export const getAllLocationsSelector = (state) => state?.setting?.allLocations ?? []
export const getCostTrackingSelector = (state) => state?.setting?.costTracking ?? []
export const getParentCostCenterSelector = (state) => state?.setting?.parentCostCenterList ?? []
export const getParentChildCostCenterSelector = (state) =>
    state?.setting?.parentChildCostCenterList ?? []
export const getAllManagersSelector = (state) => state?.setting?.managers ?? []
export const getOnBoardingConfiguration = (state) => state?.setting?.onBoardingConfigurations ?? []
export const positionMainRolesSelector = (state) => state?.setting?.positionMainRoles ?? []
export const getAllClosersSelector = (state) => state?.setting?.closers ?? []
export const getAllSettersSelector = (state) => state?.setting?.setters ?? []
export const getDepartmentWithPositionSelector = (state) =>
    state?.setting?.departmentWithPosition ?? []
export const geyAllStatesWithOfficesSelector = (state) => state?.setting?.allStatesWithOffices ?? []
export const getReconPositionListSelector = (state) => state?.setting?.positionList ?? []
export const getReconOfficeListSelector = (state) => state?.setting?.officeList ?? []
export const getSalesCRMPreferenceSelector = (state) => state?.setting?.salesCRMPreference ?? []
export const getBirthdayWishesSelector = (state) => state?.setting?.birthdayWish ?? []
