import React, {useEffect, useState, useCallback, useMemo} from 'react'
import {getAnnouncementSelector} from '../../../../../../redux/selectors/DashboardSelectors'
import {useDispatch} from 'react-redux'
import {getannouncementDataAction} from '../../../../../../redux/actions/DashboardActions'
import {useSelector} from 'react-redux'
import CustomLoader from '../../../../../../customComponents/customLoader/CustomLoader'
import {getUserDataSelector} from '../../../../../../redux/selectors/AuthSelectors'
import {Link} from 'react-router-dom'
import ManageAnnouncementModal from '../../../../common/manageAnnouncement/manageAnnouncementModal'
import AddNewAnnouncementModal from '../../../../common/manageAnnouncement/addNewAnnouncementModal'
import CustomButton, {
    BUTTON_SIZE,
    BUTTON_TYPE,
} from '../../../../../../customComponents/customButtton/CustomButton'
import CustomNoData from '../../../../../../customComponents/customNoData/CustomNoData'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {fontsFamily} from '../../../../../../assets/fonts/fonts'
import CustomLink, {LINK_SIZE} from '../../../../../../customComponents/customButtton/CustomLink'
import CustomEllipsis from '../../../../../../customComponents/customEllipsis/CustomEllipsis'
import CustomIterator from '../../../../../../customComponents/customIterator/CustomIterator'
import CustomSkeleton from '../../../../../../customComponents/customSkeleton/CustomSkeleton'
import AccessRights from '../../../../../../accessRights/AccessRights'
import {allPermissionsAccess} from '../../../../../../accessRights/useCustomAccessRights'

const AnnouncementsCard = () => {
    const announcementData = useSelector(getAnnouncementSelector)
    const loggedUser = useSelector(getUserDataSelector)
    const [loading, setLoading] = useState(false)
    const [fullLoading, setFullLoading] = useState(false)
    const [showManageAnnouncementModal, setShowManageAnnouncementModal] = useState(false)
    const [showEditAnnouncementModal, setShowEditAnnouncementModal] = useState(false)
    const [editAnnouncementData, setEditAnnouncementData] = useState(false)

    const dispatch = useDispatch()
    useEffect(() => {
        getAnnouncementData()
    }, [])

    const getAnnouncementData = useCallback(() => {
        if (announcementData) setLoading(true)
        else setFullLoading(true)
        const body = {
            id: loggedUser?.id,
        }
        dispatch(getannouncementDataAction(body)).finally(() => {
            if (announcementData) setLoading(false)
            else setFullLoading(false)
        })
    }, [dispatch])

    const onPressEditModal = (item) => {
        setShowEditAnnouncementModal(true)
        setEditAnnouncementData(item)
    }

    const announcemenFilterData = useMemo(() => {
        return announcementData?.data
            ?.filter?.(
                (filterItem) => filterItem?.status == 'Live' || filterItem?.status == 'Upcoming'
            )
            ?.sort((a, b) => b.pin_to_top - a.pin_to_top)
    }, [announcementData])

    return (
        <div
            className='mb-10 h-550px bg-cmwhite shadow-sm text-cmGrey900'
            style={{
                overflowY: 'auto',
                overflowX: 'hidden',
                fontSize: 14,
                fontWeight: 600,
                fontFamily: fontsFamily.manrope,
                position: 'relative',
                borderRadius: 10,
            }}
        >
            {/* <CustomLoader visible={fullLoading} full /> */}
            {/* Heading */}
            <div className='d-flex align-items-center flex-wrap justify-content-between p-5'>
                <div className='' style={{fontSize: 16, fontWeight: 700, position: 'relative'}}>
                    Announcements(
                    {announcemenFilterData?.length ?? '0'})
                </div>

                <div>
                    <CustomLink
                        id={'admin_dashboard_announcements_manage_button'}
                        label={'Manage'}
                        linkSize={LINK_SIZE.large}
                        onClick={() => setShowManageAnnouncementModal(true)}
                    />
                </div>
            </div>
            {/* line 1 */}
            {loading || fullLoading ? (
                <ContentLoading />
            ) : announcemenFilterData?.length > 0 ? (
                <>
                    {announcemenFilterData?.map((item, index) => (
                        <div className='p-0' key={item?.id}>
                            <div className='d-flex align-items-center justify-content-between gap-5 py-3 ms-10 me-5'>
                                <div
                                    style={{fontSize: 15, fontWeight: 700, lineHeight: '21px'}}
                                    className='text-cmGrey800 mb-1'
                                >
                                    {item?.title}
                                </div>

                                <span className=''>
                                    <AccessRights
                                        customCondition={
                                            allPermissionsAccess.administrator.dashboard.dashboard
                                                .edit
                                        }
                                    >
                                        <KTSVG
                                            path='/media/icons/duotune/art/EditIcon2.svg'
                                            className='cursor-pointer'
                                            svgClassName='w-25px h-25px p-0'
                                            id={`admin_dashboard_announcements_${index}`}
                                            onClick={() => onPressEditModal(item)}
                                        />
                                    </AccessRights>
                                </span>
                            </div>
                            <hr className='pt-2 p-0 m-0' />
                        </div>
                    ))}
                </>
            ) : (
                <CustomNoData label={'No Announcements'} className={'mt-5'} />
            )}

            {showManageAnnouncementModal ? (
                <ManageAnnouncementModal
                    show={showManageAnnouncementModal}
                    handleClose={() => {
                        setShowManageAnnouncementModal(false)
                        getAnnouncementData()
                    }}
                    announcementData={announcementData?.data}
                />
            ) : null}
            {showEditAnnouncementModal ? (
                <AddNewAnnouncementModal
                    item={editAnnouncementData}
                    show={showEditAnnouncementModal}
                    onClose={() => {
                        setShowEditAnnouncementModal(false)
                        getAnnouncementData()
                    }}
                />
            ) : null}
        </div>
    )
}

export default AnnouncementsCard

const ContentLoading = () => {
    return (
        <CustomIterator count={6}>
            <div className='d-flex  justify-content-between mb-5 mx-5'>
                <div className='d-flex flex-column'>
                    <CustomSkeleton className={'h-15px w-150px'} />
                    <CustomSkeleton className={'h-15px w-75px'} />
                </div>
                <div>
                    <CustomSkeleton className={'h-25px w-25px'} />
                </div>
            </div>
        </CustomIterator>
    )
}
