import {useMemo} from 'react'
import Chart from 'react-apexcharts'
import {formattedNumberFields} from '../../../../../../helpers/CommonHelpers'
import useSequifiConfig from '../../../../../../sequiConfig/useSequifiConfig'
import Show from '../../../../../../customComponents/show/Show'
import {fontsFamily} from '../../../../../../assets/fonts/fonts'
import './ProgressChartStyle.css'

const YourProcessChart = ({achievedGoal, targetGoal}) => {
    const {basedOnCompanyType} = useSequifiConfig()
    const isTargetGoalAvailable = useMemo(() => {
        let hasValueGreaterThanZero = false

        for (let key in targetGoal) {
            if (targetGoal.hasOwnProperty(key) && targetGoal[key] > 0) {
                hasValueGreaterThanZero = true
            }
        }
        return hasValueGreaterThanZero
    }, [targetGoal])

    const overAllpercantage = useMemo(() => {
        let overallPercentage = 0,
            percentageEarning = 0,
            percentageAccount = 0,
            percentageKwSold = 0
        if (isTargetGoalAvailable) {
            percentageEarning =
                (100 *
                    (Number(achievedGoal?.get_earning) > Number(targetGoal?.earning)
                        ? parseFloat(targetGoal?.earning)
                        : parseFloat(achievedGoal?.get_earning))) /
                parseFloat(targetGoal?.earning)

            percentageAccount =
                (100 *
                    (Number(achievedGoal?.get_account) > Number(targetGoal?.account)
                        ? parseFloat(targetGoal?.account)
                        : parseFloat(achievedGoal?.get_account))) /
                parseFloat(targetGoal?.account)

            percentageKwSold =
                (100 *
                    (Number(achievedGoal?.get_kw_sold) > Number(targetGoal?.kw_sold)
                        ? parseFloat(targetGoal?.kw_sold)
                        : parseFloat(achievedGoal?.get_kw_sold))) /
                parseFloat(targetGoal?.kw_sold)

            overallPercentage =
                (((percentageEarning ?? 0) + (percentageAccount ?? 0) + (percentageKwSold ?? 0)) *
                    100) /
                300
        }

        // return Math.min(overallPercentage)
        return {
            percentageEarning,
            percentageAccount,
            percentageKwSold,
            overallPercentage,
        }
    }, [
        achievedGoal?.get_account,
        achievedGoal?.get_earning,
        achievedGoal?.get_kw_sold,
        isTargetGoalAvailable,
        targetGoal?.account,
        targetGoal?.earning,
        targetGoal?.kw_sold,
    ])

    const chartData = {
        series: isTargetGoalAvailable
            ? [
                  overAllpercantage?.percentageEarning ?? 0,
                  overAllpercantage?.percentageAccount ?? 0,
                  overAllpercantage?.percentageKwSold ?? 0,
              ]
            : [0, 0, 0],
        // series: [50, 70, 30],
        options: {
            chart: {
                type: 'radialBar',
            },
            colors: ['#12B76A', '#6078EC', '#FFB03A'],
            plotOptions: {
                radialBar: {
                    dataLabels: {
                        value: {
                            fontSize: '22px',
                            show: false,
                        },
                        name: {
                            show: true,
                        },

                        total: {
                            show: true,
                            label: formattedNumberFields(overAllpercantage?.overallPercentage, '%'),
                            fontSize: '20px',
                            fontWeight: 700,
                            fontFamily: 'Manrope',
                        },
                    },
                },
            },

            stroke: {
                lineCap: 'round',
            },
            labels: [
                'Earnings',
                'Accounts',
                basedOnCompanyType.isCompanyTypePest
                    ? 'Revenue'
                    : `${basedOnCompanyType?.primaryUnitType} Sold`,
            ],
        },
    }
    return (
        <div className='text-center'>
            <div className='text-md-bold text-sq-Gray900 '>Your Progress</div>
            <div className='p-0 m-0 mx-auto d-flex flex-center'>
                <Chart
                    options={chartData.options}
                    series={chartData.series}
                    type='radialBar'
                    height={225}
                    style={{marginTop: -8}}
                />
            </div>

            <div className='d-flex justify-content-center gap-10' style={{marginTop: -8}}>
                <div className='text-start'>
                    <div className='h-5px w-100 bg-sqSuccess500 rounded mb-1' />
                    <div className='text-xs-semibold text-sqGray700'>Earnings</div>
                    <div className='text-sqGray900 text-sm-bold'>
                        {formattedNumberFields(achievedGoal?.get_earning, '$')}
                    </div>
                </div>
                <div className=' text-start'>
                    <div className='h-5px w-100 bg-sqPrimary600 rounded mb-1' />
                    <div className='text-xs-semibold text-sqGray700'>Accounts</div>
                    <div className='text-sqGray900 text-sm-bold'>
                        {formattedNumberFields(achievedGoal?.get_account, '$')}
                    </div>
                </div>
                <div className=' text-start'>
                    <div className='h-5px w-100 bg-cmOrange rounded mb-1' />
                    <Show>
                        <Show.When isVisible={basedOnCompanyType.isCompanyTypePest}>
                            <div className='text-xs-semibold text-sqGray700'>Revenue</div>
                        </Show.When>
                        <Show.Else>
                            <div className='text-xs-semibold text-sqGray700'>
                                {basedOnCompanyType?.primaryUnitType} Sold
                            </div>
                        </Show.Else>
                    </Show>
                    <div className='text-sqGray900 text-sm-bold'>
                        {formattedNumberFields(achievedGoal?.get_kw_sold, '$')}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default YourProcessChart
