import {useCallback, useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import CustomInput, {
    INPUT_TYPE,
} from '../../../../customComponents/customInputs/customInput/CustomInput'
import {fontsFamily} from '../../../../assets/fonts/fonts'
import {useNavigate, useParams} from 'react-router-dom'
import {validatePasswordForLoginService} from '../../../../services/Services'
import CustomToast from '../../../../customComponents/customToast/CustomToast'
import {getErrorMessageFromResponse} from '../../../../helpers/CommonHelpers'
import CustomLoader from '../../../../customComponents/customLoader/CustomLoader'

const SequiDocLogin = () => {
    const [loginFailed, setLoginFailed] = useState(false)
    const [loader, setLoader] = useState('')
    const navigate = useNavigate()

    const params = useParams()

    useEffect(() => {
        let uniquePassword = params?.password ?? null
        if (uniquePassword) validateLoginLink(uniquePassword)
    }, [params?.password])

    const validateLoginLink = useCallback(
        (uniquePassword) => {
            setLoader(true)
            setLoginFailed(false)
            const body = {
                plain_password: uniquePassword,
            }
            validatePasswordForLoginService(body)
                .then((res) => {
                    navigate('/document-signing/home', {
                        state: {
                            envelopId: res?.data?.envelope_id,
                            envelopData: res?.data,
                            isAuthenticated: 1,
                        },
                    })
                })
                .catch((err) => {
                    CustomToast.error(getErrorMessageFromResponse(err))
                    setLoginFailed(true)
                })
                .finally(() => setLoader(false))
        },
        [navigate]
    )

    return (
        <div className='h-100 d-flex flex-center' style={{fontFamily: fontsFamily.manrope}}>
            <div
                className='w-sm-30 w-75 mt-10 d-flex flex-column flex-center gap-5'
                style={{position: 'relative'}}
            >
                <div>
                    <KTSVG
                        path='/media/icons/duotune/art/lock-grey.svg'
                        svgClassName='w-60px'
                        svgStyle={{width: '60px', height: '60px'}}
                    />
                </div>
                <CustomLoader size={100} visible={loader} />

                <div className='text-cmGrey800 text-center' style={{fontWeight: 600, fontSize: 18}}>
                    {loginFailed ? (
                        <div className='mb-1'>
                            <span
                                className='bi bi-exclamation-diamond text-cmError me-2'
                                style={{size: '12px'}}
                            ></span>{' '}
                            Oops! Somethimng went wrong with your link.Check your link and try
                            again.
                        </div>
                    ) : (
                        <div className='mb-1'>Please wait while we validate...</div>
                    )}
                </div>

                {/* <div className='w-80 text-center'>
                    <form action='' onSubmit={(e) => handleSubmit(e)}>
                        <CustomInput
                            type={INPUT_TYPE.password}
                            feedback={false}
                            placeholder='Password'
                            inputBackground='cmGrey800'
                            value={password}
                            textColor={'cmGrey400'}
                            onChange={handleInputChange}
                        />
                    </form>
                </div> */}
                <div>
                    {/* <CustomLottie lottieJson={LoaderBar} width={100} /> */}
                    {/* <CustomLoader size={60} visible={loader} /> */}
                </div>
            </div>
        </div>
    )
}

export default SequiDocLogin
