import React from 'react'
import CustomImage from '../../../../customComponents/customImage/CustomImage'
import {KTSVG} from '../../../../_metronic/helpers'
import CustomDownloadIcon from '../../../../customComponents/customIcons/CustomDownloadIcon'

const OfferUnderReview = () => {
    return (
        <div
            className='bg-cmwhite mt-20 w-750px mx-auto shadow-sm p-10 text-center'
            style={{borderRadius: 24, fontFamily: 14}}
        >
            <div className='w-80 mx-auto'>
                <div className=' mb-10'>
                    <div className='mx-auto d-flex flex-center mb-5'>
                        <CustomImage className={'w-50px h-50px'} />
                    </div>
                    <div
                        className='text-cmGrey900'
                        style={{fontSize: 16, fontWeight: 700, lineHeight: '24px'}}
                    >
                        Congratulations, Leslie Alexander
                    </div>
                </div>
                <div className='d-flex flex-center gap-2 mb-5'>
                    <KTSVG
                        path='/media/icons/duotune/art/DoneCheck.svg'
                        svgClassName='w-50px h-50px'
                        svgStyle={{width: 100, height: 100}}
                    />
                    <div
                        className='text-cmGrey900'
                        style={{fontSize: 22, fontWeight: 700, lineHeight: '30px'}}
                    >
                        Your Offer is under Review
                    </div>
                </div>
                <div
                    className='text-cmGrey700 mb-10'
                    style={{fontSize: 14, fontWeight: 500, lineHeight: '24px'}}
                >
                    All mandatory documents have been signed and successfully submitted. <br /> Our
                    representative will contact you shortly. <br />
                    You can now close this page.
                </div>
                <div
                    className='py-8 text-center mb-10'
                    style={{borderRadius: 10, backgroundColor: '#F7F7F7'}}
                >
                    <div className='px-15 mx-auto'>
                        {/* For particular offer letter */}
                        <div className='d-flex align-items-center justify-content-between gap-3 flew-wrap'>
                            <div className='d-flex flex-center gap-5'>
                                <div className=''>
                                    <KTSVG
                                        path='/media/icons/duotune/art/OfferLetter.svg'
                                        svgClassName='h-50px w-50px cursor-pointer'
                                    />
                                </div>
                                <div className='text-start'>
                                    <div
                                        className='text-cmGrey900 '
                                        style={{
                                            fontSize: '18px',
                                            fontWeight: 700,
                                            lineHeight: '24px',
                                        }}
                                    >
                                        Offer Letter
                                    </div>
                                    <div
                                        className='text-cmGrey700 text-decoration-underline'
                                        style={{fontSize: 14, fontWeight: 600}}
                                    >
                                        OfferLetter_setter.pdf
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex flex-center gap-10'>
                                <div
                                    className='text-cmgreen'
                                    style={{fontWeight: 700, fontSize: 14, lineHeight: '9.3px'}}
                                >
                                    <i class='fa-regular text-cmgreen fa-circle-check me-1'></i>
                                    <span>Accepted</span>
                                </div>
                                <div>
                                    <CustomDownloadIcon />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ADDITIONAL DOCUMENTS */}
                <div className='mb-10'>
                    {/* header */}
                    <div className='d-flex justify-content-between align-itmes-center'>
                        <div
                            className='text-cmGrey500'
                            style={{fontSize: 12, fontWeight: 600, lineHeight: '16.3px'}}
                        >
                            ADDITIONAL DOCUMENTS (3)
                        </div>
                        <div
                            className='text-cmError'
                            style={{fontSize: 10, fontWeight: 600, lineHeight: '17px'}}
                        >
                            2 Remaining!
                        </div>
                    </div>
                    {/* body */}
                    <div className='mt-5'>
                        <div
                            className='d-flex align-items-center gap-5 py-4 ps-sm-5 pe-sm-20 ps-5 pe-10 flex-wrap justify-content-between'
                            style={{
                                fontSize: 14,
                                fontWeight: 600,
                                backgroundColor: '#F7F7F7',
                                borderRadius: 10,
                            }}
                        >
                            <div className='d-flex gap-1 align-items-center'>
                                <span class='fa-regular text-cmgreen fa-circle-check me-1 fs-2'></span>
                                <span>W9 form</span>
                            </div>
                            <div className='d-flex flex-center gap-10'>
                                <div
                                    className='text-cmGrey700 text-decoration-underline'
                                    style={{fontWeight: 600, fontSize: 12, lineHeight: '24px'}}
                                >
                                    W9_tax_form.pdf
                                </div>
                                <div>
                                    <CustomDownloadIcon />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* DOCUMENTS TO UPLOAD (1)*/}
                <div>
                    {/* header */}
                    <div className='d-flex justify-content-between align-itmes-center'>
                        <div
                            className='text-cmGrey500'
                            style={{fontSize: 12, fontWeight: 600, lineHeight: '16.3px'}}
                        >
                            DOCUMENTS TO UPLOAD (1)
                        </div>
                        <div
                            className='text-cmError'
                            style={{fontSize: 10, fontWeight: 600, lineHeight: '17px'}}
                        >
                            2 Remaining!
                        </div>
                    </div>
                    {/* body */}
                    <div className='mt-5'>
                        <div
                            className='d-flex align-items-center gap-5 py-4 ps-sm-5 pe-sm-20 ps-5 pe-10 flex-wrap justify-content-between'
                            style={{
                                fontSize: 14,
                                fontWeight: 600,
                                backgroundColor: '#F7F7F7',
                                borderRadius: 10,
                            }}
                        >
                            <div className='d-flex gap-1 align-items-center'>
                                <span class='fa-regular text-cmgreen fa-circle-check me-1 fs-2'></span>
                                <span>W9 form</span>
                            </div>
                            <div className='d-flex flex-center gap-10'>
                                <div
                                    className='text-cmGrey700 text-decoration-underline'
                                    style={{fontWeight: 600, fontSize: 12, lineHeight: '24px'}}
                                >
                                    W9_tax_form.pdf
                                </div>
                                <div>
                                    <CustomDownloadIcon />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OfferUnderReview
