export const mergeElementIdWithUnderscore = (element, key) => {
    return `${element}_${key}`
}

export const ElementsID = {
    administrator: {
        dashboard: {
            payroll_report_button: 'payroll_report_button',
        },
        setting: {
            payroll_report_button: 'payroll_report_button',
            positions: {
                commission_structure: {
                    view: 'administrator_setting_positon_commission_structure_view',
                    edit: '',
                },
            },
        },
    },
    standard: {
        dashboard: {
            payroll_report_button: 'payroll_report_button',
        },
        management: {
            employee: {
                employement_package: {
                    organization: {
                        view_changes:
                            'standard_employee_employement_package_organization_view_changes',
                        edit: 'standard_employee_employement_package_organization_edit',
                        cancel: 'standard_employee_employement_package_organization_cancel',
                        save: 'standard_employee_employement_package_organization_save',
                        office_state:
                            'standard_employee_employement_package_organization_office_state',
                        office_name:
                            'standard_employee_employement_package_organization_office_name',
                        department: 'standard_employee_employement_package_organization_department',
                        department_checkbox:
                            'standard_employee_employement_package_organization_department_checkbox',
                        position: 'standard_employee_employement_package_organization_position',
                        position_checkbox:
                            'standard_employee_employement_package_organization_position_checkbox',
                        position_link:
                            'standard_employee_employement_package_organization_position_link',
                        manager: 'standard_employee_employement_package_organization_manager',
                        team: 'standard_employee_employement_package_organization_team',
                        add_location:
                            'standard_employee_employement_package_organization_add_location',
                        add_location_office:
                            'standard_employee_employement_package_organization_add_location_office',
                        add_location_state:
                            'standard_employee_employement_package_organization_team_add_location_state',
                        delete_location:
                            'standard_employee_employement_package_organization_team_delete_location',
                        recruiter_press:
                            'standard_employee_employement_package_organization_recruiter_press',
                        recruiter_search:
                            'standard_employee_employement_package_organization_recruiter_search',
                        recruiter_item_click:
                            'standard_employee_employement_package_organization_recruiter_item_click',
                    },
                    redline_commission_upfront: {
                        closer_comission_view_changes:
                            'standard_employee_employement_package_redline_commission_upfront_closer_commission_view_changes',
                        edit: 'standard_employee_employement_package_redline_commission_upfront_edit',
                        cancel: 'standard_employee_employement_package_redline_commission_upfront_cancel',
                        save: 'standard_employee_employement_package_redline_commission_upfront_save',
                        closer_comission_with_effective_date_input:
                            'standard_employee_employement_package_redline_commission_upfront_comission_with_effective_date_input',
                        closer_comission_with_effective_datepicker:
                            'standard_employee_employement_package_redline_commission_upfront_comission_with_effective_datepicker',
                        closer_redline_type:
                            'standard_employee_employement_package_redline_commission_upfront_redline_type',
                        closer_redline_input:
                            'standard_employee_employement_package_redline_commission_upfront_redline_input',
                        closer_redline_amount_input:
                            'standard_employee_employement_package_redline_commission_upfront_redline_amount_input',
                        closer_redline_per_amount_type_dropdown:
                            'standard_employee_employement_package_redline_commission_upfront_redline_per_amount_type_dropdown',
                        closer_redline_effective_date:
                            'standard_employee_employement_package_redline_commission_upfront_redline_effective_date',
                        closer_upfront_pay:
                            'standard_employee_employement_package_redline_commission_upfront_upfront_pay',
                        closer_upfront_pay_type:
                            'standard_employee_employement_package_redline_commission_upfront_upfront_pay_type',
                        closer_upfront_pay_effective_date:
                            'standard_employee_employement_package_redline_commission_upfront_upfront_pay_effective_date',
                        closer_withheld_amount:
                            'standard_employee_employement_package_redline_commission_upfront_withheld_amount',
                        closer_withheld_amount_type:
                            'standard_employee_employement_package_redline_commission_upfront_withheld_amount_type',
                        closer_withheld_effective_date:
                            'standard_employee_employement_package_redline_commission_upfront_withheld_effective_date',
                        self_generation_commission_effective_date_input:
                            'standard_employee_employement_package_redline_commission_upfront_self_generation_commission_effective_date_input',
                        self_generation_commission_effective_date_dropdown:
                            'standard_employee_employement_package_redline_commission_upfront_self_generation_commission_effective_date_dropdown',
                    },
                    override: {
                        override_view_changes:
                            'standard_employee_employement_package_override_view_changes',
                        view_manual_override:
                            'standard_employee_employement_package_override_view_manual_override',
                        edit: 'standard_employee_employement_package_override_edit',
                        cancel: 'standard_employee_employement_package_override_cancel',
                        save: 'standard_employee_employement_package_override_save',
                        effective_date:
                            'standard_employee_employement_package_override_effective_date',
                        direct_override:
                            'standard_employee_employement_package_override_direct_override',
                        direct_override_type:
                            'standard_employee_employement_package_override_direct_override_type',
                        indirect_override:
                            'standard_employee_employement_package_override_indirect_override',
                        indirect_override_type:
                            'standard_employee_employement_package_override_indirect_override_type',
                        office_override:
                            'standard_employee_employement_package_override_office_override',
                        office_override_type:
                            'standard_employee_employement_package_override_office_override_type',
                        multi_office_override:
                            'standard_employee_employement_package_override_multi_office_override',
                        multi_office_override_type:
                            'standard_employee_employement_package_override_multi_office_override_type',
                    },
                    agreement: {
                        edit: 'agreement_employee_employement_package_agreement_edit',
                        hire_date: 'agreement_employee_employement_package_agreement_hire_date',
                        update_hire_date_button:
                            'agreement_employee_employement_package_agreement_update_hire_date_button',
                    },
                    deduction: {},
                    transfer: {
                        transfer: 'standard_employee_employement_package_transfer',
                        office_state: 'standard_employee_employement_package_transfer_office_state',
                        office_name: 'standard_employee_employement_package_transfer_office_name',
                        department: 'standard_employee_employement_package_transfer_department',
                        department_checkbox:
                            'standard_employee_employement_package_transfer_department_checkbox',
                        position: 'standard_employee_employement_package_transfer_position',
                        position_checkbox:
                            'standard_employee_employement_package_transfer_position_checkbox',
                        position_link:
                            'standard_employee_employement_package_transfer_position_link',
                        manager: 'standard_employee_employement_package_transfer_manager',
                        team: 'standard_employee_employement_package_transfer_team',
                        closer_redline_change:
                            'standard_employee_employement_package_transfer_closer_redline_change',
                        closer_redline_input:
                            'standard_employee_employement_package_transfer_closer_redline_change_input',
                        closer_redline_input_type:
                            'standard_employee_employement_package_transfer_closer_redline_change_input_type',
                        setter_redline_change:
                            'standard_employee_employement_package_transfer_setter_redline_change',
                        setter_redline_input:
                            'standard_employee_employement_package_transfer_setter_redline_change_input',
                        setter_redline_input_type:
                            'standard_employee_employement_package_transfer_setter_redline_change_input_type',
                        select_manager:
                            'standard_employee_employement_package_transfer_select_manager',
                        transfer_effective_date:
                            'standard_employee_employement_package_transfer_transfer_effective_date',
                        transfer_button:
                            'standard_employee_employement_package_transfer_transfer_button',
                    },
                },
            },
        },
    },
}
