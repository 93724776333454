import {useEffect, useState} from 'react'
import {getUniqueBrowserId} from '../helpers/CommonHelpers'

const useBrowserId = () => {
    const [browserId, setBrowserId] = useState(null)
    useEffect(() => {
        getUniqueBrowserId()
            .then(setBrowserId)
            .catch(() => {})
    }, [])

    return {browserId}
}

export default useBrowserId
