import {combineReducers} from '@reduxjs/toolkit'
import AuthReducer from './AuthReducer'
import {RESET_STORE, RESET_STORE_TO_DEFAULT} from '../types/ReduxTypes'
import AppDefaultReducer from '../defaults/AppDefaultReducer'
import SettingReducer from './SettingReducer'
import IntegrationReducer from './IntegrationReducer'
import SequiDocsReducer from './SequiDocsReducer'
import RequestApprovalReducer from './RequestApprovalReducer'
import PermissionsReducer from './PermissionsReducer'
import DashboardReducer from './DashboardReducer'
import FilterReducer from './FilterReducer'
import ExcelImportReducer from './ExcelImportReducer'

const appReducer = combineReducers({
    auth: AuthReducer,
    sequiDocs: SequiDocsReducer,
    setting: SettingReducer,
    integration: IntegrationReducer,
    requestApproval: RequestApprovalReducer,
    permissions: PermissionsReducer,
    dashboard: DashboardReducer,
    filter: FilterReducer,
    excelImport: ExcelImportReducer,
})

export default function rootReducer(state, action) {
    let finalState = appReducer(state, action)
    if (action.type == RESET_STORE) {
        const defaultReducer = {
            ...AppDefaultReducer,
            setting: finalState.setting,
            filter: finalState.filter,
            excelImport: finalState.excelImport,
        }
        finalState = defaultReducer
    } else if (action.type == RESET_STORE_TO_DEFAULT) {
        const defaultReducer = {
            ...AppDefaultReducer,
            setting: {
                ...AppDefaultReducer.setting,
                appVersion: finalState.setting.appVersion,
            },
            filter: finalState.filter,
            excelImport: finalState.excelImport,
        }
        finalState = defaultReducer
    }
    return finalState
}
