import loader from './loader.json'
import accessDenied from './accessDenied.json'
import maintance from './Maintance.json'

const lottieFiles = {
    loader,
    accessDenied,
    maintance,
}

export default lottieFiles
