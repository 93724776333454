import React, {useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import CustomButton, {
    BUTTON_SIZE,
    BUTTON_TYPE,
} from '../../../../customComponents/customButtton/CustomButton'
import CustomImage from '../../../../customComponents/customImage/CustomImage'
import {useLocation, useNavigate} from 'react-router-dom'

const DownloadDocumentPage = () => {
    const [nextFooter, setNextFooter] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()

    const isOfferLetter = location?.state?.isOfferLetter

    return (
        <div className=''>
            <div className=''>
                <KTSVG
                    path='/media/icons/duotune/art/BlueEllipse.svg'
                    svgClassName='w-100'
                    svgStyle={{width: '100%', height: 'auto'}}
                />
            </div>
            <div
                className={`bg-cmwhite shadow mw-500px mx-auto h-${
                    nextFooter ? '400px' : '500px'
                } position-relative`}
                style={{borderRadius: 10, marginTop: -200}}
            >
                <div className='w-sm-75 h-100 mx-auto text-center pt-10'>
                    <div className=''>
                        <KTSVG
                            path='/media/icons/duotune/art/DoneCheck.svg'
                            svgClassName='w-auto '
                            svgStyle={{width: '111px', height: '111px'}}
                        />
                    </div>

                    {isOfferLetter ? (
                        <div>
                            <div
                                className='text-cmGrey900 mb-2'
                                style={{fontSize: 24, fontWeight: 700, lineHeight: '30px'}}
                            >
                                Offer Accepted!
                            </div>
                            <div
                                className='text-cmGrey700'
                                style={{fontSize: 14, fontWeight: 700, lineHeight: '19px'}}
                            >
                                You have Accepted and signed this Offer Letter
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div
                                className='text-cmGrey900 mb-2'
                                style={{fontSize: 22, fontWeight: 700, lineHeight: '30px'}}
                            >
                                Submitted
                            </div>
                            <div
                                className='text-cmGrey700'
                                style={{fontSize: 14, fontWeight: 700, lineHeight: '19px'}}
                            >
                                Your {location?.state?.templateName}{' '}
                                {location?.state?.isPdf ? 'form' : 'document'} has been submitted
                            </div>
                        </div>
                    )}
                </div>
                <div className='bg-cmGrey100 fixed-bottom position-absolute bottom-0'>
                    {!nextFooter ? (
                        <div className='w-sm-75 mx-auto text-center my-10 d-flex flex-column gap-5'>
                            {location?.state?.isOfferLetterData ? (
                                <div
                                    className='text-cmGrey700'
                                    style={{fontSize: 14, fontWeight: 500, lineHeight: '24px'}}
                                >
                                    To complete this offer you have <br /> mandatory documents left
                                    to review, sign and Upload
                                </div>
                            ) : (
                                <></>
                            )}
                            {/* <div>
                                <CustomButton
                                    buttonLabel='Review Next Document'
                                    buttonSize={BUTTON_SIZE.large}
                                    onClick={() => setNextFooter(true)}
                                />
                            </div> */}
                            <div>
                                <CustomButton
                                    buttonLabel={
                                        isOfferLetter ? 'Back to Offer' : 'Back to dashboard'
                                    }
                                    buttonSize={BUTTON_SIZE.large}
                                    buttonType={BUTTON_TYPE.primaryBorder}
                                    onClick={() =>
                                        navigate('/document-signing/home', {
                                            state: {
                                                envelopId: location?.state?.envelopId,
                                                envelopData: location?.state?.envelopData,
                                                isAuthenticated: location?.state?.isAuthenticated,
                                            },
                                        })
                                    }
                                />
                            </div>
                        </div>
                    ) : (
                        <div className='w-sm-75 mx-auto text-center my-10 d-flex flex-column gap-5'>
                            <div
                                className='text-cmGrey700'
                                style={{fontSize: 14, fontWeight: 500, lineHeight: '24px'}}
                            >
                                Congratulations all documents are completed
                            </div>

                            <div>
                                <CustomButton
                                    buttonLabel='Back to Offer'
                                    buttonSize={BUTTON_SIZE.large}
                                    buttonType={BUTTON_TYPE.primaryBorder}
                                    onClick={() => navigate('/document-signing/under-review')}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default DownloadDocumentPage
