import React from 'react'
import CustomButton, {
    BUTTON_SIZE,
    BUTTON_TYPE,
} from '../../../../../customComponents/customButtton/CustomButton'
import {KTSVG} from '../../../../../_metronic/helpers'
import CustomLink from '../../../../../customComponents/customButtton/CustomLink'
import {useNavigate} from 'react-router-dom'

const OfferRejectedPage = () => {
    const navigate = useNavigate()
    return (
        <div className='position-relative'>
            <div className=''>
                <KTSVG
                    path='/media/icons/duotune/art/BlueEllipse.svg'
                    svgClassName='w-100'
                    svgStyle={{width: '100%', height: 'auto'}}
                />
            </div>
            <div
                className={`bg-cmwhite shadow w-500px position-absolute top-100 start-50 translate-middle`}
                style={{borderRadius: 10, borderRadius: 10}}
            >
                <div className='w-sm-75 h-100 mx-auto text-center pt-15 pb-5'>
                    <div className=''>
                        <KTSVG
                            path='/media/icons/duotune/art/DoneCheck.svg'
                            svgClassName='w-auto '
                            svgStyle={{width: '120px', height: '120px'}}
                        />
                    </div>
                    <div
                        className='text-cmGrey900'
                        style={{fontSize: 33, fontWeight: 700, lineHeight: '45px'}}
                    >
                        Offer Rejected
                    </div>
                </div>
                <div className='bg-cmGrey100 py-10' style={{borderRadius: '0px 0px 10px 10px'}}>
                    <div className='w-sm-75 mx-auto text-center d-flex flex-column gap-5'>
                        <div
                            className='text-cmGrey900'
                            style={{fontSize: 16, fontWeight: 700, lineHeight: '21px'}}
                        >
                            Your Offer Letter Has Been Rejected
                        </div>
                        <div
                            className='text-cmGrey700'
                            style={{fontSize: 14, fontWeight: 500, lineHeight: '22px'}}
                        >
                            You have Rejected the offer. <br /> Contact{' '}
                            <CustomLink label={'info@sequifi.com'} /> if this was an error!
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OfferRejectedPage
