import {createSlice} from '@reduxjs/toolkit'

export const excelImportInitialState = {
    templateList: [],
    selectedTemplates: {
        Sale: null,
        User: null,
        Lead: null,
        Job: null,
    },
    importExcelTemplateData: null,
}
const ExcelImportSlice = createSlice({
    name: 'excelImport',
    initialState: excelImportInitialState,
    reducers: {
        SET_SELECTED_EXCEL_IMPORT_TEMPLATE: (state, action) => {
            state.selectedTemplates[action?.payload?.type] = action.payload?.template
        },
        SET_IMPORT_EXCEL_TEMPLATE_DATA: (state, action) => {
            state.importExcelTemplateData = action.payload
        },
    },
})

const {actions, reducer: ExcelImportReducer} = ExcelImportSlice

export const {SET_SELECTED_EXCEL_IMPORT_TEMPLATE, SET_IMPORT_EXCEL_TEMPLATE_DATA} = actions

export default ExcelImportReducer
