export const getLocationFilterDataSelector = (state) => state?.filter?.location
export const getCostCenterFilterDataSelector = (state) => state?.filter?.costcenter
export const getSupportFilterDataSelector = (state) => state?.filter?.support_history
export const getAlertFilterDataSelector = (state) => state?.filter?.alert
export const getPermissionFilterDataSelector = (state) => state?.filter?.permission
export const getDepartmentFilterDataSelector = (state) => state?.filter?.department
export const getParticularDepartmentFilterDataSelector = (state) =>
    state?.filter?.particular_department
export const getParticularPositonFilterDataSelector = (state) => state?.filter?.particular_position
export const getPositionFilterDataSelector = (state) => state?.filter?.position
export const getMySalesFilterDataSelector = (state) => state?.filter?.mysales
export const getHiringFilterDataSelector = (state) => state?.filter?.hiring
export const getRefferalFilterDataSelector = (state) => state?.filter?.refferal
export const getDashboardFilterDataSelector = (state) => state?.filter?.dashboard
export const getReqApprovalFilterDataSelector = (state) => state?.filter?.request_approval
export const getManagementFilterDataSelector = (state) => state?.filter?.management
export const getStandardReportsOfficeFilterDataSelector = (state) => state?.filter?.standard
export const getAdminCompanyFilterDataSelector = (state) => state?.filter?.company
export const getAdminSalesFilterDataSelector = (state) => state?.filter?.sales
export const getAdminCostFilterDataSelector = (state) => state?.filter?.cost
export const getAdminPayrollFilterDataSelector = (state) => state?.filter?.payroll
export const getAdminReconciliationFilterDataSelector = (state) => state?.filter?.reconciliation
export const getAdminClawbackFilterDataSelector = (state) => state?.filter?.clawback
export const getAdminPendingInstallFilterDataSelector = (state) => state?.filter?.pendingInstall
export const getParticularPayrolllFilterDataSelector = (state) => state?.filter?.particularPayPeriod
export const getParticularReconFilterDataSelector = (state) => state?.filter?.particularReconPeriod
export const getAdminPaymentRequestFilterDataSelector = (state) => state?.filter?.paymentRequest
export const getAdminOneTimePaymentHistoryFilterDataSelector = (state) =>
    state?.filter?.oneTimePaymentHistory
export const getAdminPayrollReconFilterDataSelector = (state) =>
    state?.filter?.payrollReconciliation
export const getAdminRunParollFilterDataSelector = (state) => state?.filter?.runPayroll
export const getUserManagementFilterDataSelector = (state) => state?.filter?.userManagement

export const getSequicrmFilterDataSelector = (state) => state?.filter?.sequicrm
export const getLeaderBoardFilterDataSelector = (state) => state?.filter?.leaderBoard

//Onboarding Filter
