import {memo, useCallback, useMemo, useState} from 'react'
import {Dropdown} from 'primereact/dropdown'
import {fontsFamily} from '../../../assets/fonts/fonts'
import CustomLink from '../../customButtton/CustomLink'
import {CommonLabel, PrefixSuffixComponent} from '../customInput/CustomInput'
import './DropdownStyle.css'
import CustomArrow, {ARROW_DIRECTION} from '../../customIcons/CustomIcons'
import {KTSVG} from '../../../_metronic/helpers'
import Show from '../../show/Show'

const CustomDropdown = ({
    name = null,
    value = '',
    searching = true,
    onChange = null,
    options = [],
    displayKey = 'name',
    valueKey = 'value',
    optionGroupLabel = null,
    optionGroupChildren = 'items',
    placeholder = 'Select',
    className = '',
    rootClass = '',
    showClear = true,
    textColorClassName = 'text-sqGray900',
    style,
    errorMessage = '',
    disabled = false,
    dropdownIcon = null,
    required = false,
    label = null,
    hideLabel = false,
    filterMessage = 'No available options',
    showLink = false,
    linkLabel = '',
    onLinkPress,
    width = 'w-100',
    prefixText = '',
    suffixText = '',
    id,
    linkID,
    optionGroupTemplate = null,
    customTemplate = null,
    displayValueTemplate = null,
    subLabel = null,
    onBlur = () => {},
    customFilterTemplate = null,
}) => {
    const dropdownOptions = useMemo(() => {
        return options?.length > 0
            ? optionGroupLabel
                ? options?.map((item) => {
                      return {
                          ...item,
                          label: item?.label,
                          items: item?.items?.map((i) => {
                              return {
                                  ...i,
                                  name: i?.[displayKey],
                                  value: i?.[valueKey]?.toString(),
                              }
                          }),
                      }
                  })
                : options?.map((item) => {
                      return {
                          ...item,
                          name: item?.[displayKey],
                          value: item?.[valueKey]?.toString(),
                      }
                  })
            : []
    }, [options, optionGroupLabel, displayKey, valueKey])

    //templete to show dropdown options
    const countryOptionTemplate = (option) => {
        return (
            <div
                className=''
                style={{fontWeight: 400, fontSize: 16, fontFamily: fontsFamily.manrope}}
            >
                <div className='text-sqGray900'>{option?.name}</div>
            </div>
        )
    }
    //templete to show selcted options
    const valueTemplate = (option) => {
        return (
            <div style={{fontWeight: 400, fontSize: 16, fontFamily: fontsFamily.manrope}}>
                <div className={textColorClassName}>{option?.name ?? placeholder}</div>
            </div>
        )
    }
    //condition to show custom template or default
    const dropdownTemplate = useMemo(() => {
        let templateView = customTemplate ? customTemplate : countryOptionTemplate

        return templateView
    }, [customTemplate])

    //condition to show custom template or default
    const dropDownValueTemplate = useMemo(() => {
        let templateView = displayValueTemplate ? displayValueTemplate : valueTemplate

        return templateView
    }, [displayValueTemplate])

    const handleChange = useCallback(
        (e) => {
            if (!e?.target?.value?.disabled) onChange(e)
        },
        [onChange]
    )

    const [isActive, setIsActive] = useState(false)

    const handleFocus = () => {
        setIsActive(true)
    }

    const handleBlur = () => {
        setIsActive(false)
        onBlur()
    }
    return (
        <>
            <div
                className={`d-flex ${rootClass} flex-column gap-6px ${width} justify-content-end align-self-center`}
            >
                <CommonLabel
                    hideLabel={hideLabel}
                    label={label}
                    required={required}
                    subLabel={subLabel}
                />
                <Dropdown
                    id={id}
                    filter={searching}
                    value={value?.toString()}
                    options={dropdownOptions}
                    onChange={handleChange}
                    {...(dropdownIcon ? {dropdownIcon} : null)}
                    placeholder={placeholder}
                    itemTemplate={dropdownTemplate}
                    optionGroupTemplate={optionGroupTemplate}
                    optionLabel='name'
                    optionGroupLabel={optionGroupLabel}
                    valueTemplate={dropDownValueTemplate}
                    optionGroupChildren={optionGroupChildren}
                    showClear={value && showClear}
                    className={`${className} ${
                        errorMessage
                            ? `p-invalid ${isActive ? 'select_error_focus' : ''}`
                            : `${isActive ? 'select_focus' : ''}`
                    } ${width}`}
                    // panelStyle={{
                    //     fontFamily: fontsFamily.manrope,
                    //     borderRadius: 0,
                    //     borderBottomLeftRadius: 10,
                    //     borderBottomRightRadius: 10,
                    // }}
                    disabled={disabled}
                    name={name}
                    style={{
                        fontFamily: fontsFamily.manrope,
                        ...style,
                    }}
                    emptyFilterMessage={filterMessage}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    checkmark
                    dropdownIcon={(opts) => {
                        return opts.iconProps['data-pr-overlay-visible'] ? (
                            <KTSVG
                                path='/media/icons/duotune/art/dropdownUpIcon.svg'
                                svgClassName='w-20px h-20px'
                            />
                        ) : (
                            <KTSVG
                                path='/media/icons/duotune/art/dropdownDownIcon.svg'
                                svgClassName='w-20px h-20px'
                            />
                        )
                    }}
                    filterTemplate={customFilterTemplate}
                />
                {errorMessage ? (
                    <div className='text-sm-regular text-sqError500'>{errorMessage}</div>
                ) : null}
            </div>
        </>
    )
}

export default memo(CustomDropdown)
