import {Fragment} from 'react'
import {Tooltip} from 'primereact/tooltip'
import {useMemo} from 'react'
import {v4 as uuidv4} from 'uuid'

const CustomTooltip = ({
    title = '',
    style,
    children,
    className = '',
    position = 'bottom',
    disabled = false,
    id = '',
}) => {
    const uniqueTarget = useMemo(() => {
        return `tooltipTarget-${uuidv4()}`
    }, [])
    return children ? (
        <>
            <Tooltip id={id} target={`.${uniqueTarget}`} position={position} disabled={disabled}>
                <span style={{fontSize: 14, ...style}} className={className}>
                    {title}
                </span>
            </Tooltip>
            <div className={`${uniqueTarget} d-flex align-items-center`}>{children}</div>
        </>
    ) : null
}

export default CustomTooltip
