import React from 'react'
import CustomIterator from '../customIterator/CustomIterator'
import CustomSkeleton from './CustomSkeleton'

const CustomBarChartSkeleton = ({containerClass}) => {
    return (
        <div
            className={`d-flex justify-content-evenly align-items-end h-275px border-start border-bottom border-cmGrey100 my-5 ${containerClass}`}
        >
            <CustomIterator count={5}>
                <CustomSkeleton className={'h-225px w-20px'} />
                <CustomSkeleton className={'h-150px w-20px'} />
            </CustomIterator>
        </div>
    )
}

export default CustomBarChartSkeleton
