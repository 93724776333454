import {CURRENT_HOST, HOST_SERVERS} from '../constants/SERVERS'

const useBasedOnHost = () => {
    // Based On Server
    const checkServers = (servers = []) => servers.includes(CURRENT_HOST)

    const basedOnHost = {
        enableGoogleAnalytics: !checkServers([
            HOST_SERVERS.localhost,
            HOST_SERVERS.demo,
            HOST_SERVERS.sdev,
            HOST_SERVERS.pdev,
            HOST_SERVERS.sstage,
            HOST_SERVERS.pstage,
            HOST_SERVERS.flextest,
            HOST_SERVERS.testaveyo,
            HOST_SERVERS.preprod,
            HOST_SERVERS.pestdev,
            HOST_SERVERS.milestone,
        ]),
        futureSixPayPeriod: !checkServers([
            HOST_SERVERS.localhost,
            HOST_SERVERS.sstage,
            HOST_SERVERS.sdev,
            HOST_SERVERS.pdev,
            HOST_SERVERS.pstage,
            HOST_SERVERS.uat,
            HOST_SERVERS.preprod,
        ]),
        billingOverdue: checkServers([]),
        stripeAccount: checkServers([
            HOST_SERVERS.localhost,
            HOST_SERVERS.demo,
            HOST_SERVERS.sdev,
            HOST_SERVERS.pdev,
            HOST_SERVERS.pstage,
            HOST_SERVERS.sstage,
            HOST_SERVERS.adam,
            HOST_SERVERS.flextest,
            HOST_SERVERS.testaveyo,
            HOST_SERVERS.pestdev,
            HOST_SERVERS.preprod,
        ])
            ? 'test'
            : 'live',
        developerConfig: checkServers([
            HOST_SERVERS.localhost,
            HOST_SERVERS.sdev,
            HOST_SERVERS.sstage,
        ]),
        maintenanceConfig: checkServers([
            HOST_SERVERS.localhost,
            HOST_SERVERS.sdev,
            HOST_SERVERS.sstage,
        ]),
        resetApp: checkServers([HOST_SERVERS.preprod]),
        oneSignalNotification: checkServers([
            HOST_SERVERS.localhost,
            HOST_SERVERS.sdev,
            HOST_SERVERS.demo,
        ]),
        evereeEnabled: checkServers([
            HOST_SERVERS.flex,
            HOST_SERVERS.newera,
            HOST_SERVERS.soar,
            HOST_SERVERS.momentum,
            HOST_SERVERS.toptier,
            HOST_SERVERS.simpleflow,
        ]),
        teamRequiredForOnboarding: checkServers([HOST_SERVERS.aveyo, HOST_SERVERS.aveyo2]),
        enableCancelDates: checkServers([HOST_SERVERS.flex, HOST_SERVERS.demo]),
        zeroRedlineAndLockedWhileOnboarding: checkServers([]),
        reconcilationModule: checkServers([
            HOST_SERVERS.sdev,
            HOST_SERVERS.localhost,
            HOST_SERVERS.demo,
            HOST_SERVERS.sstage,
        ]),
        toastDuration: checkServers([HOST_SERVERS.preprod]) ? 10000 : 3000,
        notificationModule: checkServers([HOST_SERVERS.sdev, HOST_SERVERS.localhost]),
        showSequiPayLogo: checkServers([HOST_SERVERS.adam, HOST_SERVERS.localhost]),
        customerSignOffNewEra: checkServers([HOST_SERVERS.newera]),
        loginAsUser: checkServers([
            HOST_SERVERS.sdev,
            HOST_SERVERS.sstage,
            HOST_SERVERS.pdev,
            HOST_SERVERS.pstage,
        ]),
    }

    return {
        basedOnHost,
        checkServers,
    }
}

export default useBasedOnHost
