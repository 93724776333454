import {useCallback, useEffect, useRef, useState} from 'react'
import CustomCheckbox from '../../../../customComponents/customCheckbox/CustomCheckbox'
import CustomButton, {
    BUTTON_SIZE,
    BUTTON_TYPE,
} from '../../../../customComponents/customButtton/CustomButton'
import {useLocation, useNavigate} from 'react-router-dom'
import {Button} from 'primereact/button'

import DisplayPdf from './testingComonents/DisplayPdf'
import {getErrorMessageFromResponse} from '../../../../helpers/CommonHelpers'
import {
    applyDigitalSignToDocumentService,
    applyESignToDocumentService,
    applyFilledDataToDocumentService,
    getCoordinatedOFTextFromDocService,
    getSingleDocumentToSignService,
    uploadFilledSignedDocumentService,
} from '../../../../services/Services'
import CustomToast from '../../../../customComponents/customToast/CustomToast'
import {fontsFamily} from '../../../../assets/fonts/fonts'
import {NEW_SEQUIDOC_DOCUMENT_STATUS} from '../../../../constants/constants'

const SequidocSigningPdfview = () => {
    const [agreeCheck, setagreeCheck] = useState(false)
    const [lastStep, setlastStep] = useState(false)
    const [loading, setLoading] = useState(false)
    const [finishLoading, setFinishLoading] = useState(false)
    const [docLoaded, setDocLoaded] = useState(false)

    const navigate = useNavigate()
    const [documentData, setDocumentData] = useState([])
    const location = useLocation()

    const [allSignatureFieldsData, setAllSignatureFieldsData] = useState([])
    // const [allFromFieldsData, setAllFormFieldsData] = useState([])
    const allFromFieldsData = useRef(new Array())?.current
    const [totalSignatureBlock, setTotalSignatureBlock] = useState(0)
    const [envelopData, setEnvelopData] = useState(location?.state?.envelopData)
    const [coordinates, setCoordinates] = useState([])
    const [textInputCoordinated, setTextInputCoordinates] = useState([])
    const allSignButtonData = useRef(new Array())?.current

    useEffect(() => {
        setEnvelopData(location?.state?.envelopData)
    }, [location?.state?.envelopData])

    useEffect(() => {
        getDocumentToSignData()
    }, [location?.state?.docId])

    const modifyCoordinates = useCallback((coordinatesData) => {
        let data = coordinatesData
        data?.map((item) => {
            let cords = item?.location?.split(',')
            let propertyArr = {x: cords?.[0], y: cords?.[1]}

            item['location'] = propertyArr
            item['field_type'] = 'inputText'
            item['text'] = null

            return item
        })
        return data
    }, [])

    const getSignatureCordinates = useCallback(
        (data) => {
            const params = {
                pdf_path: data?.initial_pdf_path,
                target_word: '[s:employee]',
            }
            if (params?.pdf_path) {
                let signCoordinates = getCoordinatedOFTextFromDocService(params)
                    .then((res) => {
                        let data = res
                        data?.map((item) => {
                            let cords = item?.location?.split(',')
                            let propertyArr = {x: cords?.[0], y: cords?.[1]}

                            item['location'] = propertyArr

                            return item
                        })
                        return data
                    })
                    .catch(() => {})
                let inputTextCoordinates = getCoordinatedOFTextFromDocService({
                    pdf_path: data?.initial_pdf_path,
                    target_word: '[text_entry]',
                })
                    .then((res) => {
                        let coordinatesData = modifyCoordinates(res)
                        return coordinatesData
                    })
                    .catch(() => {})
                Promise.all([signCoordinates, inputTextCoordinates])
                    ?.then((res) => {
                        let signatureCoordinatesRes = res?.[0] ?? []
                        let textInputCoordinatesRes = res?.[1] ?? []
                        setCoordinates([...signatureCoordinatesRes, ...textInputCoordinatesRes])
                        setTotalSignatureBlock(signatureCoordinatesRes?.length)
                    })
                    ?.catch(() => {})
            }
        },
        [modifyCoordinates]
    )
    const getTextInputCordinates = useCallback(
        (data) => {
            const params = {
                pdf_path: data?.initial_pdf_path,
                target_word: '[text_entry]',
            }
            if (params?.pdf_path) {
                getCoordinatedOFTextFromDocService(params)
                    .then((res) => {
                        let coordinatesData = modifyCoordinates(res)
                        setTextInputCoordinates(coordinatesData)
                    })
                    .catch(() => {})
            }
        },
        [modifyCoordinates]
    )

    const getDocumentToSignData = useCallback(
        (data) => {
            const params = {
                doc_id: location?.state?.docId,
            }
            if (params?.doc_id) {
                getSingleDocumentToSignService(params)
                    .then((res) => {
                        let data = {...res?.data}
                        if (data?.status == NEW_SEQUIDOC_DOCUMENT_STATUS.signed) {
                            navigate('/document-signing/download-document', {
                                state: {
                                    isOfferLetter:
                                        documentData?.template_category_id == 1 ? true : false,
                                    envelopId: location?.state?.envelopId,
                                    templateName: documentData?.template_name,
                                    envelopData: location?.state?.envelopData,
                                    isPdf: documentData?.is_pdf,
                                    isAuthenticated: location?.state?.isAuthenticated,
                                },
                            })
                            return
                        }
                        data['pdf_file_other_parameter'] =
                            JSON.parse(res?.data?.pdf_file_other_parameter) ?? []

                        if (!data?.is_pdf) {
                            getTextInputCordinates(data)
                            getSignatureCordinates(data)
                        }
                        setDocumentData(data)
                    })
                    .catch((err) => CustomToast.error(getErrorMessageFromResponse(err)))
                    .finally(() => setLoading(false))
            }
        },
        [
            documentData?.is_pdf,
            documentData?.template_category_id,
            documentData?.template_name,
            getSignatureCordinates,
            location?.state?.docId,
            location?.state?.envelopData,
            location?.state?.envelopId,
            location?.state?.isAuthenticated,
            navigate,
            getTextInputCordinates,
        ]
    )

    const retryDocumentLoad = useCallback(() => {
        // getDocumentToSignData()
        window.location.reload()
    }, [])

    const handleAgreeCheck = () => {
        setagreeCheck(!agreeCheck)
    }

    const handleViewLastStep = () => {
        setlastStep(!lastStep)
    }

    const validateTemplate = useCallback(() => {
        let signsAdded = allSignatureFieldsData?.map((item) => item?.id)
        let notSignedBlock = allSignButtonData?.filter((item) => !signsAdded?.includes(item?.id))
        let element = document.getElementById(notSignedBlock?.[0]?.id)

        if (element) {
            element.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'})
        }
        if (totalSignatureBlock != allSignatureFieldsData?.length) return false

        return true
    }, [allSignButtonData, allSignatureFieldsData, totalSignatureBlock])

    const validatePdfTemplate = useCallback(() => {
        let nonFilledRequiredFields = allFromFieldsData?.filter(
            (item) => item?.isRequired && !item?.text
        )
        let element = document.getElementById(nonFilledRequiredFields?.[0]?.id)

        if (element) {
            element.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'})
            element.style.border = '1.3px solid #FFA800'
        }
        if (nonFilledRequiredFields?.length > 0) return false

        return true
    }, [allFromFieldsData])

    const applyESign = useCallback(
        (payload) =>
            new Promise((resolve, reject) => {
                applyESignToDocumentService({...payload})
                    ?.then((res) => resolve(res))
                    .catch((err) => reject(err))
            }),
        []
    )
    const applyDigiSign = useCallback(
        (payload) =>
            new Promise((resolve, reject) => {
                applyDigitalSignToDocumentService({...payload})
                    ?.then((res) => resolve(res))
                    .catch((err) => reject(err))
            }),
        []
    )

    const applyFieldsData = useCallback(() => {
        const body = {
            // envelope_id: location?.state?.envelopId,
            document_id: location?.state?.docId,
            signer_id: envelopData?.signer_id ?? documentData?.signers?.[0]?.signer_id,
        }
        applyFilledDataToDocumentService(body)
            .then((res) => {
                applyESign(res?.data)
                    .then((res) => {
                        applyDigiSign(res?.data)
                            .then((res) => {
                                if (location?.state?.isDirect) {
                                    navigate('/standard-dashboard', {state: {step: 3}})
                                } else {
                                    navigate('/document-signing/download-document', {
                                        state: {
                                            isOfferLetter:
                                                documentData?.template_category_id == 1
                                                    ? true
                                                    : false,
                                            envelopId: location?.state?.envelopId,
                                            templateName: documentData?.template_name,
                                            envelopData: location?.state?.envelopData,
                                            isPdf: documentData?.is_pdf,
                                            isAuthenticated: location?.state?.isAuthenticated,
                                            isOfferLetterData: location?.state?.offerLetterData,
                                        },
                                    })
                                }
                            })
                            .catch((err) => CustomToast.error(getErrorMessageFromResponse(err)))
                            .finally(() => setFinishLoading(false))
                    })
                    .catch((err) => {
                        setFinishLoading(false)
                        CustomToast.error(getErrorMessageFromResponse(err))
                    })
            })
            .catch((err) => {
                setFinishLoading(false)
                CustomToast.error(getErrorMessageFromResponse(err))
            })
    }, [
        applyDigiSign,
        applyESign,
        documentData?.is_pdf,
        documentData?.signers,
        documentData?.template_category_id,
        documentData?.template_name,
        envelopData?.signer_id,
        location?.state?.docId,
        location?.state?.envelopData,
        location?.state?.envelopId,
        location?.state?.isAuthenticated,
        location?.state?.isDirect,
        location?.state?.offerLetterData,
        navigate,
    ])

    const handleFinishTemplate = useCallback(() => {
        if (!documentData?.is_pdf) {
            if (!validateTemplate()) return CustomToast.error('Please do required signature')
        }
        if (documentData?.is_pdf) {
            if (!validatePdfTemplate()) return CustomToast.error('Please fill required fields')
        }
        setFinishLoading(true)
        // let formFieldData = allFromFieldsData?.map((item) => {
        //     delete item?.id
        // })
        const body = {
            document_signer_id: envelopData?.signer_id ?? documentData?.signers?.[0]?.signer_id,
            envelope_id: envelopData?.envelope_id,
            document_id: location?.state?.docId,
            form_data_attributes: allFromFieldsData ?? [],
            signature_attributes: allSignatureFieldsData,
        }

        uploadFilledSignedDocumentService(body)
            .then(() => {
                applyFieldsData()
            })
            .catch((err) => CustomToast.error(getErrorMessageFromResponse(err)))
            .finally(() => {})
    }, [
        allFromFieldsData,
        allSignatureFieldsData,
        applyFieldsData,
        documentData?.is_pdf,
        documentData?.signers,
        envelopData?.envelope_id,
        envelopData?.signer_id,
        location?.state?.docId,
        validatePdfTemplate,
        validateTemplate,
    ])
    const blurLayerStyle = {
        position: 'absolute', // Position the blur layer absolutely within the container
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.7)', // Background color with transparency
        backdropFilter: 'blur(1px)', // Apply a blur effect to the blur layer
    }

    const onCancel = () => {
        if (location?.state?.isDirect) {
            navigate('/standard-dashboard', {state: {step: 3}})
        } else {
            navigate(-1)
        }
    }

    return (
        <div className='position-relative'>
            <div className='w-100 sticky-top'>
                {lastStep ? (
                    <div className='h-md-60px bg-cmBlue-Crayola d-flex flex-center flex-wrap gap-5 mb-3'>
                        <div>
                            <Button
                                label='Cancel'
                                className='bg-cmBlue-Crayola text-cmwhite text-center mx-auto w-sm-auto h-sm-45px min-w-sm-225px border border-2 border-cmWhite'
                                style={{
                                    fontFamily: fontsFamily.manrope,
                                    fontWeight: '700',
                                    borderRadius: '7px',
                                    fontSize: '16px',
                                }}
                                onClick={onCancel}
                                disabled={finishLoading}
                            />
                        </div>
                        <CustomButton
                            buttonLabel='Finish'
                            buttonType={BUTTON_TYPE.primaryBorder}
                            buttonSize={BUTTON_SIZE.large}
                            onClick={handleFinishTemplate}
                            loading={finishLoading}
                            disabled={!docLoaded}
                        />
                    </div>
                ) : (
                    <div className='p-2 bg-cmBlue-Crayola d-flex flex-center gap-10 flex-wrap mb-3'>
                        <div className='d-flex flex-center gap-2'>
                            <span>
                                <CustomCheckbox checked={agreeCheck} onChange={handleAgreeCheck} />
                            </span>
                            <span className='text-cmwhite' style={{fontWeight: 700, fontSize: 16}}>
                                I agree to use electronics records and signatures{' '}
                            </span>
                        </div>

                        <div className='d-flex flex-center flex-wrap gap-5'>
                            {agreeCheck ? (
                                <CustomButton
                                    buttonLabel='Get Started'
                                    buttonType={BUTTON_TYPE.disabled}
                                    buttonSize={BUTTON_SIZE.large}
                                    onClick={handleViewLastStep}
                                />
                            ) : (
                                <div>
                                    <Button
                                        label='Get Started'
                                        className='bg-cmDisButton text-cmwhite text-center mx-auto w-sm-auto h-sm-45px min-w-sm-225px'
                                        style={{
                                            fontFamily: fontsFamily.manrope,
                                            fontWeight: '700',
                                            borderRadius: '7px',
                                            fontSize: '16px',
                                        }}
                                        disabled={true}
                                    />
                                </div>
                            )}
                            <Button
                                label='Cancel'
                                className='bg-cmBlue-Crayola text-cmwhite text-center mx-auto w-sm-auto h-sm-45px min-w-sm-225px border border-2 border-cmWhite'
                                style={{
                                    fontFamily: fontsFamily.manrope,
                                    fontWeight: '700',
                                    borderRadius: '7px',
                                    fontSize: '16px',
                                }}
                                onClick={onCancel}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className=' position-relative'>
                <div>
                    <DisplayPdf
                        url={documentData?.initial_pdf_path}
                        documentData={documentData}
                        isOfferLetter={location?.state?.isOfferLetter}
                        setAllSignatureFieldsData={setAllSignatureFieldsData}
                        allSignatureFieldsData={allSignatureFieldsData}
                        // setAllFormFieldsData={setAllFormFieldsData}
                        allFromFieldsData={allFromFieldsData}
                        setTotalSignatureBlock={setTotalSignatureBlock}
                        handleFinishTemplate={handleFinishTemplate}
                        coordinates={coordinates}
                        finishLoading={finishLoading}
                        allSignButtonData={allSignButtonData}
                        retryDocumentLoad={retryDocumentLoad}
                        setDocLoaded={setDocLoaded}
                        textInputCoordinated={textInputCoordinated}
                    />
                </div>

                {/* Blur layer */}
                <div style={agreeCheck && lastStep ? null : blurLayerStyle}></div>
            </div>
        </div>
    )
}

export default SequidocSigningPdfview
