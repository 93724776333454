import {useCallback, useMemo, useState} from 'react'
import _ from 'lodash'

const useValidation = ({ignoreKeys = []} = {}) => {
    const [beginValidating, setBeginValidating] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)

    const errorMessages = useMemo(() => {
        let data = {}
        if (beginValidating) {
            const rawData = {
                ...data,
                ...errorMessage,
            }
            data = rawData
            data.isValidate = isValidationObjectHasEmptyValue(rawData, ignoreKeys)
            data.beginValidating = true
        }
        return data
    }, [beginValidating, errorMessage, ignoreKeys])

    const validate = useCallback(
        (method = () => {}) =>
            new Promise((resolve) => {
                setBeginValidating(true)
                const responseFromValidation = method
                let data = {...responseFromValidation}
                data.isValidate = isValidationObjectHasEmptyValue(
                    responseFromValidation,
                    ignoreKeys
                )
                data.beginValidating = true
                setErrorMessage(data)
                resolve(data)
            }),
        [ignoreKeys]
    )

    return [validate, errorMessages, setBeginValidating]
}

export const isValidationObjectHasEmptyValue = (data = {}, ignoreKeys = []) => {
    let finalData = _.cloneDeep(data)
    delete finalData?.beginValidating
    delete finalData?.isValidate
    if (ignoreKeys?.length > 0) {
        ignoreKeys.forEach((ignKey) => {
            delete finalData?.[ignKey]
        })
    }
    return !_.isEmpty(finalData)
        ? Object.keys(finalData).every((key) => [undefined, null, ''].includes(finalData[key]))
        : Object.keys(finalData).length <= 0
        ? true
        : false
}

export default useValidation
