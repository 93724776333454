import {initializeApp} from 'firebase/app'
import {getDatabase} from 'firebase/database'

const firebaseConfig = {
    apiKey: 'AIzaSyD5-PTi0BJEPS43kU_1hd2p7M3nf5Sy5JA',
    authDomain: 'sequifi.firebaseapp.com',
    databaseURL: 'https://sequifi-default-rtdb.firebaseio.com',
    projectId: 'sequifi',
    storageBucket: 'sequifi.appspot.com',
    messagingSenderId: '720527543639',
    appId: '1:720527543639:web:fd067e4d3c4198b1a3c212',
    measurementId: 'G-P2CPPY7HLQ',
}

const app = initializeApp(firebaseConfig)
export const firebaseDB = getDatabase(app)
