import {useCallback, useEffect, useRef, useState} from 'react'

const useAPI = (storeResponse = false) => {
    const [data, setData] = useState(null)
    const [error, setError] = useState(null)
    const apiAbortController = useRef(null)
    const [loading, setLoading] = useState(false)

    // Abort API call on Unmount
    useEffect(() => abortAPI, [])

    // Abort API
    const abortAPI = useCallback(() => {
        if (apiAbortController?.current) {
            apiAbortController?.current?.abort()
        }
    }, [])

    // Initialize API
    const initAPI = useCallback(
        () =>
            new Promise((resolve) => {
                abortAPI()
                const controller = new AbortController()
                apiAbortController.current = controller
                resolve(controller?.signal)
            }),
        [abortAPI]
    )

    // Call API : Use this method if you wanna use loading state and response state which contain data and error
    const callAPI = useCallback(
        (func = null) =>
            new Promise((resolve, reject) => {
                if (func) {
                    setLoading(true)
                    func?.then((res) => {
                        if (storeResponse) setData(res)
                        setLoading(false)
                        resolve(res)
                    })?.catch((errorRes) => {
                        if (!errorRes?.isCancel) setLoading(false)
                        else {
                            if (storeResponse) setError(errorRes)
                        }
                        reject(errorRes)
                    })
                }
            }),
        [storeResponse]
    )

    return {
        // Methods
        initAPI,
        callAPI,
        abortAPI,
        setLoading,
        // Values
        data,
        error,
        loading,
    }
}

export default useAPI
