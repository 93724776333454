import React, {useState, useEffect, useCallback} from 'react'
import Pusher from 'pusher-js'
import {PUSHER_APP_ID, PUSHER_CHANNELS} from '../constants/constants'

const withPusher = (WrappedComponent) => {
    return (props) => {
        const [pusher, setPusher] = useState(null)
        const [pusherChannel, setPusherChannel] = useState(null)

        useEffect(() => {
            const pusherInstance = new Pusher(PUSHER_APP_ID, {
                cluster: 'us3',
            })
            const channel = pusherInstance.subscribe(PUSHER_CHANNELS.staging)
            setPusher(pusherInstance)
            setPusherChannel(channel)

            return () => {
                // Clean up and disconnect Pusher when the component unmounts
                pusherInstance.disconnect()
            }
        }, [])

        const listenPusherEvent = useCallback(
            (event_type = null, callback = () => {}) => {
                if (event_type && pusherChannel) {
                    pusherChannel?.bind(event_type, (callbackData) => {
                        console.log('rk Pusher', callbackData)
                        callback(callbackData)
                    })
                }
            },
            [pusherChannel]
        )

        return (
            <WrappedComponent
                pusher={pusher}
                pusherChannel={pusherChannel}
                listenPusherEvent={listenPusherEvent}
                {...props}
            />
        )
    }
}

export default withPusher
