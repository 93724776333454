import React from 'react'
import {fontsFamily} from '../../../../assets/fonts/fonts'
import CustomButton, {BUTTON_SIZE} from '../../../../customComponents/customButtton/CustomButton'
import {KTSVG} from '../../../../_metronic/helpers'

export const StanderdBillingNotice = () => {
    return (
        <div className='mx-auto w-md-50' style={{fontSize: 14, fontFamily: fontsFamily.manrope}}>
            <div className='  bg-cmwhite p-5  shadow-sm' style={{borderRadius: 10}}>
                <div className='d-flex flex-column gap-15 px-5 py-10 '>
                    <div
                        className='text-start text-cmGrey900 p-2'
                        style={{fontSize: 20, fontWeight: 700}}
                    >
                        🚨 Payment Required for Uninterrupted Service!
                    </div>
                    <div
                        className=' d-grid gap-10 text-start text-cmGrey700 p-2'
                        style={{fontSize: 14, fontWeight: 600}}
                    >
                        <div>
                            We regret to inform you that your account has been temporarily disabled
                            due to non-payment.
                        </div>
                        <div>
                            <div className='text-cmGrey800' style={{fontWeight: 700}}>
                                Action Required:
                            </div>
                            Kindly contact your administrator immediately to settle the outstanding
                            balance and reactivate your account.
                        </div>
                        <div>
                            <div>
                                For assistance or further details, please reach out to your
                                administrator promptly.
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>Best regards,</div>
                        <div>
                            The{' '}
                            <span className='text-cmGrey800' style={{fontWeight: 700}}>
                                Sequifi
                            </span>{' '}
                            Team
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default StanderdBillingNotice
