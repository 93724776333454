import React from 'react'

export const LINK_SIZE = {
    xsmall: '12px',
    small: '14px',
    large: '16px',
}

const CustomLink = ({
    label,
    id,
    linkSize = LINK_SIZE.small,
    onClick,
    icon,
    underline = true,
    labelClassName = '',
}) => {
    return (
        <span
            id={id}
            className={`cursor-pointer text-cmBlue-Crayola p-0 ${
                underline ? 'text-decoration-underline' : ''
            } `}
            style={{fontSize: linkSize, fontWeight: 700, width: 'fit-content'}}
            onClick={onClick}
        >
            {icon ? <i className={`text-cmBlue-Crayola ${icon} me-2`} /> : null}
            <span className={labelClassName}> {label}</span>
        </span>
    )
}

export default CustomLink
