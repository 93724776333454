import {useCallback, useEffect, useState} from 'react'
import CustomDropdown from '../../../../../customComponents/customInputs/customDropdown/CustomDropdown'
import {getSmSalesReportSelector} from '../../../../../redux/selectors/DashboardSelectors'
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux'
import {smDashboardSalesReportAction} from '../../../../../redux/actions/DashboardActions'
import {Dashboard_DURATION_DROPDOWN_LIST} from '../../../../../constants/constants'
import {formattedNumberFields, sendDataToReducer} from '../../../../../helpers/CommonHelpers'
import CustomLoader from '../../../../../customComponents/customLoader/CustomLoader'
import CustomIterator from '../../../../../customComponents/customIterator/CustomIterator'
import CustomSkeleton from '../../../../../customComponents/customSkeleton/CustomSkeleton'
import {SET_DASHBOARD_FILTER_DATA} from '../../../../../redux/reducers/FilterReducer'
import {getDashboardFilterDataSelector} from '../../../../../redux/selectors/FilterSelectors'
import useSequifiConfig from '../../../../../sequiConfig/useSequifiConfig'
import Show from '../../../../../customComponents/show/Show'

const SalesReportCard = ({setGoalFilter}) => {
    const salesReportData = useSelector(getSmSalesReportSelector)
    const dashboardData = useSelector(getDashboardFilterDataSelector)
    const [filter, setFilter] = useState(dashboardData?.standard?.sales_report)
    const [loading, setLoading] = useState(false)
    const [fullLoading, setFullLoading] = useState(false)
    const {basedOnCompanyType} = useSequifiConfig()

    const dispatch = useDispatch()
    useEffect(() => {
        sendDataToReducer(dispatch, SET_DASHBOARD_FILTER_DATA, {
            standard: {
                sales_report: filter,
                office_performance: dashboardData?.standard?.office_performance,
            },
        })
        getSalesReportData()
    }, [filter])

    const onFilterChange = (e) => {
        setFilter(e.target.value)
        setGoalFilter(e.target.value)
    }
    const getSalesReportData = useCallback(() => {
        if (salesReportData) setLoading(true)
        else setFullLoading(true)
        dispatch(smDashboardSalesReportAction(filter))
            .catch((e) => {})
            .finally(() => {
                if (salesReportData) setLoading(false)
                else setFullLoading(false)
            })
    }, [dispatch, filter])

    return (
        <div
            className='bg-cmwhite min-w-md-235px p-18px flex-grow-1 sqcard-shadow'
            style={{
                borderRadius: '10px',
            }}
        >
            <CustomLoader visible={fullLoading} full />
            <div className='text-sqGray900 mb-6 d-flex justify-content-between text-md-bold'>
                Sales Report
            </div>
            <div className='mb-18px'>
                <CustomDropdown
                    id={'Announcements_Sales_Report_Duration_Filter'}
                    options={Dashboard_DURATION_DROPDOWN_LIST}
                    onChange={onFilterChange}
                    showClear={false}
                    value={filter}
                    searching={false}
                />
            </div>
            {/* Cards begin */}
            {loading ? (
                <CustomIterator count={3}>
                    <CustomSkeleton className={'h-60px w-100 mb-2'} borderRadius={10} />
                </CustomIterator>
            ) : (
                <>
                    <div
                        className='bg-sqSuccess100 px-18px py-5px mb-14px text-sqGray900'
                        style={{borderRadius: '10px'}}
                    >
                        <div className='text-xs-semibold mb-6px'>Total Earnings:</div>
                        <div className='text-lg-bold'>
                            {formattedNumberFields(salesReportData?.totalEarning, '$')}
                        </div>
                    </div>
                    <div
                        className='bg-sqPrimary600 bg-opacity-10 px-18px py-5px text-sqGray900 mb-14px'
                        style={{borderRadius: '10px'}}
                    >
                        <div className='text-xs-semibold mb-6px'>Accounts:</div>
                        <div className='text-lg-bold'>{salesReportData?.totalSales ?? 0}</div>
                    </div>
                    <div
                        className='bg-sqWarning100 px-18px py-5px text-sqGray900'
                        style={{borderRadius: '10px'}}
                    >
                        <Show>
                            <Show.When isVisible={basedOnCompanyType.isCompanyTypePest}>
                                <div className='text-xs-semibold mb-6px'>Total Revenue:</div>
                                <div className='text-lg-bold'>
                                    {formattedNumberFields(salesReportData?.totalKw)}
                                </div>
                            </Show.When>
                            <Show.Else>
                                <div className='text-xs-semibold mb-6px'>
                                    {basedOnCompanyType?.primaryUnitType} Sold:
                                </div>
                                <div className='text-lg-bold'>
                                    {formattedNumberFields(salesReportData?.totalKw, '')}{' '}
                                    {basedOnCompanyType?.primaryUnitType}
                                </div>
                            </Show.Else>
                        </Show>
                    </div>
                </>
            )}
        </div>
    )
}

export default SalesReportCard
