import React from 'react'
import CustomIterator from '../customIterator/CustomIterator'
import CustomSkeleton from './CustomSkeleton'

const CustomCardSkeleton = ({
    count = 3,
    height = '100px',
    containerWidth = 'w-100',
    cardWidth = '',
    col = 'col-sm',
    borderRadius = 10,
    gap = 5
}) => {
    return (
        <div className={`row gap-${gap} ${containerWidth}`}>
            <CustomIterator count={count}>
                <div className={` ${col} ${cardWidth}`}>
                    <CustomSkeleton className={`w-100 h-${height}`} borderRadius={borderRadius} />
                </div>
            </CustomIterator>
        </div>
    )
}

export default CustomCardSkeleton
