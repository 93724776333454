import {AuthInitialState} from '../reducers/AuthReducer'
import {excelImportInitialState} from '../reducers/ExcelImportReducer'
import {filterInitialState} from '../reducers/FilterReducer'
import {DashboardInitialState} from '../reducers/DashboardReducer'
import {IntegrationInitialState} from '../reducers/IntegrationReducer'
import {PermissionsInitialState} from '../reducers/PermissionsReducer'
import {RequestApprovalInitialState} from '../reducers/RequestApprovalReducer'
import {SequiDocsInitialState} from '../reducers/SequiDocsReducer'
import {SettingInitialState} from '../reducers/SettingReducer'

const AppDefaultReducer = {
    auth: AuthInitialState,
    setting: SettingInitialState,
    sequiDocs: SequiDocsInitialState,
    integration: IntegrationInitialState,
    requestApproval: RequestApprovalInitialState,
    permissions: PermissionsInitialState,
    excelImport: excelImportInitialState,
    dashboard: DashboardInitialState,
    filter: filterInitialState,
}

export default AppDefaultReducer
