import {Calendar} from 'primereact/calendar'
// import 'primereact/resources/primereact.min.css'
// import 'primereact/resources/themes/saga-blue/theme.css'
// import 'primereact/resources/primereact.min.css'
import {memo, useState} from 'react'
import {CommonLabel} from '../customInput/CustomInput'
import {DEFAULT_DATE_FORMAT} from '../../../constants/constants'
import {fontsFamily} from '../../../assets/fonts/fonts'
import {} from '../../../redux/selectors/SettingsSelectors'
import moment from 'moment'
import './DatePickerCss.css'
import {KTSVG} from '../../../_metronic/helpers'

const CustomDatePicker = ({
    dateFormat = 'mm/dd/yy',
    name = 'customDate',
    value = null,
    disabled = false,
    disabledDates = [],
    onChange = null,
    onViewDateChange = null,
    className = null,
    mode = 'single',
    isModal = false,
    timeOnly = false,
    minDate = null,
    maxDate = null,
    placeholder = DEFAULT_DATE_FORMAT,
    errorMessage = '',
    viewMode = 'date',
    BackgroundColor = 'cmwhite',
    onBlur = () => {},
    readOnlyInput = null,
    label,
    subLabel,
    required = false,
    dateTemplate = null,
    disabledDays = [],
    hideLabel = false,
    showTodayAndClearButton = true,
    id,
    openWithTodayDate = false,
}) => {
    const [isActive, setIsActive] = useState(false)
    const handleFocus = () => {
        setIsActive(true)
    }

    const handleBlur = () => {
        setIsActive(false)
        onBlur()
    }

    return (
        <div className={`${className} d-flex flex-column gap-1`} aria-expanded='false'>
            <CommonLabel
                label={label}
                hideLabel={hideLabel}
                subLabel={subLabel}
                required={required}
            />
            <div
                className={`${errorMessage ? 'date_error' : 'dateInputContainer'}  ${
                    isActive ? 'focus' : ''
                }`}
            >
                <Calendar
                    id={id}
                    className={`dateInput w-100`}
                    name={name}
                    view={viewMode}
                    value={value ? moment(value)?.toDate() : null}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    {...(onChange ? {onChange: onChange} : null)}
                    readOnlyInput={readOnlyInput}
                    placeholder={placeholder}
                    selectionMode={mode}
                    showOnFocus={false}
                    onViewDateChange={onViewDateChange}
                    disabled={disabled}
                    disabledDates={disabledDates}
                    dateFormat={dateFormat}
                    showIcon
                    iconPos='left'
                    icon={() => (
                        <KTSVG
                            path='/media/icons/duotune/art/datePicker.svg'
                            svgClassName='w-20px h-20px'
                        />
                    )}
                    dateTemplate={dateTemplate}
                    disabledDays={disabledDays}
                    minDate={minDate ? moment(minDate)?.toDate() : null}
                    maxDate={maxDate ? moment(maxDate)?.toDate() : null}
                    showButtonBar={showTodayAndClearButton}
                    {...(isModal && {touchUI: true})}
                    {...(timeOnly && {timeOnly: true})}
                    hourFormat='12'
                    mask='99/99/9999'
                    viewDate={openWithTodayDate ? new Date() : null}
                    prevIcon={() => <i className='bi bi-caret-left-square-fill' />}
                    nextIcon={() => <i className='bi bi-caret-right-square-fill' />}
                />
            </div>

            {errorMessage ? (
                <div>
                    <div className='text-sm-regular text-sqError500'>{errorMessage}</div>
                </div>
            ) : null}
        </div>
    )
}

export default memo(CustomDatePicker)
