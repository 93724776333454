import {useDispatch} from 'react-redux'
import CustomModal from '../../../../customComponents/customModal/CustomModal'
import {sendDataToReducer} from '../../../../helpers/CommonHelpers'
import {SET_BIRTHDAY_WISH} from '../../../../redux/reducers/SettingReducer'
import CustomButton, {BUTTON_SIZE} from '../../../../customComponents/customButtton/CustomButton'
import CustomImage from '../../../../customComponents/customImage/CustomImage'
import {useSelector} from 'react-redux'
import {getUserDataSelector} from '../../../../redux/selectors/AuthSelectors'
import {getBirthdayWishesSelector} from '../../../../redux/selectors/SettingsSelectors'
import moment from 'moment'
import _ from 'lodash'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {getValidDate} from '../../../../constants/constants'

const BirthdayWishModal = () => {
    const dispatch = useDispatch()
    const userDetails = useSelector(getUserDataSelector)
    const birthdayWishes = useSelector(getBirthdayWishesSelector)
    const [isBirthdayWish, setBirthdayWish] = useState(false)

    useEffect(() => {
        const dob = getValidDate(userDetails?.dob, 'MM-DD')
        const findUser = birthdayWishes?.findIndex((i) => i?.id == userDetails?.id)
        if (
            (findUser == -1 ||
                (birthdayWishes.length &&
                    birthdayWishes[findUser]?.year !== moment().format('YYYY'))) &&
            dob == moment().format('MM-DD')
        ) {
            setBirthdayWish(true)
        }
    }, [userDetails?.dob])

    const handleClose = useCallback(() => {
        setBirthdayWish(false)
    }, [])

    const onWished = () => {
        let bDayWishList = _.cloneDeep(birthdayWishes)
        const findUser = bDayWishList?.findIndex((i) => i?.id == userDetails?.id)
        const updatedData = {id: userDetails?.id, year: moment().format('YYYY')}
        if (findUser === -1) {
            bDayWishList.push(updatedData)
        } else {
            bDayWishList[findUser] = updatedData
        }
        sendDataToReducer(dispatch, SET_BIRTHDAY_WISH, bDayWishList)
        handleClose()
    }
    const birthdayImage = useMemo(() => {
        const image = _.sample([
            require('../../../../assets/images/birthday1.gif'),
            require('../../../../assets/images/birthday2.png'),
            require('../../../../assets/images/birthday3.png'),
            require('../../../../assets/images/birthday4.png'),
            require('../../../../assets/images/birthday5.png'),
            require('../../../../assets/images/birthday6.png'),
            require('../../../../assets/images/birthday7.png'),
        ])
        return image
    }, [userDetails?.dob])

    return (
        <CustomModal
            show={isBirthdayWish}
            onHide={handleClose}
            maxWidth='480'
            showClose={false}
            showline={false}
        >
            <div className='modal-body' style={{fontFamily: 'Manrope'}}>
                <div
                    className='bg-cmGrey100 my-4 d-flex justify-content-center align-items-center overflow-hidden'
                    style={{height: '336px', borderRadius: 8}}
                >
                    <CustomImage src={birthdayImage} className='w-100 h-100' />
                </div>
                <div className='my-8'>
                    <div className='mb-2 text-lg-semibold text-cmGrey900'>
                        {`Happy Birthday, ${userDetails?.first_name}!`}
                    </div>
                    <div className='text-sm-regular text-cmGrey500'>
                        Happy birthday, and thank you for being such a great colleague! We
                        appreciate all the hard work and dedication you bring to our team. I hope
                        this year is filled with many extra special moments.
                    </div>
                </div>
            </div>
            <CustomButton
                width={100}
                style={{width: '100%', fontWeight: 600}}
                buttonLabel='Thank You!'
                buttonSize={BUTTON_SIZE.large}
                onClick={onWished}
            />
        </CustomModal>
    )
}

export {BirthdayWishModal}
