import {forwardRef, useEffect, useState, useImperativeHandle} from 'react'

const CustomTimer = forwardRef(({seconds = 30, preLabel = '', children}, ref) => {
    const [second, setSecond] = useState(seconds)

    useImperativeHandle(
        ref,
        () => {
            return {
                restartTimer: () => setSecond(seconds),
            }
        },
        []
    )

    useEffect(() => {
        setSecond(seconds)
    }, [seconds])

    useEffect(() => {
        let timer = null
        if (second > 0) {
            timer = setTimeout(() => {
                setSecond((val) => val - 1)
            }, 1000)
        }
        return () => {
            clearTimeout(timer)
        }
    }, [second])

    return second == 0 ? (
        children
    ) : (
        <>
            <span className={`p-0 text-sm-semibold text-sqGray600`}>
                {preLabel} <span className='text-cmBlue-Crayola '>{second} seconds</span>
            </span>
        </>
    )
})

export default CustomTimer
