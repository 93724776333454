import {useMemo} from 'react'
import {useSelector} from 'react-redux'
import {
    getUserDataSelector,
    isUserManagerSelector,
    isUserSuperAdminSelector,
} from '../redux/selectors/AuthSelectors'
import {PERMISSIONS_GROUP, PERMISSION_TYPE, ROLES} from './AccessRightsConstants'
import {isPermittedForAccess} from './AccessRights'
import {getBooleanValue} from '../helpers/CommonHelpers'

const commonCRUDAccessPermission = (permission) => {
    return {
        add: {
            permission,
            type: PERMISSION_TYPE.add,
        },
        view: {
            permission,
            type: PERMISSION_TYPE.view,
        },
        edit: {
            permission,
            type: PERMISSION_TYPE.edit,
        },
        delete: {
            permission,
            type: PERMISSION_TYPE.delete,
        },
        hireNow: {
            permission,
            type: PERMISSION_TYPE.hireNow,
        },
        hireDirectly: {
            permission,
            type: PERMISSION_TYPE.hireDirectly,
        },
        transferEmployee: {
            permission,
            type: PERMISSION_TYPE.transferEmployee,
        },
        dismissUsers: {
            permission,
            type: PERMISSION_TYPE.dismissUsers,
        },
        viewFunc: () =>
            isPermittedForAccess({
                permission,
                type: PERMISSION_TYPE.view,
            }),
        addFunc: () =>
            isPermittedForAccess({
                permission,
                type: PERMISSION_TYPE.add,
            }),
        editFunc: () =>
            isPermittedForAccess({
                permission,
                type: PERMISSION_TYPE.edit,
            }),
        deleteFunc: () =>
            isPermittedForAccess({
                permission,
                type: PERMISSION_TYPE.delete,
            }),
        hireNowFunc: () =>
            isPermittedForAccess({
                permission,
                type: PERMISSION_TYPE.hireNow,
            }),
        hireDirectlyFunc: () =>
            isPermittedForAccess({
                permission,
                type: PERMISSION_TYPE.hireDirectly,
            }),
        transferEmployeeFunc: () =>
            isPermittedForAccess({
                permission,
                type: PERMISSION_TYPE.transferEmployee,
            }),
        dismissUsersFunc: () =>
            isPermittedForAccess({
                permission,
                type: PERMISSION_TYPE.dismissUsers,
            }),

        sendTemplateFunc: () =>
            isPermittedForAccess({
                permission,
                type: PERMISSION_TYPE.sendTemplate,
            }),
    }
}

export const allPermissionsAccess = {
    administrator: {
        integrations: {
            integrations: commonCRUDAccessPermission(
                PERMISSIONS_GROUP.administrator.integrations.integrations
            ),
        },
        setting: {
            setup: commonCRUDAccessPermission(PERMISSIONS_GROUP.administrator.setting.setup),
            locations: commonCRUDAccessPermission(
                PERMISSIONS_GROUP.administrator.setting.locations
            ),
            costTracking: commonCRUDAccessPermission(
                PERMISSIONS_GROUP.administrator.setting.costTracking
            ),
            departments: commonCRUDAccessPermission(
                PERMISSIONS_GROUP.administrator.setting.departments
            ),
            positions: commonCRUDAccessPermission(
                PERMISSIONS_GROUP.administrator.setting.positions
            ),
            alerts: commonCRUDAccessPermission(PERMISSIONS_GROUP.administrator.setting.alerts),
            bankAccounts: commonCRUDAccessPermission(
                PERMISSIONS_GROUP.administrator.setting.bankAccounts
            ),
            paperWork: commonCRUDAccessPermission(
                PERMISSIONS_GROUP.administrator.setting.paperWork
            ),
        },

        dashboard: {
            dashboard: commonCRUDAccessPermission(
                PERMISSIONS_GROUP.administrator.dashboard.dashboard
            ),
        },
        sequiDocs: {
            templates: commonCRUDAccessPermission(
                PERMISSIONS_GROUP.administrator.sequiDocs.templates
            ),
            documents: commonCRUDAccessPermission(
                PERMISSIONS_GROUP.administrator.sequiDocs.documents
            ),
        },
        payroll: {
            runPayrollAndApprovals: commonCRUDAccessPermission(
                PERMISSIONS_GROUP.administrator.payroll.runPayrollAndApprovals
            ),
            oneTimePayment: commonCRUDAccessPermission(
                PERMISSIONS_GROUP.administrator.payroll.oneTimePayment
            ),
            paymentRequest: commonCRUDAccessPermission(
                PERMISSIONS_GROUP.administrator.payroll.paymentRequest
            ),
            payrollReconciliation: commonCRUDAccessPermission(
                PERMISSIONS_GROUP.administrator.payroll.payrollReconciliation
            ),
        },
        sClearance: {
            sClearance: commonCRUDAccessPermission(
                PERMISSIONS_GROUP.administrator.sClearance.sClearance
            ),
        },
        sequiCRM: {
            sequiCRM: commonCRUDAccessPermission(PERMISSIONS_GROUP.administrator.sequiCRM.sequiCRM),
        },
        reports: {
            company: commonCRUDAccessPermission(PERMISSIONS_GROUP.administrator.reports.company),
            sales: commonCRUDAccessPermission(PERMISSIONS_GROUP.administrator.reports.sales),
            cost: commonCRUDAccessPermission(PERMISSIONS_GROUP.administrator.reports.cost),
            payroll: commonCRUDAccessPermission(PERMISSIONS_GROUP.administrator.reports.payroll),
            reconciliation: commonCRUDAccessPermission(
                PERMISSIONS_GROUP.administrator.reports.reconciliation
            ),
            clawback: commonCRUDAccessPermission(PERMISSIONS_GROUP.administrator.reports.clawback),
            pendingInstall: commonCRUDAccessPermission(
                PERMISSIONS_GROUP.administrator.reports.pendingInstall
            ),
        },
        permissions: {
            group: commonCRUDAccessPermission(PERMISSIONS_GROUP.administrator.permissions.group),
            policies: commonCRUDAccessPermission(
                PERMISSIONS_GROUP.administrator.permissions.policies
            ),
        },
        marketingDeals: {
            marketingDeals: commonCRUDAccessPermission(
                PERMISSIONS_GROUP.administrator.marketingDeal.marketingDeal
            ),
        },
        importExport: {},
        alertCenter: {
            alerts: commonCRUDAccessPermission(PERMISSIONS_GROUP.administrator.alertCenter.alerts),
        },
        support: {
            support: commonCRUDAccessPermission(PERMISSIONS_GROUP.administrator.support.support),
        },
    },
    standard: {
        dashboard: {
            dashboard: commonCRUDAccessPermission(PERMISSIONS_GROUP.standard.dashboard.dashboard),
        },
        mySales: {
            mysales: commonCRUDAccessPermission(PERMISSIONS_GROUP.standard.mySales.mySales),
            myOverrides: commonCRUDAccessPermission(PERMISSIONS_GROUP.standard.mySales.myOverrides),
            payStubs: commonCRUDAccessPermission(PERMISSIONS_GROUP.standard.mySales.payStubs),
            pendingPay: commonCRUDAccessPermission(PERMISSIONS_GROUP.standard.mySales.pendingPay),
        },
        hiring: {
            hiringProgress: commonCRUDAccessPermission(
                PERMISSIONS_GROUP.standard.hiring.hiringProgress
            ),
            leads: commonCRUDAccessPermission(PERMISSIONS_GROUP.standard.hiring.leads),
            onboardingEmployees: commonCRUDAccessPermission(
                PERMISSIONS_GROUP.standard.hiring.onboardingEmployees
            ),
            pipeline: commonCRUDAccessPermission(PERMISSIONS_GROUP.standard.hiring.pipeline),
        },
        calendar: {
            calendar: commonCRUDAccessPermission(PERMISSIONS_GROUP.standard.calendar.calendar),
        },
        management: {
            employee: commonCRUDAccessPermission(PERMISSIONS_GROUP.standard.management.employee),
            team: commonCRUDAccessPermission(PERMISSIONS_GROUP.standard.management.teams),
        },
        community: {},
        projections: {},
        reports: {
            office: commonCRUDAccessPermission(PERMISSIONS_GROUP.standard.reports.office),
            sales: commonCRUDAccessPermission(PERMISSIONS_GROUP.standard.reports.sales),
            pastPayStubs: commonCRUDAccessPermission(
                PERMISSIONS_GROUP.standard.reports.pastPayStubs
            ),
            reconciliation: commonCRUDAccessPermission(
                PERMISSIONS_GROUP.standard.reports.reconciliation
            ),
            marketingDeal: null,
        },
        training: {},
        requestAndApprovels: {
            myRequests: commonCRUDAccessPermission(
                PERMISSIONS_GROUP.standard.requestsAndApprovals.myRequests
            ),
            approvals: commonCRUDAccessPermission(
                PERMISSIONS_GROUP.standard.requestsAndApprovals.approvals
            ),
        },
        support: {
            support: commonCRUDAccessPermission(PERMISSIONS_GROUP.standard.support.support),
        },
        sequidocs: {
            templates: commonCRUDAccessPermission(PERMISSIONS_GROUP.standard.sequiDocs.templates),
            documents: commonCRUDAccessPermission(PERMISSIONS_GROUP.standard.sequiDocs.documents),
        },
        referrals: {
            referrals: commonCRUDAccessPermission(PERMISSIONS_GROUP.standard.referrals.referrals),
        },
    },
    profileAccess: {
        profile: {
            personalInfo: commonCRUDAccessPermission(
                PERMISSIONS_GROUP.profile.profile.personalInfo
            ),
            employmentPackage: commonCRUDAccessPermission(
                PERMISSIONS_GROUP.profile.profile.employmentPackage
            ),
            taxInfo: commonCRUDAccessPermission(PERMISSIONS_GROUP.profile.profile.taxInfo),
            banking: commonCRUDAccessPermission(PERMISSIONS_GROUP.profile.profile.banking),
            documents: commonCRUDAccessPermission(PERMISSIONS_GROUP.profile.profile.documents),
            network: commonCRUDAccessPermission(PERMISSIONS_GROUP.profile.profile.network),
            auditLog: commonCRUDAccessPermission(PERMISSIONS_GROUP.profile.profile.auditLog),
        },
    },
}

const useCustomAccessRights = ({employeeData = null} = {}) => {
    const logggedInUserData = useSelector(getUserDataSelector)
    const isLoggedInUserSuperAdmin = useSelector(isUserSuperAdminSelector)
    const isLoggedInUserManager = useSelector(isUserManagerSelector)

    const isOwnUser = useMemo(
        () => logggedInUserData?.id == employeeData?.id,
        [employeeData?.id, logggedInUserData?.id]
    )

    const isOwnUserSuperAdmin = useMemo(
        () => isOwnUser && logggedInUserData?.is_super_admin,
        [isOwnUser, logggedInUserData?.is_super_admin]
    )
    const isResendWelcome = useMemo(
        () =>
            logggedInUserData?.is_super_admin &&
            getBooleanValue(employeeData?.first_time_changed_password) == 0,

        [logggedInUserData?.is_super_admin, employeeData?.first_time_changed_password]
    )

    const isManagerOfUser = useMemo(
        () => logggedInUserData?.id == employeeData?.manager_id,
        [employeeData?.manager_id, logggedInUserData?.id]
    )

    let profileAccessData = useMemo(() => {
        return logggedInUserData?.profile_access_config
    }, [logggedInUserData?.profile_access_config])

    const isSameOffice = useMemo(() => {
        let employee_office_ids = [employeeData?.office_id]
        if (employeeData?.additional_locations?.length > 0) {
            const additional_office_ids =
                employeeData?.additional_locations?.map((item) => item?.office_id) ?? []
            employee_office_ids = [...employee_office_ids, ...additional_office_ids]
        }

        let own_office_ids = [logggedInUserData?.office_id]
        if (logggedInUserData?.additional_location?.length > 0) {
            const additional_office_ids =
                logggedInUserData?.additional_location?.map((item) => item?.office_id) ?? []

            own_office_ids = [...own_office_ids, ...additional_office_ids]
        }

        return own_office_ids?.some((item) => employee_office_ids?.includes(item))
    }, [
        employeeData?.additional_locations,
        employeeData?.office_id,
        logggedInUserData?.additional_location,
        logggedInUserData?.office_id,
    ])

    const officeAccess = useMemo(() => {
        return profileAccessData?.profile_access
    }, [profileAccessData?.profile_access])

    const positionAccess = useMemo(() => {
        return profileAccessData?.position_access
    }, [profileAccessData?.position_access])

    const haveAccessForOffice = useMemo(() => {
        return (
            profileAccessData?.profile_access == 'all_offices' ||
            (profileAccessData?.profile_access == 'same_office' && isSameOffice)
        )
    }, [isSameOffice, profileAccessData?.profile_access])

    const haveAccessForPosition = useMemo(() => {
        return (
            profileAccessData?.position_access &&
            (profileAccessData?.position_access?.includes(0) ||
                profileAccessData?.position_access?.includes(Number(employeeData?.sub_position_id)))
        )
    }, [employeeData?.sub_position_id, profileAccessData?.position_access])

    const hasProfileAccess = useMemo(() => {
        return isOwnUser || (haveAccessForPosition && haveAccessForOffice)
    }, [haveAccessForOffice, haveAccessForPosition, isOwnUser])

    // Hiring
    const showConfigurationButtonAccess = useMemo(
        () => isLoggedInUserSuperAdmin,
        [isLoggedInUserSuperAdmin]
    )

    const employeeProfileAccess = useMemo(() => {
        return {
            hasProfileAccess,
            // firstRoute
            redirectRoute: hasProfileAccess
                ? allPermissionsAccess.profileAccess.profile.personalInfo.viewFunc()
                    ? '/user/personal-info'
                    : allPermissionsAccess.profileAccess.profile.employmentPackage.viewFunc()
                    ? '/user/employment-package'
                    : allPermissionsAccess.profileAccess.profile.taxInfo.viewFunc()
                    ? '/user/tax-info'
                    : allPermissionsAccess.profileAccess.profile.banking.viewFunc()
                    ? '/user/banking'
                    : allPermissionsAccess.profileAccess.profile.documents.viewFunc()
                    ? '/user/document'
                    : !isOwnUserSuperAdmin ||
                      allPermissionsAccess.profileAccess.profile.network.viewFunc()
                    ? '/user/Network'
                    : ''
                : '',

            // Top Button
            changeProfilePictureAccess: isOwnUser || isLoggedInUserSuperAdmin,
            showTransferButtonAccess:
                !isOwnUser &&
                hasProfileAccess &&
                allPermissionsAccess.profileAccess.profile.employmentPackage.transferEmployeeFunc(),
            showEnableDismissButtonAccess:
                !isOwnUser &&
                hasProfileAccess &&
                allPermissionsAccess.profileAccess.profile.employmentPackage.dismissUsersFunc(),
            showStartStopPayrollButtonAccess: !isOwnUser && isLoggedInUserSuperAdmin,
            showStartStopLoginButtonAccess: !isOwnUser && isLoggedInUserSuperAdmin,
            showResetPasswordButtonAccess:
                isOwnUser ||
                (hasProfileAccess && profileAccessData?.account_access?.reset_password),
            showPayrollHistoryButton:
                isOwnUser ||
                (hasProfileAccess && profileAccessData?.account_access?.payroll_history),
            showChangePasswordButton: isOwnUser,
            showSendEmailButton: isResendWelcome,

            // Personal Info
            viewPersonalInfoAccess:
                isOwnUser ||
                (hasProfileAccess && allPermissionsAccess.profileAccess.profile.personalInfo?.view),
            editPersonalInfo:
                isOwnUser ||
                (hasProfileAccess && allPermissionsAccess.profileAccess.profile.personalInfo?.edit),

            // Employment Package
            viewEmploymentPackageInfoAccess:
                isOwnUser ||
                (hasProfileAccess &&
                    allPermissionsAccess.profileAccess.profile.employmentPackage?.view),
            editEmploymentPackageAccess:
                !isOwnUser &&
                hasProfileAccess &&
                allPermissionsAccess.profileAccess.profile.employmentPackage?.edit,

            showEditHireDateButton: !isOwnUser && isLoggedInUserSuperAdmin,

            // Tax Info
            viewTaxInfoAccess:
                isOwnUser ||
                (hasProfileAccess && allPermissionsAccess.profileAccess.profile.taxInfo?.view),
            editTaxInfoAccess:
                isOwnUser ||
                (hasProfileAccess && allPermissionsAccess.profileAccess.profile.taxInfo?.edit),

            // Bankingg Info
            viewBankingAccess:
                isOwnUser ||
                (hasProfileAccess && allPermissionsAccess.profileAccess.profile.banking?.view),
            editBankingInfoAccess:
                isOwnUser ||
                (hasProfileAccess && allPermissionsAccess.profileAccess.profile.banking?.edit),

            // Documet Tab
            viewDocumentAccess:
                isOwnUser ||
                (hasProfileAccess && allPermissionsAccess.profileAccess.profile.documents?.view),
            reviewDocumentAccess: isOwnUser,
            addDocumentAccess:
                isOwnUser ||
                (hasProfileAccess && allPermissionsAccess.profileAccess.profile.documents?.add),
            deleteDocumentAccess:
                isOwnUser ||
                (hasProfileAccess && allPermissionsAccess.profileAccess.profile.documents?.delete),

            // Permission Tab
            viewPermissionAccess: isLoggedInUserSuperAdmin,
            editPermissionsAccess: isLoggedInUserSuperAdmin,

            // Network Tab
            showEnableDisableOverrideButttonAccess:
                !isOwnUser &&
                (isLoggedInUserSuperAdmin ||
                    allPermissionsAccess.profileAccess.profile.network?.edit),
            viewNetworkAccess:
                isOwnUser ||
                (hasProfileAccess && allPermissionsAccess.profileAccess.profile.network?.view),
            addManualOverrideAccess:
                isLoggedInUserSuperAdmin || allPermissionsAccess.profileAccess.profile.network?.add,
            showOverridesOnButtonAccess: isLoggedInUserSuperAdmin,

            //audit Log
            viewAuditLog:
                isOwnUser ||
                (hasProfileAccess && allPermissionsAccess.profileAccess.profile.auditLog?.view),
        }
    }, [
        hasProfileAccess,
        isLoggedInUserSuperAdmin,
        isOwnUser,
        isOwnUserSuperAdmin,
        profileAccessData?.account_access?.payroll_history,
        profileAccessData?.account_access?.reset_password,
        isResendWelcome,
    ])

    const sideBarAccess = useMemo(
        () => ({
            administrator: {
                dashboard: allPermissionsAccess.administrator.dashboard.dashboard.viewFunc(),
                setting:
                    isPermittedForAccess({
                        customCondition:
                            allPermissionsAccess.administrator.setting.setup.viewFunc(),
                    }) ||
                    allPermissionsAccess.administrator.setting.locations.viewFunc() ||
                    allPermissionsAccess.administrator.setting.costTracking.viewFunc() ||
                    allPermissionsAccess.administrator.setting.departments.viewFunc() ||
                    allPermissionsAccess.administrator.setting.positions.viewFunc() ||
                    allPermissionsAccess.administrator.setting.alerts.viewFunc() ||
                    allPermissionsAccess.administrator.setting.bankAccounts.viewFunc() ||
                    allPermissionsAccess.administrator.setting.paperWork.viewFunc(),
                integrations:
                    allPermissionsAccess.administrator.integrations.integrations.viewFunc(),
                sequiDocs:
                    allPermissionsAccess.administrator.sequiDocs.documents.viewFunc() ||
                    allPermissionsAccess.administrator.sequiDocs.templates.viewFunc(),
                payroll:
                    allPermissionsAccess.administrator.payroll.oneTimePayment.viewFunc() ||
                    allPermissionsAccess.administrator.payroll.paymentRequest.viewFunc() ||
                    allPermissionsAccess.administrator.payroll.payrollReconciliation.viewFunc(),

                sClearance: allPermissionsAccess.administrator.sClearance.sClearance.viewFunc(),
                sequiCRM: allPermissionsAccess.administrator.sequiCRM.sequiCRM.viewFunc(),
                reports:
                    allPermissionsAccess.administrator.reports.company.viewFunc() ||
                    allPermissionsAccess.administrator.reports.clawback.viewFunc() ||
                    allPermissionsAccess.administrator.reports.cost.viewFunc() ||
                    allPermissionsAccess.administrator.reports.payroll.viewFunc() ||
                    allPermissionsAccess.administrator.reports.reconciliation.viewFunc() ||
                    allPermissionsAccess.administrator.reports.pendingInstall.viewFunc() ||
                    allPermissionsAccess.administrator.reports.sales.viewFunc(),
                permissions:
                    allPermissionsAccess.administrator.permissions.group.viewFunc() ||
                    allPermissionsAccess.administrator.permissions.policies.viewFunc(),
                alertCenter: allPermissionsAccess.administrator.alertCenter.alerts.viewFunc(),
                support: allPermissionsAccess.administrator.support.support.viewFunc(),
            },
            standard: {
                dashboard: allPermissionsAccess.standard.dashboard.dashboard.viewFunc(),
                mySales:
                    allPermissionsAccess.standard.mySales.mysales.viewFunc() ||
                    allPermissionsAccess.standard.mySales.myOverrides.viewFunc() ||
                    allPermissionsAccess.standard.mySales.payStubs.viewFunc(),
                hiring:
                    allPermissionsAccess.standard.hiring.hiringProgress.viewFunc() ||
                    allPermissionsAccess.standard.hiring.leads.viewFunc() ||
                    allPermissionsAccess.standard.hiring.onboardingEmployees.viewFunc() ||
                    allPermissionsAccess.standard.hiring.pipeline.viewFunc(),
                referrals: allPermissionsAccess.standard.referrals.referrals.viewFunc(),
                sequiDocs:
                    allPermissionsAccess.standard.sequidocs.documents.viewFunc() ||
                    allPermissionsAccess.standard.sequidocs.templates.viewFunc(),
                calendar: allPermissionsAccess.standard.calendar.calendar.viewFunc(),
                management:
                    allPermissionsAccess.standard.management.employee.viewFunc() ||
                    allPermissionsAccess.standard.management.team.viewFunc(),
                reports:
                    allPermissionsAccess.standard.reports.office.viewFunc() ||
                    allPermissionsAccess.standard.reports.pastPayStubs.viewFunc() ||
                    allPermissionsAccess.standard.reports.reconciliation.viewFunc() ||
                    allPermissionsAccess.standard.reports.sales.viewFunc(),
                requestAndApprovals:
                    allPermissionsAccess.standard.requestAndApprovels.myRequests.viewFunc() ||
                    allPermissionsAccess.standard.requestAndApprovels.approvals.viewFunc(),
                support: allPermissionsAccess.standard.support.support.viewFunc(),
            },
        }),
        []
    )

    return {
        officeAccess,
        positionAccess,
        // Sidebar
        sideBarAccess: sideBarAccess,

        // Hiring
        hiringAccess: {
            showConfigurationButtonAccess,
        },
        // Employee Profile
        employeeProfileAccess: employeeProfileAccess,
    }
}

export default useCustomAccessRights
