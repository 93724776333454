import {useEffect, useState} from 'react'
import {subDomainAvailiblityCheckService} from '../services/Services'
import {CURRENT_HOST, IS_TENANT_CLIENTS} from '../constants/SERVERS'
import {SEQUIFI_WEBSITE_URL} from '../constants/constants'

const useIsServerCheck = () => {
    const [serverCheckingLoading, setServerCheckingLoading] = useState(IS_TENANT_CLIENTS)
    const [isValidServer, setIsValidServer] = useState(false)
    useEffect(() => {
        if (IS_TENANT_CLIENTS) {
            subDomainAvailiblityCheckService(CURRENT_HOST)
                .then((res) => {
                    if (!res?.status) {
                        window.open(SEQUIFI_WEBSITE_URL, '_self')
                    } else {
                        setIsValidServer(res?.status)
                    }
                })
                .catch(() => {
                    setIsValidServer(false)
                })
                .finally(() => {
                    setServerCheckingLoading(false)
                })
        } else {
            setServerCheckingLoading(false)
            setIsValidServer(true)
        }
    }, [])
    return {
        serverCheckingLoading,
        isValidServer,
    }
}

export default useIsServerCheck
