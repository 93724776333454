import {useEffect, useState} from 'react'
import {
    getDefaultImage,
    getServerImage,
    IMAGE_TYPE,
    isValidURL,
    urlToBase64,
} from '../../helpers/CommonHelpers'

const CustomImage = ({
    src = null,
    customSrc = null,
    type = IMAGE_TYPE.userAvatar,
    className = null,
    alt = 'Sequifi',
    style = null,
    showImageError = true,
    objectFit = 'cover',
    convertToBase64 = false,
}) => {
    const [isImageBroken, setIsImageBroken] = useState(false)
    const [base64Image, setBase64Image] = useState(null)
    const onImageError = (e) => {
        if (!isImageBroken) setIsImageBroken(true)
    }

    useEffect(() => {
        const modifiedSrc = customSrc ? customSrc : src
        if (convertToBase64) {
            if (isValidURL(modifiedSrc)) {
                urlToBase64(modifiedSrc)
                    .then(setBase64Image)
                    .catch(() => {})
            } else {
                setBase64Image(getDefaultImage(type))
            }
        }
    }, [customSrc, src])

    return (
        <img
            {...(showImageError
                ? {onError: (e) => (showImageError ? onImageError(e) : null)}
                : null)}
            alt={alt}
            className={className}
            src={
                isImageBroken
                    ? getDefaultImage(type)
                    : convertToBase64
                    ? base64Image
                    : customSrc
                    ? customSrc
                    : src
                    ? src
                    : getDefaultImage(type)
            }
            // src={
            //     isImageBroken
            //         ? getDefaultImage(type)
            //         : customSrc
            //         ? customSrc
            //         : src
            //         ? getServerImage(src, type)
            //         : getDefaultImage(type)
            // }
            style={{
                objectFit: objectFit,
                ...style,
            }}
        />
    )
}

export default CustomImage
