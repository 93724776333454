import React from 'react'
import Skeleton from 'react-loading-skeleton'

const CustomSkeleton = ({
    width = '100%',
    count = 1,
    isCircle = false,
    height = '100%',
    className = null,
    containerClassName = null,
    borderRadius = null,
    inline = false,
    style = null,
    duration = 1.5,
    baseColor = null,
    highlightColor = null,
    enableAnimation = true,
}) => {
    return (
        <Skeleton
            style={style}
            count={count}
            baseColor=''
            highlightColor=''
            inline={inline}
            containerClassName={containerClassName}
            duration={duration}
            className={className}
            circle={isCircle}
            borderRadius={borderRadius}
            enableAnimation={enableAnimation}
        />
    )
}

export default CustomSkeleton
