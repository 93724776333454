import {useCallback, useEffect, useState} from 'react'
import CustomLoader from '../../customLoader/CustomLoader'
import _ from 'lodash'
import CustomInput, {
    CommonLabel,
    INPUT_TYPE,
    PrefixSuffixComponent,
} from '../customInput/CustomInput'
import {AutoComplete} from 'primereact/autocomplete'
import {
    getAddressFromReverseGeocodingService,
    getLatLongByLocationService,
    getPlaceAddressService,
    getTimeZoneFromLatLongService,
} from '../../../services/Services'
import {Button} from 'primereact/button'
import CustomModal from '../../customModal/CustomModal'
import CustomButton from '../../customButtton/CustomButton'
import useValidation from '../../../hooks/useValidation'
import {manualAddressValidation} from '../../../validations/validations'
import './AddressPickerStyles.css'
import {KTSVG} from '../../../_metronic/helpers'
import {fontsFamily} from '../../../assets/fonts/fonts'
import CustomTooltip from '../../customTooltip/CustomTooltip'

export const INITIAL_ADDRESS_FIELD = {
    full_address: null,
    address_line1: null,
    address_line2: null,
    city: null,
    state: null,
    zip: null,
    lat: null,
    long: null,
    time_zone: null,
}
const CustomAddressInput = ({
    data = INITIAL_ADDRESS_FIELD,
    setData = () => {},
    modalTitle,
    label,
    hideLabel,
    subLabel,
    required,
    placeholder = 'Start typing address',
    style,
    errorMessage = '',
    className,
    disabled,
    needTimeZone = false,
    needLatLong = true,
    prefixText,
    showAddressLine2 = true,
    suffixText,
    autoCompleteId = null,
    helpText = '',
}) => {
    const [loading, setLoading] = useState(false)
    const [searchText, setSearchText] = useState(null)
    const [foundAddresses, setFoundAddresses] = useState([])
    const [showManualAddressModal, setShowManualAddressModal] = useState(false)
    const [addressData, setAddressData] = useState(INITIAL_ADDRESS_FIELD)

    useEffect(() => {
        setAddressData((val) => ({
            ...val,
            ...data,
        }))
        setFormattedText({
            ...addressData,
            ...data,
        })
    }, [data])

    const setFormattedText = useCallback((addData) => {
        let fullAddress = [
            addData?.address_line1 ?? '',
            addData?.address_line2 ?? '',
            addData?.city ?? '',
            addData?.state ?? '',
            addData?.zip ?? '',
        ]

        if (fullAddress.filter((val) => val)?.length <= 0) fullAddress.push(addData?.full_address)
        let finalAddress = fullAddress.filter((val) => val).join(', ')
        setSearchText(finalAddress)
    }, [])

    const setAddressFinalData = useCallback(
        (addData) => {
            let completeAddress = [
                addData?.address_line1 ?? '',
                addData?.address_line2 ?? '',
                addData?.city ?? '',
                addData?.state ?? '',
                addData?.zip ?? '',
            ]
                .filter((val) => val)
                .join(', ')

            setAddressData({...addData, full_address: completeAddress})
            setFormattedText({...addData, full_address: completeAddress})
            setData({...addData, full_address: completeAddress})
        },
        [setData, setFormattedText]
    )

    const searchFunc = useCallback(
        _.debounce((e) => {
            setLoading(true)
            getPlaceAddressService(encodeURI(`${e?.query}`))
                .then(async (res) => {
                    let data = res?.predictions?.map((item) => ({
                        ...item,
                        address: item?.description,
                        city: item?.terms[item?.terms?.length - 2],
                    }))
                    const promise = data?.map(
                        (item, ind) =>
                            new Promise((resolve) => {
                                getAddressFromPlaceId(item?.place_id, item)
                                    .then((res) => {
                                        let dt = {...item, ...res}
                                        dt.address = `${dt?.address}${
                                            res?.zip ? `, ${res?.zip}` : ''
                                        }`
                                        resolve(dt)
                                    })
                                    .catch(() => {
                                        resolve(item)
                                    })
                            })
                    )
                    Promise.all(promise)
                        .then((finalRes) => {
                            setFoundAddresses(finalRes)
                            setLoading(false)
                        })
                        .catch(() => {})
                })
                .catch(() => {
                    setLoading(false)
                    setFoundAddresses([])
                })
        }, 500),
        []
    )

    const onEnterManuallyPress = useCallback(() => {
        setShowManualAddressModal(true)
    }, [])

    const onHideEnterManualModal = useCallback(() => {
        setShowManualAddressModal(false)
    }, [])

    const getAddressFromPlaceId = useCallback(
        (place_id = null, data) =>
            new Promise((resolve) => {
                getAddressFromReverseGeocodingService(place_id)
                    .then((res) => {
                        let result = {
                            address_line1: [
                                data?.structured_formatting?.main_text,
                                res?.results[0]?.address_components?.filter((item) =>
                                    item?.types?.includes('neighborhood')
                                )?.[0]?.long_name,
                                res?.results[0]?.address_components?.filter((item) =>
                                    item?.types?.includes('sublocality_level_3')
                                )?.[0]?.long_name,
                                res?.results[0]?.address_components?.filter((item) =>
                                    item?.types?.includes('sublocality_level_2')
                                )?.[0]?.long_name,
                                res?.results[0]?.address_components?.filter((item) =>
                                    item?.types?.includes('sublocality_level_1')
                                )?.[0]?.long_name,
                            ]
                                .filter((val) => val)
                                ?.join(', '),
                            address_line2: '',
                            city: res?.results[0]?.address_components?.filter((item) =>
                                item?.types?.includes('locality')
                            )?.[0]?.long_name,
                            state: res?.results[0]?.address_components?.filter((item) =>
                                item?.types?.includes('administrative_area_level_1')
                            )?.[0]?.long_name,
                            zip: res?.results[0]?.address_components?.filter((item) =>
                                item?.types?.includes('postal_code')
                            )?.[0]?.long_name,
                            lat: res?.results?.[0]?.geometry?.location?.lat,
                            long: res?.results?.[0]?.geometry?.location?.lng,
                        }
                        resolve(result)
                    })
                    .catch(() => {})
            }),
        []
    )
    const onSelectAddress = useCallback(
        (e) => {
            let data = e?.value
            if (needTimeZone) {
                getTimeZoneFromLatLongService(data?.lat, data?.long)
                    .then((timeZoneRes) => {
                        data.time_zone = timeZoneRes?.timeZoneId
                        setAddressFinalData(data)
                    })
                    .catch(() => {
                        setAddressFinalData(data)
                    })
            } else {
                setAddressFinalData(data)
            }
        },
        [needTimeZone, setAddressFinalData]
    )

    const onRemoveAddressPress = useCallback(() => {
        setAddressFinalData(INITIAL_ADDRESS_FIELD)
    }, [setAddressFinalData])

    const [isActive, setIsActive] = useState(false)

    const handleFocus = () => {
        setIsActive(true)
    }

    const handleBlur = () => {
        setAddressFinalData({
            ...addressData,
        })
        setIsActive(false)
    }

    return (
        <div className={`w-100`}>
            <div className='d-flex flex-column gap-6px w-100'>
                {showManualAddressModal ? (
                    <ManualAddressPopup
                        addressData={addressData}
                        modalTitle={modalTitle}
                        show={showManualAddressModal}
                        handleClose={onHideEnterManualModal}
                        needTimeZone={needTimeZone}
                        showAddressLine2={showAddressLine2}
                        needLatLong={needLatLong}
                        setAddress={(updatedAddress) => {
                            setAddressFinalData({
                                ...addressData,
                                ...updatedAddress,
                            })
                        }}
                    />
                ) : null}
                <CommonLabel
                    label={label}
                    hideLabel={hideLabel}
                    subLabel={subLabel}
                    required={required}
                />

                <div
                    className={`addressInputContainer ${isActive ? 'focus' : ''} ${
                        errorMessage ? 'error' : ''
                    }`}
                >
                    <div
                        className='d-flex flex-center'
                        style={{cursor: disabled ? 'not-allowed' : 'pointer'}}
                    >
                        <CustomTooltip title='Enter manually' style={{fontSize: 12}}>
                            <KTSVG
                                path='/media/icons/duotune/art/keyboard.svg'
                                svgClassName='w-20px h-20px'
                                onClick={disabled ? null : onEnterManuallyPress}
                            />
                        </CustomTooltip>
                    </div>
                    <AutoComplete
                        id={autoCompleteId}
                        className={`text-md-semibold flex-grow-1 ${className}`}
                        forceSelection
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        value={searchText ?? ''}
                        onChange={(e) => {
                            setSearchText(e?.value)
                        }}
                        placeholder={placeholder}
                        disabled={disabled}
                        style={{
                            fontFamily: fontsFamily.manrope,
                            ...style,
                        }}
                        field={'address'}
                        suggestions={foundAddresses}
                        completeMethod={searchFunc}
                        onSelect={onSelectAddress}
                        loadingIcon={() => null}
                    />
                    <div
                        hidden={!loading}
                        className='my-auto spinner-border text-sqGray600 spinner-border-sm me-2'
                    ></div>
                    {helpText ? (
                        <span className='d-flex flex-center ps-2'>
                            <CustomTooltip className='' title={helpText}>
                                <KTSVG
                                    path='/media/icons/duotune/art/question-gray700.svg'
                                    svgClassName='w-14px h-14px cursor-pointer'
                                />
                            </CustomTooltip>
                        </span>
                    ) : null}
                    {searchText ? (
                        <div
                            className='d-flex flex-center'
                            style={{cursor: disabled ? 'not-allowed' : 'pointer'}}
                        >
                            <KTSVG
                                path='/media/icons/duotune/art/x.svg'
                                svgClassName='w-20px h-20px'
                                onClick={disabled ? null : onRemoveAddressPress}
                            />
                        </div>
                    ) : null}
                </div>
                <div className='text-sm-regular text-sqError500'>{errorMessage}</div>
            </div>
        </div>
    )
}

export default CustomAddressInput

const ManualAddressPopup = ({
    addressData = null,
    show,
    handleClose,
    setAddress,
    modalTitle = 'Set Address',
    needTimeZone,
    needLatLong,
    showAddressLine2,
}) => {
    const [loading, setLoading] = useState(false)
    const [manualData, setManualData] = useState(addressData ?? INITIAL_ADDRESS_FIELD)
    const [validateManualAddress, manualAddressErrors] = useValidation()

    useEffect(() => {
        if (manualAddressErrors?.beginValidating) {
            validateManualAddress(manualAddressValidation(manualData))
        }
    }, [manualData])

    useEffect(() => {
        setManualData((val) => ({
            ...val,
            ...addressData,
        }))
    }, [addressData])

    const updateCompanyProfileData = useCallback((field, value) => {
        setManualData((val) => ({
            ...val,
            [field]: value,
        }))
    }, [])

    const onChangeInputData = useCallback(
        (e) => {
            updateCompanyProfileData(e?.target?.name, e?.target?.value)
        },
        [updateCompanyProfileData]
    )

    const onSaveData = useCallback(
        (e) => {
            validateManualAddress(manualAddressValidation(manualData)).then((res) => {
                let data = {...manualData}
                if (res?.isValidate) {
                    setLoading(true)
                    if (needLatLong) {
                        getLatLongByLocationService(encodeURI(`${data?.city}, ${data?.state}`))
                            .then((res) => {
                                data.lat = res?.results?.[0]?.geometry?.location?.lat ?? null
                                data.long = res?.results?.[0]?.geometry?.location?.lng ?? null
                                if (needTimeZone) {
                                    getTimeZoneFromLatLongService(data.lat, data.long)
                                        .then((timeZoneRes) => {
                                            data.time_zone = timeZoneRes?.timeZoneId
                                            setAddress(data)
                                            handleClose()
                                            setLoading(false)
                                        })
                                        .catch(() => {
                                            setAddress(data)
                                            handleClose()
                                            setLoading(false)
                                        })
                                } else {
                                    setAddress(data)
                                    handleClose()
                                    setLoading(false)
                                }
                            })
                            .catch(() => {
                                handleClose()
                                setLoading(false)
                            })
                    } else {
                        if (needTimeZone) {
                            getTimeZoneFromLatLongService(data.lat, data.long)
                                .then((timeZoneRes) => {
                                    data.time_zone = timeZoneRes?.timeZoneId
                                    setAddress(data)
                                    handleClose()
                                })
                                .catch(() => {
                                    setAddress(data)
                                    handleClose()
                                    setLoading(false)
                                })
                        } else {
                            setAddress(data)
                            handleClose()
                            setLoading(false)
                        }
                    }
                }
            })
        },
        [handleClose, manualData, needLatLong, needTimeZone, setAddress, validateManualAddress]
    )

    return (
        <CustomModal show={show} onHide={handleClose} maxWidth='500' title={modalTitle}>
            <CustomLoader visible={loading} full />
            <div
                className='mx-5 mx-sm-auto'
                style={{fontSize: '14px', fontWeight: '600', fontFamily: 'Manrope'}}
            >
                {/* line */}
                <div className='mb-5'>
                    <CustomInput
                        id={'Address_Line_1_Input'}
                        required
                        label={'Address Line 1'}
                        placeholder='Set Address Line 1'
                        name='address_line1'
                        value={manualData?.address_line1}
                        onChange={onChangeInputData}
                        errorMessage={manualAddressErrors?.address_line1}
                    />
                </div>

                {showAddressLine2 ? (
                    <div className='mb-5'>
                        <CustomInput
                            id={'Address_Line_2_Input'}
                            label={'Address Line 2'}
                            placeholder='Set Address Line 2'
                            name='address_line2'
                            value={manualData?.address_line2 ?? ''}
                            onChange={onChangeInputData}
                        />
                    </div>
                ) : null}

                {/* line */}
                <div className='row gap-sm-0 gap-5 align-items-center mb-5 '>
                    <div className='col-sm'>
                        <CustomInput
                            id={'State_Input'}
                            required
                            label={'State'}
                            placeholder='Set State'
                            errorMessage={manualAddressErrors?.state}
                            name='state'
                            value={manualData?.state}
                            onChange={onChangeInputData}
                        />
                    </div>
                    <div className='col-sm'>
                        <CustomInput
                            id={'City_Input'}
                            required
                            label={'City'}
                            errorMessage={manualAddressErrors?.city}
                            placeholder='Set City'
                            name='city'
                            value={manualData?.city}
                            onChange={onChangeInputData}
                        />
                    </div>
                </div>
                {/* line */}
                <div className='mb-15'>
                    <CustomInput
                        required
                        label={'zip'}
                        type={INPUT_TYPE.number}
                        placeholder='Enter ZIP'
                        name='zip'
                        errorMessage={manualAddressErrors?.zip}
                        value={manualData?.zip}
                        onChange={onChangeInputData}
                    />
                </div>

                {/* <div className='mb-15 row'>
                    {needTimeZone ? (
                        <span className='col'>
                            Timezone: <strong>{manualData?.time_zone ?? '-'}</strong>
                        </span>
                    ) : null}
                    {needLatLong ? (
                        <span className='col'>
                            LatLng:{' '}
                            <strong>
                                {manualData?.lat && manualData?.long
                                    ? `${manualData?.lat}, ${manualData?.long}`
                                    : '-'}
                            </strong>
                        </span>
                    ) : null}
                </div> */}

                <div className='text-center'>
                    <CustomButton buttonLabel={modalTitle} onClick={onSaveData} />
                </div>
            </div>
        </CustomModal>
    )
}
