import React from 'react'
import {KTSVG} from '../../_metronic/helpers'
import CustomButton, {BUTTON_TYPE} from '../customButtton/CustomButton'

const CustomDelete = ({onClick, loading, id, width = 35, height = 35}) => {
    return (
        <>
            {loading ? (
                <i
                    class='fa-solid fa-circle-notch fa-spin text-cmError'
                    style={{fontSize: '20px'}}
                ></i>
            ) : (
                <KTSVG
                    id={id}
                    path='/media/icons/duotune/art/DeleteIcon.svg'
                    className='cursor-pointer'
                    onClick={onClick}
                    svgClassName={`w-${width}px h-${height}px`}
                />
            )}
        </>
    )
}

export default CustomDelete
