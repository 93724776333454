import {KTSVG} from '../../../_metronic/helpers'
import {fontsFamily} from '../../../assets/fonts/fonts'
import {Navigate, Outlet, Route, Routes, useLocation} from 'react-router-dom'
import SequiDocLogin from './components/SequiDocLogin'
import SequidocSigningPdfview from './components/SequidocSigningPdfview'
import DownloadDocumentPage from './components/DownloadDocumentPage'
import SequiDocHomePage from './components/sequidocHome/SequiDocHomePage'
import OfferRejectedPage from './components/sequidocHome/OfferRejectedPage'
import RequestChangePage from './components/sequidocHome/RequestChangePage'
import OfferUnderReview from './components/OfferUnderReview'

const SequidocDocumentRoutes = () => {
    const location = useLocation()
    const SequiDocPrivateRoute = ({children}) => {
        return location?.state?.isAuthenticated ? children : <Navigate to={'/auth'} replace />
    }

    const loginPageHeader = (
        <>
            <nav className='navbar d-flex flex-column bg-cmwhite sticky-top px-10 flex-center gap-1'>
                <div className='w-200px h-60px mx-auto'>
                    <KTSVG
                        path='/media/icons/duotune/art/SequiDocLogo.svg'
                        svgClassName='w-100'
                        width={'200px'}
                        svgStyle={{width: '100%', height: '100%'}}
                    />
                </div>
            </nav>
        </>
    )
    const homePageHeader = (
        <>
            <div>
                <div className='w-200px h-60px mx-auto'>
                    <KTSVG
                        path='/media/icons/duotune/art/SequiDocLogo.svg'
                        svgClassName='w-100'
                        width={'200px'}
                        svgStyle={{width: '100%', height: '100%'}}
                    />
                </div>
                <div>
                    <div className='d-flex flex-center '>
                        <div
                            className='text-cmGrey500'
                            style={{fontWeight: '500', fontSize: '12px'}}
                        >
                            Powered by
                        </div>

                        <KTSVG
                            path='/media/icons/duotune/art/SequifiLogoWithText.svg'
                            svgStyle={{width: '75px', height: '23px'}}
                        />
                    </div>
                </div>
            </div>
        </>
    )
    const signingPageHeader = (
        <>
            <div>
                <div className='w-200px h-60px mx-auto'>
                    <KTSVG
                        path='/media/icons/duotune/art/SequiDocLogo.svg'
                        svgClassName='w-100'
                        width={'200px'}
                        svgStyle={{width: '100%', height: '100%'}}
                    />
                </div>
                <div className='text-cmGrey700' style={{fontSize: 14, fontWeight: 600}}>
                    Please review and sign the document
                </div>
            </div>
        </>
    )

    return (
        <div style={{fontFamily: fontsFamily.manrope, height: '100vh'}}>
            <Routes>
                <Route
                    element={
                        location.pathname.includes('download-document') ||
                        location.pathname.includes('offer-rejected') ||
                        location.pathname.includes('request-change') ? (
                            <Outlet />
                        ) : (
                            <>
                                <nav className='navbar d-flex flex-column bg-cmwhite sticky-top px-10 flex-center gap-1'>
                                    {location.pathname.includes('login')
                                        ? loginPageHeader
                                        : location.pathname.includes('document-signing/sign')
                                        ? signingPageHeader
                                        : homePageHeader}
                                </nav>

                                <div className='h-100  overflow-auto app-content py-0 pb-15'>
                                    <Outlet />
                                </div>

                                <div className='bg-cmwhite d-flex flex-center fixed-bottom h-sm-5 h-10'>
                                    <div className=' d-flex align-items-center justify-content-between w-80 flex-wrap mx-auto'>
                                        <div
                                            className='text-cmGrey500'
                                            style={{fontWeight: '500', fontSize: '14px'}}
                                        >
                                            © Copyright | Sequidocs | All rights reserved
                                        </div>

                                        <div className='d-flex flex-center '>
                                            <div
                                                className='text-cmGrey500'
                                                style={{fontWeight: '500', fontSize: '12px'}}
                                            >
                                                Powered by
                                            </div>

                                            <KTSVG
                                                path='/media/icons/duotune/art/SequifiLogoWithText.svg'
                                                svgStyle={{width: '75px', height: '23px'}}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                >
                    <Route
                        path='login/:password'
                        element={
                            <>
                                <SequiDocLogin />
                            </>
                        }
                    />
                    <Route
                        path='home'
                        element={
                            <>
                                <SequiDocPrivateRoute>
                                    <SequiDocHomePage />
                                </SequiDocPrivateRoute>
                            </>
                        }
                    />
                    <Route
                        path='sign'
                        element={
                            <>
                                <SequiDocPrivateRoute>
                                    <SequidocSigningPdfview />
                                </SequiDocPrivateRoute>
                            </>
                        }
                    />

                    <Route
                        path='download-document'
                        element={
                            <>
                                <SequiDocPrivateRoute>
                                    <DownloadDocumentPage />
                                </SequiDocPrivateRoute>
                            </>
                        }
                    />
                    <Route
                        path='offer-rejected'
                        element={
                            <>
                                <SequiDocPrivateRoute>
                                    <OfferRejectedPage />
                                </SequiDocPrivateRoute>
                            </>
                        }
                    />
                    <Route
                        path='request-change'
                        element={
                            <>
                                <SequiDocPrivateRoute>
                                    <RequestChangePage />
                                </SequiDocPrivateRoute>
                            </>
                        }
                    />
                    <Route
                        path='under-review'
                        element={
                            <>
                                <SequiDocPrivateRoute>
                                    <OfferUnderReview />
                                </SequiDocPrivateRoute>
                            </>
                        }
                    />

                    {/* <Route path='document-signing/*' element={<Navigate to='login' />} /> */}
                </Route>
            </Routes>
        </div>
    )
}

export default SequidocDocumentRoutes
