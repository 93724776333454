import {Children} from 'react'

const Show = (props) => {
    let when = null,
        otherwise = null

    Children.forEach(props?.children, (children) => {
        if (children?.props?.isVisible == undefined) otherwise = children
        else if (!when && children?.props?.isVisible == true) when = children
    })
    return when || otherwise
}

Show.When = ({isVisible, children}) => isVisible && children
Show.Else = ({render, children}) => render || children

export default Show
