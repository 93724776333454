import ReactQuill from 'react-quill'
import QuillToolbar, {CustomImageSpec, formats} from './QuillToolbar'
import './styles.css'
import 'react-quill/dist/quill.snow.css'
import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {uploadImageToS3Service} from '../../../../services/Services'
import CustomLoader from '../../../../customComponents/customLoader/CustomLoader'
import CustomToast from '../../../../customComponents/customToast/CustomToast'
import {getErrorMessageFromResponse, replaceRange} from '../../../../helpers/CommonHelpers'
import AIInputBlock from './AiEditor/AIInputBlock'
import useSequifiConfig from '../../../../sequiConfig/useSequifiConfig'
import {getIntegrationListSelector} from '../../../../redux/selectors/IntegrationSelectors'
import {useSelector} from 'react-redux'
import {INTEGRATIONS_ID} from '../../../../constants/constants'
import {useResizeDetector} from 'react-resize-detector'

const QuillEditor = ({
    value,
    setValue,
    placeholder,
    style,
    quillRef = null,
    className = '',
    id = null,
    enableSequiAi = true,
}) => {
    const [loading, setLoading] = useState(false)
    const [openAIBlock, setOpenAIBlock] = useState(false)
    const {basedOnHost} = useSequifiConfig()
    const sequiAIActivationData = useSelector(getIntegrationListSelector)
    const [selection, setSelection] = useState(null)
    const aiModalRef = useRef(null)
    const aiModalDimention = useResizeDetector({targetRef: aiModalRef})

    const imageHandler = useCallback(() => {
        const input = document.createElement('input')
        input.setAttribute('type', 'file')
        input.setAttribute('accept', 'image/*')
        input.click()
        input.onchange = async () => {
            var file = input && input.files ? input.files[0] : null
            let quillObj = quillRef.current.getEditor()
            setLoading(true)
            uploadImageToS3Service(file)
                .then((res) => {
                    const range = quillObj.getSelection()
                    quillObj.editor.insertEmbed(range.index, 'image', res?.data)
                })
                .catch((e) => {
                    CustomToast.error(getErrorMessageFromResponse(e))
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [quillRef])

    //  Quill modules to attach to editor
    //       See https://quilljs.com/docs/modules/ for complete options
    const modules = useMemo(
        () => ({
            toolbar: {
                container: '#toolbar',
                handlers: {
                    image: imageHandler,
                },
            },

            blotFormatter: {
                specs: [CustomImageSpec],
            },
            history: {
                delay: 500,
                maxStack: 100,
                userOnly: true,
            },
            htmlEditButton: {
                debug: true,
                syntax: false,
            },
        }),
        [imageHandler]
    )

    const isSequiAIActived = useMemo(() => {
        return sequiAIActivationData?.find((item) => item?.id == INTEGRATIONS_ID.sequiAi)?.status
    }, [sequiAIActivationData])

    const onSequiAiPress = () => {
        if (quillRef?.current) {
            const quill = quillRef?.current?.getEditor()
            const data = quill?.getSelection()
            setSelection(data)
            //New code
            if (aiModalRef?.current?.style?.display == 'block') {
                aiModalRef.current.style.display = 'none'
                quill?.setSelection(0)
            } else {
                aiModalRef.current.style.display = 'block'
                const range = quill?.getBounds(data?.index ?? 0)
                const aiModalTop = range?.bottom ?? 0 // Adjust as needed
                aiModalRef.current.style.top = aiModalTop + 'px'
            }
        }
    }

    const contentHeight = useMemo(() => {
        const top = aiModalRef?.current?.style?.top?.replace('px', '')
        const fullHeight = Number(top) + aiModalDimention?.height
        return aiModalRef?.current?.style?.display == 'none'
            ? 'auto'
            : fullHeight < 400
            ? 400
            : fullHeight + 50
    }, [aiModalDimention?.height])

    return (
        <>
            <div className={`text-editor ${className}`}>
                <div className='float-end'>
                    <CustomLoader visible={loading} />
                </div>
                <QuillToolbar onSequiAiPress={onSequiAiPress} enableSequiAi={isSequiAIActived} />
                <div className='position-relative'>
                    <ReactQuill
                        id={id}
                        ref={quillRef}
                        theme={'snow'}
                        style={{
                            ...style,
                            height: contentHeight || 100,
                        }}
                        onChange={setValue}
                        value={value}
                        modules={modules}
                        formats={formats}
                        bounds={'.editor-container'}
                        placeholder={placeholder}
                        // onChangeSelection={(data) => {
                        //     if (data && data?.length) setSelection(data)
                        //     else setSelection(null)
                        // }}
                    />

                    <div
                        ref={aiModalRef}
                        className='mx-auto w-100 position-absolute p-5'
                        style={{minHeight: '275px', display: 'none'}}
                    >
                        <AIInputBlock
                            selection={selection}
                            quillRef={quillRef}
                            setOpenAIBlock={setOpenAIBlock}
                            editorText={value}
                            onSequiAiPress={onSequiAiPress}
                            setSelection={setSelection}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default QuillEditor
