import React, {useState} from 'react'
import CustomInput, {
    INPUT_TYPE,
} from '../../../../../../customComponents/customInputs/customInput/CustomInput'
import CustomButton, {
    BUTTON_SIZE,
} from '../../../../../../customComponents/customButtton/CustomButton'
import {KTSVG} from '../../../../../../_metronic/helpers'
import CustomImage from '../../../../../../customComponents/customImage/CustomImage'
import {IMAGE_TYPE, getErrorMessageFromResponse} from '../../../../../../helpers/CommonHelpers'
import {useSelector} from 'react-redux'
import {getCompanyProfileSelector} from '../../../../../../redux/selectors/SettingsSelectors'
import CustomToast from '../../../../../../customComponents/customToast/CustomToast'
import {resetPasswordService} from '../../../../../../services/Services'
import {useLocation, useNavigate} from 'react-router-dom'

const ResetPassword = () => {
    const companyData = useSelector(getCompanyProfileSelector)
    const [newPassword, setnewPassword] = useState(null)
    const [confirmPassword, setconfirmPassword] = useState(null)
    const [loading, setLoading] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()

    const handleSubmit = () => {
        if (!newPassword) return CustomToast.error('Enter new password')
        if (!confirmPassword) return CustomToast.error('Enter confirm password')
        if (newPassword !== confirmPassword) {
            CustomToast.error('Passwords do not match')
        } else {
            let encryptedData = location?.pathname?.split('/')
            setLoading(true)
            resetPasswordService({
                newpassword: newPassword,
                confirmpassword: confirmPassword,
                encrypt_id: encryptedData?.[encryptedData?.length - 1],
            })
                .then(() => {
                    navigate('/auth')
                    CustomToast.success('Password updated!')
                })
                .catch((e) => {
                    CustomToast.error(getErrorMessageFromResponse(e))
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    return (
        <div className='' style={{height: '100vh'}}>
            <div className='d-flex h-100' style={{marginTop: '-50px'}}>
                <div className='d-flex flex-column align-self-center w-sm-425px w-100 mx-10 mx-sm-auto'>
                    <CustomImage
                        type={IMAGE_TYPE.companyLogo}
                        src={companyData?.company_logo_s3}
                        style={{width: '100px'}}
                        className={'mx-auto mb-10'}
                    />

                    <div class='bg-cmwhite shadow-sm py-10 px-9 ' style={{borderRadius: 20}}>
                        <form className='' onSubmit={handleSubmit}>
                            <div
                                className='mb-6 text-cmGrey800'
                                style={{fontSize: 18, fontWeight: 600}}
                            >
                                Rest your password
                            </div>

                            <div className='mb-5'>
                                <CustomInput
                                    label={'New Password'}
                                    type={INPUT_TYPE.password}
                                    value={newPassword}
                                    onChange={(e) => setnewPassword(e.target.value)}
                                />
                            </div>
                            <div className='mb-10'>
                                <CustomInput
                                    label={'Confirm Password'}
                                    type={INPUT_TYPE.password}
                                    value={confirmPassword}
                                    onChange={(e) => setconfirmPassword(e.target.value)}
                                />
                            </div>
                            <div>
                                <CustomButton
                                    buttonLabel='Reset'
                                    buttonSize={BUTTON_SIZE.normal}
                                    onClick={handleSubmit}
                                    loading={loading}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className='d-flex align-items-center justify-content-end fixed-bottom m-10'>
                <span className='pe-2' style={{color: '#9E9E9E', fontSize: '12px'}}>
                    Powered by
                </span>
                <KTSVG
                    path='/media/icons/duotune/art/Sequifi-logo-with-text-inline.svg'
                    svgClassName='w-75px h-25px'
                />
            </div>
        </div>
    )
}

export default ResetPassword
