import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {Document, Page} from 'react-pdf'
import SignatureModal from './SignatureModal'

import {getValidDate} from '../../../../../constants/constants'
import CustomLoader from '../../../../../customComponents/customLoader/CustomLoader'
import MetaTags from 'react-meta-tags'
import {isMobile} from 'react-device-detect'
import CustomLottie from '../../../../../customComponents/customLottie/CustomLottie'
import RejectAnimation from '../../../../../../src/assets/lottieAnimation/RejectAnimation.json'
import CustomButton, {BUTTON_SIZE} from '../../../../../customComponents/customButtton/CustomButton'

const DisplayPdf = ({
    url,
    documentData,
    isOfferLetter = true,
    setAllSignatureFieldsData,
    allSignatureFieldsData,
    setAllFormFieldsData,
    allFromFieldsData,
    setTotalSignatureBlock,
    handleFinishTemplate,
    coordinates,
    finishLoading,
    allSignButtonData,
    retryDocumentLoad,
    setDocLoaded,
    textInputCoordinated,
}) => {
    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    }, [])

    const failedPdfView = () => (
        <div className='p-10 w-xxl-775px'>
            <div className='text-center bg-cmwhite shadow-sm p-10' style={{borderRadius: '24px'}}>
                <div
                    className='text-cmError mb-5'
                    style={{fontSize: 19.42, lineHeight: '26.5px', fontWeight: 800}}
                >
                    <div className='d-flex flex-center mb-5'>
                        <CustomLottie lottieJson={RejectAnimation} height={50} width={50} />
                    </div>
                    Failed To Load Your Document
                </div>
                <CustomButton
                    buttonLabel='Retry'
                    buttonSize={BUTTON_SIZE.small}
                    onClick={() => {
                        retryDocumentLoad()
                    }}
                />
            </div>
        </div>
    )

    return (
        <>
            {isOfferLetter ? (
                <FillOfferLetterSection
                    url={url}
                    setAllSignatureFieldsData={setAllSignatureFieldsData}
                    allSignatureFieldsData={allSignatureFieldsData}
                    documentData={documentData}
                    setTotalSignatureBlock={setTotalSignatureBlock}
                    coordinates={coordinates}
                    allSignButtonData={allSignButtonData}
                    failedPdfView={failedPdfView}
                    setDocLoaded={setDocLoaded}
                    textInputCoordinated={textInputCoordinated}
                    allFieldsData={allFromFieldsData}
                />
            ) : (
                <FillW9PdfForm
                    url={url}
                    documentData={documentData}
                    setAllFormFieldsData={setAllFormFieldsData}
                    allFieldsData={allFromFieldsData}
                    handleFinishTemplate={handleFinishTemplate}
                    finishLoading={finishLoading}
                    failedPdfView={failedPdfView}
                    setDocLoaded={setDocLoaded}
                />
            )}
        </>
    )
}

export default DisplayPdf

export const FillW9PdfForm = ({
    documentData,
    url,
    handleFinishTemplate,
    finishLoading,
    allFieldsData,
    failedPdfView,
    setDocLoaded,
}) => {
    const [pageNumber, setpageNumber] = useState(1)
    // const allFieldsData = useRef(new Array())?.current
    const [particularFieldValue, setParticularFieldValue] = useState('')
    const [currentIndex, setCurrentIndex] = useState(0)
    const [fullyLoaded, setFullyLoaded] = useState(false)
    const [documentPageLoad, setDocumentPageLoad] = useState(false)
    const [pageLoading, setPageLoading] = useState(true)
    const [openSignatureModal, setOpenSignatureModal] = useState(false)

    useEffect(() => {
        setFullyLoaded(false)
        setCurrentIndex(0)
    }, [documentData])

    useEffect(() => {
        // Remove highlight from all fields
        AllfieldIds?.map((item) => {
            let eleData = document.getElementById(item?.id)
            eleData.remove()
        })
    }, [])

    const AllfieldIds = useMemo(() => {
        var fieldsId = []
        return fieldsId
    }, [])

    useEffect(() => {
        // handleHighlightFields()
        handleHighlightCurrentField()
    }, [currentIndex, documentData, allFieldsData])

    const handleHighlightCurrentField = useCallback(() => {
        allFieldsData?.map((item) => {
            let eleData = document.getElementById(item?.id)
            if (eleData) {
                eleData.style.background = '#FAFAFA'
                eleData.style.border = '1px solid #6078EC'
                eleData.style.outlineStyle = 'solid'
                eleData.style.outlineColor = 'transparent'

                // eleData.style.color = '#6078EC'
            }
        })

        const currentElementId = allFieldsData[currentIndex]?.id
        // return
        let element = document.getElementById(currentElementId)

        if (element) {
            element.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'})

            element.style.border = '0.5px solid #FFA800'
            element.style.outlineStyle = 'solid'
            element.style.outlineColor = '#FFA800'
            // element.style.outlineWidth = 'thin'
        }
    }, [allFieldsData, currentIndex])

    const onDocumentSucess = ({numPages}) => {
        setpageNumber(numPages)
        setDocumentPageLoad(true)
        setPageLoading(false)
        setDocLoaded(true)
    }

    const finishForm = useCallback(() => {
        handleFinishTemplate()
    }, [handleFinishTemplate])

    const saveElementsData = useCallback(
        (value, id, type) => {
            var filterField = [...allFieldsData]
            var foundFieldIndex = filterField?.findIndex((item) => item?.id == id)
            let inputVal = value

            if (type == 'date') inputVal = getValidDate(value, 'MM/DD/YYYYY')
            filterField[foundFieldIndex]['text'] = inputVal

            // setAllFormFieldsData(filterField)
        },
        [allFieldsData]
    )

    const onElementClick = (index) => {
        setCurrentIndex(index)
    }

    const onElementvalueChange = useCallback(
        (e, type) => {
            if (['text', 'date'].includes(type)) {
                saveElementsData(e.target.value, e.target.id, type)
            } else if (type == 'checkbox') {
                saveElementsData(e.target.checked, e.target.id)
            }
        },
        [saveElementsData]
    )

    const onElementSignatureChange = useCallback((id) => {
        setParticularFieldValue(id)
        setOpenSignatureModal(true)
    }, [])

    const getElementLabel = useCallback((type, label) => {
        switch (type) {
            case 'date':
                return 'MM/DD/YYYY'
            case 'text':
                return label ? label : 'Text'
            case 'checkbox':
                return ''
            case 'signature':
                return label ? label : 'Signature'
            case 'initial':
                return label ? label : 'Initial'

            default:
                return 'Text'
        }
    }, [])

    const getElementHeightWidth = useCallback((type) => {
        switch (type) {
            case 'date':
                return {width: '206.67px', height: '26.02px'}
            case 'text':
                return {width: '296.67px', height: '19.02px'}
            case 'checkbox':
                return {width: '18.19px', height: '18.19px'}
            case 'signature':
                return {width: '185px', height: '29px'}
            case 'initial':
                return {width: '185px', height: '29px'}

            default:
                return {width: '296.67px', height: '26.02px'}
        }
    }, [])

    const getElementInputAtrribute = useCallback((type) => {
        switch (type) {
            case 'date':
                return 'date'
            case 'text':
                return 'text'
            case 'checkbox':
                return 'checkbox'
            case 'signature':
                return 'button'
            case 'initial':
                return 'button'

            default:
                return 'text'
        }
    }, [])
    const createHtmlElement = useCallback(
        (x, y, item, index) => {
            // let label = item?.type == 'checkbox' ? '' : item?.label ?? 'Text Field'
            let label = getElementLabel(item?.type, item?.label)
            let inputAtrribute = getElementInputAtrribute(item?.type)
            if (inputAtrribute == 'button') {
                var elCostPrice = document.createElement('div')
                elCostPrice.tabIndex = 0
            } else {
                var elCostPrice = document.createElement('INPUT')
                elCostPrice.setAttribute('type', inputAtrribute)
            }
            // var elCostPrice = document.createElement('div')

            elCostPrice.style.border = '1px solid #6078EC'
            elCostPrice.style.outlineStyle = 'solid'
            elCostPrice.style.outlineColor = 'transparent'
            elCostPrice.style.borderRadius = '2px'
            if (inputAtrribute != 'date') {
                elCostPrice.style.width = item?.w
                    ? item?.w + 'px'
                    : getElementHeightWidth(item?.type)?.width
            }
            elCostPrice.style.height = item?.h
                ? item?.h + 'px'
                : getElementHeightWidth(item?.type)?.height
            elCostPrice.style.background = '#FAFAFA'
            // elCostPrice.style.color = '#6078EC'
            elCostPrice.style.color = 'black'
            elCostPrice.style.position = 'absolute'
            elCostPrice.style.display = 'flex'
            elCostPrice.style.justifyContent = 'center'
            elCostPrice.style.alignItems = 'center'
            elCostPrice.style.fontSize = '12px'
            elCostPrice.style.fontWeight = 500
            elCostPrice.style.cursor = 'pointer'

            elCostPrice.style.top = x + 'px'
            elCostPrice.style.left = y + 'px'
            // elCostPrice.style.paddingLeft = '5px'
            elCostPrice.textContent = label
            elCostPrice.placeholder = label
            elCostPrice.style.accentColor = '#6078ec'
            elCostPrice.setAttribute('id', `${item?.type ?? 'default'}_FieldId_${index + 1}`)
            if (inputAtrribute == 'text') {
                elCostPrice.addEventListener('keyup', (e) =>
                    onElementvalueChange(e, inputAtrribute)
                )
            } else if (['checkbox', 'date'].includes(inputAtrribute)) {
                elCostPrice.addEventListener('change', (e) =>
                    onElementvalueChange(e, inputAtrribute)
                )
            } else if (inputAtrribute == 'button') {
                elCostPrice.addEventListener('click', (e) =>
                    onElementSignatureChange(e, inputAtrribute)
                )
            }
            elCostPrice.addEventListener('focus', (e) => onElementClick(index))

            return elCostPrice
        },
        [
            getElementHeightWidth,
            getElementInputAtrribute,
            getElementLabel,
            onElementSignatureChange,
            onElementvalueChange,
        ]
    )

    const handleEachPageRef = useCallback(
        (ref) => {
            // if ({ref}?.ref?.clientWidth) {
            //     setPdfWidth({ref}?.ref?.clientWidth + 'px')
            // }
            if (fullyLoaded) return
            let refPage = {current: ref}

            documentData?.pdf_file_other_parameter?.map((item, index) => {
                if (item?.page == refPage?.current?.offsetParent?.dataset?.pageNumber) {
                    let element = createHtmlElement(item?.x, item?.y, item, index)
                    element.addEventListener('click', () => {
                        onElementClick(index)
                    })

                    let Obj = {
                        page_number: item?.page,
                        x: item?.x,
                        y: item?.y,
                        type: item?.type,
                        text: null,
                        id: `${item?.type ?? 'default'}_FieldId_${index + 1}`,
                        label: item?.label,
                        isRequired: item?.isRequired,
                    }
                    allFieldsData.push(Obj)
                    refPage?.current?.offsetParent.appendChild(element)
                }
            })
            if (refPage?.current?.offsetParent?.dataset?.pageNumber == pageNumber)
                setFullyLoaded(true)
        },
        [
            allFieldsData,
            createHtmlElement,
            documentData?.pdf_file_other_parameter,
            fullyLoaded,
            pageNumber,
        ]
    )

    const addSignatureToField = useCallback(
        (dataUrl) => {
            const currentElementId = allFieldsData?.[currentIndex]?.id
            let element = document.getElementById(currentElementId)

            var filterField = [...allFieldsData]
            var foundFieldIndex = filterField?.findIndex((item) => item?.id == currentElementId)

            // if (signatureTabsActiveIndex === 1) {
            var signatureImg = document.createElement('img')
            signatureImg.src = dataUrl
            signatureImg.style.position = 'absolute'
            signatureImg.style.height = '22px'
            element.textContent = ''
            element.appendChild(signatureImg)
            filterField[foundFieldIndex]['text'] = dataUrl
        },
        [allFieldsData, currentIndex]
    )

    return (
        <>
            <MetaTags>
                {isMobile ? <meta name='viewport' content='width=803,user-scalable=no' /> : null}
            </MetaTags>
            <div className='overflow-auto'>
                <div
                    className='mx-auto mb-2'
                    style={{
                        width: 'fit-content',
                        height: documentPageLoad ? '100vh' : '100vh',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                    }}
                >
                    <div
                        className={`bg-cmwhite ${
                            documentPageLoad ? 'w-100' : 'w-800px'
                        } border border-2 border-cmGrey400 shadow-sm h-100 `}
                        style={{
                            overflowY: 'auto',
                            position: 'relative',
                        }}
                    >
                        <CustomLoader full visible={pageLoading} />
                        <Document
                            file={url}
                            onLoadSuccess={onDocumentSucess}
                            error={failedPdfView}
                            onLoadError={() => {
                                setPageLoading(false)
                            }}
                        >
                            {Array.from(new Array(pageNumber), (no, index) => (
                                <Page
                                    key={`page_${index + 1}`}
                                    pageNumber={index + 1}
                                    renderTextLayer={false}
                                    renderAnnotationLayer={false}
                                    canvasRef={(canvas) => handleEachPageRef(canvas)}
                                    scale={1.3}
                                    className={'mb-7'}
                                    style={{marginBottom: '7px'}}
                                />
                            ))}
                        </Document>
                    </div>
                    {/* <div
                        className='bg-cmBlack bg-opacity-25  px-sm-5 py-sm-5 px-5 py-2 w-sm-803px w-100'
                        style={{
                            position: 'absolute',
                            top: '50%',
                            // width: pdfWidth,
                        }}
                    >
                        <div
                            className='bg-cmwhite shadow mw-500px mx-auto p-2'
                            style={{borderRadius: 10}}
                        >
                            <div className=' mx-auto text-center'>
                                {formCompleted ? (
                                    <div className='p-10 mt-6'>
                                        <div className='d-flex flex-wrap flex-center gap-2'>
                                            <KTSVG
                                                path='/media/icons/duotune/art/DoneCheck.svg'
                                                svgClassName='w-auto '
                                                svgStyle={{width: '29px', height: '29px'}}
                                            />
                                            <span
                                                className='text-cmGrey900'
                                                style={{
                                                    fontWeight: 700,
                                                    fontSize: '20px',
                                                    fontFamily: fontsFamily.manrope,
                                                }}
                                            >
                                                Form has been completed
                                            </span>
                                        </div>
                                        <div
                                            className='text-cmGrey700 text-center mt-5'
                                            style={{
                                                fontWeight: 500,
                                                fontSize: '14px',
                                                fontFamily: fontsFamily.manrope,
                                            }}
                                        >
                                            You have filled and signed all mandatory fields!
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <div
                                            className={`text-cmGrey700 ${
                                                allFieldsData?.[currentIndex]?.isRequired
                                                    ? 'required'
                                                    : ''
                                            }`}
                                            style={{fontSize: '16px', fontWeight: 800}}
                                        >
                                            {allFieldsData?.[currentIndex]?.label ??
                                                DISPLAY_KEYS[allFieldsData?.[currentIndex]?.type]}
                                        </div>
                                        {['signature', 'initial']?.includes(
                                            allFieldsData?.[currentIndex]?.type
                                        ) ? (
                                            <CustomTabs
                                                tabs={tabsPreviewData}
                                                callOuterFunction={(val) =>
                                                    setSignatureTabsActiveIndex(val)
                                                }
                                                customVal={signatureTabsActiveIndex}
                                            />
                                        ) : // <TabView
                                        //     activeIndex={signatureTabsActiveIndex}
                                        //     onTabChange={(e) =>
                                        //         setSignatureTabsActiveIndex(e.index)
                                        //     }
                                        // >
                                        //     <TabPanel header='Type'>
                                        //         <div className='my-5'>
                                        //             <div className='mb-5'>
                                        //                 <InputText
                                        //                     onChange={(e) =>
                                        //                         setParticularFieldValue(
                                        //                             e.target.value
                                        //                         )
                                        //                     }
                                        //                     value={particularFieldValue}
                                        //                     className='w-100'
                                        //                     style={{
                                        //                         fontWeight: 600,
                                        //                         fontSize: '18px',
                                        //                     }}
                                        //                     keyfilter={/^[a-zA-Z\s]+$/}
                                        //                 />
                                        //             </div>
                                        //         </div>
                                        //     </TabPanel>
                                        //     <TabPanel header='Draw'>
                                        //         <div
                                        //             className='text-end text-decoration-underline p-0 cursor-pointer'
                                        //             onClick={clearSignatureDraw}
                                        //         >
                                        //             Clear
                                        //         </div>
                                        //         <SignatureCanvas
                                        //             ref={signatureRef}
                                        //             penColor='black'
                                        //             canvasProps={{
                                        //                 height: 138,
                                        //                 className:
                                        //                     'w-100 sigCanvas border border-2',
                                        //             }}
                                        //         />
                                        //     </TabPanel>
                                        // </TabView>
                                        allFieldsData?.[currentIndex]?.type == 'date' ? (
                                            <div className='p-10 mt-6'>
                                                <CustomDatePicker
                                                    onChange={(e) =>
                                                        setParticularFieldValue(e.target.value)
                                                    }
                                                    value={particularFieldValue}
                                                    name='MM/DD/YYYY'
                                                />
                                            </div>
                                        ) : allFieldsData?.[currentIndex]?.type == 'checkbox' ? (
                                            <div className='p-10 mt-6'>
                                                <CustomCheckbox
                                                    onChange={(e) =>
                                                        setParticularFieldValue(e.target.checked)
                                                    }
                                                    checked={particularFieldValue}
                                                />
                                            </div>
                                        ) : (
                                            <div className='p-10 mt-6'>
                                                <CustomInput
                                                    placeholder='Type Here...'
                                                    onChange={(e) =>
                                                        setParticularFieldValue(e.target.value)
                                                    }
                                                    value={particularFieldValue}
                                                />
                                            </div>
                                        )}
                                        {mandatoryError ? (
                                            <div className='text-cmError p-5'>
                                                <span className='bi bi-exclamation-triangle me-2'></span>
                                                {mandatoryError}
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}
                                    </>
                                )}
                                <div className='bg-cmGrey100 mb-0'>
                                    {formCompleted ? (
                                        <div className='d-flex  flex-wrap flex-center p-2'>
                                            <CustomButton
                                                buttonLabel='Finish'
                                                buttonSize={BUTTON_SIZE.small}
                                                onClick={finishForm}
                                                loading={finishLoading}
                                            />
                                        </div>
                                    ) : (
                                        <div className='d-flex gap-5 flex-wrap flex-center p-2'>
                                            <CustomButton
                                                buttonLabel='Previous'
                                                buttonSize={BUTTON_SIZE.small}
                                                buttonType={BUTTON_TYPE.secondary}
                                                onClick={triggerPreviousField}
                                                disabled={currentIndex == 0}
                                            />
                                            <CustomButton
                                                buttonLabel={
                                                    allFieldsData.length == currentIndex + 1
                                                        ? 'Finish'
                                                        : 'Next'
                                                }
                                                buttonSize={BUTTON_SIZE.small}
                                                onClick={hanldeNextField}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            {openSignatureModal ? (
                <SignatureModal
                    open={openSignatureModal}
                    handleClose={() => setOpenSignatureModal(false)}
                    addSignatureToPDF={addSignatureToField}
                />
            ) : null}
        </>
    )
}

export const FillOfferLetterSection = ({
    url,
    setAllSignatureFieldsData,
    allSignatureFieldsData,
    coordinates,
    allSignButtonData,
    failedPdfView,
    setDocLoaded,
    textInputCoordinated,
    allFieldsData,
}) => {
    const [openSignatureModal, setOpenSignatureModal] = useState(false)
    const [pageNumber, setpageNumber] = useState(1)
    const [signatureData, setSignatureData] = useState('')
    const [fullyLoaded, setFullyLoaded] = useState(false)
    const [pageLoading, setPageLoading] = useState(true)
    const [editableSignImg, setEditableSignImg] = useState(null)

    useEffect(() => {
        if (openSignatureModal) {
            document.body.style.overflow = 'auto'
        } else {
            document.body.style.overflow = 'auto'
        }
    }, [openSignatureModal])

    //create array of obj for signature block
    const addFieldData = useCallback(
        (signUrl, id, bigSizeSign) => {
            const field = [...allSignatureFieldsData]
            let obj = {
                page_number: signatureData?.page,
                x: signatureData?.location?.x,
                y: signatureData?.location?.y,
                data: signUrl,
                guest_name: '',
                id: id,
                bigSizeDataUrl: bigSizeSign,
            }
            field.push(obj)
            setAllSignatureFieldsData(field)
        },
        [
            allSignatureFieldsData,
            setAllSignatureFieldsData,
            signatureData?.location?.x,
            signatureData?.location?.y,
            signatureData?.page,
        ]
    )
    const updateFieldData = useCallback(
        (signUrl, bigSizeSign) => {
            const field = [...allSignatureFieldsData]
            let index = field?.findIndex((item) => item?.id == editableSignImg)
            if (index !== -1) {
                field[index].data = signUrl
                field[index].bigSizeDataUrl = bigSizeSign
            }

            setAllSignatureFieldsData(field)
        },
        [allSignatureFieldsData, editableSignImg, setAllSignatureFieldsData]
    )

    const handleSignatureElementClick = useCallback((e) => {
        setEditableSignImg(e.target.id)
        setOpenSignatureModal(true)
    }, [])

    const addSignatureToPDF = useCallback(
        (signUrl, bigSizeSign) => {
            //create html element
            if (editableSignImg) {
                let oldSignElement = document.getElementById(editableSignImg)
                oldSignElement.src = signUrl
                updateFieldData(signUrl, bigSizeSign)
            } else {
                addFieldData(signUrl, signatureData?.btnId, bigSizeSign)

                var elem = document.createElement('img')
                elem.setAttribute('id', signatureData?.btnId)
                elem.src = signUrl
                elem.style.top = signatureData?.location?.y + 'px'
                elem.style.left = signatureData?.location?.x + 'px'
                elem.style.position = 'absolute'
                elem.style.height = '25px'
                elem.style.minWidth = '80px'
                elem.style.maxWidth = '250px'

                // elem.style.width = ' 150px'
                elem.style.background = ' #f8f9fa'
                elem.title = 'Click to edit signature'
                elem.style.cursor = 'pointer'

                elem.addEventListener('click', handleSignatureElementClick)
                let oldElement = document.getElementById(signatureData?.btnId)
                oldElement.remove()
                signatureData?.pageRef?.current?.offsetParent.appendChild(elem)
            }
        },
        [
            addFieldData,
            editableSignImg,
            handleSignatureElementClick,
            signatureData?.btnId,
            signatureData?.location?.x,
            signatureData?.location?.y,
            signatureData?.pageRef,
            updateFieldData,
        ]
    )
    // const handleSignatureModal = () => {
    //     setOpenSignatureModal(!openSignatureModal)
    // }
    const handleSignature = useCallback((pageData) => {
        setSignatureData(pageData)
        setOpenSignatureModal(true)
        // handleSignatureModal()
    }, [])

    const saveElementsData = useCallback(
        (value, id, type) => {
            var filterField = [...allFieldsData]
            var foundFieldIndex = filterField?.findIndex((item) => item?.id == id)
            let inputVal = value

            filterField[foundFieldIndex]['text'] = inputVal

            // setAllFormFieldsData(filterField)
        },
        [allFieldsData]
    )

    const onElementClick = (index) => {
        // setCurrentIndex(index)
    }

    const onElementvalueChange = useCallback(
        (e) => {
            saveElementsData(e.target.value, e.target.id)
        },
        [saveElementsData]
    )

    //create a div element for signature button
    const createElement = useCallback((x, y, index) => {
        var signElement = document.createElement('div')
        signElement.style.background = ' #FFFFFF'
        signElement.textContent = 'Signature Here'
        signElement.style.color = '#6078EC'
        signElement.style.height = '30px'
        signElement.style.width = ' 127px'
        signElement.style.border = ' 1px  #6078EC dotted'
        signElement.style.borderRadius = '6px'
        signElement.style.cursor = 'pointer'
        signElement.style.textAlign = 'center'
        signElement.style.padding = ' 4px 13px 0px 13px '
        signElement.style.position = 'absolute'
        signElement.style.top = y + 'px'
        signElement.style.left = x + 'px'
        signElement.setAttribute('id', `signBtn_${index + 1}`)

        return signElement
    }, [])

    const createinputElement = useCallback(
        (x, y, index) => {
            var inputElement = document.createElement('INPUT')

            inputElement.style.border = '1px solid #6078EC'
            inputElement.style.outlineStyle = 'solid'
            inputElement.style.outlineColor = 'transparent'
            inputElement.style.borderRadius = '2px'
            inputElement.style.width = '130px'
            inputElement.style.height = '30px'
            inputElement.style.background = '#FAFAFA'
            // elCostPrice.style.color = '#6078EC'
            inputElement.style.color = 'black'
            inputElement.style.position = 'absolute'
            inputElement.style.display = 'flex'
            inputElement.style.justifyContent = 'center'
            inputElement.style.alignItems = 'center'
            inputElement.style.fontSize = '12px'
            inputElement.style.fontWeight = 500
            inputElement.style.cursor = 'auto'

            inputElement.style.top = y + 'px'
            inputElement.style.left = x + 'px'
            // elCostPrice.style.paddingLeft = '5px'
            inputElement.textContent = 'Enter Text'
            inputElement.placeholder = 'Enter Text'
            inputElement.style.accentColor = '#6078ec'
            inputElement.setAttribute('id', `input_text_${index}`)

            inputElement.addEventListener('keyup', (e) => onElementvalueChange(e))

            inputElement.addEventListener('focus', (e) => onElementClick(index))

            return inputElement
        },
        [onElementvalueChange]
    )

    const addSignButtonData = useCallback(
        (id) => {
            let obj = {
                id: id,
            }
            allSignButtonData.push(obj)
        },
        [allSignButtonData]
    )

    //render for all pages and add button
    const handleEachPageRef = useCallback(
        (ref) => {
            if (fullyLoaded) return
            let refPage = {current: ref}
            coordinates?.map((item, index) => {
                if (item?.page == refPage?.current?.offsetParent?.dataset?.pageNumber) {
                    if (item?.field_type == 'inputText') {
                        let element = createinputElement(
                            item?.location?.x,
                            item?.location?.y,
                            index
                        )

                        let Obj = {
                            page_number: item?.page,
                            x: item?.location?.x,
                            y: item?.location?.y,
                            type: item?.field_type,
                            text: null,
                            id: `input_text_${index}`,
                            isRequired: false,
                        }
                        allFieldsData.push(Obj)
                        refPage?.current?.offsetParent.appendChild(element)
                    } else {
                        let element = createElement(item?.location?.x, item?.location?.y, index)
                        element.addEventListener('click', () =>
                            handleSignature({
                                ...item,
                                btnId: `signBtn_${index + 1}`,
                                pageRef: refPage,
                            })
                        )
                        addSignButtonData(`signBtn_${index + 1}`)
                        refPage?.current?.offsetParent.appendChild(element)
                    }
                }
            })
            if (
                coordinates?.length > 0 &&
                refPage?.current?.offsetParent?.dataset?.pageNumber == pageNumber
            )
                setFullyLoaded(true)
        },
        [
            addSignButtonData,
            allFieldsData,
            coordinates,
            createElement,
            createinputElement,
            fullyLoaded,
            handleSignature,
            pageNumber,
        ]
    )

    //funcs
    const onDocumentSucess = ({numPages}) => {
        setpageNumber(numPages)
        setPageLoading(false)
        setDocLoaded(true)
    }

    return (
        <div className='overflow-auto'>
            <div
                className='mx-auto mb-2 border border-2 border-cmGrey400 shadow-sm '
                style={{
                    width: 'fit-content',
                    height: '78vh',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                }}
            >
                <CustomLoader full visible={pageLoading} />
                <Document
                    file={url}
                    onLoadSuccess={onDocumentSucess}
                    error={failedPdfView}
                    onLoadError={() => {
                        setPageLoading(false)
                    }}
                >
                    {Array.from(new Array(pageNumber), (no, index) => (
                        <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                            canvasRef={(canvas) => handleEachPageRef(canvas)}
                            scale='1'
                        />
                    ))}
                </Document>
            </div>

            {openSignatureModal ? (
                <SignatureModal
                    open={openSignatureModal}
                    handleClose={() => setOpenSignatureModal(false)}
                    signatureData={signatureData}
                    setSignatureData={setSignatureData}
                    addSignatureToPDF={addSignatureToPDF}
                />
            ) : null}
        </div>
    )
}
