import {useMemo} from 'react'
import useBasedOnCompanyType from './useBasedOnCompanyType'

const useUnitTypes = () => {
    const {basedOnCompanyType} = useBasedOnCompanyType()

    // Commission AmountType
    const COMMISSION_AMOUNT_TYPE = useMemo(() => {
        let type = [{name: '%', value: 'percent'}]
        if (basedOnCompanyType.isUsingPrimaryUnitType) {
            type.push({name: `Per ${basedOnCompanyType?.primaryUnitType}`, value: 'per kw'})
        }
        return type
    }, [basedOnCompanyType.isUsingPrimaryUnitType, basedOnCompanyType?.primaryUnitType])

    // Self-Gen Commission AmountType
    const SELF_GEN_COMMISSION_AMOUNT_TYPE = useMemo(() => {
        let type = [{name: '%', value: 'percent'}]
        if (basedOnCompanyType.isUsingPrimaryUnitType) {
            type.push({name: `Per ${basedOnCompanyType?.primaryUnitType}`, value: 'per kw'})
        }
        return type
    }, [basedOnCompanyType.isUsingPrimaryUnitType, basedOnCompanyType?.primaryUnitType])

    // Upfront amount type
    const UPFRONT_AMOUNT_TYPE = useMemo(() => {
        let type = [{name: 'Per Sale', value: 'per sale'}]
        if (basedOnCompanyType.isUsingPrimaryUnitType) {
            type.push({name: `Per ${basedOnCompanyType?.primaryUnitType}`, value: 'per kw'})
        }
        return type
    }, [basedOnCompanyType.isUsingPrimaryUnitType, basedOnCompanyType?.primaryUnitType])

    // Redline amount type
    const REDLINE_AMOUNT_TYPE = useMemo(() => {
        return [{name: `Per ${basedOnCompanyType?.secondaryUnitType}`, value: 'per watt'}]
    }, [basedOnCompanyType?.secondaryUnitType])

    // Override amount type
    const OVERRIDE_AMOUNT_TYPE = useMemo(() => {
        let type = [
            {name: 'Per Sale', value: 'per sale'},
            {name: '%', value: 'percent'},
        ]
        if (basedOnCompanyType.isUsingPrimaryUnitType) {
            type.push({name: `Per ${basedOnCompanyType?.primaryUnitType}`, value: 'per kw'})
        }
        return type
    }, [basedOnCompanyType.isUsingPrimaryUnitType, basedOnCompanyType?.primaryUnitType])

    // Withheld Amount Type
    const WITHHELD_AMOUNT_TYPE = useMemo(() => {
        let type = [{name: 'Per Sale', value: 'per sale'}]
        if (basedOnCompanyType.isUsingPrimaryUnitType) {
            type.push({name: `Per ${basedOnCompanyType?.primaryUnitType}`, value: 'per kw'})
        }
        return type
    }, [basedOnCompanyType.isUsingPrimaryUnitType, basedOnCompanyType?.primaryUnitType])

    const unitTypes = {
        commissionAmountType: COMMISSION_AMOUNT_TYPE,
        upfrontAmountType: UPFRONT_AMOUNT_TYPE,
        redlineAmountType: REDLINE_AMOUNT_TYPE,
        overrideAmountType: OVERRIDE_AMOUNT_TYPE,
        withheldAmountType: WITHHELD_AMOUNT_TYPE,
        selfGenCommissionAmountType: SELF_GEN_COMMISSION_AMOUNT_TYPE,
    }

    return {
        unitTypes,
    }
}

export default useUnitTypes
