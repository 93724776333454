import React, {useCallback, useEffect, useState} from 'react'
import {Outlet, Route, Routes, useParams} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import {fontsFamily} from '../../../../assets/fonts/fonts'
import CustomInput, {
    CommonLabel,
    INPUT_TYPE,
} from '../../../../customComponents/customInputs/customInput/CustomInput'
import CustomCheckbox from '../../../../customComponents/customCheckbox/CustomCheckbox'
import CustomButton, {BUTTON_SIZE} from '../../../../customComponents/customButtton/CustomButton'
import CustomLottie from '../../../../customComponents/customLottie/CustomLottie'
import Success_art from '../../../../assets/lottieAnimation/Success_art.json'
import WentWrongAnimation from '../../../../assets/lottieAnimation/WentWrongAnimation.json'
import {
    getBackgroundCheckUserDetailV1Service,
    getBackgroundVerificationQuestionsV1Service,
    submitBackgroundVerificationAnswersV1Service,
    updatPersonalDetailsforBackgroundCheckV1Service,
    validateRequestV1Service,
} from '../../../../services/Services'
import {
    getBooleanValue,
    getDataWithoutMask,
    getErrorMessageFromResponse,
    getMobileNumberWithoutMask,
    isEmptyObjectValue,
} from '../../../../helpers/CommonHelpers'
import CustomToast from '../../../../customComponents/customToast/CustomToast'
import CustomDropdown from '../../../../customComponents/customInputs/customDropdown/CustomDropdown'
import CustomDatePicker from '../../../../customComponents/customInputs/customDatePicker/CustomDatePicker'
import {
    BACKGROUND_CHECK_VALIDATION_FIELD,
    backGroundCheckValidation,
} from '../../../../validations/validations'
import {getValidDate} from '../../../../constants/constants'
import BackgroundverificationTandCModal from './BackgroundverificationTandCModal'
import CustomLoader from '../../../../customComponents/customLoader/CustomLoader'
import _ from 'lodash'
import CustomTooltip from '../../../../customComponents/customTooltip/CustomTooltip'
import CustomSkeleton from '../../../../customComponents/customSkeleton/CustomSkeleton'
import useValidation from '../../../../hooks/useValidation'

const FIELD_KEYS = {
    first_name: 'first_name',
    last_name: 'last_name',
    middle_name: 'middle_name',
    email: 'email',
    phone_number: 'phone_number',
    phone_type: 'phone_type',
    address_line_1: 'address_line_1',
    address_line_2: 'address_line_2',
    address_line_3: 'address_line_3',
    address_line_4: 'address_line_4',
    locality: 'locality',
    region: 'region',
    postal_code: 'postal_code',
    country: 'country',
    accepted_terms_conditions: 'accepted_terms_conditions',
    date_of_birth: 'date_of_birth',
    social_security_number: 'social_security_number',
    request_id: 'request_id',
}

const BackgroundVerificationPage = () => {
    const [step, setStep] = useState(1)
    const [loading, setLoading] = useState(false)
    const params = useParams()
    const [userData, setUserData] = useState({
        first_name: null,
        last_name: null,
        middle_name: null,
        phone_number: null,
        phone_type: null,
        address_line_1: null,
        address_line_2: null,
        address_line_3: null,
        address_line_4: null,
        locality: null,
        region: null,
        postal_code: null,
        country: null,
        accepted_terms_conditions: 0,
        date_of_birth: null,
        social_security_number: null,
        request_id: params?.userId,
    })

    const [questionsData, setQuestionsData] = useState(null)
    const [validationError, setvalidationError] = useState(BACKGROUND_CHECK_VALIDATION_FIELD)
    const [verificationSuccess, setVerificationSuccess] = useState(null)
    const [isValidRequest, setValidRequest] = useState('')
    const [validateBackGround, formErrorData] = useValidation()

    useEffect(() => {
        if (formErrorData?.beginValidating) {
            validateBackGround(backGroundCheckValidation(userData))
        }
    }, [userData])

    useEffect(() => {
        setLoading(true)
        validateRequestV1Service(params?.userId)
            .then((res) => {
                if (res.status) {
                    if (['In Progress', 'Verification Failed'].includes(res?.validate_status)) {
                        getQuestions(res?.data?.[0])
                        setStep(2)
                    } else userDetails()
                }
            })
            .catch((err) => {
                if (!err.data?.status) setValidRequest(err?.data?.message)
                setLoading(false)
            })
    }, [])

    const userDetails = () => {
        getBackgroundCheckUserDetailV1Service(params?.userId)
            .then((res) => {
                if (['In Progress', 'Verification Failed'].includes(res?.data?.[0]?.status)) {
                    getQuestions(res?.data?.[0])
                } else {
                    setUserData({
                        ...userData,
                        first_name: res?.data?.[0]?.first_name,
                        last_name: res?.data?.[0]?.last_name,
                        email: res?.data?.[0]?.email,
                    })
                    setLoading(false)
                }
            })
            .catch((err) => {
                CustomToast.error(getErrorMessageFromResponse(err))
                setLoading(false)
            })
    }

    const getQuestions = (data) => {
        const body = {
            applicantId: data?.applicant_id,
            screeningRequestId: data?.screening_request_id,
            screeningRequestApplicantId: data?.screening_request_applicant_id,
        }
        setLoading(true)
        getBackgroundVerificationQuestionsV1Service(body)
            .then((que) => {
                if (que?.manual_authentication) {
                    setValidRequest(que?.message)
                    return
                } else if (que?.verified) {
                    setValidRequest(que?.message)
                    return
                } else {
                    setQuestionsData(que?.data)
                    setTimeout(() => {
                        setStep(2)
                    }, 1000)
                }
            })
            .catch((err) => CustomToast.error(err))
            .finally(() => setLoading(false))
    }
    const updateFieldsData = (key, value) => {
        setUserData((val) => ({
            ...val,
            [key]: value,
        }))
    }

    const onChangeInputData = useCallback(
        (e) => {
            updateFieldsData(e?.target?.name, e?.target?.value)
        },
        [updateFieldsData]
    )

    const getMultipleErrorMessage = (msgArr) => {
        return msgArr?.length > 0 ? (
            <ol>
                {msgArr?.map((item, i) => {
                    return <li>{`${item?.message}`}</li>
                })}
            </ol>
        ) : null
    }

    // form submittion
    const handlePersonalDetailForm = () => {
        const body = {
            ...userData,
            phone_number: getMobileNumberWithoutMask(userData?.phone_number),
            social_security_number: getDataWithoutMask(userData?.social_security_number),
            postal_code: getDataWithoutMask(userData?.postal_code),
        }
        // const validationErrors = backGroundCheckValidation(body)
        // setvalidationError(validationErrors)
        // if (validationErrors?.accepted_terms_conditions) {
        //     CustomToast.error(validationErrors?.accepted_terms_conditions)
        // }
        validateBackGround(backGroundCheckValidation(userData)).then((res) => {
            if (res.isValidate) {
                setLoading(true)
                updatPersonalDetailsforBackgroundCheckV1Service(body)
                    .then((res) => {
                        if (res?.verified) return CustomToast.warn(res?.message)
                        getQuestions(res?.data)
                    })
                    .catch((err) => {
                        err?.data?.err?.data?.apiResponse?.errors
                            ? CustomToast.error(err?.data?.apiResponse?.errors?.[0]?.message)
                            : err?.data?.apiResponse?.errors?.[0]?.message
                            ? // ? CustomToast.error(err?.data?.apiResponse?.errors?.[0]?.message)
                              CustomToast.error(
                                  getMultipleErrorMessage(err?.data?.apiResponse?.errors)
                              )
                            : err?.data?.apiResponse?.message
                            ? CustomToast.error(err?.data?.apiResponse?.message)
                            : CustomToast.error(getErrorMessageFromResponse(err))
                        setLoading(false)
                    })
                    .finally(() => setLoading(false))
            }
        })
    }

    return (
        <div style={{fontFamily: fontsFamily.manrope}} className='position-relative'>
            <nav className='d-flex align-items-center bg-cmwhite sticky-top px-10 h-15 py-5 mb-md-15 mb-5'>
                <KTSVG
                    path='/media/icons/duotune/art/SequifiSLogo.svg'
                    svgClassName='w-45px h-45px'
                />
                <div className='text-cmGrey900' style={{fontSize: 35.6, fontWeight: 600}}>
                    Clearance
                </div>
            </nav>

            {isValidRequest ? (
                <div
                    className='container mb-20 overflow-autoborder border-cmDisabled bg-cmwhite rounded p-md-10 p-5 mw-500px '
                    style={{
                        fontFamily: fontsFamily.manrope,
                        fontWeight: 600,
                        alignSelf: 'center',
                    }}
                >
                    <div className='d-flex flex-center p-0'>
                        <CustomLottie lottieJson={WentWrongAnimation} height={300} width={300} />
                    </div>
                    <div
                        className='text-cmGrey800 text-center mb-20'
                        style={{fontWeight: 600, fontSize: 16, lineHeight: '24px'}}
                    >
                        <span>{isValidRequest}</span>
                    </div>
                </div>
            ) : (
                <div className=''>
                    <div
                        className='container mb-20 overflow-auto'
                        style={{overflow: 'auto', marginBottom: '50px'}}
                    >
                        <div className='mb-10'>
                            <div
                                className='text-cmGrey900'
                                style={{fontWeight: 600, fontSize: 20, lineHeight: '30px'}}
                            >
                                Background Check
                            </div>
                            <div
                                className='text-cmGrey700'
                                style={{fontWeight: 600, lineHeight: '24px'}}
                            >
                                Details for sending background check for Employees
                            </div>
                        </div>
                        {step == 1 ? (
                            <PersonalDetailsForm
                                loading={loading}
                                validationError={formErrorData}
                                setStep={setStep}
                                userData={userData}
                                onChangeInputData={onChangeInputData}
                                handlePersonalDetailForm={handlePersonalDetailForm}
                                setUserData={setUserData}
                            />
                        ) : step == 2 ? (
                            <VerificationForm
                                setStep={setStep}
                                questionsData={questionsData}
                                setLoading={setLoading}
                                loading={loading}
                                setVerificationSuccess={setVerificationSuccess}
                                setValidRequest={setValidRequest}
                            />
                        ) : (
                            <SuccessCard
                                verificationSuccess={verificationSuccess}
                                loading={loading}
                            />
                        )}
                    </div>

                    <div className='fixed-bottom'>
                        <div className='bg-cmwhite d-flex flex-center h-sm-50px '>
                            <div className='d-flex align-items-center justify-content-between w-md-80 px-md-0 px-10 flex-wrap mx-auto'>
                                <div
                                    className='text-cmGrey500'
                                    style={{fontWeight: '500', fontSize: '14px'}}
                                >
                                    © Copyright | Sequidocs | All rights reserved
                                </div>

                                <div className='d-flex flex-center '>
                                    <div
                                        className='text-cmGrey500'
                                        style={{fontWeight: '500', fontSize: '12px'}}
                                    >
                                        Powered by
                                    </div>

                                    <KTSVG
                                        path='/media/icons/duotune/art/SequifiLogoWithText.svg'
                                        svgStyle={{width: '75px', height: '23px'}}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default BackgroundVerificationPage

const PersonalDetailsForm = ({
    setStep,
    userData,
    onChangeInputData,
    handlePersonalDetailForm,
    setUserData,
    validationError,
    loading,
}) => {
    const eighteenYearsAgo = new Date()
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18)
    const [optionalAddress, setOptionalAddress] = useState([1])
    const [openTandCModal, setopenTandCModal] = useState(false)
    const [showCheckBox, setshowCheckBox] = useState(false)

    const handleAddInput = useCallback(() => {
        let dummyArray = [...optionalAddress]
        dummyArray.push(optionalAddress.length + 1)
        setOptionalAddress(dummyArray)
    }, [optionalAddress])

    return (
        <div
            className='border border-cmDisabled bg-cmwhite rounded p-md-10 p-5 position-relative'
            style={{fontFamily: fontsFamily.manrope, fontWeight: 600}}
        >
            <CustomLoader full visible={loading} opacity={1} />
            <div className='text-cmGrey800 mb-5' style={{fontWeight: 600, fontSize: 16}}>
                Personal Details{' '}
            </div>
            <form id='Background_Check_Personal_Detail' onSubmit={handlePersonalDetailForm}>
                {/* line 1 */}
                <div className='mb-7'>
                    <CustomInput
                        required
                        type={INPUT_TYPE.email}
                        id={'Personal_Details_Email'}
                        label={'Email Address'}
                        placeholder='abc@gmail.com'
                        value={userData?.email}
                        name={FIELD_KEYS.email}
                        onChange={onChangeInputData}
                        errorMessage={validationError?.email}
                        disabled={true}
                    />
                </div>
                {/* line 2 */}
                <div className='row mb-7 gap-sm-0 gap-5'>
                    <div className='col-sm'>
                        <CustomInput
                            required
                            id={'Personal_Details_First_Name'}
                            placeholder='Enter First Name'
                            label={'First Name'}
                            value={userData?.first_name}
                            name={FIELD_KEYS.first_name}
                            onChange={onChangeInputData}
                            errorMessage={validationError?.first_name}
                        />
                    </div>
                    <div className='col-sm'>
                        <CustomInput
                            id={'Personal_Details_Middle_Name'}
                            placeholder='Enter Middle Name'
                            label={'Middle Name'}
                            subLabel={'(Optional)'}
                            value={userData?.middle_name}
                            name={FIELD_KEYS.middle_name}
                            onChange={onChangeInputData}
                        />
                    </div>
                    <div className='col-sm'>
                        <CustomInput
                            required
                            id={'Personal_Details_Last_Name'}
                            placeholder='Enter Last Name'
                            label={'Last Name'}
                            value={userData?.last_name}
                            name={FIELD_KEYS.last_name}
                            onChange={onChangeInputData}
                            errorMessage={validationError?.last_name}
                        />
                    </div>
                </div>
                {/* line 3 */}
                <div className='row mb-7 gap-sm-0 gap-5'>
                    <div className='col-sm'>
                        <CustomInput
                            id={'Personal_Details_Phone_Number'}
                            placeholder='Enter Phone Number'
                            label={'Phone Number'}
                            type={INPUT_TYPE.mobile}
                            value={userData?.phone_number}
                            name={FIELD_KEYS.phone_number}
                            onChange={onChangeInputData}
                            errorMessage={validationError?.phone_number}
                            required
                        />
                    </div>
                    <div className='col-sm'>
                        <CustomDropdown
                            id={'Personal_Details_Phone_Type'}
                            placeholder='Enter Phone Type'
                            label={'Select phone type'}
                            searching={false}
                            showClear={false}
                            options={[
                                {name: 'Mobile', value: 'Mobile'},
                                {name: 'Home', value: 'Home'},
                                {name: 'Office', value: 'Office'},
                            ]}
                            name={FIELD_KEYS.phone_type}
                            value={userData.phone_type}
                            onChange={onChangeInputData}
                            errorMessage={validationError?.phone_type}
                        />
                    </div>
                </div>
                {/* line 4 */}
                <div className='mb-7'>
                    <CustomInput
                        id={'Personal_Details_Address'}
                        label={'Address'}
                        placeholder='Enter Address'
                        value={userData?.address_line_1}
                        name={FIELD_KEYS.address_line_1}
                        onChange={onChangeInputData}
                        errorMessage={validationError?.address_line_1}
                        required
                    />
                </div>
                {/* line 5 */}
                <div className='mb-7'>
                    <CommonLabel
                        label={'Address Line 2 (Optional)'}
                        subLabel={
                            optionalAddress?.length < 3 ? (
                                <i
                                    className='bi bi-plus-lg bg-cmBlue-Crayola p-1 rounded px-2 text-cmBlue-Crayola bg-opacity-10 cursor-pointer'
                                    onClick={() => {
                                        handleAddInput()
                                    }}
                                ></i>
                            ) : null
                        }
                    />

                    <div className='mt-2'>
                        {optionalAddress?.map((i, index) => (
                            <div className='mb-2'>
                                <CustomInput
                                    id={`Personal_Details_Address_${index}`}
                                    placeholder={`Enter Address ${index + 2}`}
                                    value={userData?.[`address_line_${index + 2}`]}
                                    name={`address_line_${index + 2}`}
                                    onChange={onChangeInputData}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                {/* line 6 */}
                <div className='row mb-7 gap-sm-0 gap-5'>
                    <div className='col-sm'>
                        <CustomInput
                            id={'Personal_Details_Locality'}
                            placeholder='Enter City'
                            label={'City'}
                            value={userData?.locality}
                            name={FIELD_KEYS.locality}
                            onChange={onChangeInputData}
                            errorMessage={validationError?.locality}
                            required
                        />
                    </div>
                    <div className='col-sm'>
                        <CustomInput
                            id={'Personal_Details_Region'}
                            placeholder='NY'
                            rejex={/^[A-Za-z]$/}
                            label={'Region'}
                            value={userData?.region}
                            name={FIELD_KEYS.region}
                            onChange={onChangeInputData}
                            errorMessage={validationError?.region}
                            required
                        />
                    </div>
                    <div className='col-sm'>
                        <CustomInput
                            id={'Personal_Details_Postal_Code'}
                            type={INPUT_TYPE.mobile}
                            placeholder='Enter Postal Code'
                            mask='99999'
                            label={'Postal Code'}
                            value={userData?.postal_code}
                            name={FIELD_KEYS.postal_code}
                            onChange={onChangeInputData}
                            errorMessage={validationError?.postal_code}
                            required
                        />
                    </div>
                </div>
                <div className='row gap-sm-0 gap-5 mb-10'>
                    <div className='col-sm'>
                        <CustomDatePicker
                            maxDate={eighteenYearsAgo}
                            id={'Personal_Details_Date_Of_Birth'}
                            label={'Date of Birth'}
                            subLabel={'(Accepting above 18)'}
                            showTodayAndClearButton={false}
                            value={userData?.date_of_birth}
                            name={FIELD_KEYS.date_of_birth}
                            onChange={onChangeInputData}
                            errorMessage={validationError?.date_of_birth}
                            required
                        />
                    </div>
                    <div className='col-sm'>
                        <CustomInput
                            id={'Personal_Details_social_security_number'}
                            type={INPUT_TYPE.mobile}
                            mask='999-99-9999'
                            label={'Social Security Number'}
                            placeholder='Enter a 9 digit Social Security Number'
                            name={FIELD_KEYS.social_security_number}
                            value={userData?.social_security_number}
                            onChange={onChangeInputData}
                            errorMessage={validationError?.social_security_number}
                            required
                        />
                    </div>
                </div>
                <div
                    className='text-cmBlack d-flex align-items-center gap-2 mb-10'
                    style={{fontWeight: 500, fontSize: 16, lineHeight: '21.8px'}}
                >
                    {showCheckBox ? (
                        <CustomCheckbox
                            disabled={!showCheckBox}
                            id={'Personal_Details_Terms_Check_Box'}
                            checked={getBooleanValue(userData?.accepted_terms_conditions) == 1}
                            value={getBooleanValue(userData?.accepted_terms_conditions) == 1}
                            onChange={() =>
                                setUserData((val) => ({
                                    ...val,
                                    accepted_terms_conditions:
                                        getBooleanValue(userData?.accepted_terms_conditions) == 1
                                            ? 0
                                            : 1,
                                }))
                            }
                        />
                    ) : (
                        <CustomTooltip title={'Please read the Terms & Conditions first'}>
                            <CustomCheckbox
                                disabled={!showCheckBox}
                                id={'Personal_Details_Terms_Check_Box'}
                                checked={getBooleanValue(userData?.accepted_terms_conditions) == 1}
                                value={getBooleanValue(userData?.accepted_terms_conditions) == 1}
                                onChange={() =>
                                    setUserData((val) => ({
                                        ...val,
                                        accepted_terms_conditions:
                                            getBooleanValue(userData?.accepted_terms_conditions) ==
                                            1
                                                ? 0
                                                : 1,
                                    }))
                                }
                            />
                        </CustomTooltip>
                    )}
                    Accept{' '}
                    <span
                        id='Personal_Details_Terms_Conditions_Btn'
                        className='text-cmBlue-Crayola cursor-pointer'
                        onClick={() => setopenTandCModal(true)}
                    >
                        Terms & Conditions
                    </span>
                </div>
                <div className='d-flex flex-start'>
                    <CustomButton
                        disabled={getBooleanValue(userData?.accepted_terms_conditions) == 0}
                        onClick={handlePersonalDetailForm}
                        buttonSize={BUTTON_SIZE.normal}
                        buttonLabel='Get Started'
                        id={'Personal_Details_Get_Started_Button'}
                    />
                </div>
            </form>
            {openTandCModal ? (
                <BackgroundverificationTandCModal
                    open={openTandCModal}
                    close={() => {
                        setopenTandCModal(false)
                        setshowCheckBox(true)
                    }}
                />
            ) : null}
        </div>
    )
}

const VerificationForm = ({
    setStep,
    loading,
    setLoading,
    questionsData,
    setVerificationSuccess,
    setValidRequest,
}) => {
    const [queData, setQueData] = useState(questionsData?.authenticationQuestions)
    const onChangeQuedata = (e, index) => {
        let data = _.cloneDeep(queData)
        data[index].value = e.target.value
        setQueData(data)
    }
    useEffect(
        () => setQueData(questionsData?.authenticationQuestions),
        [questionsData?.authenticationQuestions]
    )
    const handleAnswerSubmit = () => {
        const payloadData = queData?.map((i) => ({...i, selectedChoiceKeyName: i?.value}))
        const body = {
            examId: questionsData?.examId,
            screeningRequestApplicantId: questionsData?.screeningRequestApplicantId,
            answers: payloadData,
        }
        setLoading(true)
        submitBackgroundVerificationAnswersV1Service(body)
            .then((res) => {
                if (res?.manual_authentication) {
                    setValidRequest(
                        res?.message ||
                            'We are unable to verify your identity online. Please call customer support at 888.710.0272 for assistance with completing your screening over the phone.'
                    )
                    return
                }
                if (res?.status) {
                    setVerificationSuccess(res?.status)
                    setStep(3)
                } else {
                    CustomToast.error(res?.message)
                    window.location.reload(true) // Reload the page
                }
            })

            .catch((err) => CustomToast.error(getErrorMessageFromResponse(err)))
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div
            className='border border-cmDisabled bg-cmwhite rounded p-md-10 p-5 position-relative'
            style={{fontFamily: fontsFamily.manrope, fontWeight: 600}}
        >
            <CustomLoader full visible={loading} opacity={0.6} />
            <div className='text-cmGrey800 mb-5' style={{fontWeight: 600, fontSize: 16}}>
                Verification
            </div>
            {loading ? (
                <VerificationSkeleton />
            ) : (
                <form id='Background_Check_Verification_Form'>
                    {/* line 1 */}
                    {queData?.map((item, index) => {
                        return (
                            <div className='mb-7'>
                                <CustomDropdown
                                    showClear={false}
                                    searching={false}
                                    options={item?.choices}
                                    displayKey='choiceDisplayName'
                                    valueKey='choiceKeyName'
                                    value={item?.value}
                                    name={item?.questionKeyName}
                                    id={`Verification_input_field_${index}`}
                                    label={item?.questionDisplayName}
                                    placeholder='Select answer'
                                    onChange={(e) => onChangeQuedata(e, index)}
                                />
                            </div>
                        )
                    })}
                    <div className='d-flex flex-start'>
                        <CustomButton
                            onClick={() => handleAnswerSubmit()}
                            buttonSize={BUTTON_SIZE.normal}
                            buttonLabel='Next'
                            id={'Verification_Next_Btn'}
                        />
                    </div>
                </form>
            )}
        </div>
    )
}

const SuccessCard = ({verificationSuccess}) => {
    return (
        <div
            className='border border-cmDisabled bg-cmwhite rounded p-md-10 p-5'
            style={{fontFamily: fontsFamily.manrope, fontWeight: 600}}
        >
            <div className='d-flex flex-center p-0'>
                {verificationSuccess ? (
                    <CustomLottie lottieJson={Success_art} height={300} width={300} />
                ) : (
                    <CustomLottie lottieJson={WentWrongAnimation} height={300} width={300} />
                )}
            </div>
            <div
                className='text-cmGrey800 text-center mb-20'
                style={{fontWeight: 600, fontSize: 16, lineHeight: '24px'}}
            >
                {verificationSuccess ? (
                    <span>
                        You Verification was Successful!{<br />} Report has been submitted to your
                        manager.
                    </span>
                ) : (
                    <span>
                        We are unable to verify your identity online. Please call customer support
                        at
                        <br /> <span style={{fontWeight: 700}}>(888)710.0272</span> for assistance
                        with completing your screening over the phone.
                    </span>
                )}
            </div>
        </div>
    )
}

const VerificationSkeleton = () => (
    <div>
        <CustomSkeleton className={'h-20px'} borderRadius={10} />
        <CustomSkeleton className={'h-50px'} borderRadius={10} />
        <CustomSkeleton className={'h-20px'} borderRadius={10} />
        <CustomSkeleton className={'h-50px'} borderRadius={10} />
        <CustomSkeleton className={'h-20px'} borderRadius={10} />
        <CustomSkeleton className={'h-50px'} borderRadius={10} />
    </div>
)
