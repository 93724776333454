import React from 'react'
import {fontsFamily} from '../../../../assets/fonts/fonts'
import CustomButton, {
    BUTTON_SIZE,
    BUTTON_TYPE,
} from '../../../../customComponents/customButtton/CustomButton'
import {KTSVG} from '../../../../_metronic/helpers'
import {useNavigate} from 'react-router-dom'

export const AdminBillingNotice = () => {
    const navigate = useNavigate()
    return (
        <div className='mx-auto w-md-50 ' style={{fontSize: 14, fontFamily: fontsFamily.manrope}}>
            <div className='  bg-cmwhite p-5  shadow-sm' style={{borderRadius: 10}}>
                <div className='d-flex flex-column gap-12 px-5 py-10'>
                    <div
                        className='text-start text-cmGrey900 p-2 '
                        style={{fontSize: 20, fontWeight: 700}}
                    >
                        🚨 Your Account is Deactivated
                    </div>
                    <div
                        className=' d-grid gap-10 text-start text-cmGrey700 p-2 '
                        style={{fontSize: 14, fontWeight: 600}}
                    >
                        <div>
                            We appreciate your continued trust in our services. However, it has come
                            to our attention that your account is currently overdue.
                        </div>
                        <div>
                            To ensure uninterrupted access to our premium features and to maintain
                            the high-quality service you've come to expect, we kindly request you to
                            settle your outstanding bill at your earliest convenience.
                        </div>
                        <div>
                            <div>Best regards,</div>
                            <div>
                                The{' '}
                                <span className='text-cmGrey800' style={{fontWeight: 700}}>
                                    Sequifi
                                </span>{' '}
                                Team
                            </div>
                        </div>
                    </div>
                    <div>
                        <CustomButton
                            buttonType={BUTTON_TYPE.primary}
                            buttonLabel='Go to Billing'
                            id='go_to_billing'
                            onClick={() => navigate('/settings/billings/billing-history')}
                            buttonSize={BUTTON_SIZE.large}
                        />
                    </div>
                    <div className='text-center '>
                        <KTSVG
                            path='/media/icons/duotune/art/billingmembership.svg'
                            id={'billing_notice_image'}
                            svgClassName='w-225px h-225px'
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AdminBillingNotice
