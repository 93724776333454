import {useNavigate} from 'react-router'
import {KTSVG} from '../../../../_metronic/helpers'
import CustomButton, {BUTTON_SIZE} from '../../../../customComponents/customButtton/CustomButton'
import {useSelector} from 'react-redux'
import Show from '../../../../customComponents/show/Show'
import {useCallback, useEffect, useState} from 'react'
import {sendDataToReducer} from '../../../../helpers/CommonHelpers'
import {useDispatch} from 'react-redux'
import {SET_OVERDUE_BILLING_DATA} from '../../../../redux/reducers/SettingReducer'
import {MINIMUM_BILLING_OVERDUE_DAYS, getValidDate} from '../../../../constants/constants'
import {getActiveRoleSelector} from '../../../../redux/selectors/AuthSelectors'
import {ROLES} from '../../../../accessRights/AccessRightsConstants'
import useIsAccountBillingDue from '../../../../hooks/useIsAccountBillingDue'

export const BillingNoticeHeader = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {overDueBillingData} = useIsAccountBillingDue()
    const [showBillingNoticeAlert, setShowBillingNoticeAlert] = useState(false)
    const activeRole = useSelector(getActiveRoleSelector)

    const onClickPayNow = () => {
        navigate('/settings/billings/billing-history')
    }

    const onCloseAlert = useCallback(
        (e) => {
            sendDataToReducer(dispatch, SET_OVERDUE_BILLING_DATA, {
                ...overDueBillingData,
                closeDate: getValidDate(new Date(), 'YYYY-MM-DD'),
            })
        },
        [overDueBillingData, dispatch]
    )
    const modalNotClosedToday = useCallback(() => {
        return (
            getValidDate(overDueBillingData?.closeDate, 'YYYY-MM-DD') !=
            getValidDate(new Date(), 'YYYY-MM-DD')
        )
    }, [overDueBillingData?.closeDate])

    useEffect(() => {
        if (modalNotClosedToday()) setShowBillingNoticeAlert(true)
        else setShowBillingNoticeAlert(false)
    }, [overDueBillingData])

    return (
        <Show>
            <Show.When
                isVisible={
                    !overDueBillingData?.isBillingOverdue &&
                    activeRole == ROLES.administrator.roleValue &&
                    overDueBillingData?.overdueDays >= 15 &&
                    MINIMUM_BILLING_OVERDUE_DAYS - overDueBillingData?.overdueDays > 0 &&
                    showBillingNoticeAlert
                }
            >
                <div
                    className='d-flex   bg-cmError bg-opacity-5 p-5 border-bottom border-1 border-cmBlue-Crayola border-bottom-dashed'
                    style={{fontWeight: 600, fontSize: 16}}
                >
                    <div className='d-flex  flex-grow-1 flex-wrap justify-content-center gap-5 align-items-center text-cmError'>
                        <KTSVG
                            path='/media/icons/duotune/art/warning.svg'
                            className='cursor-pointer'
                            svgClassName='w-25px h-25px'
                        />
                        <div>
                            Account Will be disabled in{' '}
                            {MINIMUM_BILLING_OVERDUE_DAYS - overDueBillingData?.overdueDays} days!
                            Please Pay now to continue using our services
                        </div>
                        <div>
                            <CustomButton
                                buttonSize={BUTTON_SIZE.small}
                                buttonLabel='Pay Now'
                                onClick={onClickPayNow}
                            />
                        </div>
                    </div>
                    <div className=''>
                        <KTSVG
                            path='/media/icons/duotune/art/CrossIcon.svg'
                            className='cursor-pointer'
                            svgClassName='w-35px h-35px'
                            onClick={onCloseAlert}
                        />
                    </div>
                </div>
            </Show.When>
        </Show>
    )
}
