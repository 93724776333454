// PERMISSIONS_GROUP.map((item) => ({roleName: item?.name, roleValue: item?.name }))
// PERMISSIONS_GROUP[0].grouppolicy.map((item) => ({moduleName: item?.policies?.replace(/[\s\/]/g,''), moduleValue: item?.policies }))
// PERMISSIONS_GROUP[0].grouppolicy[1].policytab.map((item) => ({ item?.tabs?.replace(/[\s\/]/g,''),  item?.tabs }))
// PERMISSIONS_GROUP[0].grouppolicy[1].policytab[0].permission.map((item) => ({permissionName: item?.guard_name?.toLowerCase(), permissionValue: item?.name }))

// PERMISSIONS_GROUP.map((roleItem) => {
//     const roleName = roleItem.name.replace(/[\s&\/]/g,'')
//     permissions[roleName] = {};
//     roleItem.grouppolicy.map((moduleItem) => {
//         const moduleName = moduleItem.policies.replace(/[\s&\/]/g,'')
//         permissions[roleName][moduleName] = {}
//         moduleItem.policytab.map((subModuleItem) => {
//             const subModuleName = subModuleItem.tabs.replace(/[\s&\/]/g,'')
//             permissions[roleName][moduleName][subModuleName] = {}
//             permissions[roleName][moduleName][subModuleName] = {
//                     roleName: roleName,
//                     roleValue: roleItem.name,
//                     moduleName: moduleName,
//                     moduleValue: moduleItem.policies,
//                      subModuleName,
//                      subModuleItem.tabs,
//                     view: subModuleItem?.permission?.some((item) => item.guard_name == 'View'),
//                     add: subModuleItem?.permission?.some((item) => item.guard_name == 'Add'),
//                     edit: subModuleItem?.permission?.some((item) => item.guard_name == 'Edit'),
//                     delete: subModuleItem?.permission?.some((item) => item.guard_name == 'Delete'),
//                 }

//         })
//     })
// })

/**
 * name -> Will be use for identification from frontend
 * valye -> Will came from backend
 */
const ALL_PERMISSIONS = {
    administrator: {
        // Dashboard
        dashboard: {
            view: 'dashboards-view',
            add: 'dashboards-add',
            edit: 'dashboards-edit',
            delete: 'dashboards-delete',
        },
        // Settings
        setup: {
            view: 'setup-view',
            add: 'setup-add',
            edit: 'setup-edit',
            delete: 'setup-delete',
        },
        locations: {
            view: 'locations-view',
            add: 'locations-add',
            edit: 'locations-edit',
            delete: 'locations-delete',
        },
        costTracking: {
            view: 'cost-tracking-view',
            add: 'cost-tracking-add',
            edit: 'cost-tracking-edit',
            delete: 'cost-tracking-delete',
        },
        departments: {
            view: 'departments-view',
            add: 'departments-add',
            edit: 'departments-edit',
            delete: 'departments-delete',
        },
        positions: {
            view: 'positions-view',
            add: 'positions-add',
            edit: 'positions-edit',
            delete: 'positions-delete',
        },
        alerts: {
            view: 'alerts-view',
            add: 'alerts-add',
            edit: 'alerts-edit',
            delete: 'alerts-delete',
        },
        bankAccounts: {
            view: 'bank-account-view',
            add: 'bank-account-add',
            edit: 'bank-account-edit',
            delete: 'bank-account-delete',
        },
        paperWork: {
            view: 'paperwork-view',
            add: 'paperwork-add',
            edit: 'paperwork-edit',
            delete: 'paperwork-delete',
        },

        // Integrations
        integrations: {
            view: 'integrations-view',
            add: 'integrations-add',
            edit: 'integrations-edit',
            delete: 'integrations-delete',
        },

        // Sequi Docs
        templates: {
            view: 'templates-view',
            add: 'templates-add',
            edit: 'templates-edit',
            delete: 'templates-delete',
            sendTemplate: 'templates-send',
        },
        documents: {
            view: 'documents-view',
            add: 'documents-add',
            edit: 'documents-edit',
            delete: 'documents-delete',
        },
        // Payroll
        runPayrollAndApprovals: {
            view: 'run-payroll-view',
            add: 'run-payroll-add',
            edit: 'run-payroll-edit',
            delete: 'run-payroll-delete',
        },
        oneTimePayment: {
            view: 'one-time-payment-view',
            add: 'one-time-payment-add',
            edit: 'one-time-payment-edit',
            delete: 'one-time-payment-delete',
        },
        paymentRequest: {
            view: 'payment-request-view',
            add: 'payment-request-add',
            edit: 'payment-request-edit',
            delete: 'payment-request-delete',
        },
        payrollReconciliation: {
            view: 'reconciliation-view',
            add: 'reconciliation-add',
            edit: 'reconciliation-edit',
            delete: 'reconciliation-delete',
        },

        sClearance: {
            view: 's-clearance-view',
            add: 's-clearance-add',
            edit: 's-clearance-edit',
            delete: 's-clearance-delete',
        },
        // Dashboard
        sequiCRM: {
            view: 'sequi-crm-view',
            add: 'sequi-crm-add',
            edit: 'sequi-crm-edit',
            delete: 'sequi-crm-delete',
        },
        
        // Reports
        company: {
            view: 'compony-view',
            add: 'compony-add',
            edit: 'compony-edit',
            delete: 'compony-delete',
        },
        sales: {
            view: 'sales-view',
            add: 'sales-add',
            edit: 'sales-edit',
            delete: 'sales-delete',
        },
        cost: {
            view: 'cost-view',
            add: 'cost-add',
            edit: 'cost-edit',
            delete: 'cost-delete',
        },
        payroll: {
            view: 'payroll-view',
            add: 'payroll-add',
            edit: 'payroll-edit',
            delete: 'payroll-delete',
        },
        reconciliation: {
            view: 'report-reconciliation-view',
            add: 'report-reconciliation-add',
            edit: 'report-reconciliation-edit',
            delete: 'report-reconciliation-delete',
        },
        clawback: {
            view: 'clawback-view',
            add: 'clawback-add',
            edit: 'clawback-edit',
            delete: 'clawback-delete',
        },
        pendingInstall: {
            view: 'pending-install-view',
            add: 'pending-install-add',
            edit: 'pending-install-edit',
            delete: 'pending-install-delete',
        },

        // Permissions
        group: {
            view: 'group-view',
            add: 'group-add',
            edit: 'group-edit',
            delete: 'group-delete',
        },
        policies: {
            view: 'policies-view',
            add: 'policies-add',
            edit: 'policies-edit',
            delete: 'policies-delete',
        },

        // Marketing Deal
        marketingDeal: {
            view: 'marketing-deal-view',
            add: 'marketing-deal-add',
            edit: 'marketing-deal-edit',
            delete: 'marketing-deal-delete',
        },
        marketingDealCostTracking: {
            view: 'cast-tracking-view',
            add: 'cast-tracking-add',
            edit: 'cast-tracking-edit',
            delete: 'cast-tracking-delete',
        },
        // Alert Center
        alertCenter: {
            view: 'alert-center-view',
            add: 'alert-center-add',
            edit: 'alert-center-edit',
            delete: 'alert-center-delete',
        },
        support: {
            view: 'support-view',
            add: 'support-add',
            edit: 'support-edit',
            delete: 'support-delete',
        },
    },
    standard: {
        standardSequiDocsTemplates: {
            view: 'sequidoc-templates-view',
            add: 'sequidoc-templates-add',
            edit: 'sequidoc-templates-edit',
            delete: 'sequidoc-templates-delete',
        },
        standardSequiDocsDocuments: {
            view: 'sequidoc-documents-view',
            add: 'sequidoc-documents-add',
            edit: 'sequidoc-documents-edit',
            delete: 'sequidoc-documents-delete',
        },

        dashboard: {
            view: 'dashboard-view',
            add: 'dashboard-add',
            edit: 'dashboard-edit',
            delete: 'dashboard-delete',
        },
        mySales: {
            view: 'my-sales-view',
            add: 'my-sales-add',
            edit: 'my-sales-edit',
            delete: 'my-sales-delete',
        },
        payStubs: {
            view: 'paystub-view',
            add: 'paystub-add',
            edit: 'paystub-edit',
            delete: 'paystub-delete',
        },
        myOverrides: {
            view: 'my-overrides-view',
            add: 'my-overrides-add',
            edit: 'my-overrides-edit',
            delete: 'my-overrides-delete',
        },
        pendingPay: {
            view: 'paystub-view',
        },

        referrals: {
            view: 'referrals-view',
            add: 'referrals-add',
            edit: 'referrals-edit',
            delete: 'referrals-delete',
        },

        hiringProgress: {
            view: 'hiring-progress-view',
            add: 'hiring-progress-add',
            edit: 'hiring-progress-add',
            delete: 'hiring-progress-add',
        },
        leads: {
            view: 'leads-view',
            add: 'leads-add',
            edit: 'leads-edit',
            delete: 'leads-delete',
        },
        pipeline: {
            view: 'pipeline-view',
            add: 'pipeline-add',
            edit: 'pipeline-edit',
            delete: 'pipeline-delete',
        },
        onboardingEmployees: {
            view: 'onboarding-employees-view',
            add: 'onboarding-employees-add',
            edit: 'onboarding-employees-edit',
            delete: 'onboarding-employees-delete',
            hireNow: 'onboarding-employees-hire-now',
            hireDirectly: 'onboarding-employees-hire-directly',
        },
        Calendar: {
            view: 'calendar-view',
            add: 'calendar-add',
            edit: 'calendar-edit',
            delete: 'calendar-delete',
        },
        employee: {
            view: 'employee-view',
            add: 'employee-add',
            edit: 'employee-edit',
            delete: 'employee-delete',
        },
        teams: {
            view: 'team-view',
            add: 'team-add',
            edit: 'team-edit',
            delete: 'team-delete',
        },
        sequiDocs: {
            view: 'sequifiDocs-view',
            add: 'sequifiDocs-add',
            edit: 'sequifiDocs-edit',
            delete: 'sequifiDocs-delete',
        },
        community: {
            view: 'community-view',
            add: 'community-add',
            edit: 'community-edit',
            delete: 'community-delete',
        },
        projections: {
            view: 'projections-view',
            add: 'projections-add',
            edit: 'projections-edit',
            delete: 'projections-delete',
        },
        office: {
            view: 'office-view',
            add: 'office-add',
            edit: 'office-edit',
            delete: 'office-delete',
        },
        standardSales: {
            view: 'report-sales-view',
            add: 'report-sales-add',
            edit: 'report-sales-edit',
            delete: 'report-sales-delete',
        },
        reconciliation: {
            view: 'reports-reconciliation-view',
            add: 'reports-reconciliation-add',
            edit: 'reports-reconciliation-edit',
            delete: 'reports-reconciliation-delete',
        },
        pastPayStubs: {
            view: 'past-pay-stubs-view',
            add: 'past-pay-stubs-add',
            edit: 'past-pay-stubs-edit',
            delete: 'past-pay-stubs-delete',
        },
        training: {
            view: 'training-view',
            add: 'training-add',
            edit: 'training-edit',
            delete: 'training-delete',
        },
        request: {
            view: 'request-view',
            add: 'request-add',
            edit: 'request-edit',
            delete: 'request-delete',
        },
        approvals: {
            view: 'approvals-view',
            add: 'approvals-add',
            edit: 'approvals-edit',
            delete: 'approvals-delete',
        },
        support: {
            view: 'supports-view',
            add: 'supports-add',
            edit: 'supports-edit',
            delete: 'supports-delete',
        },
    },
    profile: {
        personalInfo: {
            view: 'personal-info-view',
            add: 'personal-info-add',
            edit: 'personal-info-edit',
            delete: 'personal-info-delete',
        },
        employmentPackage: {
            view: 'employment-package-view',
            add: 'employment-package-add',
            edit: 'employment-package-edit',
            delete: 'employment-package-delete',
            transferEmployee: 'employment-package-transfer-employee',
            dismissUsers: 'employment-package-dismiss-users',
        },
        taxInfo: {
            view: 'tax-info-view',
            add: 'tax-info-add',
            edit: 'tax-info-edit',
            delete: 'tax-info-delete',
        },
        banking: {
            view: 'banking-view',
            add: 'banking-add',
            edit: 'banking-edit',
            delete: 'banking-delete',
        },
        documents: {
            view: 'profile-documents-view',
            add: 'profile-documents-add',
            edit: 'profile-documents-edit',
            delete: 'profile-documents-delete',
        },
        network: {
            view: 'network-view',
            add: 'network-add',
            edit: 'network-edit',
            delete: 'network-delete',
        },
        auditLog: {
            view: 'audit-log-view',
        },
    },
}

const getRoleData = (roleName = '', roleValue = '') => {
    return {roleName, roleValue}
}

export const ROLES = {
    administrator: getRoleData('administrator', 'administrator'),
    standard: getRoleData('standard', 'standard'),
    profile: getRoleData('profile', 'profile'),
}

const getModuleData = (moduleName = '', moduleValue = '') => {
    return {moduleName, moduleValue}
}

const MODULES = {
    // Administrator
    administrator: {
        dashboard: getModuleData('dashboard', 'Dashboard'),
        setting: getModuleData('setting', 'Setting'),
        integrations: getModuleData('integrations', 'Integrations'),
        sequiDocs: getModuleData('sequiDocs', 'SequiDocs'),
        payroll: getModuleData('payroll', 'PayRoll'),
        sClearance: getModuleData('sClearance', 'S-Clearance'),
        sequiCRM: getModuleData('sequiCRM', 'Sequi-CRM'),
        reports: getModuleData('reports', 'Reports'),
        permissions: getModuleData('permissions', 'Permissions'),
        marketingDeals: getModuleData('marketingDeals', 'Marketing Deals'),
        importExport: getModuleData('importExport', 'Import/Export'),
        alertCenter: getModuleData('alertCenter', 'Alerts Center'),
        support: getModuleData('support', 'Support'),
    },
    standard: {
        dashboard: getModuleData('dashboard', 'Dashboard'),
        mySales: getModuleData('mySales', 'My Sales'),
        hiring: getModuleData('hiring', 'Hiring'),
        calendar: getModuleData('calendar', 'Calendar'),
        sequiDocs: getModuleData('sequiDocs', 'SequiDocs'),
        management: getModuleData('management', 'Management'),
        reports: getModuleData('reports', 'Reports'),
        community: getModuleData('community', 'Community'),
        projections: getModuleData('projections', 'Projections'),
        training: getModuleData('training', 'Training'),
        requestsAndApprovals: getModuleData('requestsAndApprovals', 'Requests & Approvals'),
        support: getModuleData('support', 'Support'),
        referrals: getModuleData('referrals', 'Referrals'),
    },
    profile: {
        profile: getModuleData('profile', 'Profile'),
    },
}

const getSubModuleData = (subModuleName = '', subModuleValue = '') => {
    return {subModuleName, subModuleValue}
}

export const SUB_MODULES = {
    administrator: {
        // Dashboard
        dashboard: getSubModuleData('dashboard', 'Dashboard'),
        // Setup
        setup: getSubModuleData('setup', 'Setup'),
        locations: getSubModuleData('locations', 'Locations'),
        costTracking: getSubModuleData('costTracking', 'Cost Traking'),
        departments: getSubModuleData('departments', 'Departments'),
        positions: getSubModuleData('positions', 'Positions'),
        alerts: getSubModuleData('alerts', 'Alerts', ALL_PERMISSIONS.administrator.alerts),
        bankAccounts: getSubModuleData('bankAccounts', 'Bank Accounts'),
        paperWork: getSubModuleData('paperWork', 'Paperwork'),

        // Integration
        integrations: getSubModuleData('integrations', 'Integrations'),

        // Sequi Docs
        templates: getSubModuleData('templates', 'Templates'),
        documents: getSubModuleData('documents', 'Documents'),

        // Payroll
        runPayrollAndApprovals: getSubModuleData(
            'runPayrollAndApprovals',
            'Run Payroll & Approvals'
        ),

        sClearance: getSubModuleData('sClearance', 'S-Clearance'),

        oneTimePayment: getSubModuleData('oneTimePayment', 'One-time Payment'),
        paymentRequest: getSubModuleData('paymentRequest', 'Payment Request'),
        payrollReconciliation: getSubModuleData('payrollReconciliation', 'Reconciliation'),

        // sequiCRM
        sequiCRM: getSubModuleData('sequiCRM', 'Sequi-CRM'),

        // Reports
        company: getSubModuleData('company', 'Company'),
        sales: getSubModuleData('sales', 'Sales'),
        cost: getSubModuleData('cost', 'Cost'),
        payroll: getSubModuleData('payroll', 'Payroll'),
        clawback: getSubModuleData('clawback', 'Clawback'),
        pendingInstall: getSubModuleData('pendingInstall', 'Pending Install'),
        reconciliation: getSubModuleData('reconciliation', 'Reconciliation'),

        // Permissions
        group: getSubModuleData('group', 'Group'),
        policies: getSubModuleData('policies', 'Policies'),

        // Marketing Deal
        marketingDeal: getSubModuleData('marketingDeal', 'Marketing Deal'),

        // Alert Center
        alertCenterAlerts: getSubModuleData('alerts', 'Alerts'),

        // Support
        support: getSubModuleData('support', 'Support'),
    },
    // standard
    standard: {
        // Dashboard
        dashboard: getSubModuleData('dashboard', 'Dashboard'),

        // My Sales
        mySales: getSubModuleData('mySales', 'My Sales'),
        myOverrides: getSubModuleData('myOverrides', 'My Overrides'),
        payStubs: getSubModuleData('payStubs', 'Pay Stubs'),
        pendingPay: getSubModuleData('pendingPay', 'Pending Pay'),

        // Hiring
        hiringProgress: getSubModuleData('hiringProgress', 'Hiring Progress'),
        leads: getSubModuleData('leads', 'Leads'),
        onboardingEmployees: getSubModuleData('onboardingEmployees', 'Onboarding Employees'),

        // Referrals
        referrals: getSubModuleData('referrals', 'Referrals'),

        // Pipeline
        pipeline: getSubModuleData('pipeline', 'Pipeline', ALL_PERMISSIONS.standard.pipeline),

        // Calendar
        calendar: getSubModuleData('calendar', 'Calendar'),

        // SequiDocs
        sequiDocs: getSubModuleData('sequiDocs', 'SequifiDocs'),
        templates: getSubModuleData('templates', 'Templates'),
        documents: getSubModuleData('documents', 'Documents'),

        // Management
        employee: getSubModuleData('employee', 'Employee'),
        teams: getSubModuleData('teams', 'Team'),

        // Community
        community: getSubModuleData('community', 'Community', ALL_PERMISSIONS.standard.community),

        // Projections
        projections: getSubModuleData(
            'projections',
            'Projections',
            ALL_PERMISSIONS.standard.projections
        ),

        // Reports
        office: getSubModuleData('office', 'Office'),
        sales: getSubModuleData('sales', 'Sales'),
        pastPayStubs: getSubModuleData('pastPayStubs', 'Past Pay Stubs'),
        reconciliation: getSubModuleData('reconciliation', 'Reconciliation'),

        // Training
        training: getSubModuleData('training', 'Training', ALL_PERMISSIONS.standard.training),

        // Request And Approvals
        myRequests: getSubModuleData('myRequests', 'Request'),
        approvals: getSubModuleData('approvals', 'Approvals'),

        // Support
        support: getSubModuleData('support', 'Support'),
    },
    profile: {
        personalInfo: getSubModuleData('personalInfo', 'Personal Info'),
        employmentPackage: getSubModuleData('employmentPackage', 'Employment Package'),
        taxInfo: getSubModuleData('taxInfo', 'Tax Info'),
        banking: getSubModuleData('banking', 'Banking'),
        documents: getSubModuleData('documents', 'Documents'),
        network: getSubModuleData('network', 'Network'),
        auditLog: getSubModuleData('auditLog', 'Audit Log'),
    },
}

export const PERMISSION_TYPE = {
    view: 'view',
    add: 'add',
    edit: 'edit',
    delete: 'delete',
    hireNow: 'hireNow',
    hireDirectly: 'hireDirectly',
    transferEmployee: 'transferEmployee',
    dismissUsers: 'dismissUsers',
    sendTemplate: 'sendTemplate',
}

const getPermissionGroupData = (role, module, subModule, permission) => {
    return {
        ...role,
        ...module,
        ...subModule,
        ...permission,
    }
}
export const PERMISSIONS_GROUP = {
    administrator: {
        dashboard: {
            dashboard: getPermissionGroupData(
                ROLES.administrator,
                MODULES.administrator.dashboard,
                SUB_MODULES.administrator.dashboard,
                ALL_PERMISSIONS.administrator.dashboard
            ),
        },
        setting: {
            setup: getPermissionGroupData(
                ROLES.administrator,
                MODULES.administrator.setting,
                SUB_MODULES.administrator.setup,
                ALL_PERMISSIONS.administrator.setup
            ),
            locations: getPermissionGroupData(
                ROLES.administrator,
                MODULES.administrator.setting,
                SUB_MODULES.administrator.locations,
                ALL_PERMISSIONS.administrator.locations
            ),
            costTracking: getPermissionGroupData(
                ROLES.administrator,
                MODULES.administrator.setting,
                SUB_MODULES.administrator.costTracking,
                ALL_PERMISSIONS.administrator.costTracking
            ),
            departments: getPermissionGroupData(
                ROLES.administrator,
                MODULES.administrator.setting,
                SUB_MODULES.administrator.departments,
                ALL_PERMISSIONS.administrator.departments
            ),
            positions: getPermissionGroupData(
                ROLES.administrator,
                MODULES.administrator.setting,
                SUB_MODULES.administrator.positions,
                ALL_PERMISSIONS.administrator.positions
            ),
            alerts: getPermissionGroupData(
                ROLES.administrator,
                MODULES.administrator.setting,
                SUB_MODULES.administrator.alerts,
                ALL_PERMISSIONS.administrator.positions
            ),
            bankAccounts: getPermissionGroupData(
                ROLES.administrator,
                MODULES.administrator.setting,
                SUB_MODULES.administrator.bankAccounts,
                ALL_PERMISSIONS.administrator.bankAccounts
            ),
            paperWork: getPermissionGroupData(
                ROLES.administrator,
                MODULES.administrator.setting,
                SUB_MODULES.administrator.paperWork,
                ALL_PERMISSIONS.administrator.paperWork
            ),
        },
        integrations: {
            integrations: getPermissionGroupData(
                ROLES.administrator,
                MODULES.administrator.integrations,
                SUB_MODULES.administrator.integrations,
                ALL_PERMISSIONS.administrator.integrations
            ),
        },
        sequiDocs: {
            templates: getPermissionGroupData(
                ROLES.administrator,
                MODULES.administrator.sequiDocs,
                SUB_MODULES.administrator.templates,
                ALL_PERMISSIONS.administrator.templates
            ),
            documents: getPermissionGroupData(
                ROLES.administrator,
                MODULES.administrator.sequiDocs,
                SUB_MODULES.administrator.documents,
                ALL_PERMISSIONS.administrator.documents
            ),
        },
        payroll: {
            runPayrollAndApprovals: getPermissionGroupData(
                ROLES.administrator,
                MODULES.administrator.payroll,
                SUB_MODULES.administrator.runPayrollAndApprovals,
                ALL_PERMISSIONS.administrator.runPayrollAndApprovals
            ),
            oneTimePayment: getPermissionGroupData(
                ROLES.administrator,
                MODULES.administrator.payroll,
                SUB_MODULES.administrator.oneTimePayment,
                ALL_PERMISSIONS.administrator.oneTimePayment
            ),
            paymentRequest: getPermissionGroupData(
                ROLES.administrator,
                MODULES.administrator.payroll,
                SUB_MODULES.administrator.paymentRequest,
                ALL_PERMISSIONS.administrator.paymentRequest
            ),
            payrollReconciliation: getPermissionGroupData(
                ROLES.administrator,
                MODULES.administrator.payroll,
                SUB_MODULES.administrator.payrollReconciliation,
                ALL_PERMISSIONS.administrator.payrollReconciliation
            ),
        },
        sClearance: {
            sClearance: getPermissionGroupData(
                ROLES.administrator,
                MODULES.administrator.sClearance,
                SUB_MODULES.administrator.sClearance,
                ALL_PERMISSIONS.administrator.sClearance
            ),
        },
        sequiCRM: {
            sequiCRM: getPermissionGroupData(
                ROLES.administrator,
                MODULES.administrator.sequiCRM,
                SUB_MODULES.administrator.sequiCRM,
                ALL_PERMISSIONS.administrator.sequiCRM
            ),
        },
        reports: {
            company: getPermissionGroupData(
                ROLES.administrator,
                MODULES.administrator.reports,
                SUB_MODULES.administrator.company,
                ALL_PERMISSIONS.administrator.company
            ),
            payroll: getPermissionGroupData(
                ROLES.administrator,
                MODULES.administrator.reports,
                SUB_MODULES.administrator.payroll,
                ALL_PERMISSIONS.administrator.payroll
            ),
            sales: getPermissionGroupData(
                ROLES.administrator,
                MODULES.administrator.reports,
                SUB_MODULES.administrator.sales,
                ALL_PERMISSIONS.administrator.sales
            ),
            cost: getPermissionGroupData(
                ROLES.administrator,
                MODULES.administrator.reports,
                SUB_MODULES.administrator.cost,
                ALL_PERMISSIONS.administrator.cost
            ),

            clawback: getPermissionGroupData(
                ROLES.administrator,
                MODULES.administrator.reports,
                SUB_MODULES.administrator.clawback,
                ALL_PERMISSIONS.administrator.clawback
            ),
            pendingInstall: getPermissionGroupData(
                ROLES.administrator,
                MODULES.administrator.reports,
                SUB_MODULES.administrator.pendingInstall,
                ALL_PERMISSIONS.administrator.pendingInstall
            ),
            reconciliation: getPermissionGroupData(
                ROLES.administrator,
                MODULES.administrator.reports,
                SUB_MODULES.administrator.reconciliation,
                ALL_PERMISSIONS.administrator.reconciliation
            ),
        },
        permissions: {
            group: getPermissionGroupData(
                ROLES.administrator,
                MODULES.administrator.permissions,
                SUB_MODULES.administrator.group,
                ALL_PERMISSIONS.administrator.group
            ),
            policies: getPermissionGroupData(
                ROLES.administrator,
                MODULES.administrator.permissions,
                SUB_MODULES.administrator.policies,
                ALL_PERMISSIONS.administrator.policies
            ),
        },
        marketingDeal: {
            marketingDeal: getPermissionGroupData(
                ROLES.administrator,
                MODULES.administrator.setting,
                SUB_MODULES.administrator.marketingDeal,
                ALL_PERMISSIONS.administrator.marketingDeal
            ),
        },
        importExport: {},
        alertCenter: {
            alerts: getPermissionGroupData(
                ROLES.administrator,
                MODULES.administrator.alertCenter,
                SUB_MODULES.administrator.alertCenterAlerts,
                ALL_PERMISSIONS.administrator.alertCenter
            ),
        },
        support: {
            support: getPermissionGroupData(
                ROLES.administrator,
                MODULES.administrator.support,
                SUB_MODULES.administrator.support,
                ALL_PERMISSIONS.administrator.support
            ),
        },
    },
    standard: {
        dashboard: {
            dashboard: getPermissionGroupData(
                ROLES.standard,
                MODULES.standard.dashboard,
                SUB_MODULES.standard.dashboard,
                ALL_PERMISSIONS.standard.dashboard
            ),
        },
        mySales: {
            mySales: getPermissionGroupData(
                ROLES.standard,
                MODULES.standard.mySales,
                SUB_MODULES.standard.mySales,
                ALL_PERMISSIONS.standard.mySales
            ),
            myOverrides: getPermissionGroupData(
                ROLES.standard,
                MODULES.standard.mySales,
                SUB_MODULES.standard.myOverrides,
                ALL_PERMISSIONS.standard.myOverrides
            ),
            payStubs: getPermissionGroupData(
                ROLES.standard,
                MODULES.standard.mySales,
                SUB_MODULES.standard.payStubs,
                ALL_PERMISSIONS.standard.payStubs
            ),
            pendingPay: getPermissionGroupData(
                ROLES.standard,
                MODULES.standard.mySales,
                SUB_MODULES.standard.pendingPay,
                ALL_PERMISSIONS.standard.pendingPay
            ),
        },
        hiring: {
            hiringProgress: getPermissionGroupData(
                ROLES.standard,
                MODULES.standard.hiring,
                SUB_MODULES.standard.hiringProgress,
                ALL_PERMISSIONS.standard.hiringProgress
            ),
            leads: getPermissionGroupData(
                ROLES.standard,
                MODULES.standard.hiring,
                SUB_MODULES.standard.leads,
                ALL_PERMISSIONS.standard.leads
            ),
            onboardingEmployees: getPermissionGroupData(
                ROLES.standard,
                MODULES.standard.hiring,
                SUB_MODULES.standard.onboardingEmployees,
                ALL_PERMISSIONS.standard.onboardingEmployees
            ),
            pipeline: getPermissionGroupData(
                ROLES.standard,
                MODULES.standard.hiring,
                SUB_MODULES.standard.pipeline,
                ALL_PERMISSIONS.standard.pipeline
            ),
        },
        calendar: {
            calendar: getPermissionGroupData(
                ROLES.standard,
                MODULES.standard.calendar,
                SUB_MODULES.standard.calendar,
                ALL_PERMISSIONS.standard.Calendar
            ),
        },
        sequiDocs: {
            templates: getPermissionGroupData(
                ROLES.standard,
                MODULES.standard.sequiDocs,
                SUB_MODULES.standard.templates,
                ALL_PERMISSIONS.standard.standardSequiDocsTemplates
            ),
            documents: getPermissionGroupData(
                ROLES.standard,
                MODULES.standard.sequiDocs,
                SUB_MODULES.standard.documents,
                ALL_PERMISSIONS.standard.standardSequiDocsDocuments
            ),
        },

        management: {
            employee: getPermissionGroupData(
                ROLES.standard,
                MODULES.standard.management,
                SUB_MODULES.standard.employee,
                ALL_PERMISSIONS.standard.employee
            ),
            teams: getPermissionGroupData(
                ROLES.standard,
                MODULES.standard.management,
                SUB_MODULES.standard.teams,
                ALL_PERMISSIONS.standard.teams
            ),
            sequiDocs: getPermissionGroupData(
                ROLES.standard,
                MODULES.standard.management,
                SUB_MODULES.standard.sequiDocs,
                ALL_PERMISSIONS.standard.sequiDocs
            ),
        },

        referrals: {
            referrals: getPermissionGroupData(
                ROLES.standard,
                MODULES.standard.referrals,
                SUB_MODULES.standard.referrals,
                ALL_PERMISSIONS.standard.referrals
            ),
        },

        reports: {
            office: getPermissionGroupData(
                ROLES.standard,
                MODULES.standard.reports,
                SUB_MODULES.standard.office,
                ALL_PERMISSIONS.standard.office
            ),
            sales: getPermissionGroupData(
                ROLES.standard,
                MODULES.standard.reports,
                SUB_MODULES.standard.sales,
                ALL_PERMISSIONS.standard.standardSales
            ),
            pastPayStubs: getPermissionGroupData(
                ROLES.standard,
                MODULES.standard.reports,
                SUB_MODULES.standard.pastPayStubs,
                ALL_PERMISSIONS.standard.pastPayStubs
            ),
            reconciliation: getPermissionGroupData(
                ROLES.standard,
                MODULES.standard.reports,
                SUB_MODULES.standard.reconciliation,
                ALL_PERMISSIONS.standard.reconciliation
            ),
        },
        requestsAndApprovals: {
            myRequests: getPermissionGroupData(
                ROLES.standard,
                MODULES.standard.requestsAndApprovals,
                SUB_MODULES.standard.myRequests,
                ALL_PERMISSIONS.standard.request
            ),
            approvals: getPermissionGroupData(
                ROLES.standard,
                MODULES.standard.requestsAndApprovals,
                SUB_MODULES.standard.approvals,
                ALL_PERMISSIONS.standard.approvals
            ),
        },
        support: {
            support: getPermissionGroupData(
                ROLES.standard,
                MODULES.standard.support,
                SUB_MODULES.standard.support,
                ALL_PERMISSIONS.standard.support
            ),
        },
    },
    profile: {
        profile: {
            personalInfo: getPermissionGroupData(
                ROLES.profile,
                MODULES.profile.profile,
                SUB_MODULES.profile.personalInfo,
                ALL_PERMISSIONS.profile.personalInfo
            ),
            employmentPackage: getPermissionGroupData(
                ROLES.profile,
                MODULES.profile.profile,
                SUB_MODULES.profile.employmentPackage,
                ALL_PERMISSIONS.profile.employmentPackage
            ),
            taxInfo: getPermissionGroupData(
                ROLES.profile,
                MODULES.profile.profile,
                SUB_MODULES.profile.taxInfo,
                ALL_PERMISSIONS.profile.taxInfo
            ),
            banking: getPermissionGroupData(
                ROLES.profile,
                MODULES.profile.profile,
                SUB_MODULES.profile.banking,
                ALL_PERMISSIONS.profile.banking
            ),
            documents: getPermissionGroupData(
                ROLES.profile,
                MODULES.profile.profile,
                SUB_MODULES.profile.documents,
                ALL_PERMISSIONS.profile.documents
            ),
            network: getPermissionGroupData(
                ROLES.profile,
                MODULES.profile.profile,
                SUB_MODULES.profile.network,
                ALL_PERMISSIONS.profile.network
            ),
            auditLog: getPermissionGroupData(
                ROLES.profile,
                MODULES.profile.profile,
                SUB_MODULES.profile.auditLog,
                ALL_PERMISSIONS.profile.auditLog
            ),
        },
    },
}
