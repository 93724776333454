import {useMemo} from 'react'
import useQueryString from '../../hooks/useQueryString'
import CustomButton from '../../customComponents/customButtton/CustomButton'
import {useLocation, useNavigate} from 'react-router-dom'
import sequifiIcon from '../../../src/assets/images/sequifiIcon.svg'
import {STRIPE_PAYMENT_INTENT_STATUS_ICON, STRIPE_PAYMENT_STATUS} from '../../constants/constants'
import Show from '../../customComponents/show/Show'
const FieldRow = ({title = '-', value = ''}) => {
    return value ? (
        <div className='w-100 my-1  row'>
            <div className='col-sm-4 text-start  w-100'>{title}: </div>
            <div className='col-sm-8 text-start w-100 flex-wrap' style={{fontWeight: 600}}>
                {value ?? '-'}
            </div>
        </div>
    ) : null
}

function Completion(props) {
    const location = useLocation()
    const [searchParams] = useQueryString()
    const navigate = useNavigate()

    const paymentStatus = useMemo(
        (paymentStatus) => {
            return location?.state?.paymentMethod
                ? 'Payment Method Added'
                : STRIPE_PAYMENT_STATUS?.find((item) =>
                      item?.stripe_status_code?.includes(searchParams?.status)
                  )?.stripe_status
        },
        [searchParams?.status]
    )
    return (
        <div className='w-100 mt-10 d-flex align-self-center justify-content-center align-items-center'>
            <div className='card bg-white h-auto shadow  w-sm-35 p-5 d-flex align-items-center justify-content-center'>
                <img
                    src={sequifiIcon}
                    className='app-sidebar-logo-default'
                    style={{width: '150px', height: '150px'}}
                />
                <div className='d-flex align-items-center'>
                    <i
                        className={`${
                            STRIPE_PAYMENT_INTENT_STATUS_ICON[searchParams?.status]
                        } me-2`}
                        style={{fontSize: 20}}
                    />
                    <span style={{fontSize: '20px'}}>{paymentStatus}</span>
                </div>

                <Show>
                    <Show.When isVisible={!location?.state?.paymentMethod}>
                        <>
                            <FieldRow
                                title={'Message'}
                                value={location?.state?.last_payment_message}
                            />
                            <FieldRow title={'Billing ID'} value={location?.state?.billing_id} />
                            <FieldRow
                                title={'Transaction ID'}
                                value={location?.state?.payment_intent}
                            />
                        </>
                    </Show.When>
                </Show>

                <div className='mt-10 p-2 gap-2'>
                    <CustomButton
                        buttonLabel={
                            location?.state?.paymentMethod
                                ? 'Go to Payment Method'
                                : 'Go to Billing History'
                        }
                        onClick={() => {
                            navigate(
                                location?.state?.paymentMethod
                                    ? '/settings/billings/payment-method'
                                    : '/settings/billings/billing-history'
                            )
                        }}
                    />
                    {/* <CustomButton
                        buttonType={BUTTON_TYPE.primaryBorder}
                        style={{marginLeft: '20px'}}
                        buttonLabel={'Download Receipt'}
                        onClick={() => {
                            CustomToast.success('Receipt Downloaded(Under development)')
                        }}
                    /> */}
                </div>
            </div>
        </div>
    )
}

export default Completion
