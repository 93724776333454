import {useMemo} from 'react'
import NoServerFound from './modules/admin/errors/components/NoServerFound'
import Show from '../customComponents/show/Show'
import useIsServerCheck from '../hooks/useIsServerCheck'
import useIsAccountBillingDue from '../hooks/useIsAccountBillingDue'

const PreLoadCheck = ({children}) => {
    const {serverCheckingLoading, isValidServer} = useIsServerCheck()
    const {isBillingOverdueLoading} = useIsAccountBillingDue(true)

    const loading = useMemo(() => {
        return serverCheckingLoading || isBillingOverdueLoading
    }, [isBillingOverdueLoading, serverCheckingLoading])

    return (
        <Show>
            <Show.When isVisible={loading}>
                <div
                    style={{height: '100vh', width: '100vw'}}
                    className='d-flex align-items-center justify-content-center flex-column splash-screen'
                >
                    <img src='/media/logos/sequifiLogoSVG.svg' alt='Sequifi logo' />
                    <div
                        dangerouslySetInnerHTML={{
                            __html: `<div>
                                        <dotlottie-player src="https://lottie.host/1e67f7a3-aeeb-4caa-a947-64f06ab1fb4c/2EuKxO0oeX.json"
                                            background="transparent" speed="1" style="width: 300px; height: 100px;" loop autoplay></dotlottie-player>
                                </div>`,
                        }}
                    />
                </div>
            </Show.When>
            <Show.When isVisible={!isValidServer}>
                <NoServerFound />
            </Show.When>
            <Show.Else>{children}</Show.Else>
        </Show>
    )
}

export default PreLoadCheck
