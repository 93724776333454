import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import useSequifiConfig from '../../sequiConfig/useSequifiConfig'

const CustomToastContainer = () => {
    const {basedOnHost} = useSequifiConfig()
    return (
        <ToastContainer
            position='top-right'
            autoClose={basedOnHost?.toastDuration}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover
        />
    )
}
export default CustomToastContainer
