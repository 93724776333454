import {useEffect, useState} from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import {fontsFamily} from '../../assets/fonts/fonts'

const CustomTabs = ({tabs, callOuterFunction, customVal}) => {
    const [value, setValue] = useState(0)


    useEffect(() => {
        setValue(customVal ?? 0)
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue)
        if (callOuterFunction) callOuterFunction(newValue)
    }

    return (
        <div style={{width: '100%'}}>
            <Tabs
                value={value}
                onChange={handleChange}
                className='border  border-bottom-2 border-cmGrey300 border-top-0 border-left-0 border-right-0'
                TabIndicatorProps={{
                    style: {
                        backgroundColor: '#6078ec',
                    },
                }}
            >
                {tabs?.map((tab, index) => (
                    <Tab
                        key={index}
                        label={tab?.label}
                        style={{
                            fontSize: 14,
                            fontWeight: '700',
                            fontFamily: fontsFamily.manrope,
                            textTransform: 'none',
                        }}
                        className={value === index ? 'text-cmBlue-Crayola' : 'text-cmGrey600'}
                    />
                ))}
            </Tabs>
            {tabs?.map((tab, index) => (
                <div key={index} hidden={value !== index}>
                    {value === index && <div style={{padding: '16px'}}>{tab?.content}</div>}
                </div>
            ))}
        </div>
    )
}

export default CustomTabs
