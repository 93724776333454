import {useCallback, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {getOverdueBillingDataSelector} from '../redux/selectors/SettingsSelectors'
import {useDispatch} from 'react-redux'
import {getOverdueBillingDataAction} from '../redux/actions/SettingActions'
import useSequifiConfig from '../sequiConfig/useSequifiConfig'

const useIsAccountBillingDue = (callDefaultAPI = false) => {
    const dispatch = useDispatch()
    const {basedOnHost} = useSequifiConfig()
    const [isBillingOverdueLoading, setIsBillingOverdueLoading] = useState(false)
    const overDueBillingData = useSelector(getOverdueBillingDataSelector)

    const getIsAccountBillingDueStatus = useCallback(() => {
        if (basedOnHost.billingOverdue) {
            setIsBillingOverdueLoading(true)
            dispatch(getOverdueBillingDataAction()).finally(() => {
                setIsBillingOverdueLoading(false)
            })
        }
    }, [basedOnHost.billingOverdue, dispatch])

    useEffect(() => {
        if (callDefaultAPI) getIsAccountBillingDueStatus()
    }, [])

    return {
        isBillingOverdueLoading,
        getIsAccountBillingDueStatus,
        billingOverdueModalCloseDate: overDueBillingData?.closeDate,
        overDueBillingData,
        isBillingOverdue: overDueBillingData?.isBillingOverdue,
        overdueDays: overDueBillingData?.overdueDays,
        lastDueDate: overDueBillingData?.lastDueDate,
        paidStatus: overDueBillingData?.paidStatus,
        modalCloseDate: overDueBillingData?.closeDate,
    }
}

export default useIsAccountBillingDue
