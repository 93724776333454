import {FC, useEffect, useMemo, useState} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {checkIsActive, KTSVG, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'
import {Badge} from 'primereact/badge'

type Props = {
    to: string
    title: string
    icon?: string
    fontIcon?: string
    hasBullet?: boolean
    badgeCount?: number
    onClick?: () => {}
    followRouteIndex?: number
    isActiveRoute?: boolean
    followActiveRouteInnerLogic?: boolean
}

const SidebarMenuItem: FC<Props & WithChildren> = ({
    children,
    to,
    title,
    icon,
    fontIcon,
    followActiveRouteInnerLogic = true,
    isActiveRoute = false,
    hasBullet = false,
    badgeCount = 0,
    onClick = () => {},
}) => {
    const {pathname} = useLocation()
    const isActive = checkIsActive(pathname, to)
    const {config} = useLayout()
    const {app} = config
    const [flag, setFlag] = useState(false)

    useEffect(() => {
        setFlag((val) => !val)
    }, [pathname])

    const parentRoute = pathname?.split('/')?.[1]

    const isActivatedRoute = useMemo(() => {
        return (followActiveRouteInnerLogic && to.includes(parentRoute)) || isActiveRoute
    }, [followActiveRouteInnerLogic, isActiveRoute, parentRoute, to])

    return (
        <div
            // style={{fontSize: '16px', fontFamily: 'Manrope', color: '#757575', overflow: 'auto'}}
            className='menu-item mt-10px overflow-auto pe-0'
        >
            <Link
                style={{
                    borderRadius: 10,
                    // transitionDuration: 'all 500ms',
                    transitionTimingFunction: 'ease-in',
                }}
                className={clsx(
                    `menu-link without-sub ${
                        isActivatedRoute
                            ? 'bg-sqPrimary600 bg-opacity-10'
                            : 'bg-hover-sqGray300 bg-opacity-5'
                    }`,
                    {active: isActive}
                )}
                to={to}
                onClick={onClick}
            >
                {hasBullet && (
                    <span className='menu-bullet'>
                        <span className='bullet bullet-dot text-black'></span>
                    </span>
                )}
                {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
                    <span className='menu-icon text-black '>
                        {' '}
                        <KTSVG
                            path={icon}
                            className={
                                isActivatedRoute
                                    ? 'text-cmBlue-Crayola  svg-icon-2'
                                    : 'text-cmGrey700 svg-icon-2 '
                            }
                        />
                    </span>
                )}
                {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
                    <i className={clsx('bi fs-3', fontIcon)}></i>
                )}
                <span
                    className={`text-md-semibold text-nowrap
                       ${
                           isActivatedRoute
                               ? 'text-sqPrimary600  menu-title '
                               : 'text-sqGray600 menu-title'
                       }
                   `}
                >
                    {title}
                </span>
                {badgeCount > 0 ? <Badge value={badgeCount} severity='danger'></Badge> : null}
            </Link>
            {children}
        </div>
    )
}

export {SidebarMenuItem}
