import {KTSVG} from '../../../../../_metronic/helpers'

const NoServerFound = () => {
    return (
        <div className='w-100 h-100 d-flex align-items-center justify-content-center flex-column'>
            <div className='mb-5'>
                <KTSVG
                    path='/media/icons/duotune/art/Error-404.svg'
                    svgStyle={{width: '100%', height: 'auto'}}
                    svgClassName='w-500px '
                />
            </div>

            <div className='text-center'>
                <div
                    className='text-cmGrey800 mb-20'
                    style={{fontSize: '18px', fontWeight: 800, lineHeight: '24.59px'}}
                >
                    The server you requested does not exist!
                </div>
            </div>

            {/* end::Link */}
        </div>
    )
}

export default NoServerFound
