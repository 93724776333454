import axios from 'axios'
import {BASE_URL} from '../constants/constants'

const withoutAuthAxios = axios.create({
    baseURL: BASE_URL,
    timeout: 10 * 60 * 1000, // 10 minutes
    headers: {
        'Content-Type': 'application/json',
        responseType: 'json',
    },
})

withoutAuthAxios.interceptors.request.use((config) => {
    return config
})

withoutAuthAxios.interceptors.response.use(
    (res) => {
        return Promise.resolve(res?.data)
    },
    (error) => {
        if (axios?.isCancel(error)) return Promise.reject({...error, isCancel: true})
        else return Promise.reject(error?.response)
    }
)

export default withoutAuthAxios
