import {configureStore} from '@reduxjs/toolkit'
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'
import rootReducer from './reducers/rootReducer'
import ReduxPersistConfig from './persist/ReduxPersistConfig'
import {composeWithDevToolsDevelopmentOnly} from '@redux-devtools/extension'

const persistedReducer = persistReducer(ReduxPersistConfig, rootReducer)

const composeEnhancers = composeWithDevToolsDevelopmentOnly({
    // Specify here name, actionsDenylist, actionsCreators and other options
})

const bindMiddleware = (getDefaultMiddleware) => {
    const middleware = [
        ...getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
            warnTimeout: 100,
        }),
    ]

    // middleware.push(logger)
    return middleware
}

const store = configureStore({
    reducer: persistedReducer,
    devTools: false,
    enhancers: [composeEnhancers()],
    middleware: (getDefaultMiddleware) => bindMiddleware(getDefaultMiddleware),
})

const persistor = persistStore(store)

export {store, persistor}
