import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {
    deletePreHiringDocumentInSigningService,
    getDownloadPathForUploadedDocumentService,
    newSequiDocTemplateDetailByIdService,
    uploadPreHiringDocumentInSigningService,
} from '../../../../../services/Services'
import {v4 as uuidv4} from 'uuid'
import {TABLE_BORDER} from '../../../../../helpers/CommonHelpers'
import AccessRights from '../../../../../accessRights/AccessRights'
import useCustomAccessRights from '../../../../../accessRights/useCustomAccessRights'
import {getValidDate} from '../../../../../constants/constants'
import CustomButton, {
    BUTTON_SIZE,
    BUTTON_TYPE,
} from '../../../../../customComponents/customButtton/CustomButton'
import {useLocation, useNavigate} from 'react-router-dom'
import CustomEllipsis from '../../../../../customComponents/customEllipsis/CustomEllipsis'
import axios from 'axios'
import CustomToast from '../../../../../customComponents/customToast/CustomToast'
import DocumentPreviewModal from '../../../standard_manager/management/particularEmployee/compoents/documents/DocumentPreviewModal'

const EachDocTypeRow = ({employeeData, item, userId, index, getDocument}) => {
    const navigate = useNavigate()
    const profileFilePickerRef = useRef(null)
    const [uploadedDocument, setUploadedDocument] = useState(item?.upload_document_file)
    const [unUploadedDocument, setUnUploadedDocument] = useState([])
    const {employeeProfileAccess} = useCustomAccessRights({employeeData})
    const location = useLocation()
    const [reviewLoading, setReviewLoading] = useState(false)

    useEffect(() => {
        setUploadedDocument(item?.upload_document_file)
    }, [item?.upload_document_file])

    const deleteDocument = useCallback(
        (docIndex) => {
            const tempData = [...uploadedDocument]
            const itemToBeDelete = uploadedDocument?.[docIndex]
            const finalData = tempData?.filter((tempItem, tempIndex) => tempIndex != docIndex)
            setUploadedDocument(finalData)
            deletePreHiringDocumentInSigningService(itemToBeDelete?.id)
                .then(() => {
                    getDocument()
                })
                .catch(() => {})
        },
        [getDocument, uploadedDocument]
    )

    const onUploadNewDocument = useCallback(
        (e) => {
            if (e?.target?.files?.length > 0) {
                const isUploadingState = unUploadedDocument?.some(
                    (docItem) => docItem?.status == 'uploading'
                )
                const filesData = [...e?.target?.files]?.map((fileItem, fileIndex) => ({
                    id: uuidv4(),
                    status:
                        isUploadingState || (!isUploadingState && fileIndex > 0)
                            ? 'pending'
                            : 'uploading',
                    document: fileItem?.name,
                    document_file_path: fileItem?.name,
                    progress: 0,
                    file: fileItem,
                }))
                setUnUploadedDocument((val) => [...val, ...filesData])
            }
        },
        [unUploadedDocument]
    )

    const currentUploadDocument = useMemo(() => {
        return unUploadedDocument?.length > 0
            ? unUploadedDocument.find((docItem) => docItem?.status == 'uploading')
            : null
    }, [unUploadedDocument])

    useEffect(() => {
        if (currentUploadDocument) {
            const docIndex = unUploadedDocument?.findIndex(
                (docItem) => docItem?.id == currentUploadDocument?.id
            )
            let formData = new FormData()
            formData.append('document_id', item?.id)

            formData.append('document_file[]', currentUploadDocument?.file)
            uploadPreHiringDocumentInSigningService(formData, (uploadProgress) => {
                const data = [...unUploadedDocument]
                data[docIndex].progress = uploadProgress?.completed
                setUnUploadedDocument(data)
            })
                .then((res) => {
                    const data = [...unUploadedDocument]
                    const uploadedData = [...uploadedDocument]
                    data[docIndex].status = 'uploaded'
                    if (docIndex < unUploadedDocument?.length - 1) {
                        data[docIndex + 1].status = 'uploading'
                    }
                    uploadedData.push({
                        ...currentUploadDocument,
                        ...res,
                    })
                    setUploadedDocument(uploadedData)
                    setUnUploadedDocument(data)
                })
                .catch(() => {})
        } else {
            getDocument()
        }
    }, [currentUploadDocument])

    const downloadDocument = (item, extension = 'pdf') => {
        const body = {
            document_file_path: item,
        }
        getDownloadPathForUploadedDocumentService(body)
            .then((res) => {
                let file = item?.replace(/\/([^/]+)\.\w+$/, '$1')
                const fileName = `${file}.${extension}`
                const filePath = res?.data
                axios
                    .get(filePath, {
                        responseType: 'blob',
                        headers: {
                            'Content-Type': 'application/json',
                            Accept: 'application/pdf',
                        },
                    })
                    .then((response) => {
                        let url = window.URL.createObjectURL(response?.data)
                        let a = document.createElement('a')
                        a.href = url
                        a.download = fileName
                        a.click()
                        a.remove()
                    })
                    .catch(() => CustomToast.error('failed to download'))
            })
            .catch(() => {})
    }
    const getUserAndDocumentStatus = useCallback(
        (id) =>
            new Promise((resolve) => {
                newSequiDocTemplateDetailByIdService(id)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(() => {
                        resolve([])
                    })
            }),
        []
    )

    const redirectToReviewPage = () => {
        setReviewLoading(true)
        getUserAndDocumentStatus(item.template_id)
            .then((res) => {
                let idPdf = res?.data?.is_pdf ? false : true
                navigate('/document-signing/sign', {
                    state: {
                        docId: item?.signature_request_document_id,
                        envelopId: item?.envelope_id,
                        isOfferLetter: idPdf,
                        envelopData: {envelope_id: item?.envelope_id, signer_id: item?.signer_id},
                        isDirect: true,
                        isAuthenticated: 1,
                    },
                })
            })
            .catch(() => {})
            .finally(() => setReviewLoading(false))
        return
    }
    return (
        <tr
            key={index}
            style={{
                fontSize: '14px',
                fontFamily: 'Manrope',
                fontWeight: '600',
            }}
            className={`text-cmGrey700 stripRow ${TABLE_BORDER}`}
        >
            <td width={'175px'} className={`p-5`} style={{fontWeight: '700'}}>
                <div className={`${item?.is_sign_required_for_hire ? 'required' : ''}`}>
                    {item?.description}
                </div>
                {item?.field_link ? (
                    <a
                        href={item?.field_link}
                        target='_blank'
                        rel='noreferrer'
                        className=' text-cmBlue-Crayola text-decoration-underline'
                    >
                        {item?.field_link}
                    </a>
                ) : null}
            </td>

            <td className='p-5' width={'150px'} style={{fontWeight: '700'}}>
                {/* {uploadedDocument?.length > 0
                    ? getValidDate(uploadedDocument?.[0]?.updated_at, 'MM/DD/YYYY')
                        ? getValidDate(uploadedDocument?.[0]?.updated_at, 'MM/DD/YYYY')
                        : getValidDate(item?.updated_at, 'MM/DD/YYYY')
                        ? getValidDate(item?.updated_at, 'MM/DD/YYYY')
                        : '-'
                    : null} */}
                {getValidDate(item?.document_response_date, 'MM/DD/YYYY')}
            </td>
            <td className='p-5' width={'300px'}>
                {item?.upload_document_type_id ? (
                    <>
                        {uploadedDocument?.length > 0
                            ? uploadedDocument?.map((doc, docIndex) => (
                                  <EachDocRow
                                      employeeData={employeeData}
                                      doc={doc}
                                      docIndex={docIndex}
                                      deleteDocument={() => deleteDocument(docIndex)}
                                      key={doc?.document}
                                      downloadDoc={(file, extension) =>
                                          downloadDocument(file, extension)
                                      }
                                  />
                              ))
                            : null}
                        {unUploadedDocument?.length > 0
                            ? unUploadedDocument
                                  ?.filter((docItem) => docItem?.status != 'uploaded')
                                  .map((doc, docIndex) => (
                                      <EachDocRow type={'unuploaded'} doc={doc} />
                                  ))
                            : null}
                    </>
                ) : (
                    <>
                        {item?.signed_document ? (
                            <CustomEllipsis
                                style={{
                                    fontFamily: 'Manrope',
                                    color: '#757575',
                                    whiteSpace: 'nowrap',
                                    fontSize: '14px',
                                }}
                                text={item?.signed_document}
                                width='250px'
                            >
                                <span
                                    className='bg-cmBlue-Crayola rounded-pill text-cmBlue-Crayola bg-opacity-10 p-2 cursor-pointer'
                                    onClick={() => downloadDocument(item?.signed_document)}
                                >
                                    <i className='fa-solid fa-paperclip text-cmBlue-Crayola pe-2'></i>
                                    <span>{item?.signed_document}-----</span>
                                </span>
                            </CustomEllipsis>
                        ) : null}
                    </>
                )}
            </td>
            {!item?.is_deleted ? (
                <AccessRights customCondition={employeeProfileAccess.addDocumentAccess}>
                    <td className='p-5 d-flex  gap-4 flex-center mx-auto'>
                        {item?.upload_document_type_id ? (
                            <>
                                {' '}
                                <CustomButton
                                    buttonLabel='Browse'
                                    buttonType={BUTTON_TYPE.disabled}
                                    onClick={() => profileFilePickerRef?.current?.click()}
                                    buttonSize={BUTTON_SIZE.small}
                                />
                                <input
                                    type='file'
                                    ref={profileFilePickerRef}
                                    style={{display: 'none'}}
                                    required
                                    multiple
                                    onChange={onUploadNewDocument}
                                    name={'image'}
                                    accept='.heic,.png,.jpeg,.jpg,.doc,.docx,.pdf'
                                />
                            </>
                        ) : !item?.signed_status ? (
                            <CustomButton
                                buttonLabel='Review'
                                buttonType={BUTTON_TYPE.primary}
                                onClick={redirectToReviewPage}
                                buttonSize={BUTTON_SIZE.small}
                                loading={reviewLoading}
                            />
                        ) : (
                            <div></div>
                        )}
                    </td>
                </AccessRights>
            ) : null}
        </tr>
    )
}

const EachDocRow = ({
    employeeData,
    docIndex,
    doc,
    deleteDocument = () => {},
    type = 'uploaded',
    downloadDoc,
}) => {
    const {employeeProfileAccess} = useCustomAccessRights({employeeData})

    const fileExtension = doc?.document_file_path?.split('.')?.pop()

    return (
        <CustomEllipsis
            key={docIndex}
            width='275px'
            style={{fontSize: '14px'}}
            text={type == 'unuploaded' && doc?.status != 'uploaded' ? doc?.progress : doc?.document}
        >
            <div
                key={doc?.document}
                className='my-2'
                style={{
                    overflow: 'hidden',
                    borderRadius: 10,
                    position: 'relative',
                    // backgroundColor:
                    //     type == 'uploaded'
                    //         ? 'white'
                    //         : doc?.status != 'uploaded'
                    //         ? '#EEEEEE'
                    //         : 'white',
                }}
            >
                {type == 'unuploaded' && doc?.status != 'uploaded' ? (
                    <div
                        style={{
                            width: `${doc?.progress ?? 0}%`,
                            position: 'absolute',
                            backgroundColor: 'rgba(0,0,0, 0.5)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <span style={{color: 'white', marginRight: 10}}>{doc?.progress}%</span>
                    </div>
                ) : null}
                <div>
                    <AccessRights customCondition={employeeProfileAccess.deleteDocumentAccess}>
                        <span className='me-5'>
                            {type == 'uploaded' ? (
                                <i
                                    className='bi bi-trash text-cmBlue-Crayola cursor-pointer'
                                    onClick={deleteDocument}
                                ></i>
                            ) : null}
                        </span>
                    </AccessRights>
                    <span className='bg-cmBlue-Crayola rounded-pill text-cmBlue-Crayola bg-opacity-10 p-2 cursor-pointer'>
                        <span
                            style={{
                                padding: 5,
                                paddingLeft: 10,
                                fontFamily: '',
                            }}
                            className='cursor-pointer text-decoration-underline'
                            onClick={() => downloadDoc(doc?.document_file_path, fileExtension)}
                        >
                            <i className='fa-solid fa-paperclip text-cmBlue-Crayola pe-2'></i>
                            {doc?.document_file_path}
                        </span>
                    </span>
                </div>
            </div>
        </CustomEllipsis>
    )
}
export default EachDocTypeRow
