import React from 'react'
import Collapse from '@mui/material/Collapse'

export const DIRECTION = {
    HORIZONTAL: 'horizontal',
}

const CustomCollapse = ({children, toggle, direction = null, className = null, timeout = 800}) => {
    return (
        <Collapse in={toggle} orientation={direction} className={className} timeout={timeout}>
            {children}
        </Collapse>
    )
}

export default CustomCollapse
