import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {getAlertDataAction} from '../../../../../../redux/actions/DashboardActions'
import {useSelector} from 'react-redux'
import {getAlertSelector} from '../../../../../../redux/selectors/DashboardSelectors'
import CustomLoader from '../../../../../../customComponents/customLoader/CustomLoader'
import CustomEllipsis from '../../../../../../customComponents/customEllipsis/CustomEllipsis'
import {fontsFamily} from '../../../../../../assets/fonts/fonts'
import {useNavigate} from 'react-router'
import {KTSVG} from '../../../../../../_metronic/helpers'
import CustomLink, {LINK_SIZE} from '../../../../../../customComponents/customButtton/CustomLink'
import CustomIterator from '../../../../../../customComponents/customIterator/CustomIterator'
import CustomSkeleton from '../../../../../../customComponents/customSkeleton/CustomSkeleton'
import useSequifiConfig from '../../../../../../sequiConfig/useSequifiConfig'

const AlertSectionCard = () => {
    const alertData = useSelector(getAlertSelector)
    const navigate = useNavigate()
    const {basedOnCompanyType} = useSequifiConfig()
    const [loading, setLoading] = useState(false)
    const [fullLoading, setFullLoading] = useState(false)

    const dispatch = useDispatch()
    useEffect(() => {
        getAlertData()
    }, [])

    const getAlertData = useCallback(() => {
        if (alertData) setLoading(true)
        else setFullLoading(true)
        dispatch(getAlertDataAction()).finally(() => {
            if (alertData) setLoading(false)
            else setFullLoading(false)
        })
    }, [alertData, dispatch])

    const alertNavigation = useCallback(
        (item) => {
            let key
            // setViewRepRepRedline(true)
            // return
            switch (item?.type) {
                case 'location Redline':
                    key = 'locationRedline'
                    // path = '/alert-center/clawback'
                    break
                case 'Missing Rep':
                    key = 'missingRep'
                    break
                case 'Closed Payroll':
                    key = 'closedPayroll'
                    break
                case 'Sales':
                    key = 'sales'
                    break

                default:
                    key = ''
            }
            navigate('/alert-center/alerts', {
                state: {alertType: key, search: item?.pid},
            })
        },
        [navigate]
    )

    return (
        <div
            className='mb-10 bg-cmwhite px-6 py-5 pt-5 shadow-sm text-cmGrey900'
            style={{
                fontSize: 14,
                fontWeight: 600,
                fontFamily: fontsFamily.manrope,
                borderRadius: 12,
            }}
        >
            {/* <CustomLoader visible={fullLoading} full /> */}

            {/* Heading */}

            <div
                className='mb-5 d-flex align-items-center justify-content-between'
                style={{fontSize: 16, fontWeight: 700, position: 'relative'}}
            >
                Alert Section ({alertData?.missing_data?.length ?? 0})
                {alertData?.missing_data?.length > 0 ? (
                    <CustomLink
                        id={'admin_dashboard_alertSection_viewAll_button'}
                        linkSize={LINK_SIZE.large}
                        label={'View all'}
                        onClick={() => navigate('/alert-center/alerts')}
                    />
                ) : null}
            </div>
            {/* line 1 */}

            {loading ? (
                <CustomIterator count={6}>
                    <div className='d-flex mb-5'>
                        <div>
                            <CustomSkeleton className={'h-50px w-50px'} />
                        </div>
                        <div className='d-flex flex-column ms-5'>
                            <CustomSkeleton className={'h-15px w-250px'} />
                            <CustomSkeleton className={'h-15px w-200px'} />
                        </div>
                    </div>
                </CustomIterator>
            ) : alertData?.addres_data?.length > 0 ||
              alertData?.missing_data?.length > 0 ||
              alertData?.clawback_data?.length > 0 ||
              alertData?.payroll_alert?.length > 0 ? (
                <div className='h-450px' style={{overflowY: 'auto', overflowX: 'hidden'}}>
                    {alertData?.addres_data?.length > 0 &&
                        alertData?.addres_data?.map((item) => (
                            <div className='d-flex align-items-start gap-5 mb-10' key={item?.id}>
                                <div className='bi bi-lightning-charge fs-1 text-cmError bg-cmError bg-opacity-10 rounded px-2 py-1' />
                                {/* Title */}
                                <div>
                                    <div style={{fontSize: 14, fontWeight: 800}}>
                                        LIS13014 - Scott Huber | Data Missing
                                    </div>
                                    <div className='text-cmGrey600' style={{fontWeight: 600}}>
                                        Update {basedOnCompanyType?.primaryUnitType} sold
                                    </div>
                                </div>
                            </div>
                        ))}
                    {alertData?.clawback_data?.length > 0 &&
                        alertData?.clawback_data?.map((item) => (
                            <div className='d-flex align-items-start gap-5 mb-10' key={item.id}>
                                <div className='bi bi-people fs-1 text-cmError bg-cmError bg-opacity-10 rounded px-2 py-1' />
                                {/* Title */}
                                <div>
                                    <div style={{fontSize: 14, fontWeight: 800}}>
                                        LIS13014 - Scott Huber | Data Missing
                                    </div>
                                    <div className='text-cmGrey600' style={{fontWeight: 600}}>
                                        {' '}
                                        Update {basedOnCompanyType?.primaryUnitType} sold
                                    </div>
                                </div>
                            </div>
                        ))}
                    {alertData?.missing_data?.length > 0 &&
                        alertData?.missing_data?.map((item, index) => (
                            <div
                                className='d-flex align-items-start gap-5 mb-10 cursor-pointer'
                                key={item?.id}
                                id={`admin_dashboard_alertSection_${index}`}
                                onClick={() => alertNavigation(item)}
                            >
                                <div className=''>
                                    {item?.type == 'Sales Info' ? (
                                        <span
                                            style={{borderRadius: 10}}
                                            className='w-30px h-30px d-flex flex-center bg-cmError bg-opacity-10'
                                        >
                                            <KTSVG
                                                path='/media/icons/duotune/art/MissingInfo-Chart.svg'
                                                className='cursor-pointer'
                                                svgClassName='w-20px h-20px'
                                            />
                                        </span>
                                    ) : item?.type == 'location Redline' ? (
                                        <i
                                            className={
                                                'pi pi-map-marker fs-2 text-cminfo bg-cminfo w-30px h-30px d-flex flex-center bg-opacity-10 fs-2'
                                            }
                                            style={{borderRadius: 10}}
                                        ></i>
                                    ) : item?.type == 'Missing Rep' ? (
                                        <i
                                            className={
                                                'pi pi-user-edit fs-2 text-cmgreen bg-cmgreen w-30px h-30px d-flex flex-center bg-opacity-10 fs-2'
                                            }
                                            style={{borderRadius: 10}}
                                        ></i>
                                    ) : item?.type == 'Closed Payroll' ? (
                                        <i
                                            className={
                                                'pi pi-delete-left text-cmError bg-cmError w-30px h-30px d-flex flex-center bg-opacity-10 fs-2'
                                            }
                                            style={{borderRadius: 10}}
                                        ></i>
                                    ) : (
                                        <span
                                            style={{borderRadius: 10}}
                                            className='w-30px h-30px d-flex flex-center bg-cmError bg-opacity-10'
                                        >
                                            <KTSVG
                                                path='/media/icons/duotune/art/MissingInfo-Chart.svg'
                                                className='cursor-pointer'
                                                svgClassName='w-20px h-20px'
                                            />
                                        </span>
                                    )}
                                </div>
                                {/* Title */}
                                <div>
                                    <div style={{fontSize: 14, fontWeight: 800}}>
                                        {item?.heading}
                                    </div>

                                    <CustomEllipsis
                                        width='200px'
                                        className='text-cmGrey600 '
                                        style={{
                                            whiteSpace: 'nowrap',
                                            fontSize: '14px',
                                            fontWeight: 600,
                                        }}
                                        text={item?.alert_summary}
                                    >
                                        {item?.alert_summary}
                                    </CustomEllipsis>
                                </div>
                            </div>
                        ))}
                    {alertData?.payroll_alert?.length > 0 &&
                        alertData?.payroll_alert?.map((item) => (
                            <div className='d-flex align-items-start gap-5 mb-10' key={item?.id}>
                                <div className='bi bi-lightning-charge fs-1 text-cmError bg-cmError bg-opacity-10 rounded px-2 py-1' />
                                {/* Title */}
                                <div>
                                    <div style={{fontSize: 14, fontWeight: 800}}>
                                        {item?.pid} - {item?.sales_rep_name} | Data Missing
                                    </div>
                                    <div className='text-cmGrey600' style={{fontWeight: 600}}>
                                        {' '}
                                        {item?.alert_summary}
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            ) : (
                <div className='text-cmError text-center'>No Alerts</div>
            )}
        </div>
    )
}

export default AlertSectionCard
