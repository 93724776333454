import {useEffect} from 'react'
import {GOOGLE_ANALYTICS_MEASUREMENT_ID} from '../../../constants/constants'
import ReactGA from 'react-ga4'
import {useLocation} from 'react-router-dom'
import useBasedOnHost from '../../../sequiConfig/useBasedOnHost'

const GoogleAnalyticsConfig = () => {
    const {basedOnHost} = useBasedOnHost()
    useEffect(() => {
        if (basedOnHost?.enableGoogleAnalytics && GOOGLE_ANALYTICS_MEASUREMENT_ID) {
            ReactGA.initialize(GOOGLE_ANALYTICS_MEASUREMENT_ID)
        }
    }, [])

    const location = useLocation()
    useEffect(() => {
        if (basedOnHost?.enableGoogleAnalytics) {
            if (GOOGLE_ANALYTICS_MEASUREMENT_ID) {
                const excludedPaths = ['/dashboard']
                if (!excludedPaths.includes(location.pathname)) {
                    ReactGA.send({
                        hitType: 'pageview',
                        page: location.pathname + location.search,
                        // testMode: true
                    })
                }
            }
        }
    }, [location])

    return <></>
}

export default GoogleAnalyticsConfig
