import React, {useMemo} from 'react'
import {Button} from 'primereact/button'
import {fontsFamily} from '../../assets/fonts/fonts'
import './ButtonStyle.css'
import Show from '../show/Show'

export const BUTTON_STYLE = {
    linkColor: 'text-sqPrimary600',
    linkGray: 'linkGray',
    distructiveLink: 'distructiveLink',
    primary: 'bg-sqPrimary600 text-white border-sqPrimary600 defaultButton',
    secondaryColor:
        'bg-sqPrimary600 bg-opacity-10 border-sqPrimary600 border-opacity-10 text-sqPrimary600 defaultButton',
    secondaryGray: 'bg-white border-sqGray300 text-sqGray700 grayButton bg-hover-sqGray50',
    tertiaryColor: 'text-sqPrimary600 tertiaryColor hideFocus',
    tertiaryGray: 'hideFocus tertiaryGray',
    distructivePrimary:
        'bg-sqError600 bg-hover-sqError700 text-white border-sqError600 destructiveButton',
    distructiveSecondaryColor:
        'bg-sqError50 bg-hover-sqError100 text-sqError700 border-transparent',
    distructiveSecondaryGray:
        'bg-white border-sqError300 text-sqError700 destructiveButton bg-hover-sqError50',
    distructiveTertiaryGray: 'hideFocus tertiaryDestructiveGray',
}

export const BUTTON_TYPE = {
    linkColor: 'linkColor',
    linkGray: 'linkGray',
    distructiveLink: 'distructiveLink',
    primary: 'primary',
    secondaryColor: 'secondaryColor',
    secondaryGray: 'secondaryGray',
    tertiaryColor: 'tertiaryColor',
    tertiaryGray: 'tertiaryGray',
    distructivePrimary: 'distructivePrimary',
    distructiveSecondaryColor: 'distructiveSecondaryColor',
    distructiveSecondaryGray: 'distructiveSecondaryGray',
    distructiveTertiaryGray: 'distructiveTertiaryGray',
}

export const BUTTON_SIZE = {
    sm: `sm`,
    md: 'md',
    lg: 'lg',
    xl: 'xl',
    '2xl': '2xl',
}

export const BUTTON_SIZE_STYLE = {
    sm: `h-36px px-14px py-2`,
    md: 'h-40px py-10px px-4',
    lg: 'h-44px py-10px px-18px',
    xl: 'h-48px py-3 px-5',
    '2xl': 'h-60px py-4 px-7',
}

export const BUTTON_FONT = {
    sm: `text-sm-semibold`,
    md: 'text-sm-semibold',
    lg: 'text-md-semibold',
    xl: 'text-md-semibold',
    '2xl': 'text-lg-semibold',
}

const CustomButton2 = ({
    buttonLabel = '',
    id = null,
    type = 'button',
    buttonType = BUTTON_STYLE.primary,
    buttonSize = BUTTON_SIZE.small,
    disabled,
    className,
    onlyIcon = true,
    icon = null,
    iconPosition = 'left',
    loading = false,
    onClick,
    style,
    width = null,
    padding = null,
    children = null,
    isLink = false,
}) => {
    const buttonStyle = `${BUTTON_STYLE[buttonType]} ${BUTTON_SIZE_STYLE[buttonSize]} ${BUTTON_FONT[buttonSize]}`

    return (
        <div>
            <Show>
                <Show.When isVisible={isLink}>
                    <Button
                        loading={loading}
                        disabled={disabled}
                        iconPos={iconPosition}
                        onClick={onClick}
                        link
                        className={`p-0
                        border-0
                        hideFocus d-flex gap-2 align-items-center ${BUTTON_FONT[buttonSize]} ${BUTTON_STYLE[buttonType]} text-decoration-underline`}
                    >
                        {typeof icon == 'string' ? <i className={`${icon}`} /> : icon ? icon : null}
                        <span>{buttonLabel}</span>
                    </Button>
                </Show.When>
                <Show.Else>
                    <Button
                        id={id}
                        type={type}
                        loading={loading}
                        disabled={disabled}
                        iconPos={iconPosition}
                        onClick={onClick}
                        className={`d-flex flex-center gap-2 text-center ${buttonStyle} w-${
                            width ?? 'auto'
                        }`}
                        style={{
                            fontFamily: fontsFamily.manrope,
                            borderRadius: '8px',
                        }}
                    >
                        {typeof icon == 'string' ? <i className={`${icon}`} /> : icon ? icon : null}
                        <span>{buttonLabel}</span>
                    </Button>
                </Show.Else>
            </Show>
        </div>
    )
}

export default CustomButton2
