import CustomButton, {
    BUTTON_SIZE,
    BUTTON_TYPE,
} from '../../../../customComponents/customButtton/CustomButton'
import CustomDropdown from '../../../../customComponents/customInputs/customDropdown/CustomDropdown'
import CustomInput, {
    INPUT_TYPE,
} from '../../../../customComponents/customInputs/customInput/CustomInput'
import CustomModal from '../../../../customComponents/customModal/CustomModal'
import Edit from '../../admin/sequidocs/Icon/edit.png'
import AddNewAnnouncementModal from './addNewAnnouncementModal'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {deleteAnnouncementService} from '../../../../services/Services'
import CustomLoader from '../../../../customComponents/customLoader/CustomLoader'
import useOfficeLocation from '../../../../hooks/useOfficeLocation'
import CustomDialog from '../../../../customComponents/customDialog/CustomDialog'
import debounce from 'lodash.debounce'
import {getannouncementDataAction} from '../../../../redux/actions/DashboardActions'
import {getAnnouncementSelector} from '../../../../redux/selectors/DashboardSelectors'
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux'
import {getUserDataSelector} from '../../../../redux/selectors/AuthSelectors'
import {getValidDate} from '../../../../constants/constants'
import {
    TABLE_BORDER,
    getGlobalSearchData,
    sendDataToReducer,
} from '../../../../helpers/CommonHelpers'
import {SET_ANNOUNCEMENT_DATA} from '../../../../redux/reducers/DashboardReducer'
import {getPositionsSelector} from '../../../../redux/selectors/SettingsSelectors'
import CustomEditIcon from '../../../../customComponents/customIcons/CustomEditIcon'
import CustomDelete from '../../../../customComponents/customIcons/CustomDelete'
import Pagination from '../../admin/sequidocs/component/Pagination'
import CustomNoData from '../../../../customComponents/customNoData/CustomNoData'
import CustomTableSkeleton from '../../../../customComponents/customSkeleton/CustomTableSkeleton'
import AccessRights from '../../../../accessRights/AccessRights'
import {allPermissionsAccess} from '../../../../accessRights/useCustomAccessRights'
import useAPI from '../../../../hooks/useAPI'

const STATUS_DROPDOWN_VALUES = [
    {name: 'All Status', value: 'all'},
    {name: 'Upcoming', value: 'Upcoming'},
    {name: 'Live', value: 'Live'},
    {name: 'Expired', value: 'Expired'},
    {name: 'Disabled', value: 'disabled'},
]

const ManageAnnouncementModal = ({
    show,
    handleClose,
    role,
    // announcementData,
    // getAnnouncementData,
    // headFilters,
    // setHeadFilters,
    // loading,
    // setLoading,
}) => {
    const [showAddNewAnnouncementModal, setShowAddNewAnnouncementModal] = useState(false)
    const [editAnnouncementData, setEditAnnouncementData] = useState(null)
    const loggedUser = useSelector(getUserDataSelector)
    const allPositions = useSelector(getPositionsSelector)

    const [officeList, selectedLocation, setSelectedLocation] = useOfficeLocation()
    const announcementData = useSelector(getAnnouncementSelector)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)

    const [headFilters, setHeadFilters] = useState({
        search: '',
        // office: selectedLocation,
        position: '',
    })
    const [statusFilter, setStatusFilter] = useState('all')
    const {callAPI, initAPI, loading: apiLoader} = useAPI()

    useEffect(() => {
        if (selectedLocation) getAnnouncementData()
    }, [headFilters, selectedLocation, statusFilter, page, limit])

    const getAnnouncementData = useCallback(() => {
        const body = {
            id: loggedUser?.id,
            search: headFilters?.search,
            office: selectedLocation,
            position_filter: headFilters?.position,
            status: statusFilter,
            page: page,
            perpage: limit,
        }
        initAPI()
            .then((signal) => {
                callAPI(dispatch(getannouncementDataAction(body, signal)))
                    .catch((err) => {})
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {})
    }, [
        dispatch,
        headFilters?.position,
        headFilters?.search,
        limit,
        loggedUser?.id,
        page,
        selectedLocation,
        statusFilter,
    ])
    const onPressDeleteAnnouncemennt = (id) => {
        CustomDialog.warn('Are you sure want to delete this announcement', () => {
            setLoading(true)
            deleteAnnouncementService(id)
                .finally(() => {
                    getAnnouncementData()
                    setLoading(false)
                })
                .catch(() => {})
        })
    }

    const onPressAddandEditModal = (item) => {
        if (item) {
            setEditAnnouncementData(item)
        } else {
            setEditAnnouncementData(null)
        }
        setShowAddNewAnnouncementModal(true)
    }

    const locationChange = (e) => {
        // setLoading(true)
        setSelectedLocation(e.target.value)
        setHeadFilters({...headFilters, office: e.target.value})
    }

    const onSearch = (e) => {
        setSearchText(e.target.value)
        delaySearch(e.target.value)
    }
    const delaySearch = useCallback(
        debounce((val) => {
            setHeadFilters({...headFilters, search: val})
        }, 500),
        []
    )
    const getAnnouncements = () => {
        getAnnouncementData()
    }

    const onStatusChange = (e) => {
        setStatusFilter(e?.value)
    }
    const positionList = useMemo(() => {
        let list = [{name: 'All Positions', value: ''}]
        allPositions.map((i) => {
            list.push({name: i.position_name, value: i.id})
        })
        return list
    }, [])
    const onChangePositonFilter = (e) => {
        setHeadFilters({...headFilters, position: e.target.value})
    }

    const displayData = useMemo(() => {
        let filteredData = announcementData?.data
        if (searchText) {
            filteredData = getGlobalSearchData(
                announcementData?.data,
                ['title', 'status'],
                searchText
            )
        }
        return filteredData
    }, [announcementData, searchText])

    const hasPermission = useMemo(() => {
        return {
            add:
                role == 'standard'
                    ? allPermissionsAccess.standard.dashboard.dashboard.addFunc()
                    : allPermissionsAccess.administrator.dashboard.dashboard.addFunc(),
            edit:
                role == 'standard'
                    ? allPermissionsAccess.standard.dashboard.dashboard.editFunc()
                    : allPermissionsAccess.administrator.dashboard.dashboard.editFunc(),
            delete:
                role == 'standard'
                    ? allPermissionsAccess.standard.dashboard.dashboard.deleteFunc()
                    : allPermissionsAccess.administrator.dashboard.dashboard.deleteFunc(),
        }
    }, [allPermissionsAccess, role])
    return (
        <>
            <CustomModal
                show={show}
                onHide={handleClose}
                maxWidth='1300'
                title={'Manage Announcements'}
            >
                {/* Body Starts */}
                <div>
                    {/* <CustomLoader full visible={loading} /> */}

                    <div
                        className='d-flex flex-wrap justify-content-between mb-5 gap-3'
                        style={{fontWeight: '700', fontFamily: 'Manrope', fontSize: '14px'}}
                    >
                        <div>
                            <CustomInput
                                id={'admin_dashboard_manageAnnouncements_searchBar'}
                                type={INPUT_TYPE.search}
                                name='search'
                                onChange={onSearch}
                                value={searchText}
                            />
                        </div>

                        <div className='d-flex flex-wrap gap-3'>
                            <div>
                                <CustomDropdown
                                    id={'admin_dashboard_manageAnnouncements_selectStatus'}
                                    placeholder='Select Status'
                                    value={statusFilter}
                                    onChange={onStatusChange}
                                    options={STATUS_DROPDOWN_VALUES}
                                    showClear={false}
                                    searching={false}
                                />
                            </div>
                            <div>
                                <CustomDropdown
                                    id={'admin_dashboard_manageAnnouncements_selectOffices'}
                                    placeholder='Select Office'
                                    value={selectedLocation}
                                    onChange={locationChange}
                                    options={officeList}
                                    showClear={false}
                                    // valueKey='name'
                                />
                            </div>
                            <div>
                                <CustomDropdown
                                    id={'admin_dashboard_manageAnnouncements_selectPositions'}
                                    placeholder='Select Position'
                                    value={headFilters?.position}
                                    onChange={onChangePositonFilter}
                                    options={positionList}
                                    showClear={false}
                                />
                            </div>
                            {/* <div>
                                <CustomDropdown
                                    placeholder='Select Status'
                                    value={statusFilter}
                                    onChange={onStatusChange}
                                    options={STATUS_DROPDOWN_VALUES}
                                    showClear={false}
                                />
                            </div> */}
                            <div>
                                <AccessRights customCondition={hasPermission?.add}>
                                    <CustomButton
                                        id={'admin_dashboard_manageAnnouncements_addNewButton'}
                                        type='submit'
                                        buttonType={BUTTON_TYPE.primary}
                                        buttonSize={BUTTON_SIZE.small}
                                        buttonLabel={'Add New'}
                                        onClick={() => onPressAddandEditModal(null)}
                                    />
                                </AccessRights>
                            </div>
                        </div>
                    </div>
                    <div className='table-responsive'>
                        <table
                            className='table'
                            style={{tableLayout: 'fixed', width: '100%', wordBreak: 'break-word'}}
                        >
                            <thead className={TABLE_BORDER}>
                                <tr
                                    className='bg-cmGrey300 text-cmGrey900 '
                                    style={{
                                        fontSize: '14px',
                                        fontWeight: '700',
                                        fontFamily: 'Manrope',
                                    }}
                                >
                                    <th className=' p-5 w-50px'></th>
                                    <th className=' p-5 w-200px'>Title</th>
                                    <th className=' p-5 w-100px'>Status</th>
                                    <th className=' p-5 w-200px'>Sent to</th>
                                    <th className=' p-5 w-200px'>Office</th>
                                    <th className=' p-5 w-150px'>Start Date</th>
                                    <th className=' p-5 w-125px'>Duration</th>
                                    <AccessRights
                                        customCondition={
                                            hasPermission?.edit || hasPermission?.delete
                                        }
                                    >
                                        <th className=' p-5 w-125px '></th>
                                    </AccessRights>
                                </tr>
                            </thead>
                            <tbody className={TABLE_BORDER}>
                                {loading || apiLoader ? (
                                    <CustomTableSkeleton coloums={8} />
                                ) : displayData?.length > 0 ? (
                                    displayData?.map((item, index) => {
                                        return (
                                            <tr
                                                key={item?.id}
                                                style={{
                                                    fontSize: '14px',
                                                    fontFamily: 'Manrope',
                                                    fontWeight: '500',
                                                }}
                                                className='stripRow'
                                            >
                                                {item?.pin_to_top ? (
                                                    <td className='fs-1 px-5 text-cminfo'>
                                                        <div className='bi bi-pin-angle p-0' />
                                                    </td>
                                                ) : (
                                                    <td></td>
                                                )}
                                                <td className='p-5  text-cmGrey800'>
                                                    {item?.title}
                                                </td>
                                                <td
                                                    className={`p-5  ${
                                                        item?.status == 'Expired' && 'text-cmError'
                                                    }`}
                                                >
                                                    {item?.status}
                                                </td>

                                                <td className='p-5 '>
                                                    {item?.positions?.map((val, i) => (
                                                        <span key={i}>
                                                            {val?.position_name}

                                                            {item?.positions?.length - 1 > i
                                                                ? ','
                                                                : null}
                                                        </span>
                                                    ))}
                                                </td>
                                                <td className='p-5'>
                                                    {item?.office?.map((val, i) => (
                                                        <span key={i}>
                                                            {val?.office_name}{' '}
                                                            {item?.office?.length - 1 > i
                                                                ? ','
                                                                : null}
                                                        </span>
                                                    ))}
                                                </td>
                                                <td className='p-5 '>
                                                    {getValidDate(item?.start_date)}
                                                </td>

                                                <td className='p-5 '>{item?.durations}</td>
                                                <AccessRights
                                                    customCondition={
                                                        hasPermission?.edit || hasPermission?.delete
                                                    }
                                                >
                                                    {loggedUser?.id == item?.user_id ? (
                                                        <td className='p-5 flex-center d-flex gap-2'>
                                                            <AccessRights
                                                                customCondition={
                                                                    hasPermission?.edit
                                                                }
                                                            >
                                                                <CustomEditIcon
                                                                    id={`admin_dashboard_manageAnnouncements_editButton_${index}`}
                                                                    onClick={() =>
                                                                        onPressAddandEditModal(item)
                                                                    }
                                                                />
                                                            </AccessRights>
                                                            <AccessRights
                                                                customCondition={
                                                                    hasPermission?.delete
                                                                }
                                                            >
                                                                <CustomDelete
                                                                    id={`admin_dashboard_manageAnnouncements_deleteButton_${index}`}
                                                                    onClick={() => {
                                                                        onPressDeleteAnnouncemennt(
                                                                            item?.id
                                                                        )
                                                                    }}
                                                                />
                                                            </AccessRights>
                                                        </td>
                                                    ) : null}
                                                </AccessRights>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan={8}>
                                            <CustomNoData label={'No data found'} />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {displayData?.length > 0 && (
                        <Pagination
                            page={page}
                            limit={limit}
                            setLimit={(limit) => {
                                setLimit(limit)
                                setPage(1)
                            }}
                            setPage={(changedPage) => setPage(changedPage)}
                            totalPages={announcementData?.last_page}
                        />
                    )}
                </div>
            </CustomModal>
            {showAddNewAnnouncementModal ? (
                <AddNewAnnouncementModal
                    item={editAnnouncementData}
                    show={showAddNewAnnouncementModal}
                    onClose={() => setShowAddNewAnnouncementModal(false)}
                    getAnnouncements={getAnnouncements}
                />
            ) : null}
        </>
    )
}
export default ManageAnnouncementModal
