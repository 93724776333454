import {PaymentElement} from '@stripe/react-stripe-js'
import {useCallback, useMemo, useState} from 'react'
import {useStripe, useElements} from '@stripe/react-stripe-js'
import CustomButton, {BUTTON_TYPE} from '../../customComponents/customButtton/CustomButton'
import {useNavigate} from 'react-router-dom'
import CustomToast from '../../customComponents/customToast/CustomToast'
import CustomModal from '../../customComponents/customModal/CustomModal'
import Show from '../../customComponents/show/Show'
import {STRIPE_CLIENT_SECRET_TYPE} from '../../constants/constants'

export default function CheckoutForm({billingData, clientSecret, paymentMethod}) {
    const stripe = useStripe()
    const elements = useElements()
    const navigate = useNavigate()
    const [savedPaymentIntent, setSavedPaymentIntent] = useState(false)
    const [message, setMessage] = useState(null)
    const [isProcessing, setIsProcessing] = useState(false)
    const [isAutoPay, setIsAutoPay] = useState(false)

    const isRequireAction = useMemo(() => {
        return savedPaymentIntent?.status == 'requires_action'
    }, [savedPaymentIntent?.status])

    const checkAndUpdatePaymentStatusForSequifi = useCallback(
        (paymentIntent) => {
            navigate(`/completion?status=${paymentIntent.status}`, {
                state: {
                    autoPay: isAutoPay,
                    billing_id: billingData?.invoice_no,
                    payment_intent: paymentIntent?.id,
                    last_payment_message: paymentIntent?.last_payment_error?.message ?? '',
                    paymentMethod,
                },
            })
        },
        [billingData?.invoice_no, isAutoPay, navigate]
    )

    const clientSecretType = useMemo(() => {
        let type = null
        Object.keys(STRIPE_CLIENT_SECRET_TYPE).map((key) => {
            type = !type
                ? clientSecret.includes(STRIPE_CLIENT_SECRET_TYPE[key])
                    ? STRIPE_CLIENT_SECRET_TYPE[key]
                    : null
                : type
        })
        return type
    }, [clientSecret])

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return
        }

        setIsProcessing(true)

        if (clientSecretType == STRIPE_CLIENT_SECRET_TYPE.payment_intent) {
            const {paymentIntent, error} = await stripe.confirmPayment({
                elements,
                redirect: 'if_required',
            })

            setSavedPaymentIntent(paymentIntent)
            if (error) {
                // Handle error here
                setMessage(`${error?.message} | Error Code: ${error?.code}`)
                CustomToast.error(`${error?.message} | Error Code: ${error?.code}`)
                setIsProcessing(false)
            } else if (paymentIntent) {
                checkAndUpdatePaymentStatusForSequifi(paymentIntent)
            } else {
                CustomToast.error(
                    'Something went wrong with your selected payment method or your bank account, try again or change payment method or do valid payment'
                )
                setIsProcessing(false)
            }
        } else if (clientSecretType == STRIPE_CLIENT_SECRET_TYPE.payment_setup_intent) {
            const {setupIntent, error} = await stripe.confirmSetup({
                elements,
                redirect: 'if_required',
            })

            setSavedPaymentIntent(setupIntent)
            if (error) {
                // Handle error here
                setMessage(`${error?.message} | Error Code: ${error?.code}`)
                CustomToast.error(`${error?.message} | Error Code: ${error?.code}`)
                setIsProcessing(false)
            } else if (setupIntent) {
                checkAndUpdatePaymentStatusForSequifi(setupIntent)
            } else {
                CustomToast.error('Something went wrong')
                setIsProcessing(false)
            }
        }
    }

    const onCancelPress = useCallback(() => {
        if (clientSecretType == STRIPE_CLIENT_SECRET_TYPE.payment_setup_intent) {
            CustomToast.error('You pressed cancel')
        } else {
            CustomToast.error('Payment cancelled')
        }

        paymentMethod
            ? navigate('/settings/billings/payment-method')
            : navigate('/settings/billings/billing-history')
    }, [clientSecretType, navigate, paymentMethod])

    return (
        <>
            <form className='card bg-white h-auto shadow p-5 mt-10' onSubmit={handleSubmit}>
                {/* Auto Pay */}
                {/* <div className='mb-15'>
                    <CustomCheckbox
                        checked={getBooleanValue(isAutoPay) == 1}
                        onChange={() => setIsAutoPay((val) => !val)}
                    />
                    <strong className='text-cmGrey600'> Auto Pay</strong>
                </div> */}
                <PaymentElement id='payment-element' />
                <div className='d-flex  mt-5'>
                    <Show>
                        <Show.When isVisible={!isRequireAction}>
                            <Show>
                                <Show.When
                                    isVisible={
                                        clientSecretType ==
                                        STRIPE_CLIENT_SECRET_TYPE.payment_setup_intent
                                    }
                                >
                                    <CustomButton
                                        type='submit'
                                        disabled={isProcessing || !stripe || !elements}
                                        id='submit'
                                        buttonLabel={
                                            isProcessing ? 'Processing ... ' : 'Add Payment Method'
                                        }
                                    />
                                </Show.When>
                                <Show.When
                                    isVisible={
                                        clientSecretType ==
                                        STRIPE_CLIENT_SECRET_TYPE.payment_setup_intent
                                    }
                                >
                                    <CustomButton
                                        type='submit'
                                        disabled={isProcessing || !stripe || !elements}
                                        id='submit'
                                        buttonLabel={
                                            isProcessing
                                                ? 'Processing ... '
                                                : isAutoPay
                                                ? 'Authorize & Pay'
                                                : 'Pay now'
                                        }
                                    />
                                </Show.When>
                            </Show>
                        </Show.When>
                    </Show>

                    <CustomButton
                        className={'ms-5'}
                        buttonType={BUTTON_TYPE.error}
                        style={{marginLeft: 10}}
                        disabled={isProcessing || !stripe || !elements}
                        buttonLabel={'Cancel'}
                        onClick={onCancelPress}
                    />
                </div>
                {/* Show any error or success messages */}
            </form>
            {message ? <div id='payment-message'>{message}</div> : null}
        </>
    )
}

export const VerifyMicroDepositsModal = ({show, onHide, onVerifyButtonPress}) => {
    return (
        <CustomModal show={show} onHide={onHide}>
            <CustomButton buttonLabel='Verify' onClick={onVerifyButtonPress} />
        </CustomModal>
    )
}
