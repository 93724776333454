import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import CustomLottie from '../../../../../customComponents/customLottie/CustomLottie'
import AILoadingWhite from '../../../../../assets/lottieAnimation/AILoadingWhite.json'
import {fontsFamily} from '../../../../../assets/fonts/fonts'
import {KTSVG} from '../../../../../_metronic/helpers'
import CustomDelete from '../../../../../customComponents/customIcons/CustomDelete'
import CustomButton, {
    BUTTON_SIZE,
    BUTTON_TYPE,
} from '../../../../../customComponents/customButtton/CustomButton'
import {getOpenAPIChatV1Service} from '../../../../../services/Services'
import {
    getErrorMessageFromResponse,
    getGlobalSearchData,
    replaceRange,
} from '../../../../../helpers/CommonHelpers'
import CustomToast from '../../../../../customComponents/customToast/CustomToast'

const AI_SUGGESTIONS = [
    {
        icon: 'ImproveWriting',
        feature: 'Improve Writing',
        id: 1,
    },
    {
        icon: 'language-square',
        feature: 'Fix spelling & grammar',
        id: 2,
    },
    {
        icon: 'lamp-on',
        feature: 'Generate',
        id: 3,
    },
    {
        icon: 'makeShorter',
        feature: 'Make Shorter',
        id: 4,
    },
    {
        icon: 'book',
        feature: 'Change tone to Legal',
        id: 5,
    },
    {
        icon: 'briefcase',
        feature: 'Change tone to professional',
        id: 6,
    },
    {
        icon: 'lovely',
        feature: 'Change tone to empathetic',
        id: 7,
    },
    {
        icon: 'icon-park-outline_cool',
        feature: 'Change tone to casual',
        id: 8,
    },
    {
        icon: 'iconamoon_neutral-face-light',
        feature: 'Change tone to neutral',
        id: 9,
    },
]

const AIInputBlock = ({
    quillRef,
    selection,
    setOpenAIBlock,
    editorText,
    onSequiAiPress = () => null,
    setSelection,
}) => {
    const [selectedSuggestion, setSelectedSuggestion] = useState(null)
    const [responseScreen, setResponseScreen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState()
    const [confirmAction, setConfirmAction] = useState(false)
    const [currentSelection, setCurrentSelction] = useState(selection)
    const textRef = useRef(null)

    const quillEditor = quillRef?.current?.getEditor()
    const plainText = editorText?.replace(/<[^>]+>/g, '')

    useEffect(() => {
        setCurrentSelction(selection)
    }, [selection])

    const suggestionList = useMemo(() => {
        return search ? getGlobalSearchData(AI_SUGGESTIONS, ['feature'], search) : AI_SUGGESTIONS
    }, [search])

    const handleSuggestions = (value) => {
        setSelectedSuggestion(value)
        getAIFormat(value)
    }

    const selectedTextToUse = useMemo(() => {
        let selectedText = null
        if (currentSelection?.length) {
            selectedText = plainText?.slice(
                currentSelection?.index,
                currentSelection?.index + currentSelection?.length
            )
            // quillEditor?.setSelection(currentSelection?.index, currentSelection?.length)
        }
        return selectedText
    }, [currentSelection?.index, currentSelection?.length, plainText])

    const getAIFormat = useCallback(
        (value) => {
            let selectedSearchText = selectedTextToUse ? selectedTextToUse : search

            if (!selectedSearchText) {
                CustomToast.error('Please write any text')
                return
            }
            setResponseScreen(true)
            setLoading(true)
            // let selectedText = null

            // if (currentSelection?.length) {
            //     selectedText = plainText?.slice(
            //         currentSelection?.index,
            //         currentSelection?.index + currentSelection?.length
            //     )
            //     quillEditor?.setSelection(currentSelection?.index, currentSelection?.length)
            // } else {
            //     quillEditor?.setSelection(0, selectedText?.length)
            // }
            // return
            getOpenAPIChatV1Service({type: value ?? 'Generate', body: selectedSearchText})
                .then((res) => {
                    setResponseScreen(res?.data)
                })
                .catch((e) => CustomToast.error(getErrorMessageFromResponse(e)))
                .finally(() => setLoading(false))
        },
        [search, selectedTextToUse]
    )

    const onInsertPress = () => {
        let message = responseScreen

        // if (currentSelection?.length !== 0) {
        message = replaceRange(
            plainText,
            currentSelection?.index,
            currentSelection?.index + currentSelection?.length,
            responseScreen
        )
        // }

        quillEditor?.setText(message)
        quillEditor?.setSelection(0)
        setSelection(null)
        onSequiAiPress()
        setResponseScreen(null)
        setSearch(null)
        if (textRef?.current) textRef.current.value = ''
    }
    const clearSelection = () => {
        setResponseScreen(null)
        setSelectedSuggestion(null)
        setSearch(null)
    }
    const handleKeyUp = useCallback(
        (e) => {
            if (e.key === 'Escape') {
                if (textRef?.current) textRef.current.value = ''
                clearSelection()
                onSequiAiPress()
            } else if (e.key === 'Enter') {
                getAIFormat()
            }
        },
        [getAIFormat, onSequiAiPress]
    )

    useEffect(() => {
        const handleKeyPress = (e) => {
            handleKeyUp(e)
        }
        const element = document.getElementById('sequiAiBlock')
        if (element && !responseScreen) element.addEventListener('keyup', handleKeyPress)

        return () => {
            if (element) {
                element.removeEventListener('keyup', handleKeyPress)
            }
        }
    }, [handleKeyUp, responseScreen])

    return (
        <div
            className='border border-2 border-cmBlue-Crayola overflow-hidden bg-cmwhite h-100'
            style={{borderRadius: 10, fontFamily: fontsFamily.manrope, fontSize: 14}}
            id='sequiAiBlock'
        >
            <div className='px-10 py-5 d-flex gap-2 align-items-center border-1 border-bottom border-cmGrey400'>
                <KTSVG
                    path={`/media/icons/duotune/art/AIAvatar.svg`}
                    svgClassName='w-30px h-30px cursor-pointer'
                    onClick={() => {
                        setSelectedSuggestion(null)
                        setResponseScreen(false)
                    }}
                />

                {!selectedSuggestion ? (
                    <input
                        type='text'
                        className='flex-sm-grow-1 text-cmGrey700 border-0'
                        placeholder='Write with Ai or select from below'
                        style={{fontWeight: 600, fontSize: 14}}
                        onChange={(e) => setSearch(e?.target?.value)}
                        value={search}
                        ref={textRef}
                    />
                ) : (
                    <div
                        className='flex-grow-1 text-cmGrey700'
                        style={{fontWeight: 600, fontSize: 14}}
                        onClick={clearSelection}
                    >
                        {selectedSuggestion}
                    </div>
                )}

                <KTSVG
                    path={`/media/icons/duotune/art/sendPrompt.svg`}
                    svgClassName='w-35px h-35px cursor-pointer'
                    onClick={() => getAIFormat(selectedSuggestion)}
                />
            </div>
            {!responseScreen ? (
                <div className='d-flex flex-column overflow-auto mh-200px'>
                    {suggestionList?.map((i) => (
                        <div
                            onClick={() => handleSuggestions(i?.feature)}
                            className='d-flex align-items-center gap-2 py-5 px-10 bg-hover-cmGrey100 text-cmGrey800 text-hover-dark'
                            style={{fontWeight: 600}}
                            key={i?.id}
                        >
                            <KTSVG
                                path={`/media/icons/duotune/art/${i?.icon}.svg`}
                                svgClassName='w-30px h-30px'
                            />
                            <div>{i?.feature}</div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className=' card'>
                    {loading ? (
                        <div className='card-body h-200px d-flex flex-center'>
                            <div className='bg-cmBlue-Crayola p-3 rounded-circle shadow-lg'>
                                <CustomLottie
                                    lottieJson={AILoadingWhite}
                                    height={73}
                                    width={73}
                                    loop={true}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className='card-body p-0'>
                            <div className='card h-100 rounded-0 p-0'>
                                <div
                                    className='card-body px-md-10 px-5 py-2 text-cmGrey700 mh-275px overflow-auto'
                                    style={{fontSize: 12, fontWeight: 500, whiteSpace: 'pre-wrap'}}
                                >
                                    {responseScreen}
                                </div>
                                <div className='card-footer border-0  px-md-10 px-5 py-2'>
                                    {confirmAction ? (
                                        <div
                                            className='border border-2 border-cmBlue-Crayola p-3 d-flex align-items-center justify-content-between flex-wrap gap-2'
                                            style={{borderRadius: 10}}
                                        >
                                            <div>Do you want to delete your response?</div>
                                            <div className='d-flex flex-center gap-5'>
                                                <CustomButton
                                                    buttonLabel='No'
                                                    className={'py-2 px-sm-10'}
                                                    onClick={() => setConfirmAction(false)}
                                                    buttonType={BUTTON_TYPE.primaryBorder}
                                                />
                                                <CustomButton
                                                    buttonLabel='Yes'
                                                    className={'py-2 px-sm-10'}
                                                    onClick={() => {
                                                        setConfirmAction(false)
                                                        clearSelection()
                                                        onSequiAiPress()
                                                    }}
                                                    buttonType={BUTTON_TYPE.secondary}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='d-flex justify-content-end align-items-center gap-5'>
                                            <CustomDelete
                                                height={30}
                                                width={30}
                                                onClick={() => setConfirmAction(true)}
                                            />
                                            <KTSVG
                                                path={`/media/icons/duotune/art/refresh.svg`}
                                                svgClassName='w-30px h-30px cursor-pointer'
                                                onClick={() => getAIFormat(selectedSuggestion)}
                                            />
                                            <KTSVG
                                                path={`/media/icons/duotune/art/CopyButtonSVG.svg`}
                                                svgClassName='w-30px h-30px cursor-pointer'
                                                onClick={() =>
                                                    navigator.clipboard.writeText(responseScreen)
                                                }
                                            />
                                            <CustomButton
                                                onClick={onInsertPress}
                                                buttonLabel='Insert'
                                                className={'py-2'}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className='border-top border-1 m-0 card-footer border-cmGrey400 w-100 px-10 py-5 d-flex justify-content-between'>
                        <div
                            className='text-cmBlue-Crayola'
                            style={{fontWeight: 400, fontSize: 15}}
                        >
                            Sequi
                            <span className='text-cmBlack' style={{fontWeight: 600, fontSize: 16}}>
                                Ai
                            </span>
                        </div>
                        <div className='d-flex align-items-center gap-1'>
                            <KTSVG
                                path={`/media/icons/duotune/art/TumbLike.svg`}
                                svgClassName='w-20px h-20px cursor-pointer'
                                className='bg-hover-cmGrey100 p-1 rounded-circle '
                            />
                            <KTSVG
                                path={`/media/icons/duotune/art/ThumbDislike.svg`}
                                svgClassName='w-20px h-20px cursor-pointer'
                                className='bg-hover-cmGrey100 p-1 rounded-circle '
                            />
                        </div>
                    </div>
                </div>
            )}
            {!responseScreen ? (
                <div className='border-top border-1 m-0 card-footer border-cmGrey400 w-100 px-10 py-2 d-flex justify-content-between align-items-center'>
                    <div className='text-cmBlue-Crayola' style={{fontWeight: 400, fontSize: 15}}>
                        Sequi
                        <span className='text-cmBlack' style={{fontWeight: 600, fontSize: 16}}>
                            Ai
                        </span>
                    </div>
                    <CustomButton
                        buttonLabel='Cancel'
                        className={'py-2 px-sm-5'}
                        onClick={() => {
                            if (textRef?.current) textRef.current.value = ''
                            clearSelection()
                            onSequiAiPress()
                        }}
                        buttonType={BUTTON_TYPE.disabled}
                        icon={<div className='bg-sqGray200 rounded px-1 text-xs-semibold'>ESC</div>}
                    />
                </div>
            ) : null}
        </div>
    )
}

export default AIInputBlock
