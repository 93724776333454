import React from 'react'
import CustomIterator from '../customIterator/CustomIterator'
import CustomSkeleton from './CustomSkeleton'

const CustomTableSkeleton = ({rows = 10, coloums = 5, colSpan = null, width = 100}) => {
    return (
        <CustomIterator count={rows}>
            <tr className='stripRow '>
                <CustomIterator count={coloums}>
                    <td className='p-5' colSpan={colSpan}>
                        <CustomSkeleton className={`h-15px w-${width}`} />
                    </td>
                </CustomIterator>
            </tr>
        </CustomIterator>
    )
}

export default CustomTableSkeleton
