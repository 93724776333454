import OtpInput from 'react-otp-input'
import Show from '../show/Show'

const CustomOTPInput = ({otpDigit = 6, otp, setOtp = () => {}, errorMessage = ''}) => {
    return (
        <>
            <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={otpDigit}
                shouldAutoFocus
                placeholder={new Array(otpDigit).fill('•')?.join('')}
                renderSeparator={<span className='mx-1' />}
                renderInput={(props) => <input {...props} />}
                inputType='number'
                inputStyle={{
                    display: 'flex',
                    flex: 1,
                    border: `2px solid ${errorMessage ? '#f64e60' : '#E0E0E0'}`,
                    borderRadius: '8px',
                    fontFamily: 'Manrope',
                    height: '55px',
                    color: '#616162',
                    fontSize: '36px',
                    lineHeight: '60px',
                    fontWeight: '500',
                }}
            />
            <Show>
                <Show.When isVisible={errorMessage ? true : false}>
                    <div
                        className='p-error ms-2 mt-2 text-cmError'
                        style={{fontSize: '11px', fontFamily: 'Manrope', fontWeight: 500}}
                    >
                        {errorMessage}
                    </div>
                </Show.When>
            </Show>
        </>
    )
}

export default CustomOTPInput
