import storage from 'redux-persist/lib/storage'
import {encryptTransform} from 'redux-persist-transform-encrypt'
import {SEQUIFI_ENCRYPTION_KEY} from '../../constants/constants'

const encryptor = encryptTransform({
    secretKey: SEQUIFI_ENCRYPTION_KEY,
    onError: function (error) {
        // Handle the error.
    },
})

const ReduxPersistConfig = {
    key: 'root',
    version: 1,
    storage,
    whitelist: [
        'auth',
        'setting',
        'integration',
        'requestApproval',
        'permissions',
        'filter',
        'excelImport',
    ],
    transforms: [encryptor],
}

export default ReduxPersistConfig
