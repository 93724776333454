import React from 'react'
import Chart from 'react-apexcharts'
import CustomSkeleton from '../../../../../../customComponents/customSkeleton/CustomSkeleton'
import CustomIterator from '../../../../../../customComponents/customIterator/CustomIterator'

const CustomOfficeSalesRadialChart = ({
    TotalHeadValue,
    TotalHeadName,
    MajorValueName,
    MajorValue,
    MinorValueName,
    MinorValue,
    MajorValueColor,
    progressColor,
    nonProgressColor,
    percentage,
    loading,
}) => {
    const chartData = {
        series: [percentage],

        options: {
            chart: {
                type: 'radialBar',
            },

            plotOptions: {
                radialBar: {
                    size: '20%',
                    hollow: {
                        margin: 15,
                        size: '60%',
                    },
                    dataLabels: {
                        value: {
                            fontSize: '22px',
                            show: false,
                        },
                        name: {
                            show: true,
                        },

                        total: {
                            show: true,
                            label: `${percentage}%`,
                            fontSize: '24px',
                            fontWeight: 700,
                            fontFamily: 'Manrope',
                        },
                    },
                    track: {
                        background: nonProgressColor,
                    },
                },
            },
            colors: [progressColor],

            stroke: {
                lineCap: 'round', // Add rounded line cap effect
            },
        },
    }
    return (
        <div style={{fontFamily: 'Manrope'}} className='text-center'>
            {/* Heding */}
            <div>
                <div className='text-cmGrey600' style={{fontSize: '12px', fontWeight: 600}}>
                    {TotalHeadName}
                </div>
                <div className='text-cmGrey900' style={{fontSize: '14px', fontWeight: 700}}>
                    {TotalHeadValue}
                </div>
            </div>
            <div className='w-sm-225px h-sm-225px mx-auto p-0 m-0'>
                {loading ? (
                    <CustomSkeleton isCircle={true} className={'h-150px w-150px my-10'} />
                ) : (
                    <Chart
                        options={chartData.options}
                        series={chartData.series}
                        track={chartData.track}
                        type='radialBar'
                        height='100%'
                    />
                )}
            </div>
            {/* Labels */}
            <div className='d-flex flex-column flex-center'>
                {/* Line 1 */}

                <div className='d-flex align-items-center gap-3 mb-5'>
                    <div className={`bg-${MajorValueColor}  rounded h-25px w-25px`} />
                    {/* Title */}
                    <div>
                        <div style={{fontSize: 12, fontWeight: 600}}>
                            {loading ? (
                                <CustomSkeleton className={'h-15px w-100px'} />
                            ) : (
                                MajorValueName
                            )}
                        </div>
                        <div className='text-cmGrey900 text-start' style={{fontWeight: 700}}>
                            {loading ? <CustomSkeleton className={'h-15px w-25px'} /> : MajorValue}
                        </div>
                    </div>
                </div>

                {/* Line 1 */}

                <div className='d-flex align-items-center gap-3 mb-5'>
                    <div className={`bg-${MajorValueColor} bg-opacity-20 rounded h-25px w-25px`} />
                    {/* Title */}
                    <div className=''>
                        <div className='' style={{fontSize: '12px', fontWeight: 600}}>
                            {loading ? (
                                <CustomSkeleton className={'h-15px w-100px'} />
                            ) : (
                                MinorValueName
                            )}
                        </div>
                        <div className='text-cmGrey900 text-start' style={{fontWeight: 700}}>
                            {loading ? <CustomSkeleton className={'h-15px w-25px'} /> : MinorValue}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomOfficeSalesRadialChart

const LoaderForLabel = () => {
    return <CustomSkeleton className={'h-15px w-50px'} />
}
