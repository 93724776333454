import React from 'react'
import {KTSVG} from '../../_metronic/helpers'

const CustomEditIcon = ({id, onClick, svgClassName = 'w-35px h-35px'}) => {
    return (
        <div id={id}>
            <KTSVG
                path='/media/icons/duotune/art/EditIcon.svg'
                className='cursor-pointer'
                onClick={onClick}
                svgClassName={svgClassName}
            />
        </div>
    )
}

export default CustomEditIcon
