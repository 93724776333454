import {useCallback, useEffect, useState} from 'react'
import {fontsFamily} from '../../../../../assets/fonts/fonts'
import {
    dashboardActionItemStatusChangeService,
    getDashboardActionItemsListService,
} from '../../../../../services/Services'
import {getErrorMessageFromResponse, sendDataToReducer} from '../../../../../helpers/CommonHelpers'
import CustomToast from '../../../../../customComponents/customToast/CustomToast'
import {KTSVG} from '../../../../../_metronic/helpers'
import {useNavigate} from 'react-router-dom'
import CustomSkeleton from '../../../../../customComponents/customSkeleton/CustomSkeleton'
import CustomIterator from '../../../../../customComponents/customIterator/CustomIterator'
import Show from '../../../../../customComponents/show/Show'
import {useDispatch, useSelector} from 'react-redux'
import {
    SET_MYSALES_FILTER_DATA,
    SET_REQAPPROVAL_FILTER_DATA,
} from '../../../../../redux/reducers/FilterReducer'
import {
    getMySalesFilterDataSelector,
    getReqApprovalFilterDataSelector,
} from '../../../../../redux/selectors/FilterSelectors'

const ActionItemsCard = () => {
    const [listData, setlistData] = useState(null)

    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    useEffect(() => {
        getListData()
    }, [])

    const getListData = () => {
        getDashboardActionItemsListService()
            .then((res) => {
                setlistData(res?.data)
            })
            .catch((e) => CustomToast.error(getErrorMessageFromResponse(e)))
            .finally(() => setLoading(false))
    }

    const changeItemReadUnredStatus = useCallback(
        (bodyData) =>
            new Promise((resolve, reject) => {
                const body = {
                    id: bodyData?.id,
                    user_id: bodyData?.user_id,
                    type: bodyData?.type,
                }
                dashboardActionItemStatusChangeService(body)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            }),
        []
    )

    return (
        <div className='sqcard-shadow bg-cmwhite py-18px' style={{borderRadius: 10}}>
            <div className='text-cmGrey900 pb-6 px-18px text-md-bold'>
                Action Items({listData?.total ?? '-'})
            </div>
            {/* body */}
            {loading ? (
                <CustomIterator count={6}>
                    <div className='px-18px d-flex gap-3 align-items-start py-3 '>
                        <div className={`h-30px w-30px`}>
                            <CustomSkeleton className={'h-100 w-100'} />
                        </div>
                        <div>
                            <div>
                                <CustomSkeleton className={'h-15px w-100px'} />
                            </div>
                            <div>
                                <CustomSkeleton className={'h-10px w-250px'} />
                            </div>
                        </div>
                    </div>
                </CustomIterator>
            ) : (
                <div className='h-sm-580px overflow-auto'>
                    <Show>
                        <Show.When isVisible={listData?.document_sign_review?.length > 0}>
                            <SignandReviewList
                                data={listData?.document_sign_review}
                                icon={'office-1'}
                                iconBackground='cmBlue-Crayola'
                                navigate={navigate}
                                changeItemReadUnredStatus={changeItemReadUnredStatus}
                            />
                        </Show.When>
                    </Show>

                    <Show>
                        <Show.When isVisible={listData?.missing_data?.length > 0}>
                            <MissingDataList
                                data={listData?.missing_data}
                                iconBackground={'cmError'}
                                icon={'missing-setter'}
                                navigate={navigate}
                                changeItemReadUnredStatus={changeItemReadUnredStatus}
                            />
                        </Show.When>
                    </Show>

                    <Show>
                        <Show.When isVisible={listData?.new_request?.length > 0}>
                            <NewRequestDataList
                                data={listData?.new_request}
                                icon={'reimbursement-icon'}
                                iconBackground={'cmSuccess'}
                                navigate={navigate}
                                changeItemReadUnredStatus={changeItemReadUnredStatus}
                            />
                        </Show.When>
                    </Show>

                    <Show>
                        <Show.When isVisible={listData?.tax_information?.length > 0}>
                            <TaxInformationList
                                data={listData?.tax_information}
                                icon={'reimbursement-icon'}
                                iconBackground={'cmSuccess'}
                                navigate={navigate}
                                changeItemReadUnredStatus={changeItemReadUnredStatus}
                            />
                        </Show.When>
                    </Show>

                    <Show>
                        <Show.When
                            isVisible={listData?.acknowledge_data?.position_data?.length > 0}
                        >
                            <AcknowledgeDataList
                                data={listData?.acknowledge_data?.position_data}
                                navigate={navigate}
                                changeItemReadUnredStatus={changeItemReadUnredStatus}
                            />
                        </Show.When>
                    </Show>

                    <Show>
                        <Show.When
                            isVisible={listData?.acknowledge_data?.commssion_data?.length > 0}
                        >
                            <AcknowledgeDataList
                                data={listData?.acknowledge_data?.commssion_data}
                                navigate={navigate}
                                changeItemReadUnredStatus={changeItemReadUnredStatus}
                            />
                        </Show.When>
                    </Show>

                    <Show>
                        <Show.When
                            isVisible={listData?.acknowledge_data?.override_data?.length > 0}
                        >
                            <AcknowledgeDataList
                                data={listData?.acknowledge_data?.override_data}
                                navigate={navigate}
                                changeItemReadUnredStatus={changeItemReadUnredStatus}
                            />
                        </Show.When>
                    </Show>

                    <Show>
                        <Show.When isVisible={listData?.acknowledge_data?.redline_data?.length > 0}>
                            <AcknowledgeDataList
                                data={listData?.acknowledge_data?.redline_data}
                                navigate={navigate}
                                changeItemReadUnredStatus={changeItemReadUnredStatus}
                            />
                        </Show.When>
                    </Show>

                    <Show>
                        <Show.When isVisible={listData?.acknowledge_data?.upfront_data?.length > 0}>
                            <AcknowledgeDataList
                                data={listData?.acknowledge_data?.upfront_data}
                                navigate={navigate}
                                changeItemReadUnredStatus={changeItemReadUnredStatus}
                            />
                        </Show.When>
                    </Show>

                    <Show>
                        <Show.When
                            isVisible={listData?.acknowledge_data?.withheld_data?.length > 0}
                        >
                            <AcknowledgeDataList
                                data={listData?.acknowledge_data?.withheld_data}
                                navigate={navigate}
                                changeItemReadUnredStatus={changeItemReadUnredStatus}
                            />
                        </Show.When>
                    </Show>
                    <Show>
                        <Show.When isVisible={listData?.hiring_accepted?.length > 0}>
                            <HiringAcceptedList
                                data={listData?.hiring_accepted}
                                navigate={navigate}
                                changeItemReadUnredStatus={changeItemReadUnredStatus}
                            />
                        </Show.When>
                    </Show>
                </div>
            )}
        </div>
    )
}

export default ActionItemsCard

const SignandReviewList = ({data, icon, iconBackground, navigate, changeItemReadUnredStatus}) => {
    const onItemClick = (i) => {
        changeItemReadUnredStatus(i).then((res) => {
            navigate(`/user/document?employeeId=${i?.user_id}`, {
                state: {employee_id: i?.user_id},
            })
        })
    }
    return (
        <>
            {data?.map((i, index) => (
                <div
                    id={`Action_Item_SignandReview_${index}`}
                    className='px-18px d-flex gap-3 align-items-center py-3  cursor-pointer bg-hover-cmGrey100'
                    key={index}
                    onClick={() => onItemClick(i)}
                >
                    <div
                        className={`h-30px w-30px rounded bg-${iconBackground} bg-opacity-10 d-flex flex-center`}
                    >
                        <KTSVG
                            path={`/media/icons/duotune/art/${icon}.svg`}
                            svgClassName='w-20px h-20px'
                        />
                    </div>
                    <div>
                        <div className='text-sqGray900 text-md-bold'>{i?.title}</div>
                        <div className='text-seGray600 text-sm-semibold'>{i?.status}</div>
                    </div>
                </div>
            ))}
        </>
    )
}
const MissingDataList = ({data, iconBackground, icon, navigate, changeItemReadUnredStatus}) => {
    const dispatch = useDispatch()
    const mySalesFilterData = useSelector(getMySalesFilterDataSelector)

    const onItemClick = (i) => {
        changeItemReadUnredStatus(i).then((res) => {
            sendDataToReducer(dispatch, SET_MYSALES_FILTER_DATA, {
                mysales: {
                    ...mySalesFilterData?.mySales,
                    search: i?.pid,
                    m1: '',
                    m2: '',
                    closed: '',
                    page: 1,
                },
            })
            navigate(`/mysales/sales`)
        })
    }

    return (
        <>
            {data?.map((i, index) => (
                <div
                    id={`Action_Item_Missing_Data_${index}`}
                    className='px-18px d-flex gap-3 align-items-center py-3  cursor-pointer bg-hover-cmGrey100'
                    key={index}
                    onClick={() => onItemClick(i)}
                >
                    <div
                        className={`h-30px w-30px rounded bg-${iconBackground} bg-opacity-10 d-flex flex-center`}
                    >
                        <KTSVG
                            path={`/media/icons/duotune/art/${icon}.svg`}
                            svgClassName='w-20px h-20px'
                        />
                    </div>
                    <div>
                        <div className='text-sqGray900 text-md-bold'>
                            {i?.pid} - {i?.title}
                        </div>
                        <div className='text-sqGray600 text-sm-semibold'>{i?.status}</div>
                    </div>
                </div>
            ))}
        </>
    )
}
const NewRequestDataList = ({data, icon, iconBackground, navigate, changeItemReadUnredStatus}) => {
    const dispatch = useDispatch()
    const reqApprovalFilterData = useSelector(getReqApprovalFilterDataSelector)

    const onItemClick = (i) => {
        changeItemReadUnredStatus(i).then((res) => {
            sendDataToReducer(dispatch, SET_REQAPPROVAL_FILTER_DATA, {
                approval: {
                    ...reqApprovalFilterData?.approval,
                    search: i?.req_no,
                    filter: {
                        status: '',
                        type: '',
                    },
                    apiType: 'approval',
                    page: 1,
                },
            })
            navigate('/requests/approvals')
        })
    }
    return (
        <>
            {data?.map((i, index) => (
                <div
                    id={`Action_Item_New_Request_Data_${index}`}
                    className='px-18px d-flex gap-3 align-items-center py-3  cursor-pointer bg-hover-cmGrey100'
                    key={index}
                    onClick={() => onItemClick(i)}
                >
                    <div
                        className={`h-30px w-30px rounded bg-${iconBackground} bg-opacity-10 d-flex flex-center`}
                    >
                        <KTSVG
                            path={`/media/icons/duotune/art/${icon}.svg`}
                            svgClassName='w-20px h-20px'
                        />
                    </div>
                    <div>
                        <div className='text-sqGray900 text-md-bold'>
                            {i?.req_no} - {i?.title}
                        </div>
                        <div className='text-sqGray600 text-sm-semibold'>{i?.status}</div>
                    </div>
                </div>
            ))}
        </>
    )
}

const TaxInformationList = ({data, icon, iconBackground, navigate, changeItemReadUnredStatus}) => {
    const onItemClick = (i) => {
        changeItemReadUnredStatus(i).then((res) => {
            navigate(`/user/tax-info?employeeId=${i?.user_id}`, {
                state: {employee_id: i?.user_id},
            })
        })
    }
    return (
        <>
            {data?.map((i, index) => (
                <div
                    id={`Action_Item_New_Request_Data_${index}`}
                    className='px-18px d-flex gap-3 align-items-center py-3  cursor-pointer bg-hover-cmGrey100'
                    key={index}
                    onClick={() => onItemClick(i)}
                >
                    <div
                        className={`h-30px w-30px rounded bg-${iconBackground} bg-opacity-10 d-flex flex-center`}
                    >
                        <KTSVG
                            path={`/media/icons/duotune/art/${icon}.svg`}
                            svgClassName='w-20px h-20px'
                        />
                    </div>
                    <div>
                        <div className='text-sqGray900 text-md-bold'>
                            {i?.req_no} - {i?.title}
                        </div>
                        <div className='text-sqGray600 text-sm-semibold'>{i?.status}</div>
                    </div>
                </div>
            ))}
        </>
    )
}
const AcknowledgeDataList = ({data, icon, iconBackground, navigate, changeItemReadUnredStatus}) => {
    const onItemClick = (i) => {
        changeItemReadUnredStatus(i).then((res) => {
            navigate(`/user/employment-package?employeeId=${i?.user_id}&type=${i?.type}`)
        })
    }
    return (
        <>
            {data?.map((i, index) => (
                <div
                    id={`Action_Item_Acknowledge_Data_${index}`}
                    className='px-18px d-flex gap-3 align-items-center py-3  cursor-pointer bg-hover-cmGrey100'
                    key={index}
                    onClick={() => onItemClick(i)}
                >
                    <div
                        className={`h-30px w-30px rounded bg-cmgreen bg-opacity-10 d-flex flex-center`}
                    >
                        <KTSVG
                            path={`/media/icons/duotune/art/members.svg`}
                            svgClassName='w-20px h-20px'
                        />
                    </div>
                    <div>
                        <div className='text-sqGray900 text-md-bold'>{i?.title}</div>
                        <div className='text-sqGray600 text-sm-semibold'>{i?.type}</div>
                    </div>
                </div>
            ))}
        </>
    )
}

const HiringAcceptedList = ({data, icon, iconBackground, navigate, changeItemReadUnredStatus}) => {
    const onItemClick = (i) => {
        changeItemReadUnredStatus(i).then((res) => {
            navigate('/onboard-employe-profile/employement-package', {
                state: {
                    id: i?.user_id,
                    moduleType: 'onboarding',
                },
            })
        })
    }
    return (
        <>
            {data?.map((i, index) => (
                <div
                    id={`Action_Item_Acknowledge_Data_${index}`}
                    className='px-18px d-flex gap-3 align-items-center py-3  cursor-pointer bg-hover-cmGrey100'
                    key={index}
                    onClick={() => onItemClick(i)}
                >
                    <div
                        className={`h-30px w-30px rounded bg-cmgreen bg-opacity-10 d-flex flex-center`}
                    >
                        <KTSVG
                            path={`/media/icons/duotune/art/members.svg`}
                            svgClassName='w-20px h-20px'
                        />
                    </div>
                    <div>
                        <div className='text-sqGray900 text-md-bold'>{i?.title}</div>
                        <div className='text-sqGray600 text-sm-semibold'>{i?.type}</div>
                    </div>
                </div>
            ))}
        </>
    )
}
