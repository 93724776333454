import React, {useCallback, useEffect, useState} from 'react'
import QuikLinksCard from './components/QuikLinksCard'
import PayrollSummaryCard from './components/payrollSummary/PayrollSummaryCard'
import OfficeSalesPerformanceCard from './components/officeSalesPerformance/OfficeSalesPerformanceCard'
import AlertSectionCard from './components/alertSection/AlertSectionCard'
import AnnouncementsCard from './components/anouncements/AnnouncementsCard'
import {getValidDate} from '../../../../constants/constants'
import {getUserDataSelector} from '../../../../redux/selectors/AuthSelectors'
import {useDispatch, useSelector} from 'react-redux'
import {fontsFamily} from '../../../../assets/fonts/fonts'
import CustomButton, {
    BUTTON_SIZE,
    BUTTON_TYPE,
} from '../../../../customComponents/customButtton/CustomButton'
import {resetAppService} from '../../../../services/Services'
import CustomToast from '../../../../customComponents/customToast/CustomToast'
import {logoutAction} from '../../../../redux/actions/AuthActions'
import CustomDialog from '../../../../customComponents/customDialog/CustomDialog'
import {getAllNotification} from '../../../../redux/actions/SettingActions'
import useAPI from '../../../../hooks/useAPI'
import useSequifiConfig from '../../../../sequiConfig/useSequifiConfig'
import {KTSVG} from '../../../../_metronic/helpers'
import {getAdminDashboardPayrollSelector} from '../../../../redux/selectors/DashboardSelectors'
import {useNavigate} from 'react-router'
import {Button} from 'primereact/button'
import {InputText} from 'primereact/inputtext'

const AdminDashbaord = () => {
    const [payrollExecuteStatus, setPayrollExecuteStatus] = useState(null)
    const userData = useSelector(getUserDataSelector)
    const payrollData = useSelector(getAdminDashboardPayrollSelector)
    const dispatch = useDispatch()
    const {loading: resetAPILoading, callAPI: callResetAPI} = useAPI()
    const {basedOnHost} = useSequifiConfig()

    const onResetAppClick = useCallback(() => {
        CustomDialog.confirm('Are you sure want to reset app ?', () => {
            callResetAPI(resetAppService())
                .then(() => {
                    CustomToast.success('App Reset Successfully')
                    dispatch(logoutAction())
                })
                .catch(() => {})
        })
    }, [callResetAPI, dispatch])

    useEffect(() => {
        const data = {
            page: 1,
        }
        dispatch(getAllNotification(data)).catch((e) => {})
    }, [])

    return (
        <div style={{fontFamily: fontsFamily.manrope, fontSize: '14px', marginTop: -20}}>
            <div className='text-cmGrey900 mb-8' style={{fontWeight: 600}}>
                <span className='text-cmGrey800'> Last Login:</span>{' '}
                {getValidDate(userData?.lastLogin, 'MM/DD/YYYY [at] hh:mm a') ?? '-'}
            </div>
            <div className='row'>
                <div className='col-xl-8'>
                    {payrollData?.payroll_failed_data?.is_payroll_failed ? (
                        <PaymentFailureBlock payrollData={payrollData?.payroll_failed_data} />
                    ) : null}

                    <QuikLinksCard payrollExecuteStatus={payrollExecuteStatus} />

                    <PayrollSummaryCard setPayrollExecuteStatus={setPayrollExecuteStatus} />

                    <OfficeSalesPerformanceCard />
                </div>
                <div className='col-sm'>
                    <div className='mb-10'>
                        {basedOnHost?.resetApp ? (
                            <CustomButton
                                disabled={resetAPILoading}
                                className='mb-5'
                                buttonLabel={resetAPILoading ? 'Reseting App' : 'Reset App'}
                                onClick={onResetAppClick}
                            />
                        ) : null}
                        <AlertSectionCard />
                    </div>
                    <div>
                        <AnnouncementsCard />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminDashbaord

const PaymentFailureBlock = ({payrollData}) => {
    const navigate = useNavigate()

    const onRedirectToPayroll = () => {
        const state = {
            periodFrom: payrollData?.pay_period_start,
            periodTo: payrollData?.pay_period_end,
            rediret: true,
            frequency_type_name: payrollData?.frequency_type_name,
        }
        navigate('/payroll/run-payroll', {state: state})
    }
    return (
        <div
            className='d-flex justify-content-between align-items-center mb-6 bg-cmError bg-opacity-5 p-5  border-1 border-cmError border-dashed'
            style={{borderRadius: 10}}
        >
            <div>
                <div className='d-flex  gap-2'>
                    <KTSVG
                        path='/media/icons/duotune/art/Red-Warn.svg'
                        svgClassName='w-25px h-25px cursor-pointer'
                    />
                    <div
                        className='text-cmGrey900'
                        style={{fontSize: 16, fontWeight: 700, lineHeight: '21px'}}
                    >
                        Payment Failure Alert
                        <div style={{fontSize: 14, fontWeight: 500, lineHeight: '21px'}}>
                            {payrollData?.payment_failed_count} payments failed for pay period{' '}
                            <b>
                                ({getValidDate(payrollData?.pay_period_start)} -{' '}
                                {getValidDate(payrollData?.pay_period_end)})
                            </b>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <CustomButton
                    buttonSize={BUTTON_SIZE.small}
                    key='move-to-next-payroll-button'
                    buttonType={BUTTON_TYPE.primary}
                    buttonLabel='Review'
                    onClick={onRedirectToPayroll}
                />
            </div>
        </div>
    )
}
