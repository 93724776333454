import React, {useCallback, useEffect, useMemo, useState} from 'react'
import OfficeSalesPerformanceChart from './OfficeSalesPerformanceChart'
import CustomOfficeSalesRadialChart from './CustomOfficeSalesRadialChart'
import {
    getOfficeSalesPeformanceGraphSelector,
    getOfficeSalesPeformanceSelector,
} from '../../../../../../redux/selectors/DashboardSelectors'
import {
    officeSalesPerformanceDataAction,
    officeSalesPerformanceGraphAction,
} from '../../../../../../redux/actions/DashboardActions'
import {useDispatch} from 'react-redux'
import {useSelector} from 'react-redux'
import {getUserDataSelector} from '../../../../../../redux/selectors/AuthSelectors'
import useOfficeLocation from '../../../../../../hooks/useOfficeLocation'
import {
    Dashboard_DURATION_DROPDOWN_LIST,
    REPORTS_DURATION_DROPDOWN_LIST,
} from '../../../../../../constants/constants'
import CustomLoader from '../../../../../../customComponents/customLoader/CustomLoader'
import {
    formattedNumberFields,
    formattedNumberFieldsWithoutDecimal,
    sendDataToReducer,
} from '../../../../../../helpers/CommonHelpers'
import CustomDropdown from '../../../../../../customComponents/customInputs/customDropdown/CustomDropdown'
import CustomSkeleton from '../../../../../../customComponents/customSkeleton/CustomSkeleton'
import CustomIterator from '../../../../../../customComponents/customIterator/CustomIterator'
import {SET_DASHBOARD_FILTER_DATA} from '../../../../../../redux/reducers/FilterReducer'
import {getDashboardFilterDataSelector} from '../../../../../../redux/selectors/FilterSelectors'
import useSequifiConfig from '../../../../../../sequiConfig/useSequifiConfig'
import Show from '../../../../../../customComponents/show/Show'

const OfficeSalesPerformanceCard = () => {
    const dashboardData = useSelector(getDashboardFilterDataSelector)

    const officeSalesPeformanceData = useSelector(getOfficeSalesPeformanceSelector)
    const officeSalesPeformanceGraphData = useSelector(getOfficeSalesPeformanceGraphSelector)
    const [filter, setFilter] = useState(dashboardData?.admin?.office_sales_year_filter)
    const [loading, setLoading] = useState(false)
    const [fullLoading, setFullLoading] = useState(false)
    const loggedUser = useSelector(getUserDataSelector)
    const [officeList, selectedLocation, setSelectedLocation] = useOfficeLocation(
        dashboardData?.admin?.office_sales_location_filter
    )
    const {basedOnCompanyType} = useSequifiConfig()

    const dispatch = useDispatch()

    useEffect(() => {
        sendDataToReducer(dispatch, SET_DASHBOARD_FILTER_DATA, {
            admin: {
                payroll_year_filter: dashboardData?.admin?.payroll_year_filter,
                office_sales_year_filter: filter,
                office_sales_location_filter: selectedLocation,
            },
        })
    }, [filter, selectedLocation])
    useEffect(() => {
        if (selectedLocation) {
            getOfficeSalesPeformanceData()
            getOfficeSalesPeformanceGraphData()
        }
    }, [filter, selectedLocation])

    // useEffect(() => {
    //     getOfficeSalesPeformanceGraphData()
    // }, [filter,])

    const onFilterChange = (e) => {
        setFilter(e.target.value)
    }
    const getOfficeSalesPeformanceData = useCallback(() => {
        if (officeSalesPeformanceData) setLoading(true)
        else setFullLoading(true)
        const body = {
            filter: filter,
            user_id: loggedUser?.id,
            office_id: selectedLocation,
        }

        dispatch(officeSalesPerformanceDataAction(body)).finally(() => {
            if (officeSalesPeformanceData) setLoading(false)
            else setFullLoading(false)
        })
    }, [dispatch, filter, loggedUser?.id, officeSalesPeformanceData, selectedLocation])

    const getOfficeSalesPeformanceGraphData = useCallback(() => {
        if (officeSalesPeformanceData) setLoading(true)
        else setFullLoading(true)
        const body = {
            filter: filter,
            user_id: loggedUser?.id,
            office_id: selectedLocation,
        }

        dispatch(officeSalesPerformanceGraphAction(body)).finally(() => {
            if (officeSalesPeformanceData) setLoading(false)
            else setFullLoading(false)
        })
    }, [dispatch, filter, loggedUser?.id, officeSalesPeformanceData, selectedLocation])
    const accountInstalledPercentage = useMemo(() => {
        const value =
            officeSalesPeformanceGraphData?.totalSales > 0
                ? (officeSalesPeformanceGraphData?.install_account * 100) /
                  officeSalesPeformanceGraphData?.totalSales
                : 0
        return formattedNumberFieldsWithoutDecimal(value, '')
    }, [officeSalesPeformanceGraphData])

    const kwInstalledPercentage = useMemo(() => {
        const value =
            officeSalesPeformanceGraphData?.totalKw > 0
                ? (officeSalesPeformanceGraphData?.install_kw * 100) /
                  officeSalesPeformanceGraphData?.totalKw
                : 0
        return formattedNumberFieldsWithoutDecimal(value, '')
    }, [officeSalesPeformanceGraphData])

    const onLocationChange = (e) => {
        setSelectedLocation(e.target.value)
    }

    const thisLastValueDisplayKey = useMemo(() => {
        let text = ''
        switch (filter) {
            case 'this_week':
                text = 'Week'
                break
            case 'this_year':
                text = 'Year'
                break
            case 'this_month':
                text = 'Month'
                break
            case 'this_quarter':
                text = 'Quarter'
                break
            default:
            // code block
        }
        return text
    }, [filter])

    return (
        <div className='bg-cmwhite shadow-sm px-5 py-5' style={{borderRadius: '10px'}}>
            <div className='mb-5'>
                <div
                    className='d-flex justify-content-between text-cmGrey900 mb-5'
                    style={{fontWeight: 600, fontSize: '16px'}}
                >
                    Office Sales Performance
                </div>

                <div className='d-flex align-items-center justify-content-between gap-3 flex-wrap mb-5'>
                    <div className='d-flex align-items-center gap-3'>
                        <div>
                            <CustomDropdown
                                id={'admin_dashboard_officeSalesPerformance_timePeriod'}
                                options={Dashboard_DURATION_DROPDOWN_LIST}
                                onChange={onFilterChange}
                                value={filter}
                                showClear={false}
                                searching={false}
                            />
                        </div>

                        <div>
                            <CustomDropdown
                                id={'admin_dashboard_officeSalesPerformance_offices'}
                                options={officeList}
                                onChange={onLocationChange}
                                value={selectedLocation}
                                showClear={false}
                                searching={false}
                            />{' '}
                        </div>
                    </div>
                    <div className='d-flex align-items-center gap-3'>
                        <div className='d-flex align-items-center gap-3'>
                            <div className='h-10px w-25px bg-cmBlue-Crayola'></div>{' '}
                            <div
                                className='text-cmGrey900'
                                style={{fontWeight: 600, fontSize: '12px'}}
                            >
                                This {thisLastValueDisplayKey}
                            </div>
                        </div>
                        <div className='d-flex align-items-center gap-3'>
                            <div className='h-10px w-25px bg-cmGrey400'></div>{' '}
                            <div
                                className='text-cmGrey900'
                                style={{fontWeight: 600, fontSize: '12px'}}
                            >
                                Last {thisLastValueDisplayKey}
                            </div>
                        </div>
                    </div>
                </div>
                {/* Chart begins */}
                {loading || fullLoading ? (
                    <div className='d-flex justify-content-evenly align-items-end  h-275px border-bottom border-cmGrey100'>
                        <CustomIterator count={5}>
                            <CustomSkeleton className={'h-225px w-20px'} />
                            <CustomSkeleton className={'h-150px w-20px'} />
                        </CustomIterator>
                    </div>
                ) : (
                    <div>
                        <OfficeSalesPerformanceChart
                            chartData={officeSalesPeformanceData}
                            thisLastValueDisplayKey={thisLastValueDisplayKey}
                        />
                    </div>
                )}
            </div>

            <div className='row gap-sm-0 gap-10'>
                <div className='col-sm'>
                    <CustomOfficeSalesRadialChart
                        loading={loading}
                        TotalHeadName='Total Sales'
                        TotalHeadValue={`${officeSalesPeformanceGraphData?.totalSales} Accounts`}
                        MajorValueName={
                            <Show>
                                <Show.When isVisible={basedOnCompanyType?.isCompanyTypePest}>
                                    {`Accounts Serviced`}
                                </Show.When>
                                <Show.Else>{`Accounts Installed`}</Show.Else>
                            </Show>
                        }
                        MajorValue={officeSalesPeformanceGraphData?.install_account}
                        MinorValueName='Accounts Pending'
                        MinorValue={officeSalesPeformanceGraphData?.pending_account}
                        MajorValueColor='cmSuccess'
                        progressColor={'#00c247'}
                        nonProgressColor={'#CCF3DA'}
                        percentage={accountInstalledPercentage}
                    />
                </div>
                <hr className='d-sm-none d-block' />
                <div className='col-sm'>
                    <Show>
                        <Show.When isVisible={basedOnCompanyType?.isCompanyTypePest}>
                            <CustomOfficeSalesRadialChart
                                loading={loading}
                                TotalHeadName={`Total Gross Value`}
                                TotalHeadValue={`${formattedNumberFieldsWithoutDecimal(
                                    officeSalesPeformanceGraphData?.totalKw,
                                    '$'
                                )}`}
                                MajorValueName={`Gross Value Serviced`}
                                MajorValue={formattedNumberFieldsWithoutDecimal(
                                    officeSalesPeformanceGraphData?.install_kw,
                                    ''
                                )}
                                MinorValueName={`Gross Value Pending`}
                                MinorValue={formattedNumberFieldsWithoutDecimal(
                                    officeSalesPeformanceGraphData?.pending_kw,
                                    ''
                                )}
                                MajorValueColor='cmOrange'
                                progressColor={'#f29913'}
                                nonProgressColor={'#FFEFD8'}
                                percentage={kwInstalledPercentage}
                            />
                        </Show.When>
                        <Show.Else>
                            {' '}
                            <CustomOfficeSalesRadialChart
                                loading={loading}
                                TotalHeadName={`Total ${basedOnCompanyType?.primaryUnitType}`}
                                TotalHeadValue={`${formattedNumberFieldsWithoutDecimal(
                                    officeSalesPeformanceGraphData?.totalKw,
                                    ''
                                )} ${basedOnCompanyType?.primaryUnitType}`}
                                MajorValueName={`${basedOnCompanyType?.primaryUnitType} Installed`}
                                MajorValue={formattedNumberFieldsWithoutDecimal(
                                    officeSalesPeformanceGraphData?.install_kw,
                                    ''
                                )}
                                MinorValueName={`${basedOnCompanyType?.primaryUnitType} Pending`}
                                MinorValue={formattedNumberFieldsWithoutDecimal(
                                    officeSalesPeformanceGraphData?.pending_kw,
                                    ''
                                )}
                                MajorValueColor='cmOrange'
                                progressColor={'#f29913'}
                                nonProgressColor={'#FFEFD8'}
                                percentage={kwInstalledPercentage}
                            />
                        </Show.Else>
                    </Show>
                </div>
            </div>
        </div>
    )
}

export default OfficeSalesPerformanceCard

const loaderforRadialChart = () => {
    return (
        <div>
            <CustomSkeleton isCircle={true} className={'h-50px w-50px'} />
        </div>
    )
}
