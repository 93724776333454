import {confirmDialog} from 'primereact/confirmdialog' // To use confirmDialog method
import 'primereact/confirmdialog'

const CustomDialog = {
    warn: (
        title = 'Are you sure ?',
        onAcceptPress = () => {},
        message = 'Confirmation',
        onRejectPress = () => {}
    ) => {
        confirmDialog({
            header: message,
            message: title,
            icon: 'pi pi-exclamation-triangle',
            accept: onAcceptPress,
            reject: onRejectPress,
            closable: false,
            appendTo: document.body,
            style: {zIndex: 10000, maxWidth: '750px'},
        })
    },
    confirm: (
        title = 'Are you sure ?',
        onAcceptPress = () => {},
        onRejectPress = () => {},
        header = 'Confirmation'
    ) => {
        confirmDialog({
            header: header,
            message: title,
            icon: 'pi pi-exclamation-triangle',
            accept: onAcceptPress,
            reject: onRejectPress,
            closable: false,
            appendTo: document.body,
            style: {zIndex: 10000, maxWidth: '750px'},
        })
    },
}

export default CustomDialog
