import {useEffect, useState} from 'react'
import SalesReportCard from './salesReport/SalesReportCard'
import GoalTracker from './goalTraker/GoalTracker'
import QuickLinksCardStandard from './quickLinksStd/QuickLinksCardStandard'
import AnnouncementsCardStandard from './announcementsStd/AnnouncementsCardStandard'
import OfficePerformaceCard from './officePerformanceStd/OfficePerformaceCard'
import DashBoardCalendarCard from './dashboardCalendar/DashBoardCalendarCard'
import {useSelector} from 'react-redux'
import {getUserDataSelector} from '../../../../redux/selectors/AuthSelectors'
import {getValidDate} from '../../../../constants/constants'
import AccessRights from '../../../../accessRights/AccessRights'
import ActionItemsCard from './actionItems/ActionItemsCard'
import {getDashboardFilterDataSelector} from '../../../../redux/selectors/FilterSelectors'
import useSequifiConfig from '../../../../sequiConfig/useSequifiConfig'
import Show from '../../../../customComponents/show/Show'
import CustomIterator from '../../../../customComponents/customIterator/CustomIterator'
import CustomSkeleton from '../../../../customComponents/customSkeleton/CustomSkeleton'

const StandardDashboard = () => {
    const dashboardData = useSelector(getDashboardFilterDataSelector)
    const [filter, setFilter] = useState(dashboardData?.standard?.sales_report)
    const userData = useSelector(getUserDataSelector)
    const {basedOnHost, moveCodeToServers} = useSequifiConfig()

    useEffect(() => {
        // moveCodeToServers('frontend-live', 'live')
    }, [moveCodeToServers])

    return (
        <div style={{fontSize: '14px', marginTop: '-35px'}} className=''>
            <div className='d-flex flex-sm-row flex-column  align-items-sm-center justify-content-between mb-30px flex-wrap'>
                <div className='text-sqGray900 text-lg-bold'>Dashboard</div>
                <div className='text-cmGrey900 text-sm-semibold'>
                    <span className='text-sqGray700'> Last Login:</span>
                    <span className='text-sqGray900'>
                        {' '}
                        {getValidDate(userData?.lastLogin, 'MM/DD/YYYY [at] hh:mm a') ?? '-'}
                    </span>
                </div>
            </div>
            <div className='d-flex flex-wrap gap-18px justify-content-between gap-18px'>
                <div className='w-xl-60 w-100 flex-grow-1'>
                    <div className='mb-18px'>
                        <AnnouncementsCardStandard />
                    </div>

                    <div className='mb-18px'>
                        <QuickLinksCardStandard />
                    </div>

                    <div className='d-flex flex-wrap align-items-stretch mb-18px gap-18px'>
                        <SalesReportCard setGoalFilter={setFilter} />
                        <GoalTracker filter={filter} />
                    </div>
                    <AccessRights forManager forSuperAdmin>
                        <OfficePerformaceCard />
                    </AccessRights>
                </div>
                <div className='min-w-sm-380px flex-grow-1'>
                    <div className='mb-18px'>
                        <DashBoardCalendarCard />
                    </div>
                    {/* <div><AlertSectionCardStandard /></div> */}

                    <div className=''>
                        <ActionItemsCard />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StandardDashboard
