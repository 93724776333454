import React, {Children} from 'react'
import {fontsFamily} from '../../assets/fonts/fonts'

const CustomEllipsis = ({
    text,
    style,
    children,
    width = '80px',
    className,
    onClick = () => {},
    textColor = 'cmGrey800',
}) => {
    return (
        <div
            data-toggle='tooltip'
            data-placement='left'
            title={text}
            onClick={onClick}
            style={{
                fontFamily: fontsFamily.manrope,
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                cursor: 'pointer',
                width,
                ...style,
            }}
            className={`text-${textColor} ${className}`}
        >
            {children}
        </div>
    )
}

export default CustomEllipsis
