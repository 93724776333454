import React, {useCallback, useMemo, useRef, useState} from 'react'
import CustomModal from '../../../../../customComponents/customModal/CustomModal'
import SignatureCanvas from 'react-signature-canvas'
import CustomImage from '../../../../../customComponents/customImage/CustomImage'
import {IMAGE_TYPE} from '../../../../../helpers/CommonHelpers'
import CustomButton, {
    BUTTON_SIZE,
    BUTTON_TYPE,
} from '../../../../../customComponents/customButtton/CustomButton'
import CustomInput from '../../../../../customComponents/customInputs/customInput/CustomInput'
import {TabPanel, TabView} from 'primereact/tabview'
import {InputText} from 'primereact/inputtext'
import CustomToast from '../../../../../customComponents/customToast/CustomToast'
import CustomTabs from '../../../../../customComponents/customTab/CustomTab'

const SignatureModal = ({
    open,
    handleClose,
    signatureData,
    setSignatureData,
    addSignatureToPDF,
}) => {
    const signatureRef = useRef()
    const signatureTextRef = useRef()
    const [bgColor, setbgColor] = useState('')
    const [signatureTextData, setSignatureTextData] = useState('')
    const [selectedStyle, setSelectedStyle] = useState('Brush Script MT')

    const handleClear = () => {
        signatureRef.current.clear()
        // setSignatureData('')
    }

    const handleSave = () => {
        const dataUrl = signatureRef.current.getTrimmedCanvas().toDataURL('image/png')
        // setSignatureData(dataUrl)
        addSignatureToPDF(dataUrl)
        handleClose()
    }

    const fonts = [
        {
            fontFamily: 'Cookie',
            id: 1,
        },
        {
            fontFamily: 'Aguafina Script',
            id: 2,
        },
        {
            fontFamily: 'Nanum Brush Script',
            id: 3,
        },
        {
            fontFamily: 'Dancing Script',
            id: 4,
        },
        {
            fontFamily: 'Euphoria Script',
            id: 5,
        },
        {
            fontFamily: 'Lily Script One',
            fontSize: 22,
            id: 6,
        },
        {
            fontFamily: 'Seaweed Script',
            fontSize: 25,
            id: 7,
        },

        {
            fontFamily: 'Style Script',
            fontSize: 28,
            id: 8,
        },
    ]

    const handleSignText = (e) => {
        setSignatureTextData(e.target.value)
    }

    const changeTextSignatureStyle = useCallback((style) => {
        setSelectedStyle(style)
        signatureTextRef.current.style.fontFamily = style
    }, [])

    const makeBigTextSignatureCanvas = useCallback(() => {
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        let canvsDynamicWidth = signatureTextData?.length * 30
        canvas.width = canvsDynamicWidth
        // canvas.height = 40
        canvas.height = 80

        context.font = `60px ${selectedStyle}`
        context.fillStyle = 'black'
        context.fillText(signatureTextData, 10, 50)

        const dataUrl = canvas.toDataURL('image/png')

        return dataUrl
    }, [selectedStyle, signatureTextData])

    const handleTextSignatureSave = useCallback(() => {
        if (!signatureTextData.trim()) return CustomToast.error('Enter signature text')
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        let canvsDynamicWidth = signatureTextData?.length * 17
        canvas.width = canvsDynamicWidth

        canvas.height = 40

        context.font = `30px ${selectedStyle}`
        context.fillStyle = 'black'
        context.fillText(signatureTextData, 5, 25)

        const dataUrl = canvas.toDataURL('image/png')
        const bigSizeSign = makeBigTextSignatureCanvas()

        addSignatureToPDF(dataUrl, bigSizeSign)
        // setSignatureData(dataUrl)
        handleClose()
    }, [
        addSignatureToPDF,
        handleClose,
        makeBigTextSignatureCanvas,
        selectedStyle,
        signatureTextData,
    ])
    const tabsPreviewData = useMemo(() => {
        let tabsData = [
            {
                label: 'Type',
                content: (
                    <div>
                        <div className='mb-5'>
                            <div className='my-5'>
                                <div className='mb-5'>
                                    <InputText
                                        ref={signatureTextRef}
                                        onChange={handleSignText}
                                        value={signatureTextData}
                                        className='w-100'
                                        style={{fontWeight: 600, fontSize: '18px'}}
                                        keyfilter={/^[a-zA-Z\s]+$/}
                                    />
                                </div>
                                {/* styles */}
                                <div className='d-flex flex-center flex-wrap gap-5'>
                                    {fonts.map((i, idx) => (
                                        <div
                                            key={idx}
                                            className={`d-flex align-items-center w-225px border border-cmGrey400 rounded h-60px ps-2 border-hover-cmBlue-Crayola cursor-pointer ${bgColor}`}
                                            style={{
                                                fontSize: i?.fontSize ?? 28,
                                                lineHeight: '32px',
                                                fontFamily: i?.fontFamily,
                                            }}
                                            onClick={() => changeTextSignatureStyle(i?.fontFamily)}
                                            // onMouseEnter={() => {
                                            //     setbgColor('bg-cmBlue-Crayola bg-opacity-10')
                                            // }}
                                            // onMouseLeave={() => setbgColor('')}
                                        >
                                            Type your name here
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='d-flex flex-center gap-5 bg-cmGrey100 p-5'>
                                <CustomButton
                                    buttonSize={BUTTON_SIZE.small}
                                    buttonLabel='Save'
                                    onClick={handleTextSignatureSave}
                                />
                            </div>
                        </div>
                    </div>
                ),
            },
            {
                label: 'Draw',
                content: (
                    <>
                        <SignatureCanvas
                            ref={signatureRef}
                            penColor='black'
                            canvasProps={{
                                height: 450,
                                className: 'w-100 sigCanvas border border-2 border-cmGrey400',
                            }}
                        />
                        <div className='d-flex flex-center gap-5 bg-cmGrey100 p-5'>
                            <CustomButton
                                buttonSize={BUTTON_SIZE.small}
                                buttonLabel='Clear'
                                onClick={handleClear}
                                buttonType={BUTTON_TYPE.secondary}
                            />
                            <CustomButton
                                buttonSize={BUTTON_SIZE.small}
                                buttonLabel='Save'
                                onClick={handleSave}
                            />
                        </div>
                    </>
                ),
            },
        ]

        return tabsData
    }, [
        bgColor,
        changeTextSignatureStyle,
        fonts,
        handleSave,
        handleTextSignatureSave,
        signatureTextData,
    ])

    return (
        <CustomModal
            maxWidth='750'
            show={open}
            onHide={handleClose}
            showline={false}
            title={'Add Signature'}
        >
            <CustomTabs tabs={tabsPreviewData} />

            {/* manual signature */}
        </CustomModal>
    )
}

export default SignatureModal
