import {useEffect, useState} from 'react'
import {Sidebar} from 'primereact/sidebar'
import {fontsFamily} from '../../../../assets/fonts/fonts'
import {KTSVG} from '../../../helpers'
import CustomLink from '../../../../customComponents/customButtton/CustomLink'
import Pagination from '../../../../app/modules/admin/sequidocs/component/Pagination'
import {useSelector} from 'react-redux'
import {getNotificationSelector} from '../../../../redux/selectors/SettingsSelectors'
import {getAllNotificationService} from '../../../../services/Services'
import CustomToast from '../../../../customComponents/customToast/CustomToast'
import {getErrorMessageFromResponse} from '../../../../helpers/CommonHelpers'
import CustomLoader from '../../../../customComponents/customLoader/CustomLoader'

const NotificationSidebar = ({open, onClose}) => {
    const notification = useSelector(getNotificationSelector)
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false)

    const [notificationData, setNotificationData] = useState(notification?.data)

    useEffect(() => {
        setLoading(true)
        const body = {
            page: page,
        }
        getAllNotificationService(body)
            .then((res) => {
                setNotificationData(res?.data?.data)
            })
            .catch((e) => {
                CustomToast.error(getErrorMessageFromResponse(e))
            })
            .finally(() => {
                setLoading(false)
            })
    }, [page])

    const onPageChange = (page) => {
        setPage(page)
    }

    return (
        <Sidebar
            visible={open}
            position='right'
            onHide={onClose}
            showCloseIcon={false}
            className={'w-sm-30 w-100'}
            header={() => (
                <div className='d-flex align-items-center my-2 justify-content-between pb-5 border-cmGrey200 w-100 border-bottom'>
                    <div
                        className='text-cmBlack'
                        style={{
                            fontSize: '16px',
                            fontWeight: '600',
                            lineHeight: '24px',
                            fontFamily: fontsFamily.manrope,
                        }}
                    >
                        Notifications ({notification?.total})
                    </div>
                    <div
                        className='bi bi-x-lg fs-5 text-cmGrey700 rounded-circle cursor-pointer text-hover-dark px-1 border rounded-circle border-cmGrey700 border-2 px-1 border-hover-dark'
                        onClick={onClose}
                    ></div>
                </div>
            )}
        >
            <div className=''>
                <div
                    className='d-flex align-items-center gap-2 text-cmGrey500'
                    style={{fontSize: '14px', fontWeight: 700, lineHeight: '19px'}}
                >
                    <KTSVG
                        path='/media/icons/duotune/art/Double-Check.svg'
                        className='cursor-pointer'
                        svgClassName='w-20px '
                    />
                    <div>Mark all as read</div>
                </div>
                <CustomLoader full visible={loading} />
                {notificationData?.map((i) => (
                    <div
                        className='px-5 p-3 border-bottom border-cmGrey200'
                        style={{
                            fontSize: '14px',
                            fontWeight: 600,
                            marginRight: '-15px',
                            marginLeft: '-15px',
                        }}
                    >
                        <div className='d-flex gap-3 align-items-center'>
                            <i class='bi bi-circle-fill fs-9 text-cmgreen'></i>
                            <div
                                className='text-cmBlack'
                                style={{fontWeight: 700, lineHeight: '21px'}}
                            >
                                {i?.name ?? '-'}
                            </div>
                        </div>
                        <div className='d-flex gap-3 align-items-center mb-2'>
                            <i class='text-cmwhite bi bi-circle-fill fs-9'></i>
                            <div className='text-cmGrey600'>{i?.subtitle ?? '-'}</div>
                        </div>
                        {/* <div className='d-flex gap-3 align-items-center'>
                            <i class='text-cmwhite bi bi-circle-fill fs-9'></i>
                            <div>
                                <CustomLink label={'View Now'} underline={false} />
                            </div>
                        </div> */}
                    </div>
                ))}
                {notificationData?.length > 0 && (
                    <Pagination
                        width={null}
                        page={page}
                        showLimitPagination={false}
                        showCustomPaginationInput={false}
                        totalPages={notification?.last_page}
                        setPage={(changedPage) => onPageChange(changedPage)}
                    />
                )}
            </div>
        </Sidebar>
    )
}

export default NotificationSidebar
