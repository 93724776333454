import {useEffect, useState, useMemo} from 'react'
import CustomButton, {
    BUTTON_SIZE,
    BUTTON_TYPE,
} from '../../../../customComponents/customButtton/CustomButton'
import {CommonLabel} from '../../../../customComponents/customInputs/customInput/CustomInput'
import RedirectToEmployeeProfile from '../../../../customComponents/redirectToEmployeeProfile/RedirectToEmployeeProfile'
import CustomUserAvatar from '../../../../customComponents/customUserAvatar/CustomUserAvatar'
import {
    getUpdaterReportV1Service,
    sClearanceReportV1Service,
    updateReportStatusV1Service,
} from '../../../../services/Services'
import {getErrorMessageFromResponse} from '../../../../helpers/CommonHelpers'
import CustomToast from '../../../../customComponents/customToast/CustomToast'

import useQueryString from '../../../../hooks/useQueryString'
import CustomLoader from '../../../../customComponents/customLoader/CustomLoader'

const SClearanceReportFullPage = () => {
    const [approvedData, setApprovedData] = useState({})
    const [report, setReport] = useState(null)
    const [loader, setLoader] = useState(false)
    const [searchParams] = useQueryString()
    const {screeningID, status, screeningRequest} = useMemo(() => searchParams, [searchParams])
    
    useEffect(() => {
        setLoader(true)
        sClearanceReportV1Service({
            screeningRequestApplicantId: screeningID,
        })
            .then((res) => {
                setReport(res?.data)
            })
            .catch((error) => CustomToast.error(getErrorMessageFromResponse(error)))
            .finally(() => setLoader(false))
        getReportUpdater()
    }, [screeningID, status])

    const getReportUpdater = () => {
        getUpdaterReportV1Service({
            screening_request_applicant_id: screeningID,
        })
            .then((res) => {
                setApprovedData(res.data)
            })
            .catch((error) => CustomToast.error(getErrorMessageFromResponse(error)))
    }

    const handleApproveDeclineButton = (status) => {
        updateReportStatusV1Service({
            screening_request_id: screeningRequest,
            approval_status: status,
        })
            .then((res) => {
                CustomToast.success(res.message)
                getReportUpdater()
            })
            .catch((error) => CustomToast.error(getErrorMessageFromResponse(error)))
    }
    return (
        <div className='text-center card'>
            {/* pdf */}

            <div className='card-body overflow-auto'>
                {loader ? (
                    <div className='text-center h-100 d-flex gap-5 flex-column justify-content-center'>
                        <CustomLoader visible />
                        <CommonLabel label='Retrieving Report' />
                    </div>
                ) : null}
                {!loader && !report ? (
                    'Report not found.'
                ) : (
                    <div
                        className='overflow-auto'
                        dangerouslySetInnerHTML={{
                            __html: report || '',
                        }}
                    />
                )}
            </div>
            {/* Buttons */}
            <div className='card-footer'>
                {approvedData?.status == 'Approval Pending' ? (
                    <div className='d-flex flex-center gap-5 noprint'>
                        <CustomButton
                            buttonLabel='Approve'
                            buttonSize={BUTTON_SIZE.normal}
                            id={'S_Clearance_Report_Approve_Btn'}
                            onClick={() => handleApproveDeclineButton('approve')}
                            disabled={loader}
                        />
                        <CustomButton
                            buttonLabel='Decline'
                            buttonSize={BUTTON_SIZE.normal}
                            buttonType={BUTTON_TYPE.secondary}
                            id={'S_Clearance_Report_Decline_Btn'}
                            onClick={() => handleApproveDeclineButton('decline')}
                            disabled={loader}
                        />
                    </div>
                ) : ['Approved', 'Declined'].includes(approvedData?.status) ? (
                    <div className='d-flex align-items-center gap-5'>
                        {approvedData?.user ? (
                            <>
                                <CommonLabel
                                    label={`${
                                        approvedData?.status == 'Approved'
                                            ? 'Approved By:'
                                            : 'Rejected By:'
                                    }`}
                                    labelColor={
                                        approvedData?.status == 'Declined' ? 'cmError' : 'default'
                                    }
                                />
                                <RedirectToEmployeeProfile>
                                    <CustomUserAvatar
                                        image={approvedData?.user?.image}
                                        username={approvedData?.user?.user_name}
                                    />
                                    <div className='text-decoration-underline'>
                                        {approvedData?.user?.user_name}
                                    </div>
                                </RedirectToEmployeeProfile>
                            </>
                        ) : null}
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default SClearanceReportFullPage
