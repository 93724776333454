import React from 'react'
import ReactApexChart from 'react-apexcharts'
import {fontsFamily} from '../../../../../assets/fonts/fonts'
import useSequifiConfig from '../../../../../sequiConfig/useSequifiConfig'

const OfficePerformanceChart = ({chartData, thisLastValueDisplayKey}) => {
    const {basedOnCompanyType} = useSequifiConfig()
    const chartOptions = {
        series: [
            {
                // name: `This ${thisLastValueDisplayKey}`,
                name: `${thisLastValueDisplayKey}`,
                data: basedOnCompanyType.isCompanyTypePest
                    ? [
                          chartData?.totalSold ?? 0,
                          chartData?.m2Complete ?? 0,
                          chartData?.cancelled ?? 0,
                          chartData?.clawback ?? 0,
                      ]
                    : [
                          chartData?.totalSold ?? 0,
                          chartData?.m1Complete ?? 0,
                          chartData?.m2Complete ?? 0,
                          chartData?.cancelled ?? 0,
                          chartData?.clawback ?? 0,
                      ],
                // color: '#6078EC',
            },
            {
                // name: `Last ${thisLastValueDisplayKey}`,
                name: `${thisLastValueDisplayKey}`,
                data: basedOnCompanyType.isCompanyTypePest
                    ? [
                          chartData?.lastTotalSold ?? 0,
                          chartData?.lastM2Complete ?? 0,
                          chartData?.lastCancelled ?? 0,
                          chartData?.lastClawback ?? 0,
                      ]
                    : [
                          chartData?.lastTotalSold ?? 0,
                          chartData?.lastM1Complete ?? 0,
                          chartData?.lastM2Complete ?? 0,
                          chartData?.lastCancelled ?? 0,
                          chartData?.lastClawback ?? 0,
                      ],

                // color: '#bdbdbd',
            },
        ],
        options: {
            chart: {
                width: '100%',
                height: '100%',
                type: 'column',
            },

            fill: {
                opacity: 1,
            },
            xaxis: {
                categories: basedOnCompanyType.isCompanyTypePest
                    ? ['Sold', 'Serviced', 'Cancelled', 'Clawback']
                    : ['Sold', 'M1 complete', 'M2 Complete', 'Cancelled', 'Clawback'],
                tickPlacement: 'between',

                labels: {
                    style: {
                        fontWeight: 600,
                        colors: '#212121',
                        fontSize: '12px',
                        fontfamily: fontsFamily.manrope,
                    },
                },
            },
            toolbar: {
                show: true, // Set to false to hide the toolbar
            },
            dataLabels: {
                enabled: true,
                position: 'top',

                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ['#304758'],
                },
            },
            legend: {
                position: 'top',
                show: true,
                horizontalAlign: 'left',
                markers: {
                    width: 23,
                    height: 9,
                    radius: 10,
                    margin: 20,
                },
                labels: {
                    // margin: 10,
                    colors: '#212121',
                    useSeriesColors: false,
                },

                fontSize: '10px',
                fontWeight: '500',
                padding: 10,
                fontFamily: fontsFamily.manrope,
            },

            yaxis: {
                labels: {
                    style: {
                        fontWeight: 500,
                        colors: '#BDBDBD',
                        fontSize: '10px',
                        fontFamily: fontsFamily.manrope,
                    },
                },
                title: {
                    display: true,
                    text: 'Accounts',
                    style: {
                        fontWeight: 600,
                        lineHeight: '20px',
                        fontSize: '12px',
                        fontFamily: fontsFamily.manrope,
                        color: '#212121',
                    },
                },
            },

            plotOptions: {
                bar: {
                    dataLabels: {
                        position: 'top',
                        offsetY: 0,
                    },
                    horizontal: false,
                    columnWidth: '25%',
                    endingShape: 'rounded',
                    colors: {
                        opacity: 1, // Set the opacity to 1 for full opacity
                    },
                },
            },
            colors: ['#6078EC', '#E0E0E0'],
            grid: {
                show: true,
                borderColor: '#E0E0E0',
                strokeDashArray: 5,
                // position: 'top',
                xaxis: {
                    lines: {
                        show: false,
                    },
                },
                yaxis: {
                    lines: {
                        show: true,
                    },
                },
                row: {
                    colors: undefined,
                    opacity: 0.5,
                },
                column: {
                    colors: undefined,
                    opacity: 0.5,
                },
                // padding: {
                //     top: 0,
                //     right: 0,
                //     bottom: 0,
                //     left: 0,
                // },
            },
        },
    }
    return (
        <ReactApexChart
            options={chartOptions.options}
            series={chartOptions.series}
            type='bar'
            // className='w-100'
            height={300}
        />
    )
}

export default OfficePerformanceChart
