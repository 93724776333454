import React, {useState} from 'react'
import CustomModal from '../../../../customComponents/customModal/CustomModal'
import {Document, Page} from 'react-pdf'
import BackgroundVerificationTerms from './BackgroundVerificationTerms.pdf'

const BackgroundverificationTandCModal = ({open, close}) => {
    const [pageNumber, setpageNumber] = useState(1)

    const onDocumentSucess = ({numPages}) => {
        setpageNumber(numPages)
    }

    return (
        <CustomModal show={open} onHide={close} title={'Terms and Condition'} maxWidth='950'>
            <div
                className='mx-auto mb-10'
                style={{
                    width: 'fit-content',
                    height: '65vh',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                }}
            >
                <Document file={BackgroundVerificationTerms} onLoadSuccess={onDocumentSucess}>
                    {Array.from(new Array(pageNumber), (no, index) => (
                        <Page
                            className={''}
                            key={`page_${index + 1}`}
                            scale={1.5}
                            pageNumber={index + 1}
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                        />
                    ))}
                </Document>
            </div>
        </CustomModal>
    )
}

export default BackgroundverificationTandCModal
