import {useEffect, useState} from 'react'
import {fontsFamily} from '../../../../../assets/fonts/fonts'
import {KTSVG} from '../../../../../_metronic/helpers'
import CustomButton, {
    BUTTON_SIZE,
    BUTTON_TYPE,
} from '../../../../../customComponents/customButtton/CustomButton'
import {useNavigate} from 'react-router-dom'
import {
    getCurrentActivePlanV1Service,
    upgradeSalesCRMPlanV1Service,
} from '../../../../../services/Services'
import CustomToast from '../../../../../customComponents/customToast/CustomToast'
import {
    formattedNumberFields,
    getErrorMessageFromResponse,
} from '../../../../../helpers/CommonHelpers'
import CustomCardSkeleton from '../../../../../customComponents/customSkeleton/CustomCardSkeleton'
import {INTEGRATIONS_ID} from '../../../../../constants/constants'

const SequiCRMPlans = () => {
    const [selectedPlan, setSelectedPlan] = useState(null) // 1 = Basic | 2 = Plus
    const naviagte = useNavigate()
    const [loading, setLoading] = useState(true)
    const [selectedPlanData, setSelectedPlanData] = useState(null) // 1 = Basic | 2 = Plus

    useEffect(() => {
        getPlanData()
    }, [])

    const getPlanData = () => {
        getCurrentActivePlanV1Service({crm_id: INTEGRATIONS_ID.salesCRM})
            .then((res) => {
                if (res?.data?.length > 0)
                    setSelectedPlan(res?.data[0].plan_name == 'BASIC' ? 1 : 2)
                setSelectedPlanData(res?.data[0])
            })
            .catch((err) => CustomToast.error(getErrorMessageFromResponse(err)))
            .finally(() => setLoading(false))
    }

    const updatePlan = () => {
        const data = {
            crm_id: INTEGRATIONS_ID.salesCRM,
            plan_name: selectedPlan == 1 ? 'BASIC' : 'PLUS',
            amount_per_job: selectedPlan == 1 ? '10' : '15',
        }
        upgradeSalesCRMPlanV1Service(data)
            .then((res) => {
                naviagte('/sequicrm')
            })
            .catch((err) => CustomToast.error(getErrorMessageFromResponse(err)))
            .finally(() => setLoading(false))
    }
    return (
        <div className='mx-sm-10' style={{marginTop: -20, fontFamily: fontsFamily.manrope}}>
            <div
                className='bg-cmwhite shadow-sm p-sm-10 p-5'
                style={{borderRadius: 10, fontFamily: fontsFamily.manrope}}
            >
                {/* title */}
                <div className='text-center mb-15'>
                    <div
                        className='text-cmGrey900 mb-5'
                        style={{fontWeight: 700, fontSize: 29.89, lineHeight: '41.85px'}}
                    >
                        Minimize Confusion, Maximize Efficiency
                    </div>
                    <div
                        className='text-cmGrey700'
                        style={{fontWeight: 600, fontSize: 13.45, lineHeight: '18.83px'}}
                    >
                        Get started today and make job tracking and management a breeze. Review the{' '}
                        <br />
                        features and pricing offered below.
                    </div>
                </div>
                {/* plans */}
                <div className='d-flex flex-wrap  flex-center gap-sm-10 gap-5 mb-sm-15 mb-10'>
                    {loading ? (
                        <CustomCardSkeleton count={2} height='480px' />
                    ) : (
                        <>
                            <BasicPlanCard
                                selectedPlan={selectedPlan}
                                setSelectedPlan={setSelectedPlan}
                                selectedPlanData={selectedPlanData}
                            />
                            {/* <PlusPlanCard
                                selectedPlan={selectedPlan}
                                setSelectedPlan={setSelectedPlan}
                            /> */}
                        </>
                    )}
                </div>
                {/* button */}
                <div>
                    <CustomButton
                        id={'purchase_plan_Get_Started_Btn'}
                        disabled={selectedPlan == null}
                        buttonLabel='Get Started'
                        buttonSize={BUTTON_SIZE.large}
                        onClick={updatePlan}
                    />
                </div>
            </div>
        </div>
    )
}

export default SequiCRMPlans

// 1
const BasicPlanCard = ({selectedPlan, setSelectedPlan, selectedPlanData}) => {
    const planItems = [
        '• Access for unlimited users',
        '• Easily add jobs',
        '• Track jobs with custom boards',
        '• Easily Import or Export data',
        '• Customizable reports',
        '• Upload documents and jobsite pictures',
        '• Share comments and job notes',
        '• Easily view critical information',
        '• Job status automatically updates your reps in Sequifi',
        '• Seamlessly trigger rep payments',
        '• Email and chat support',
    ]

    return (
        <div
            id='Basic_Plan_Card'
            onClick={() => {
                setSelectedPlan(1)
            }}
            className={`${
                selectedPlan == 1
                    ? 'h-530px bg-cmBlue-Crayola bg-opacity-10'
                    : 'h-520px bg-strip  cursor-pointer'
            } w-450px p-5 card m-0`}
            style={{borderRadius: 18}}
        >
            <div className='card-body p-0 m-0 h-100'>
                <div className='mb-10'>
                    <div className='d-flex align-items-center flex-wrap justify-content-between'>
                        <div
                            className='text-cmBlack mb-2'
                            style={{fontWeight: 800, fontSize: 22, lineHeight: '33px'}}
                        >
                            {/* {data?.plan_name} */}
                            Basic
                        </div>
                        <div className='text-cmBlue-Crayola'>
                            <span style={{fontWeight: 700, fontSize: 16, lineHeight: '18.75px'}}>
                                $
                            </span>
                            <span style={{fontWeight: 700, fontSize: 38, lineHeight: '44.53px'}}>
                                {/* {data?.price} */}
                                {formattedNumberFields(selectedPlanData?.amount_per_job, '')}
                            </span>
                            <span
                                className='text-cmGrey500'
                                style={{fontWeight: 500, fontSize: 12, lineHeight: '14.06px'}}
                            >
                                {' '}
                                / Job
                            </span>
                        </div>
                    </div>

                    <div
                        className='text-cmGrey600 mb-5'
                        style={{fontWeight: 500, fontSize: 13.3, lineHeight: '18.43px'}}
                    >
                        Essentials for Job Management
                    </div>
                </div>
                <div className='px-5'>
                    {planItems.map((i) => (
                        <div className='d-flex justify-content-between mb-3'>
                            <div
                                className='text-cmGrey800'
                                style={{
                                    fontWeight: 500,
                                    fontSize: 13.47,
                                    lineHeight: '18.4px',
                                }}
                            >
                                {i}
                            </div>
                            <KTSVG
                                path='/media/icons/duotune/art/Done-Mark.svg'
                                svgClassName='w-20px h-20px'
                            />
                        </div>
                    ))}
                </div>
            </div>

            <div className='card-footer m-0 m-0 border-0'>
                <CustomButton
                    buttonLabel={`${selectedPlan == 1 ? 'Subscribed' : 'Subscribe'}`}
                    buttonType={
                        selectedPlan == 1 ? BUTTON_TYPE.secondaryBorder : BUTTON_TYPE.secondary
                    }
                    buttonSize={BUTTON_SIZE.large}
                />
            </div>
        </div>
    )
}
// 2
const PlusPlanCard = ({selectedPlan, setSelectedPlan}) => {
    const planItems = [
        '• Basic plan features',
        '• Job Financials',
        '• Customer messaging and communication',
        '• Customer Job portal',
        '• Allow customer to view Job progress',
        '• Custom Integrations',
    ]
    return (
        <div
            id='Plus_Plan_Card'
            onClick={() => {
                setSelectedPlan(2)
            }}
            className={`${
                selectedPlan === 2
                    ? 'h-550px bg-cmBlue-Crayola bg-opacity-10'
                    : 'h-550px bg-strip  cursor-pointer'
            } w-450px p-5 card m-0`}
            style={{borderRadius: 18}}
        >
            <div
                className='h-25px mb-5 d-flex align-items-center bg-cmOrange bg-opacity-10 px-5 text-cmOrange'
                style={{
                    borderRadius: '10px',
                    width: 'fit-content',
                    fontWeight: 700,
                    fontSize: 14,
                    lineHeight: '19.12px',
                }}
            >
                <div>🔥 Most Popular </div>
            </div>
            {/* contnet */}
            <div className='card-body p-0 m-0 h-100px'>
                <div className='mb-10'>
                    <div className='d-flex align-items-center flex-wrap justify-content-between'>
                        <div
                            className='text-cmBlack mb-2'
                            style={{fontWeight: 800, fontSize: 22, lineHeight: '33px'}}
                        >
                            {/* {data?.plan_name} */}
                            Plus
                        </div>
                        <div className='text-cmBlue-Crayola'>
                            <span style={{fontWeight: 700, fontSize: 16, lineHeight: '18.75px'}}>
                                $
                            </span>
                            <span style={{fontWeight: 700, fontSize: 38, lineHeight: '44.53px'}}>
                                {/* {data?.price} */}
                                15
                            </span>
                            <span
                                className='text-cmGrey500'
                                style={{fontWeight: 500, fontSize: 12, lineHeight: '14.06px'}}
                            >
                                {' '}
                                / Job
                            </span>
                        </div>
                    </div>

                    <div
                        className='text-cmGrey600 mb-5'
                        style={{fontWeight: 500, fontSize: 13.3, lineHeight: '18.43px'}}
                    >
                        Customer portal & Job Financials
                    </div>
                </div>
                <div className='px-5'>
                    {planItems.map((i) => (
                        <div className='d-flex justify-content-between mb-3'>
                            <div
                                className='text-cmGrey800'
                                style={{
                                    fontWeight: 500,
                                    fontSize: 13.47,
                                    lineHeight: '18.4px',
                                }}
                            >
                                {i}
                            </div>
                            <KTSVG
                                path='/media/icons/duotune/art/Done-Mark.svg'
                                svgClassName='w-20px h-20px'
                            />
                        </div>
                    ))}
                </div>
            </div>

            <div className='card-footer m-0 p-0 border-0 mb-5'>
                <CustomButton
                    buttonLabel={`${selectedPlan == 2 ? 'Subscribed' : 'Subscribe'}`}
                    buttonType={
                        selectedPlan == 2 ? BUTTON_TYPE.secondaryBorder : BUTTON_TYPE.secondary
                    }
                    buttonSize={BUTTON_SIZE.large}
                />
            </div>
        </div>
    )
}
