import {Sidebar} from 'primereact/sidebar'
import CustomButton, {BUTTON_TYPE} from '../../../../customComponents/customButtton/CustomButton'
import {useMemo, useState} from 'react'
import {IMAGE_URL, getValidDate} from '../../../../constants/constants'
import CustomImage from '../../../../customComponents/customImage/CustomImage'
import {IMAGE_TYPE, isImageUrl} from '../../../../helpers/CommonHelpers'
import {KTSVG} from '../../../../_metronic/helpers'
import CustomEllipsis from '../../../../customComponents/customEllipsis/CustomEllipsis'
import CustomInput, {
    INPUT_TYPE,
} from '../../../../customComponents/customInputs/customInput/CustomInput'
import {FULL_IMAGE_URL} from '../../../../constants/SERVERS'

const ViewSingleAnnouncement = ({open, close, announcementData}) => {
    const convertToFullURL = (domain) => {
        if (domain.startsWith('http://') || domain.startsWith('https://')) {
            return domain
        } else {
            return `https://www.${domain}`
        }
    }

    const DisplayImage = useMemo(() => {
        return isImageUrl(announcementData?.file?.name || announcementData?.file)
    }, [announcementData?.file])

    return (
        <>
            <Sidebar
                visible={open}
                position='right'
                onHide={close}
                showCloseIcon={false}
                className={'w-sm-25'}
                header={() => (
                    <div className='d-flex align-items-center my-2 justify-content-between  w-100 '>
                        <div
                            style={{
                                fontSize: '16px',
                                color: '#0D1821',
                                fontFamily: 'Manrope',
                                fontWeight: '700',
                            }}
                        >
                            Announcement Detail
                        </div>
                        <div
                            onClick={close}
                            id='Close_Announcemen_Detail_Sidebar'
                            className='bi bi-x-lg cursor-pointer border border-cmGrey700 rounded-circle px-1 text-hover-danger border-hover-danger border-2'
                        />
                    </div>
                )}
            >
                {/* <CustomLoader full visible={loading} /> */}
                <div>
                    <div style={{background: '#FFF3DA', borderRadius: 10}} className='p-8 pe-2'>
                        <h2 className=''>{announcementData?.title}</h2>
                    </div>
                    <div
                        className='text-end py-2 text-cmGrey500'
                        style={{
                            fontSize: '14px',
                            fontWeight: 700,
                            lineHeight: '21px',
                            fontFamily: 'Manrope',
                        }}
                    >
                        {getValidDate(announcementData?.start_date, 'MM/DD/YYYY')}
                    </div>
                    <div style={{fontSize: '14px', fontWeight: '600'}}>
                        {/* <div>{announcementData?.description}</div> */}

                        <div
                            className=''
                            dangerouslySetInnerHTML={{__html: announcementData?.description}}
                        />

                        {announcementData?.link ? (
                            <>
                                <p>Click on the link to read more about the benefits</p>
                                <a
                                    id='Announcement_Link_Redirect'
                                    target='blank'
                                    className='text-cminfo'
                                    rel='noopener noreferrer'
                                    href={convertToFullURL(announcementData?.link)}
                                >
                                    {announcementData?.link}
                                </a>
                            </>
                        ) : null}
                    </div>
                    {announcementData?.file ? (
                        <div className='mt-5'>
                            <div
                                className='mb-2 text-cmGrey600'
                                style={{fontWeight: 600, fontSize: 14, lineHeight: '28px'}}
                            >
                                {' '}
                                Attached Files
                            </div>

                            {DisplayImage ? (
                                <CustomImage
                                    src={
                                        announcementData?.file?.name
                                            ? window.URL.createObjectURL(announcementData?.file)
                                            : `${FULL_IMAGE_URL}/${announcementData?.file}`
                                    }
                                    className={'w-100px h-100px rounded '}
                                />
                            ) : null}
                            <div
                                className='btn text-cmGrey600 d-flex align-items-center gap-2 border px-5 py-3 w-175px'
                                style={{fontWeight: '600', fontSize: 12}}
                            >
                                <span
                                    className='bg-cmBlue-Crayola bg-opacity-10 d-flex  p-2 rounded'
                                    style={{width: 'fit-content'}}
                                >
                                    <KTSVG
                                        path='/media/icons/duotune/art/doc1.svg'
                                        className='cursor-pointer p-0 m-0'
                                        // svgClassName='w-15px h-15px'
                                    />
                                </span>
                                <span>
                                    <CustomEllipsis
                                        style={{
                                            whiteSpace: 'nowrap',
                                            fontSize: '12px',
                                            width: '100px',
                                            lineHeight: '17px',
                                            fontWeight: 600,
                                        }}
                                        text={announcementData?.file}
                                    >
                                        <a
                                            className='text-cmBlue-Crayola text-decoration-underline'
                                            target='blank'
                                            href={`${IMAGE_URL}/${announcementData?.file}`}
                                        >
                                            {announcementData?.file}
                                        </a>
                                    </CustomEllipsis>
                                </span>
                            </div>
                        </div>
                    ) : null}
                </div>
            </Sidebar>
        </>
    )
}

export default ViewSingleAnnouncement
