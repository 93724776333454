import {useCallback, useEffect, useMemo, useState} from 'react'
import {getAnnouncementCardDataAction} from '../../../../../redux/actions/DashboardActions'
import {getAnnouncementCardDataSelector} from '../../../../../redux/selectors/DashboardSelectors'
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux'
import CustomLoader from '../../../../../customComponents/customLoader/CustomLoader'
import ManageAnnouncementModal from '../../../common/manageAnnouncement/manageAnnouncementModal'
import ViewAllAnnouncement from '../../../common/manageAnnouncement/viewAllAnnouncement'
import CustomEllipsis from '../../../../../customComponents/customEllipsis/CustomEllipsis'
import ViewSingleAnnouncement from '../../../common/manageAnnouncement/viewSingleAnnouncement'
import CustomNoData from '../../../../../customComponents/customNoData/CustomNoData'
import AccessRights from '../../../../../accessRights/AccessRights'
import CustomIterator from '../../../../../customComponents/customIterator/CustomIterator'
import CustomSkeleton from '../../../../../customComponents/customSkeleton/CustomSkeleton'
import CustomLink from '../../../../../customComponents/customButtton/CustomLink'
import {KTSVG} from '../../../../../_metronic/helpers'
import CustomButton2, {
    BUTTON_SIZE,
    BUTTON_TYPE,
} from '../../../../../customComponents/customButtton/CustomButton2'
import {TruncatedHtml} from '../../../../../helpers/CommonHelpers'

const AnnouncementsCardStandard = () => {
    const announcementCardData = useSelector(getAnnouncementCardDataSelector)

    const [loading, setLoading] = useState(false)
    const [fullLoading, setFullLoading] = useState(false)
    const [showManageAnnouncementModal, setShowManageAnnouncementModal] = useState(false)
    const [showViewAllAnnouncement, setShowViewAllAnnouncement] = useState(false)
    const [showSingleAnnouncement, setShowSingleAnnouncement] = useState(false)
    const [singleAnnouncementData, setSingleAnnouncementData] = useState(null)

    const dispatch = useDispatch()
    useEffect(() => {
        getAnnouncementData()
    }, [])

    const getAnnouncementData = useCallback(() => {
        if (announcementCardData) setLoading(true)
        else setFullLoading(true)

        dispatch(getAnnouncementCardDataAction()).finally(() => {
            if (announcementCardData) setLoading(false)
            else setFullLoading(false)
        })
    }, [dispatch])

    const handleViewAllAnnouncement = () => {
        setShowViewAllAnnouncement(!showViewAllAnnouncement)
    }

    const handleModalClose = () => {
        setShowManageAnnouncementModal(false)
        getAnnouncementData()
    }

    const handleSingleAnnouncement = (item) => {
        setSingleAnnouncementData(item)
        setShowSingleAnnouncement(!showSingleAnnouncement)
    }

    const announcemenFilterData = useMemo(() => {
        return announcementCardData
            ?.filter?.((filterItem) => filterItem?.status == 'Live')
            ?.sort((a, b) => b.pin_to_top - a.pin_to_top)
    }, [announcementCardData])

    const colorClasses = useMemo(
        () => [
            'rgba(255, 224, 164, 0.4)',
            'rgba(207, 223, 254, 0.4)',
            'rgba(219, 249, 190, 0.4)',
            'rgba(252, 222, 255, 0.4)',
        ],
        []
    )

    const stylishCard = useMemo(
        () => [
            <KTSVG
                path='/media/icons/duotune/art/card1_bg_icon.svg'
                svgClassName='position-absolute h-75 w-75 start-0 bottom-0'
                svgStyle={{zIndex: '0'}}
            />,
            <KTSVG
                path='/media/icons/duotune/art/card3_bg_icon.svg'
                svgClassName='position-absolute  top-50 end-0 translate-middle-y pe-5'
                svgStyle={{zIndex: '0', width: '70px', height: '140px'}}
            />,
            <KTSVG
                path='/media/icons/duotune/art/crad2_bg_icon.svg'
                svgClassName='position-absolute w-100 h-75 bottom-0 start-50 translate-middle-x'
                svgStyle={{zIndex: '0'}}
            />,
        ],
        []
    )

    return (
        <div>
            <div
                className='bg-cmwhite p-18px pb-0 sqcard-shadow text-md-bold'
                style={{
                    borderRadius: '10px',
                    position: 'relative',
                }}
            >
                <CustomLoader visible={fullLoading} full />
                <div
                    className='d-flex flex-wrap align-items-center justify-content-between mb-6'
                    style={{position: 'relative'}}
                >
                    <div className='text-sqGray900'>
                        Announcements ({announcemenFilterData?.length ?? 0})
                    </div>
                    <div className='d-flex flex-wrap align-items-center gap-1'>
                        <AccessRights forManager>
                            <CustomButton2
                                id={'Std_Announcemnts_Mange_Btn'}
                                icon={
                                    <KTSVG
                                        path='/media/icons/duotune/art/add-square.svg'
                                        svgClassName='w-20px h-20px'
                                    />
                                }
                                onClick={() => setShowManageAnnouncementModal(true)}
                                buttonLabel='Manage'
                                buttonSize={BUTTON_SIZE.sm}
                                buttonType={BUTTON_TYPE.tertiaryColor}
                            />
                        </AccessRights>
                        <CustomButton2
                            id={'Std_Announcemnts_View_All_Btn'}
                            onClick={handleViewAllAnnouncement}
                            buttonLabel='View all'
                            buttonSize={BUTTON_SIZE.sm}
                            buttonType={BUTTON_TYPE.tertiaryGray}
                        />
                    </div>
                </div>
                <div
                    className='d-flex align-itmes-center gap-3 pb-18px'
                    style={{overflowX: 'auto', overflowY: 'hidden'}}
                >
                    {loading ? (
                        <div className='d-flex gap-5'>
                            <CustomIterator count={4}>
                                <CustomSkeleton className={'w-175px h-95px'} borderRadius={10} />
                            </CustomIterator>
                        </div>
                    ) : announcemenFilterData?.length > 0 ? (
                        announcemenFilterData?.map((item, i) => {
                            const colorClass = colorClasses[i % colorClasses.length]
                            const bgIcon = stylishCard[i % stylishCard.length]

                            return (
                                <div
                                    id={`Std_Announcemnts_Particular_Card_${i}`}
                                    className={`cursor-pointer position-relative`}
                                    onClick={() => handleSingleAnnouncement(item)}
                                    style={{
                                        backgroundColor: colorClass,
                                        zIndex: 1,
                                        borderRadius: 10,
                                        paddingTop: '14px',
                                        paddingLeft: '10px',
                                        paddingBottom: '24px',
                                    }}
                                    key={i}
                                >
                                    <div
                                        className='position-relative w-210px'
                                        style={{
                                            zIndex: 10,
                                            height: 'fit-content',
                                        }}
                                    >
                                        <CustomEllipsis
                                            className={'mb-1 text-md-bold text-sqGray900'}
                                            style={{
                                                whiteSpace: 'nowrap',
                                                width: '150px',
                                                fontSize: '14px',
                                                fontWeight: '700',
                                                lineHeight: '20px',
                                            }}
                                            text={item?.title}
                                        >
                                            {item?.title}
                                        </CustomEllipsis>

                                        <CustomEllipsis
                                            className={'mb-1 text-sm-semibold  text-cmGrey600'}
                                            style={{
                                                whiteSpace: 'nowrap',
                                                width: '180px',
                                            }}
                                            text={item?.description.replace(/<[^>]+>/g, '')}
                                        >
                                            {item?.description.replace(/<[^>]+>/g, '')}
                                        </CustomEllipsis>
                                    </div>
                                    {bgIcon}
                                </div>
                            )
                        })
                    ) : (
                        <div className='col-sm'>
                            <CustomNoData label={'No Announcements'} />
                        </div>
                    )}
                </div>
            </div>
            {showManageAnnouncementModal ? (
                <ManageAnnouncementModal
                    // announcementData={announcementData}
                    role='standard'
                    show={showManageAnnouncementModal}
                    handleClose={handleModalClose}
                    // getAnnouncementData={() => getAnnouncementData()}
                    // setHeadFilters={setHeadFilters}
                    // headFilters={headFilters}
                    // loading={loading}
                    // setLoading={setLoading}
                />
            ) : null}
            {showViewAllAnnouncement ? (
                <ViewAllAnnouncement
                    open={showViewAllAnnouncement}
                    close={handleViewAllAnnouncement}
                    announcementData={announcemenFilterData}
                />
            ) : null}

            {showSingleAnnouncement ? (
                <ViewSingleAnnouncement
                    open={showSingleAnnouncement}
                    close={handleSingleAnnouncement}
                    announcementData={singleAnnouncementData}
                />
            ) : null}
        </div>
    )
}

export default AnnouncementsCardStandard
