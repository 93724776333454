export const BREADCRUMBS = {
    // Top setting gear
    DEVELOPER_CONFIG: {
        MAINTENANCE: [
            {
                title: 'Developer Config/',
                path: '/developer-config/maintenance',
            },
        ],
    },
    NAVBAR_SETTINGS: {
        USER_MANAGEMENT: [
            {
                title: 'Settings/',
                path: '/settings/user-management',
            },
        ],
        USER_MANAGEMENT_AUDIT_LOG: [
            {
                title: 'Settings/ User Management/',
                path: '/settings/user-management',
            },
        ],
        BILLING: [
            {
                title: 'Billings/',
                path: '/settings/billings/subscription',
            },
        ],
        SYSTEM: [
            {
                title: 'System/',
                path: '/settings/system/general',
            },
        ],
    },
    // ADMIN
    ADMIN: {
        SETTING: [{title: 'Settings/', path: '/setting/setup'}],
        SEQUIDOCS: [{title: 'Sequidocs/', path: '/admin/sequidocs/templates'}],
        SENDDOCUMENT: [
            {title: 'Sequidocs/', path: '/admin/sequidocs/templates'},
            {title: 'Templates/', path: '/admin/sequidocs/templates'},
        ],
        PAYROLL: [{title: 'Payroll/', path: '/payroll/run-payroll'}],
        REPORTS: [{title: 'Reports/', path: '/admin/reports/company'}],
        PERMISSIONS: [{title: 'Permissions/', path: '/permissions/groups'}],
        SQUICRM: [{title: 'SequiCRM/', path: '/sequicrm/pipeline'}],
    },
    STANDARD: {
        MY_SALES: [{title: 'My Earnings/', path: '/mysales/sales'}],
        SEQUIDOCS: [{title: 'Sequidocs/', path: '/standard/sequidocs/templates'}],
        HIRING: [{title: 'Hiring/', path: '/hiring/hiring-process'}],
        MANAGEMENT: [{title: 'Management/', path: '/management/employee'}],
        REPORTS: [{title: 'Reports/', path: 'standard/reports/office'}],
        REQUEST_AND_APROVALS: [{title: 'Requests & Approvals/', path: 'requests/request'}],
    },
    EMPLOYEE_PROFILE: (emp_id) => [
        {title: 'User Profile/', path: `/user/personal-info?employeeId=${emp_id}`},
    ],
}
