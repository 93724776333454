import {Route, Routes} from 'react-router-dom'
import {AuthLayout} from './AuthLayout'
import {SuspensedView} from '../../../../routing/PrivateRoutes'
import {lazy} from 'react'
import NewLogin from './components/login/NewLogin'
import {lazyRetry} from '../../../../helpers/CommonHelpers'

const AuthPage = () => {
    const Login = lazy(() => lazyRetry(() => import('./components/login/Login'), 'login'))
    const ForgotPassword = lazy(() =>
        lazyRetry(() => import('./components/ForgotPassword'), 'ForgotPassword')
    )
    return (
        <Routes>
            {/* <Route element={<AuthLayout />}>
                <Route
                    index
                    element={
                        <SuspensedView>
                            <Login />
                        </SuspensedView>
                    }
                /> */}
            <Route
                index
                element={
                    <SuspensedView>
                        <NewLogin />
                    </SuspensedView>
                }
            />

            <Route
                path='forgot-password'
                element={
                    <SuspensedView>
                        <ForgotPassword />
                    </SuspensedView>
                }
            />
        </Routes>
    )
}

export {AuthPage}
