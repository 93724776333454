import {useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import CustomButton, {BUTTON_SIZE} from '../../../../../customComponents/customButtton/CustomButton'
import {useLocation, useNavigate} from 'react-router-dom'
import CustomInput, {
    INPUT_TYPE,
} from '../../../../../customComponents/customInputs/customInput/CustomInput'
import {requestChangeForOfferLetterService} from '../../../../../services/Services'
import CustomToast from '../../../../../customComponents/customToast/CustomToast'
import {getErrorMessageFromResponse} from '../../../../../helpers/CommonHelpers'

const RequestChangePage = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [requestSubmitted, setRequestSubmitted] = useState(false)
    const [comment, setComment] = useState(null)

    useState(() => {
        setRequestSubmitted(false)
    }, [])

    return (
        <div className='position-relative'>
            <div className=''>
                <KTSVG
                    path='/media/icons/duotune/art/BlueEllipse.svg'
                    svgClassName='w-100 text-cmGrey200'
                    svgStyle={{width: '100%', height: 'auto'}}
                />
            </div>
            {!requestSubmitted ? (
                <RequestChange
                    handleChnage={() => setRequestSubmitted(true)}
                    comment={comment}
                    setComment={setComment}
                    docId={location?.state?.docId}
                />
            ) : (
                <SubmittedRequest
                    navigate={navigate}
                    comment={comment}
                    requestSubmitted={requestSubmitted}
                />
            )}
        </div>
    )
}
export default RequestChangePage

const RequestChange = ({handleChnage, comment, setComment, docId}) => {
    const [commentError, setCommentError] = useState(null)
    const [buttonLoading, setButtonLoading] = useState(null)
    const handleCommentChange = (e) => {
        setCommentError(null)
        setComment(e.target.value)
    }

    const handleSubmitRequest = () => {
        if (!comment) return setCommentError('Enter a comment')

        setButtonLoading(true)
        const body = {
            signature_request_document_id: docId,
            user_request_change_message: comment,
        }

        requestChangeForOfferLetterService(body)
            .then(() => handleChnage())
            .catch((err) => CustomToast.error(getErrorMessageFromResponse(err)))
            .finally(() => setButtonLoading(false))
    }
    return (
        <div
            className={`bg-cmwhite shadow w-500px position-absolute top-100 start-50 translate-middle`}
            style={{borderRadius: 10, borderRadius: 10}}
        >
            <div className='h-100 mx-auto text-center pt-15 py-5 px-8 mb-5'>
                <div
                    className='text-cmGrey900 mb-5'
                    style={{fontSize: 24, fontWeight: 700, lineHeight: '32px'}}
                >
                    Requesting Change
                </div>
                <div
                    className='text-cmGrey700 mb-10'
                    style={{fontSize: 14, fontWeight: 600, lineHeight: '19px'}}
                >
                    Please let us know what change you want to request?
                </div>
                <div className='text-start'>
                    <div className='text-cmGrey900 mb-2' style={{fontWeight: 700, fontSize: 14}}>
                        Leave Comment{' '}
                    </div>
                    <div>
                        <CustomInput
                            placeholder='Enter Your Comment Here...'
                            type={INPUT_TYPE.textarea}
                            value={comment}
                            onChange={handleCommentChange}
                            errorMessage={commentError}
                        />
                    </div>
                </div>
            </div>
            <div className='bg-cmGrey100 py-10' style={{borderRadius: '0px 0px 10px 10px'}}>
                <CustomButton
                    buttonLabel='Submit Request'
                    buttonSize={BUTTON_SIZE.large}
                    onClick={handleSubmitRequest}
                    loading={buttonLoading}
                />
            </div>
        </div>
    )
}

const SubmittedRequest = ({navigate, requestSubmitted, comment}) => {
    return (
        <div
            className={`bg-cmwhite shadow w-500px position-absolute top-100 start-50 translate-middle`}
            style={{borderRadius: 10, borderRadius: 10}}
        >
            <div className='w-sm-75 h-100 mx-auto text-center pt-15 pb-5 mb-5'>
                <div className=''>
                    <KTSVG
                        path='/media/icons/duotune/art/DoneCheck.svg'
                        svgClassName='w-auto '
                        svgStyle={{width: '120px', height: '120px'}}
                    />
                </div>
                <div
                    className='text-cmGrey900 mb-2'
                    style={{fontSize: 22, fontWeight: 700, lineHeight: '30px'}}
                >
                    Change Requested
                </div>
                <div
                    className='text-cmGrey700'
                    style={{fontSize: 14, fontWeight: 500, lineHeight: '19px'}}
                >
                    You have Requested a change to this Offer Letter
                </div>
            </div>
            <div className='bg-cmGrey100 py-10' style={{borderRadius: '0px 0px 10px 10px'}}>
                <div className='px-10 mx-auto text-center d-flex flex-column gap-5'>
                    <div
                        className='text-cmGrey900 mb-5'
                        style={{fontSize: 16, fontWeight: 700, lineHeight: '21px'}}
                    >
                        Your Response Has Been Submitted
                    </div>
                    <div className='text-start'>
                        <div
                            className='text-cmGrey900 mb-2'
                            style={{fontWeight: 700, fontSize: 14}}
                        >
                            Your comment
                        </div>
                        <div>
                            <CustomInput
                                type={INPUT_TYPE.textarea}
                                disabled={requestSubmitted}
                                value={comment}
                            />
                        </div>
                    </div>

                    <div className='mt-5'>
                        <CustomButton
                            buttonLabel='Back to Offer'
                            buttonSize={BUTTON_SIZE.large}
                            onClick={() => navigate(-1)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
