import React from 'react'
import {KTSVG} from '../../_metronic/helpers'
import CustomLoader from '../customLoader/CustomLoader'

const CustomDownloadIcon = ({id, onClick, svgClassName = 'w-35px h-35px', loading = false}) => {
    return (
        <>
            {loading ? (
                <i className='pi pi-spin pi-spinner' style={{fontSize: '1rem'}}></i>
            ) : (
                <div id={id}>
                    <KTSVG
                        path='/media/icons/duotune/art/DownloadIcon.svg'
                        className='cursor-pointer'
                        onClick={onClick}
                        svgClassName={svgClassName}
                    />
                </div>
            )}
        </>
    )
}

export default CustomDownloadIcon
// import React from 'react'
// import {KTSVG} from '../../_metronic/helpers'

// const CustomDownloadIcon = ({id, onClick, svgClassName = 'w-35px h-35px'}) => {
//     return (
//         <div id={id}>
//             <KTSVG
//                 path='/media/icons/duotune/art/DownLoad-btn.svg'
//                 className='cursor-pointer'
//                 onClick={onClick}
//                 svgClassName={svgClassName}
//             />
//         </div>
//     )
// }

// export default CustomDownloadIcon
