import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {fontsFamily} from '../../../../../assets/fonts/fonts'
import {KTSVG} from '../../../../../_metronic/helpers'
import CustomButton, {
    BUTTON_SIZE,
    BUTTON_TYPE,
} from '../../../../../customComponents/customButtton/CustomButton'
import CustomImage from '../../../../../customComponents/customImage/CustomImage'
import {
    deletePreHiringDocumentInSigningService,
    downloadSignedDocumentDocumentService,
    getAllDocumentsToSignService,
    getDocumentToUploadForSignService,
    getDownloadPathForUploadedDocumentService,
    getNewSequiDocDocumentAndOtherDetailsService,
    rejectOfferLetterService,
    uploadPreHiringDocumentInSigningService,
} from '../../../../../services/Services'
import CustomToast from '../../../../../customComponents/customToast/CustomToast'
import {IMAGE_TYPE, getErrorMessageFromResponse} from '../../../../../helpers/CommonHelpers'
import {useLocation, useNavigate} from 'react-router-dom'
import CustomDownloadIcon from '../../../../../customComponents/customIcons/CustomDownloadIcon'
import CustomDialog from '../../../../../customComponents/customDialog/CustomDialog'
import CustomEllipsis from '../../../../../customComponents/customEllipsis/CustomEllipsis'
import {v4 as uuidv4} from 'uuid'
import CustomCollapse from '../../../../../customComponents/customCollapse/CustomCollapse'
import {NEW_SEQUIDOC_DOCUMENT_STATUS, NEW_SEQUIDOC_FAQS} from '../../../../../constants/constants'
import CustomLink from '../../../../../customComponents/customButtton/CustomLink'
import axios from 'axios'
import {getCompanyProfileSelector} from '../../../../../redux/selectors/SettingsSelectors'
import {useSelector} from 'react-redux'
import CustomSkeleton from '../../../../../customComponents/customSkeleton/CustomSkeleton'
import CustomIterator from '../../../../../customComponents/customIterator/CustomIterator'
import CustomTooltip from '../../../../../customComponents/customTooltip/CustomTooltip'
import CustomLottie from '../../../../../customComponents/customLottie/CustomLottie'
import RejectAnimation from '../../../../../../src/assets/lottieAnimation/RejectAnimation.json'
import MetaTags from 'react-meta-tags'
import {isMobile} from 'react-device-detect'
import Show from '../../../../../customComponents/show/Show'

const SequiDocHomePage = () => {
    const companyData = useSelector(getCompanyProfileSelector)
    const [loading, setLoading] = useState(false)
    const [rejectLoading, setRejectLoading] = useState(false)
    const [allDopcumentData, setAllDocumentData] = useState([])
    const [allDopcumentToUploadData, setAllDocumentToUploadData] = useState([])
    const [documentAndUserStatus, setDocumentAndUserStatus] = useState(null)
    const location = useLocation()
    const navigate = useNavigate()
    const [envelopData, setEnvelopData] = useState(location?.state?.envelopData)
    const [faqVisibleIds, setFaqVisibleIds] = useState([])

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    }, [])

    useEffect(() => {
        if (location?.state?.envelopData) setEnvelopData(location?.state?.envelopData)
    }, [location?.state?.envelopData])

    useEffect(() => {
        if (location?.state?.envelopId) {
            getDocumentToUploadData()
            getAllDocumentsToSignData()
        }
    }, [location?.state?.envelopId])

    const getUserAndDocumentStatus = useCallback((id) => {
        const body = {
            signature_request_document_id: id,
        }
        getNewSequiDocDocumentAndOtherDetailsService(body)
            .then((res) => {
                setDocumentAndUserStatus(res?.data)
            })
            .catch(() => {})
            .finally(() => setLoading(false))
    }, [])

    const getAllDocumentsToSignData = useCallback(() => {
        const params = {
            envelope_id: location?.state?.envelopId,
        }
        setLoading(true)
        getAllDocumentsToSignService(params)
            .then((res) => {
                setAllDocumentData(res?.data)
                let offerLetterId = res?.data?.documents?.find(
                    (item) => item?.template_category_id == 1
                )
                getUserAndDocumentStatus(offerLetterId?.document_id)
            })
            .catch((err) => CustomToast.error(getErrorMessageFromResponse(err)))
    }, [getUserAndDocumentStatus, location?.state?.envelopId])

    const getDocumentToUploadData = useCallback(() => {
        getDocumentToUploadForSignService(location?.state?.envelopId)
            .then((res) => {
                setAllDocumentToUploadData(res?.data)
            })
            .catch((err) => CustomToast.error(getErrorMessageFromResponse(err)))
        // .finally(() => setLoading(false))
    }, [location?.state?.envelopId])

    const offerLetterData = useMemo(() => {
        return allDopcumentData?.documents?.find((item) => item?.template_category_id == 1)
    }, [allDopcumentData?.documents])

    const additionalDocumentList = useMemo(() => {
        return allDopcumentData?.documents?.filter((item) => item?.template_category_id != 1)
    }, [allDopcumentData?.documents])

    //calculate offer expiry date
    const offerExpiryTime = useMemo(() => {
        let expiry_date_time = allDopcumentData?.expiry_date_time
        let remainingTime = new Date(expiry_date_time) - new Date()

        let days = Math.floor(remainingTime / (1000 * 60 * 60 * 24))
        let hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))

        return {days: days >= 0 ? days : 0, hours: hours >= 0 ? hours : 0}
    }, [allDopcumentData?.expiry_date_time])

    //Reject offer letter api
    const rejectOfferLetter = () => {
        CustomDialog.warn('Are you sure you want to reject your offer letter ?', () => {
            setRejectLoading(true)
            const body = {
                signature_request_document_id: offerLetterData?.document_id,
            }
            rejectOfferLetterService(body)
                .then(() =>
                    navigate('/document-signing/offer-rejected', {
                        state: {isAuthenticated: location?.state?.isAuthenticated},
                    })
                )
                .catch((err) => CustomToast.error(getErrorMessageFromResponse(err)))
                .finally(() => {
                    setRejectLoading(false)
                })
        })
    }

    //Download document
    const downloadSignedDocument = (docId, name) => {
        const body = {
            document_id: docId,
        }

        downloadSignedDocumentDocumentService(body)
            .then((res) => {
                const fileName = `${name}.pdf`
                const filePath = res?.awsPath
                axios
                    .get(filePath, {
                        responseType: 'blob',
                        headers: {
                            'Content-Type': 'application/json',
                            Accept: 'application/pdf',
                        },
                    })
                    .then((response) => {
                        let url = window.URL.createObjectURL(response?.data)
                        let a = document.createElement('a')
                        a.href = url
                        a.download = fileName
                        a.click()
                        a.remove()
                    })
                    .catch(() => CustomToast('failed to download'))
            })
            .catch((err) => CustomToast.error(getErrorMessageFromResponse(err)))
            .finally(() => {})
    }

    const requiredDocumentsCount = useMemo(() => {
        let additionalDocRequired = additionalDocumentList?.filter(
            (item) => item?.is_sign_required_for_hire && !item?.status
        )?.length

        let docToUpload = allDopcumentToUploadData?.filter(
            (item) => item?.is_sign_required_for_hire && item?.upload_document_file?.length == 0
        )?.length
        return {additionalDocCount: additionalDocRequired, docToUpload: docToUpload}
    }, [additionalDocumentList, allDopcumentToUploadData])

    const isOfferLetterCompleted = useMemo(() => {
        let isOfferLetterSigned = offerLetterData?.status ? true : false
        let requiredAdditionalSigned = requiredDocumentsCount?.additionalDocCount == 0
        let requiredUploadDoc = requiredDocumentsCount?.docToUpload == 0

        let isNotCompleted = [
            isOfferLetterSigned,
            requiredAdditionalSigned,
            requiredUploadDoc,
        ]?.includes(false)

        return isNotCompleted ? false : true
    }, [
        offerLetterData?.status,
        requiredDocumentsCount?.additionalDocCount,
        requiredDocumentsCount?.docToUpload,
    ])
    const CompleteYourOfferCard = [
        <div>Start by reviewing the Offer Letter</div>,
        <div>
            Proceed to mandatory documents marked with an asterisk (
            <span className='text-cmError'>*</span>)
        </div>,
        <div>Upload required personal documents</div>,
        <div>Accept, Reject the offer or request changes if needed'</div>,
    ]

    const toggleFaqComponent = (id) => {
        let data = [...faqVisibleIds]
        const isExistInData = data.some((item) => item == id)
        if (isExistInData) data = data.filter((item) => item != id)
        else data.push(id)
        setFaqVisibleIds(data)
    }

    const FAQSection = () => (
        <div className='d-flex flex-column gap-8 '>
            {offerLetterData ? (
                <div
                    className='mw-500px min-h-250px bg-cmwhite shadow-sm py-6 ps-10 pe-5'
                    style={{borderRadius: '24px'}}
                >
                    <div
                        className='text-cmBlue-Crayola mb-1'
                        style={{fontSize: 19.42, lineHeight: '26.5px', fontWeight: 800}}
                    >
                        Complete Your Offer
                    </div>
                    <div
                        className='mb-5 text-cmGrey500'
                        style={{fontSize: '12px', fontWeight: 600}}
                    >
                        Follow the steps below
                    </div>
                    {/* body */}
                    <div className='text-cmGrey700 d-flex flex-column gap-3'>
                        {CompleteYourOfferCard.map((i, index) => (
                            <div className='d-flex gap-5 align-items-center'>
                                <KTSVG
                                    path='/media/icons/duotune/art/green-arrow.svg'
                                    svgClassName='w-20px h-20px'
                                />
                                <div
                                    className='text-cmGrey700'
                                    style={{fontSize: 14, fontWeight: 600}}
                                >
                                    {i}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : null}
            {/* Faq card starts */}
            <div
                className='mw-500px min-h-500px bg-cmwhite shadow-sm py-6 ps-10 pe-5'
                style={{borderRadius: '24px'}}
            >
                <div
                    className='text-cmGrey900 mb-5'
                    style={{fontSize: '20px', lineHeight: '27.35px', fontWeight: 800}}
                >
                    FAQ’s
                </div>
                {NEW_SEQUIDOC_FAQS.map((item) => (
                    <div className='d-flex gap-5 align-items-start border-bottom pb-5 border-2 p-5'>
                        <KTSVG
                            path={`/media/icons/duotune/art/${
                                faqVisibleIds.includes(item?.id)
                                    ? 'minus-square.svg'
                                    : 'plus-square.svg'
                            }`}
                            svgClassName='w-25px h-25px cursor-pointer'
                            onClick={() => toggleFaqComponent(item?.id)}
                        />
                        <div className=''>
                            <div
                                className='text-cmGrey900'
                                style={{fontWeight: 600, lineHeight: '19.12px'}}
                            >
                                {item?.heading}
                            </div>
                            <CustomCollapse toggle={faqVisibleIds.includes(item?.id)} timeout={300}>
                                <div
                                    className='text-cmGrey700 mt-2'
                                    style={{fontSize: 14, fontWeight: 600}}
                                >
                                    {item?.content}
                                </div>
                            </CustomCollapse>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )

    const blockViewCondition = useMemo(() => {
        return {
            offerExpired:
                offerLetterData &&
                allDopcumentData?.is_expired &&
                documentAndUserStatus &&
                documentAndUserStatus?.signature_request_document_data?.user_id_from != 'users',
            showOfferLetterBlock:
                offerLetterData?.status != NEW_SEQUIDOC_DOCUMENT_STATUS.rejected &&
                (documentAndUserStatus?.reviewAndUpload?.reviewAndUpload_status ||
                    !documentAndUserStatus),
        }
    }, [allDopcumentData?.is_expired, documentAndUserStatus, offerLetterData])

    return (
        <>
            <MetaTags>
                {isMobile ? (
                    <meta name='viewport' content='width=device-width, initial-scale=1.0' />
                ) : null}
            </MetaTags>
            <div
                className='mx-auto justify-content-center d-flex flex-wrap gap-8 my-sm-20 my-10 px-sm-0 px-5'
                style={{fontSize: '14px', fontFamily: fontsFamily.manrope}}
            >
                {!isMobile ? <FAQSection /> : null}

                {blockViewCondition?.showOfferLetterBlock ? (
                    <div
                        className='w-xxl-775px bg-cmwhite shadow-sm py-6 px-10'
                        style={{borderRadius: '24px', position: 'relative'}}
                    >
                        {/* <CustomLoader full visible={loading} /> */}

                        {offerLetterData &&
                        !isOfferLetterCompleted &&
                        documentAndUserStatus?.signature_request_document_data?.user_id_from !=
                            'users' ? (
                            <div
                                className='text-cmGrey900 d-flex align-items-start gap-3'
                                style={{fontSize: '12px', lineHeight: '24px', fontWeight: 600}}
                            >
                                <div>Offer Expires in</div>
                                <div>
                                    <div
                                        className='border border-cmGrey400 text-cmError d-flex flex-center min-w-30px h-25px border-2 mb-1'
                                        style={{borderRadius: '5px'}}
                                    >
                                        {offerExpiryTime?.days}
                                    </div>
                                    <div
                                        className='text-cmGrey600 text-center'
                                        style={{
                                            fontWeight: 600,
                                            fontSize: 8,
                                            lineHeight: '10.98px',
                                        }}
                                    >
                                        Days{' '}
                                    </div>
                                </div>
                                <div>
                                    <div
                                        className='border border-cmGrey400 text-cmError d-flex flex-center min-w-30px h-25px border-2 mb-1'
                                        style={{borderRadius: '5px'}}
                                    >
                                        {offerExpiryTime?.hours}
                                    </div>
                                    <div
                                        className='text-cmGrey600 text-center'
                                        style={{
                                            fontWeight: 600,
                                            fontSize: 8,
                                            lineHeight: '10.98px',
                                        }}
                                    >
                                        Hours
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        <div className='w-sm-85 mx-auto'>
                            <div className='mx-auto d-flex flex-center mb-5'>
                                <CustomImage
                                    type={IMAGE_TYPE.companyLogo}
                                    src={companyData?.company_logo_s3}
                                    className='w-55px h-55px'
                                />
                            </div>
                            {offerLetterData ? (
                                <>
                                    <div className='text-center mb-10'>
                                        {loading ? (
                                            <CustomSkeleton className=' rounded' />
                                        ) : (
                                            <div
                                                className='text-cmGrey900'
                                                style={{
                                                    fontSize: 16,
                                                    fontWeight: 700,
                                                    lineHeight: '24px',
                                                }}
                                            >
                                                Congratulations, {envelopData?.signer_name}
                                            </div>
                                        )}
                                    </div>
                                    {isOfferLetterCompleted ? <OfferUnderReviewBlock /> : null}
                                    {loading ? (
                                        <div className='mb-10'>
                                            {' '}
                                            <CustomSkeleton className='h-110px rounded' />
                                        </div>
                                    ) : offerLetterData ? (
                                        <div
                                            className='py-8 text-center'
                                            style={{borderRadius: 10, backgroundColor: '#F7F7F7'}}
                                        >
                                            <div className='w-sm-75 mx-auto'>
                                                {!offerLetterData?.status ? (
                                                    <div>
                                                        <div className='d-flex flex-center gap-5 mb-5'>
                                                            <div className=''>
                                                                <KTSVG
                                                                    path='/media/icons/duotune/art/OfferLetter.svg'
                                                                    svgClassName='h-50px w-50px cursor-pointer'
                                                                />
                                                            </div>
                                                            <div className='text-start'>
                                                                <div
                                                                    className='text-cmGrey900 '
                                                                    style={{
                                                                        fontSize: '18px',
                                                                        fontWeight: 700,
                                                                        lineHeight: '24px',
                                                                    }}
                                                                >
                                                                    Offer Letter
                                                                </div>
                                                                <div
                                                                    className='text-cmGrey700 text-decoration-underline'
                                                                    style={{
                                                                        fontSize: 14,
                                                                        fontWeight: 600,
                                                                    }}
                                                                >
                                                                    {offerLetterData?.template_name}
                                                                    .pdf
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {documentAndUserStatus
                                                            ?.signature_request_document_data
                                                            ?.document_response_status == 6 ? (
                                                            <div className='p-2'>
                                                                <span className='bi bi-exclamation-triangle text-cminfo'>
                                                                    You have requested change for
                                                                    your offer letter
                                                                </span>
                                                            </div>
                                                        ) : null}

                                                        <div className='d-flex flex-center flex-wrap gap-5'>
                                                            {documentAndUserStatus
                                                                ?.signature_request_document_data
                                                                ?.document_response_status != 6 ? (
                                                                <CustomButton
                                                                    buttonLabel='Review & Accept'
                                                                    buttonSize={BUTTON_SIZE.small}
                                                                    onClick={() =>
                                                                        navigate(
                                                                            '/document-signing/sign',
                                                                            {
                                                                                state: {
                                                                                    docId: offerLetterData?.document_id,
                                                                                    isOfferLetter: true,
                                                                                    envelopId:
                                                                                        location
                                                                                            ?.state
                                                                                            ?.envelopId,
                                                                                    envelopData:
                                                                                        location
                                                                                            ?.state
                                                                                            ?.envelopData,
                                                                                    isAuthenticated:
                                                                                        location
                                                                                            ?.state
                                                                                            ?.isAuthenticated,
                                                                                    offerLetterData:
                                                                                        offerLetterData,
                                                                                },
                                                                            }
                                                                        )
                                                                    }
                                                                />
                                                            ) : (
                                                                <div></div>
                                                            )}
                                                            {documentAndUserStatus
                                                                ?.signature_request_document_data
                                                                ?.user_id_from == 'users' ? (
                                                                <div></div>
                                                            ) : (
                                                                <>
                                                                    <CustomButton
                                                                        buttonLabel='Reject'
                                                                        onClick={rejectOfferLetter}
                                                                        buttonType={
                                                                            BUTTON_TYPE.error
                                                                        }
                                                                        buttonSize={
                                                                            BUTTON_SIZE.small
                                                                        }
                                                                        loading={rejectLoading}
                                                                    />
                                                                    <CustomButton
                                                                        buttonLabel='Request Change'
                                                                        onClick={() =>
                                                                            navigate(
                                                                                '/document-signing/request-change',
                                                                                {
                                                                                    state: {
                                                                                        docId: offerLetterData?.document_id,
                                                                                        isAuthenticated:
                                                                                            location
                                                                                                ?.state
                                                                                                ?.isAuthenticated,
                                                                                    },
                                                                                }
                                                                            )
                                                                        }
                                                                        buttonType={
                                                                            BUTTON_TYPE.secondary
                                                                        }
                                                                        buttonSize={
                                                                            BUTTON_SIZE.small
                                                                        }
                                                                    />
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className='d-flex align-items-center justify-content-between gap-3 flew-wrap  mb-5'>
                                                        <div className='d-flex flex-center gap-5'>
                                                            <div className=''>
                                                                <KTSVG
                                                                    path='/media/icons/duotune/art/OfferLetter.svg'
                                                                    svgClassName='h-50px w-50px cursor-pointer'
                                                                />
                                                            </div>
                                                            <div className='text-start'>
                                                                <div
                                                                    className='text-cmGrey900 '
                                                                    style={{
                                                                        fontSize: '18px',
                                                                        fontWeight: 700,
                                                                        lineHeight: '24px',
                                                                    }}
                                                                >
                                                                    Offer Letter
                                                                </div>
                                                                <div
                                                                    className='text-cmGrey700 text-decoration-underline'
                                                                    style={{
                                                                        fontSize: 14,
                                                                        fontWeight: 600,
                                                                    }}
                                                                >
                                                                    {offerLetterData?.template_name}
                                                                    .pdf
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex flex-center gap-5'>
                                                            <div
                                                                className='text-cmgreen'
                                                                style={{
                                                                    fontWeight: 700,
                                                                    fontSize: 14,
                                                                    lineHeight: '9.3px',
                                                                }}
                                                            >
                                                                <i class='fa-regular text-cmgreen fa-circle-check me-1'></i>
                                                                <span>Accepted</span>
                                                            </div>
                                                            <div>
                                                                <CustomDownloadIcon
                                                                    onClick={() =>
                                                                        downloadSignedDocument(
                                                                            offerLetterData?.document_id,
                                                                            offerLetterData?.template_name
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                </>
                            ) : (
                                <div></div>
                            )}
                            {/* ADDITIONAL DOCUMENTS */}
                            <div className='mb-0'>
                                {additionalDocumentList?.length > 0 ? (
                                    <>
                                        <div
                                            className='bg-cmYellow border border-cmYellow border-2 bg-opacity-10 p-5 py-2 mb-10  mt-10'
                                            style={{
                                                borderRadius: 10,
                                                fontSize: 12,
                                                fontWeight: 600,
                                                color: '#977800',
                                            }}
                                        >
                                            Documents marked with (
                                            <span className='text-cmError'>*</span>)are mandatory{' '}
                                            <br />{' '}
                                            {`This ${
                                                offerLetterData ? 'offer' : ''
                                            } is incomplete until you review and sign the
                            mandatory documents listed below.`}{' '}
                                        </div>
                                        {/* header */}
                                        <div className='d-flex justify-content-between align-itmes-center mb-2'>
                                            <div
                                                className='text-cmGrey500'
                                                style={{
                                                    fontSize: 12,
                                                    fontWeight: 600,
                                                    lineHeight: '16.3px',
                                                }}
                                            >
                                                ADDITIONAL DOCUMENTS (
                                                {additionalDocumentList?.length ?? '0'})
                                            </div>
                                            <div
                                                className='text-cmError'
                                                style={{
                                                    fontSize: 10,
                                                    fontWeight: 600,
                                                    lineHeight: '17px',
                                                }}
                                            >
                                                {requiredDocumentsCount?.additionalDocCount > 0
                                                    ? `${requiredDocumentsCount?.additionalDocCount} Remaining!`
                                                    : null}
                                            </div>
                                        </div>
                                        {/* body */}
                                        <div className='mb-5'>
                                            {loading ? (
                                                <div className='d-flex flex-column gap-3 justify-content-center'>
                                                    <CustomIterator count={3}>
                                                        <CustomSkeleton
                                                            className={'w-100 h-sm-100px h-30px'}
                                                            borderRadius={10}
                                                        />{' '}
                                                    </CustomIterator>
                                                </div>
                                            ) : additionalDocumentList?.length > 0 ? (
                                                additionalDocumentList?.map((item) => (
                                                    <div
                                                        className='d-flex gap-5 py-4 ps-sm-5 pe-sm-10 ps-5 pe-10 flex-wrap justify-content-between mb-5 align-items-center'
                                                        style={{
                                                            fontSize: 14,
                                                            fontWeight: 600,
                                                            backgroundColor: '#F7F7F7',
                                                            borderRadius: 10,
                                                        }}
                                                    >
                                                        <div className=''>
                                                            {item?.status ? (
                                                                <KTSVG
                                                                    path='/media/icons/duotune/art/sequiDocSuccess.svg'
                                                                    svgClassName='w-13px h-13px me-2'
                                                                />
                                                            ) : item?.is_sign_required_for_hire ? (
                                                                <KTSVG
                                                                    path='/media/icons/duotune/art/red-star.svg'
                                                                    svgClassName='w-10px h-10px me-2'
                                                                />
                                                            ) : (
                                                                <span></span>
                                                            )}

                                                            {item?.template_name}
                                                            {item?.is_pdf ? (
                                                                <span
                                                                    className='ms-2 badge px-3 rounded-pill text-cmError bg-cmError bg-opacity-10'
                                                                    style={{
                                                                        fontSize: '10px',
                                                                        fontWeight: '600',
                                                                    }}
                                                                >
                                                                    {'PDF'}
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                        {item?.status ? (
                                                            <CustomDownloadIcon
                                                                onClick={() =>
                                                                    downloadSignedDocument(
                                                                        item?.document_id,
                                                                        item?.template_name
                                                                    )
                                                                }
                                                                svgClassName='w-30px h-30px'
                                                            />
                                                        ) : (
                                                            <CustomButton
                                                                buttonLabel='Review & Sign'
                                                                onClick={() =>
                                                                    navigate(
                                                                        '/document-signing/sign',
                                                                        {
                                                                            state: {
                                                                                docId: item?.document_id,
                                                                                envelopId:
                                                                                    location?.state
                                                                                        ?.envelopId,
                                                                                isOfferLetter:
                                                                                    item?.is_pdf
                                                                                        ? false
                                                                                        : true,
                                                                                envelopData:
                                                                                    location?.state
                                                                                        ?.envelopData,
                                                                                isAuthenticated:
                                                                                    location?.state
                                                                                        ?.isAuthenticated,
                                                                                offerLetterData:
                                                                                    offerLetterData,
                                                                            },
                                                                        }
                                                                    )
                                                                }
                                                                buttonType={BUTTON_TYPE.secondary}
                                                                buttonSize={BUTTON_SIZE.small}
                                                            />
                                                        )}
                                                    </div>
                                                ))
                                            ) : (
                                                <div
                                                    className='text-center py-4 ps-sm-5 pe-sm-20 ps-5 pe-10'
                                                    style={{
                                                        fontSize: 12,
                                                        fontWeight: 600,
                                                        backgroundColor: '#F7F7F7',
                                                        borderRadius: 10,
                                                    }}
                                                >
                                                    No Additional Documents
                                                </div>
                                            )}
                                        </div>
                                    </>
                                ) : null}
                            </div>
                            {/* DOCUMENTS TO UPLOAD (1)*/}
                            <div>
                                {allDopcumentToUploadData?.length > 0 ? (
                                    <>
                                        {/* header */}
                                        <div className='d-flex justify-content-between align-itmes-center mb-2'>
                                            <div
                                                className='text-cmGrey500'
                                                style={{
                                                    fontSize: 12,
                                                    fontWeight: 600,
                                                    lineHeight: '16.3px',
                                                }}
                                            >
                                                DOCUMENTS TO UPLOAD (
                                                {allDopcumentToUploadData?.length ?? '0'})
                                            </div>
                                            <div
                                                className='text-cmError'
                                                style={{
                                                    fontSize: 10,
                                                    fontWeight: 600,
                                                    lineHeight: '17px',
                                                }}
                                            >
                                                {requiredDocumentsCount?.docToUpload > 0
                                                    ? `${requiredDocumentsCount?.docToUpload} Remaining!`
                                                    : null}
                                            </div>
                                        </div>
                                        {/* body */}

                                        <div className=''>
                                            {loading ? (
                                                <div className='d-flex flex-column gap-3 justify-content-center'>
                                                    <CustomIterator count={2}>
                                                        <CustomSkeleton
                                                            className={'w-100 h-sm-100px h-30px'}
                                                            borderRadius={10}
                                                        />{' '}
                                                    </CustomIterator>
                                                </div>
                                            ) : allDopcumentToUploadData?.length > 0 ? (
                                                allDopcumentToUploadData?.map((item) => (
                                                    <div>
                                                        <div
                                                            className='d-flex align-items-start gap-5 py-4 ps-5 pe-sm-8 p-5 flex-wrap justify-content-between mb-5'
                                                            style={{
                                                                fontSize: 14,
                                                                fontWeight: 600,
                                                                backgroundColor: '#F7F7F7',
                                                                borderRadius: 10,
                                                            }}
                                                        >
                                                            <EachDocTypeRow
                                                                item={item}
                                                                getDocumentToUploadData={
                                                                    getDocumentToUploadData
                                                                }
                                                                isOfferLetterCompleted={
                                                                    isOfferLetterCompleted
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <div
                                                    className='text-center py-4 ps-sm-5 pe-sm-20 ps-5 pe-10'
                                                    style={{
                                                        fontSize: 12,
                                                        fontWeight: 600,
                                                        backgroundColor: '#F7F7F7',
                                                        borderRadius: 10,
                                                    }}
                                                >
                                                    No Documents to upload
                                                </div>
                                            )}
                                        </div>
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </div>
                ) : blockViewCondition?.offerExpired ? (
                    <OfferExpiredBlock />
                ) : (
                    <div
                        className='w-xxl-775px bg-cmwhite shadow-sm py-6 px-10'
                        style={{borderRadius: '24px', position: 'relative'}}
                    >
                        {loading ? (
                            <CustomSkeleton className={'h-700px'} />
                        ) : (
                            <div className='position-relative'>
                                <div className=''>
                                    <KTSVG
                                        path='/media/icons/duotune/art/BlueEllipse.svg'
                                        svgClassName='w-100'
                                        svgStyle={{width: '100%', height: 'auto'}}
                                    />
                                </div>
                                <div
                                    className={`bg-cmwhite shadow w-500px mx-auto mt-20`}
                                    style={{borderRadius: 10, borderRadius: 10}}
                                >
                                    <div className='w-sm-75 h-100 mx-auto text-center pt-15 pb-5'>
                                        <div className=''>
                                            <KTSVG
                                                path='/media/icons/duotune/art/DoneCheck.svg'
                                                svgClassName='w-auto '
                                                svgStyle={{width: '120px', height: '120px'}}
                                            />
                                        </div>
                                        <div
                                            className='text-cmGrey900'
                                            style={{
                                                fontSize: 33,
                                                fontWeight: 700,
                                                lineHeight: '45px',
                                            }}
                                        >
                                            Offer Rejected
                                        </div>
                                    </div>
                                    <div
                                        className='bg-cmGrey100 py-10'
                                        style={{borderRadius: '0px 0px 10px 10px'}}
                                    >
                                        <div className='w-sm-75 mx-auto text-center d-flex flex-column gap-5'>
                                            {offerLetterData?.status ==
                                            NEW_SEQUIDOC_DOCUMENT_STATUS.rejected ? (
                                                <div
                                                    className='text-cmGrey700'
                                                    style={{
                                                        fontSize: 14,
                                                        fontWeight: 500,
                                                        lineHeight: '22px',
                                                    }}
                                                >
                                                    You have Rejected the offer. <br /> Contact{' '}
                                                    <CustomLink label={'info@sequifi.com'} /> if
                                                    this was an error!
                                                </div>
                                            ) : (
                                                <div
                                                    className='text-cmGrey700'
                                                    style={{
                                                        fontSize: 14,
                                                        fontWeight: 500,
                                                        lineHeight: '22px',
                                                    }}
                                                >
                                                    You offer has been Rejected by admin. <br />{' '}
                                                    Contact{' '}
                                                    <CustomLink label={'info@sequifi.com'} /> if
                                                    this was an error!
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {isMobile ? <FAQSection /> : null}
            </div>
        </>
    )
}
const EachDocTypeRow = ({item, getDocumentToUploadData, isOfferLetterCompleted}) => {
    const documentFilePickerRef = useRef(null)
    const [unUploadedDocument, setUnUploadedDocument] = useState([])
    const [uploadedDocument, setUploadedDocument] = useState(item?.upload_document_file)
    const [isUploadEnabled, setIsUploadEnabled] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)

    useEffect(() => {
        setUploadedDocument(item?.upload_document_file ?? [])
    }, [item])

    const uploadDocuments = useCallback(
        (e) => {
            if (e?.target?.files?.length > 0) {
                const isUploadingState = unUploadedDocument?.some(
                    (docItem) => docItem?.status == 'uploading'
                )
                const filesData = [...e?.target?.files]?.map((fileItem, fileIndex) => ({
                    id: uuidv4(),
                    status:
                        isUploadingState || (!isUploadingState && fileIndex > 0)
                            ? 'pending'
                            : 'uploading',
                    document: fileItem?.name,
                    progress: 0,
                    file: fileItem,
                }))
                setUnUploadedDocument((val) => [...val, ...filesData])
            }
        },
        [unUploadedDocument]
    )

    const currentUploadDocument = useMemo(() => {
        return unUploadedDocument?.length > 0
            ? unUploadedDocument.find((docItem) => docItem?.status == 'uploading')
            : null
    }, [unUploadedDocument])

    // useEffect(() => {
    //     if (currentUploadDocument) {
    //         const docIndex = unUploadedDocument?.findIndex(
    //             (docItem) => docItem?.id == currentUploadDocument?.id
    //         )
    //         let formData = new FormData()
    //         formData.append('document_id', item?.id)

    //         formData.append('document_file[]', currentUploadDocument?.file)
    //         uploadPreHiringDocumentInSigningService(formData, (uploadProgress) => {
    //             const data = [...unUploadedDocument]
    //             data[docIndex].progress = uploadProgress?.completed
    //             setUnUploadedDocument(data)
    //         }).then((res) => {
    //             const data = [...unUploadedDocument]
    //             const uploadedData = [...uploadedDocument]
    //             data[docIndex].status = 'uploaded'
    //             if (docIndex < unUploadedDocument?.length - 1) {
    //                 data[docIndex + 1].status = 'uploading'
    //             }
    //             uploadedData.push({
    //                 ...currentUploadDocument,
    //                 ...res,
    //             })
    //             setUploadedDocument(uploadedData)
    //             setUnUploadedDocument(data)
    //             getDocumentToUploadData()
    //         })
    //     }
    // }, [currentUploadDocument])

    const onDownloadDocument = useCallback((item) => {
        const body = {
            document_file_path: item?.document_file_path,
        }
        getDownloadPathForUploadedDocumentService(body)
            .then((res) => {
                let file = item?.document_file_path?.replace(/\/([^/]+)\.\w+$/, '$1')
                const fileName = `${file}.pdf`
                const filePath = res?.data
                axios
                    .get(filePath, {
                        responseType: 'blob',
                        headers: {
                            'Content-Type': 'application/json',
                            Accept: 'application/pdf',
                        },
                    })
                    .then((response) => {
                        let url = window.URL.createObjectURL(response?.data)
                        let a = document.createElement('a')
                        a.href = url
                        a.download = fileName
                        a.click()
                        a.remove()
                    })
                    .catch(() => CustomToast.error('failed to download'))
            })
            .catch(() => {})
    }, [])

    const deleteUploadedDocument = (docIndex) => {
        CustomDialog.warn('Are you sure you want to delete ?', () => {
            const tempData = [...uploadedDocument]
            const itemToBeDelete = uploadedDocument?.[docIndex]?.id

            const finalData = tempData?.filter((tempItem, tempIndex) => tempIndex != docIndex)
            setUploadedDocument(finalData)
            deletePreHiringDocumentInSigningService(itemToBeDelete)
                .then(() => {
                    getDocumentToUploadData()
                })
                .catch(() => {})
        })
    }

    const onSubmitUploadedDocument = useCallback(() => {
        const uploadDocument = (index = 0) => {
            setSubmitLoading(true)
            const docIndex = index
            let currentDoc = unUploadedDocument?.[index]

            let formData = new FormData()
            formData.append('document_id', item?.id)

            formData.append('document_file[]', currentDoc?.file)
            uploadPreHiringDocumentInSigningService(formData, (uploadProgress) => {
                const data = [...unUploadedDocument]
                data[docIndex].progress = uploadProgress?.completed
                setUnUploadedDocument(data)
            })
                .then((res) => {
                    const data = [...unUploadedDocument]
                    const uploadedData = [...uploadedDocument]
                    data[docIndex].status = 'uploaded'
                    if (docIndex < unUploadedDocument?.length - 1) {
                        data[docIndex + 1].status = 'uploading'
                    }
                    uploadedData.push({
                        ...currentUploadDocument,
                        ...res,
                    })
                    setUploadedDocument(uploadedData)
                    setUnUploadedDocument(data)
                    if (unUploadedDocument?.length != index + 1) uploadDocument(index + 1)
                    if (unUploadedDocument?.length == index + 1) {
                        setIsUploadEnabled(!isUploadEnabled)
                        setSubmitLoading(false)
                        getDocumentToUploadData()
                    }
                })
                .catch(() => {})
        }
        if (unUploadedDocument?.length > 0) uploadDocument(0)
    }, [
        currentUploadDocument,
        getDocumentToUploadData,
        isUploadEnabled,
        item?.id,
        unUploadedDocument,
        uploadedDocument,
    ])

    const hanldeEnableDisableUpload = () => {
        setIsUploadEnabled(!isUploadEnabled)
    }

    const deleteUnUploadedDocument = (docIndex) => {
        const tempData = [...unUploadedDocument]
        const finalData = tempData?.filter((tempItem, tempIndex) => tempIndex != docIndex)
        setUnUploadedDocument(finalData)
    }
    return (
        <>
            <div className=''>
                {uploadedDocument?.length > 0 ? (
                    <KTSVG
                        path='/media/icons/duotune/art/sequiDocSuccess.svg'
                        svgClassName='w-13px h-13px me-2'
                    />
                ) : item?.is_sign_required_for_hire ? (
                    <KTSVG
                        path='/media/icons/duotune/art/red-star.svg'
                        svgClassName='w-10px h-10px me-2'
                    />
                ) : (
                    <span></span>
                )}
                {item?.description}
            </div>
            <div className='d-flex gap-5  align-items-start'>
                <div>
                    {uploadedDocument?.length > 0 && !isUploadEnabled
                        ? uploadedDocument?.map((doc, docIndex) => (
                              <EachDocRow
                                  doc={doc}
                                  deleteDocument={() => deleteUploadedDocument(docIndex)}
                                  key={doc?.document}
                                  onDownloadDocument={(docItem) => onDownloadDocument(docItem)}
                                  isOfferLetterCompleted={isOfferLetterCompleted}
                              />
                          ))
                        : null}
                    {unUploadedDocument?.length > 0 || isUploadEnabled
                        ? unUploadedDocument
                              ?.filter((docItem) => docItem?.status != 'uploaded')
                              .map((doc, docIndex) => (
                                  <EachDocRow
                                      type={'unuploaded'}
                                      doc={doc}
                                      isOfferLetterCompleted={isOfferLetterCompleted}
                                      deleteUnUploadedDocument={() =>
                                          deleteUnUploadedDocument(docIndex)
                                      }
                                  />
                              ))
                        : null}{' '}
                </div>
                <div className='d-flex gap-2 align-items-center text-cmBlue-Crayola flex-wrap'>
                    {/* {!isOfferLetterCompleted ? (
                        <span
                            className='cursor-pointer'
                            onClick={() => documentFilePickerRef?.current?.click()}
                        >
                            Upload
                        </span>
                    ) : (
                        <CustomTooltip
                            title={`If you want to upload this document, you need to go to your profile and upload it`}
                        >
                            <i
                                className='bi bi-exclamation-circle ms-2 text-cmOrange'
                                style={{
                                    fontSize: 15,
                                }}
                            />
                        </CustomTooltip>
                    )}{' '} */}
                    {item?.upload_document_file?.length > 0 && !isUploadEnabled ? null : (
                        <CustomButton
                            buttonLabel='Upload'
                            buttonType={BUTTON_TYPE.primary}
                            onClick={() => {
                                setIsUploadEnabled(true)
                                documentFilePickerRef?.current?.click()
                            }}
                            buttonSize={'h-sm-30px min-w-sm-85px'}
                        />
                    )}
                    {isUploadEnabled ? (
                        unUploadedDocument?.length > 0 ? (
                            <CustomButton
                                buttonLabel='Submit'
                                buttonType={BUTTON_TYPE.primary}
                                onClick={onSubmitUploadedDocument}
                                buttonSize={'h-sm-30px min-w-sm-85px'}
                                loading={submitLoading}
                            />
                        ) : (
                            <CustomButton
                                buttonLabel='Cancel'
                                buttonType={BUTTON_TYPE.secondary}
                                onClick={hanldeEnableDisableUpload}
                                buttonSize={'h-sm-30px min-w-sm-85px'}
                            />
                        )
                    ) : null}
                </div>
            </div>

            <input
                type='file'
                ref={documentFilePickerRef}
                style={{display: 'none'}}
                required
                multiple
                onChange={uploadDocuments}
                name={'image'}
                accept='.xlsx,.xls,.heic,.png,.jpeg,.jpg,.doc, .docx,.ppt, .pptx,.txt,.pdf'
            />
        </>
    )
}
const EachDocRow = ({
    doc,
    deleteDocument = () => {},
    type = 'uploaded',
    onDownloadDocument,
    isOfferLetterCompleted,
    deleteUnUploadedDocument,
}) => {
    // function downloadDoc(file) {
    //     const fileName = file?.document?.split('/')?.[1]
    //     saveAs(getServerImage(file?.document), fileName)
    // }

    return (
        <div
            key={doc?.document}
            className='mb-2 d-flex gap-2'
            style={{
                overflow: 'hidden',
                // borderRadius: 5,
                position: 'relative',
            }}
        >
            {type == 'unuploaded' && doc?.status != 'uploaded' ? (
                <div
                    style={{
                        width: `${doc?.progress ?? 0}%`,
                        position: 'absolute',
                        backgroundColor: 'rgba(0,0,0, 0.5)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                    }}
                >
                    <span style={{color: 'white', marginRight: 10}}>{doc?.progress}%</span>
                </div>
            ) : null}
            <div className='d-flex flex-center'>
                <span className='me-2 d-flex align-items-center'>
                    {type == 'unuploaded' ? (
                        <i
                            className='pi pi-trash text-cmError cursor-pointer'
                            onClick={deleteUnUploadedDocument}
                        ></i>
                    ) : null}
                    {type == 'uploaded' ? (
                        <span className='ms-2 cursor-pointer'>
                            <CustomDownloadIcon
                                svgClassName='w-20px h-20px'
                                onClick={() => onDownloadDocument(doc)}
                            />
                        </span>
                    ) : null}
                </span>
                <CustomEllipsis
                    style={{
                        whiteSpace: 'nowrap',
                        fontSize: '12px',
                        width: type == 'unuploaded' ? '150px' : '100',
                        lineHeight: '17px',
                        fontWeight: 700,
                    }}
                    text={
                        type == 'unuploaded' && doc?.status != 'uploaded'
                            ? doc?.progress
                            : doc?.document
                    }
                >
                    <span
                        className='text-Grey700  p-2 cursor-pointer'
                        // onClick={() => downloadDoc(doc)}
                    >
                        <span
                            className='cursor-pointer text-decoration-underline'
                            // onClick={() => downloadDoc(doc)}
                        >
                            {doc?.document_file_path ?? doc?.document}
                        </span>
                    </span>
                </CustomEllipsis>
            </div>
        </div>
    )
}

const OfferUnderReviewBlock = () => {
    return (
        <div className='mx-auto'>
            <div className='d-flex flex-center gap-2 mb-5'>
                <KTSVG
                    path='/media/icons/duotune/art/DoneCheck.svg'
                    svgClassName='w-50px h-50px'
                    svgStyle={{width: 100, height: 100}}
                />
                <div
                    className='text-cmGrey900'
                    style={{fontSize: 22, fontWeight: 700, lineHeight: '30px'}}
                >
                    Your Offer is under Review
                </div>
            </div>
            <div
                className='text-cmGrey700 mb-10 text-center'
                style={{fontSize: 14, fontWeight: 500, lineHeight: '24px'}}
            >
                All mandatory documents have been signed and successfully submitted. <br /> Our
                representative will contact you shortly. <br />
                You can now close this page.
            </div>
        </div>
    )
}
const OfferExpiredBlock = () => {
    return (
        <div
            className='w-xxl-775px bg-cmwhite shadow-sm py-6 px-10'
            style={{borderRadius: '24px', position: 'relative'}}
        >
            <div className='position-relative'>
                <div className=''>
                    <KTSVG
                        path='/media/icons/duotune/art/BlueEllipse.svg'
                        svgClassName='w-100'
                        svgStyle={{width: '100%', height: 'auto'}}
                    />
                </div>
                <div
                    className={`bg-cmwhite shadow w-500px mx-auto mt-20`}
                    style={{borderRadius: 10, borderRadius: 10}}
                >
                    <div className='w-sm-75 h-100 mx-auto text-center pt-15 pb-5'>
                        <div className='d-flex flex-center mb-5'>
                            <CustomLottie lottieJson={RejectAnimation} height={100} width={100} />
                        </div>
                        <div
                            className='text-cmGrey900'
                            style={{fontSize: 33, fontWeight: 700, lineHeight: '45px'}}
                        >
                            Offer Expired
                        </div>
                    </div>
                    <div className='bg-cmGrey100 py-10' style={{borderRadius: '0px 0px 10px 10px'}}>
                        <div className='w-sm-75 mx-auto text-center d-flex flex-column gap-5'>
                            <div
                                className='text-cmGrey700'
                                style={{
                                    fontSize: 14,
                                    fontWeight: 500,
                                    lineHeight: '22px',
                                }}
                            >
                                You offer has been Expired. <br /> Contact{' '}
                                <CustomLink label={'info@sequifi.com'} /> if this was an error!
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SequiDocHomePage
